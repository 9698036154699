import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBUserInput, KBButton } from 'components'

const templates = [
  {
    id: 1,
    name: '样式一'
  },
  {
    id: 2,
    name: '样式二'
  },
  {
    id: 3,
    name: '样式三'
  }
]
const template_urls = {
  1: 'https://media-ssl.kuban.io/static/ent/webbadge_1.png',
  2: 'https://media-ssl.kuban.io/static/ent/webbadge_2.png',
  3: 'https://media-ssl.kuban.io/static/ent/webbadge_3.png'
}

var VisitTypeEditBadgeForm = React.createClass({
  saveTemplate(model) {
    const { updateCurrentType, currentVisitType } = this.props
    let newVisitType = Object.assign({}, currentVisitType)
    newVisitType.badge.badge_template = model.badge_template
    return updateCurrentType(newVisitType)
  },

  render() {
    const {
      fields: { badge_template },
      error,
      submitting,
      handleSubmit
    } = this.props
    let selectedTemplate =
      templates &&
      templates.filter(temp => {
        return temp.id == badge_template.value
      })

    return (
      <form onSubmit={handleSubmit(this.saveTemplate)}>
        <div className="kb-form-container visit-setting-block">
          <div className="title-container" style={{ marginBottom: 0 }}>
            <div style={{ width: '100%' }}>
              <KBFormError error={error} />
              <div className="title-and-des">
                <div className="title">访客标签</div>
                <div className="des">选择访客标签模板</div>
              </div>
              <div className="kb-row" style={{ marginTop: 50 }}>
                <div style={{ marginBottom: 5 }}>选择模板</div>
                <KBUserInput
                  placeholder="选择模板"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={badge_template}
                  multi={false}
                  users={templates}
                  selectedUsers={selectedTemplate}
                />
              </div>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                保存
              </KBButton>
            </div>

            <img
              className="template-img"
              src={
                template_urls[badge_template.value] ||
                'https://media-ssl.kuban.io/que.png'
              }
              alt=""
            />
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    currentVisitType: { badge }
  } = props

  return {
    initialValues: {
      badge_template: (badge && badge.badge_template) || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitTypeEditBadgeForm',
  fields: ['badge_template'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitTypeEditBadgeForm)
