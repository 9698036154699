import React from 'react'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { KBButton, KBPopover } from 'components'
import { getCrmSources } from 'app/selectors'
import { crmSourcesActions } from 'app/actions'
import { OPPORTUNITIES_SOURCE_OBJ } from 'app/constants'
import SpacesCrmSourcesForm from './SpacesCrmSourcesForm'

var SpacesCrmSources = React.createClass({
  createCrmSource() {
    KBPopover.show(<SpacesCrmSourcesForm />)
  },
  updateCrmSource(source) {
    KBPopover.show(
      <SpacesCrmSourcesForm
        initialValues={source}
        type="update"
        source={source}
      />
    )
  },
  componentDidMount() {
    const { getCrmSourcesActions, sources } = this.props
    // CRM 来源
    api.getCrmSources().then(
      json => {
        getCrmSourcesActions.success(json)
      },
      error => {
        getCrmSourcesActions.failure(error)
      }
    )
  },
  deleteCrmSource(source) {
    const { deleteCrmSourceActions } = this.props
    KBPopover.plugins.confirm(
      '删除渠道来源',
      '是否要删除渠道来源 ' + source.name + ' !',
      {
        confirm: () => {
          api.deleteCrmSource(source.id).then(json => {
            deleteCrmSourceActions.success(json, {
              id: source.id,
              key: 'crmSources'
            })
            KBPopover.close()
          })
        }
      }
    )
  },
  render() {
    const { sources } = this.props
    return (
      <div>
        <table className="content-table edit-table">
          <thead>
            <tr>
              <th>渠道名称</th>
              <th>分类</th>
              <th>分成比例</th>
              <th className="t-right" style={{ paddingRight: 40 }}>
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {sources &&
              sources.map((source, index) => {
                return (
                  <tr key={index}>
                    <td>{source.name}</td>
                    <td>{OPPORTUNITIES_SOURCE_OBJ[source.source_type]}</td>
                    <td>
                      {source.allotment
                        ? `${Math.floor(source.allotment * 1000000) / 10000} %`
                        : '无分成'}
                    </td>
                    {!source.space_id ? (
                      <td />
                    ) : (
                      <td className="t-right">
                        <i
                          className="iconfont icon-edit"
                          onClick={this.updateCrmSource.bind(null, source)}
                        />
                        <i
                          className="iconfont icon-delete"
                          style={{ marginLeft: 10 }}
                          onClick={this.deleteCrmSource.bind(null, source)}
                        />
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>
        <button
          className="bordered-btn"
          onClick={this.createCrmSource}
          style={{ marginLeft: 0, marginTop: 30 }}
        >
          新增渠道来源
        </button>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let sources = getCrmSources(state)

  return {
    sources
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCrmSourceActions: bindActionCreators(
      crmSourcesActions.delete,
      dispatch
    ),
    getCrmSourcesActions: bindActionCreators(crmSourcesActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpacesCrmSources)
