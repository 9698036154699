import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'

var LocationSettingRepairForm = React.createClass({
  setLocation(model) {
    const { updateLocation } = this.props
    const { repair_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'repair',
          range: 'all',
          user_ids: repair_ids ? repair_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { repair_ids },
      error,
      submitting,
      handleSubmit,
      users,
      repairManager
    } = this.props
    const hint = FIX_LOCATION

    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <div className="kb-row">
            <KBUserInput
              title="物业负责人"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.repair}
              field={repair_ids}
              multi={true}
              users={users}
              selectedUsers={repairManager}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation
  const manager = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'location_manager'
  )
  let repairManager = selectors.getTeamRolesOfLocation(state, loc_id, 'repair')

  if (repairManager.length == 0) {
    repairManager = manager
  }
  return {
    updateLocation,
    users,
    repairManager
  }
}

const formConfig = {
  form: 'LocationSettingRepairForm',
  fields: ['repair_ids'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(LocationSettingRepairForm)
