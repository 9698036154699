import React, { Component, PropTypes } from 'react'
import StatisticOrgMemberCount from './org/StatisticOrgMemberCount'
import StatisticActiveMemberCount from './org/StatisticActiveMemberCount'
import StatisticOrgMemberStatistic from './org/StatisticOrgMemberStatistic'
import moment from 'moment'

/**
 * 公司统计box
 * @type {[type]}
 */
var StatisticOrgBox = React.createClass({
  displayName: 'StatisticMeetingBox',

  formatStatisticToArray(obj, date_type) {
    if (!obj) {
      return []
    }
    let keys = Object.keys(obj)
    let values = Object.values(obj)
    let newArr = []
    keys.map((key, index) => {
      let singleValue = {}
      let date = ''
      let formatedDateStr = String(key)
        .replace(/(.{6})/, '$1-')
        .replace(/(.{4})/, '$1-')
      if (date_type === 'month') {
        date = moment(formatedDateStr).month() + 1 + '月'
      } else if (date_type === 'day') {
        date = moment(formatedDateStr).date() + '日'
      } else {
        date = formatedDateStr
      }
      singleValue.formated_date = date
      singleValue.value = values[index]
      singleValue.date = formatedDateStr
      newArr.push(singleValue)
      return key
    })
    return newArr
  },

  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticActiveMemberCount
            formatStatisticToArray={this.formatStatisticToArray}
          />
          <StatisticOrgMemberCount group_id={this.props.group_id} />
          <StatisticOrgMemberStatistic group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticOrgBox
