import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import {
  locationGroupsAction,
  groupsActions,
  getCitiesAction,
  getProvincesAction
} from 'app/actions'
import { KBButton, KBPopover } from 'components'
import { Radio, Button, message } from 'antd'
import { ANNOUNCEMENTS_TYPE } from 'app/constants'
import * as selectors from 'app/selectors'
import _, { rest } from 'lodash'
import {
  arrayToObject,
  objectToArray,
  groupLocationsByLocationGroup
} from 'utils/kbUtil'
import config from 'app/config'
import GroupSetBannerForm from './GroupSetBannerForm'

var GroupSetBanner = React.createClass({
  getInitialState() {
    return {
      bannerList: [],
      type: 'announcement',
      client_type:'bridgego',
      activityList: [],
      valueAddedServicesList: []
    }
  },

  componentDidMount() {
    const { groups_id } = this.props
    api.getBridgegoList({ location_group_id: groups_id }).then(res => {
      const { json = [] } = res
      const list = json.map(x => ({ id: x.id, name: x.title }))
      list.unshift({ id: '', name: '请选择活动' })
      this.setState({ activityList: list })
    })
    api.getValueAddedServicesList({
        location_group_id: groups_id,
      }).then(res => {
        const { json = [] } = res
        const list = json.map(x => ({ id: x.id, name: x.name }))
        list.unshift({ id: '', name: '请选择增值服务' })
        this.setState({ valueAddedServicesList: list })
      })
    this.getList()
  },

  getList() {
    const { groups_id } = this.props
    const { type } = this.state
    api
      .getAnnouncements({
        location_group_id: groups_id,
        client_type:'bridgego',
        announcement_type: type,
        page: 1,
        per_page: 1000
      })
      .then(json => {
        const data = _.get(json, 'response.body') || []
        this.setState({ bannerList: data })
      })
  },

  changeType(e) {
    this.setState({ type: e.target.value }, () => {
      this.getList()
    })
  },

  addBanner() {
    const { groups_id } = this.props
    const { activityList, valueAddedServicesList } = this.state
    KBPopover.showForm(
      <GroupSetBannerForm
        type="create"
        activityList={activityList}
        valueAddedServicesList={valueAddedServicesList}
        groups_id={groups_id}
        getList={this.getList}
      />
    )
  },

  editBanner(item) {
    const { groups_id } = this.props
    const { activityList, valueAddedServicesList } = this.state
    KBPopover.showForm(
      <GroupSetBannerForm
        type="edit"
        activityList={activityList}
        valueAddedServicesList={valueAddedServicesList}
        groups_id={groups_id}
        item={item}
        getList={this.getList}
      />
    )
  },

  delBanner(id) {
    api.deleteAnnouncements(id).then(res => {
      this.getList()
    })
  },

  AnnouncementJsx() {
    const { bannerList } = this.state
    return bannerList.map((item, index) => {
      return (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
            backgroundColor: '#FAFBFC',
            border: '1px solid #DFE2E5',
            borderRadius: 4,
            marginBottom: 20
          }}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <img
              src={item.image}
              style={{ width: 112, height: 105, borderRadius: 4 }}
              alt=""
            />
            <div style={{ marginLeft: 20 }}>
              <span
                style={{
                  padding: '5px 10px',
                  color: '#18A5D6',
                  border: '1px solid #18A5D6',
                  borderRadius: 4,
                  fontSize: 12,
                  fontWeight: 600
                }}
              >
                {ANNOUNCEMENTS_TYPE[item.announcement_type]}
              </span>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#354052',
                  marginTop: 10
                }}
              >
                {item.title}
              </div>
            </div>
          </div>
          {!item.is_top&&<div style={{ alignSelf: 'flex-end', minWidth: 160 }}>
            <KBButton
              className="c-btn-red"
              onClick={() => this.delBanner(item.id)}
            >
              删除
            </KBButton>
            <KBButton
              className="bordered-btn"
              onClick={() => this.editBanner(item)}
            >
              编辑
            </KBButton>
          </div>}
          {!item.is_top&&<div
            style={{
              position: 'absolute',
              top: 10,
              right: 20
            }}
          >
            {(index !== 0&&(bannerList[index-1]&&!bannerList[index-1].is_top)) && (
              <Button
                icon="arrow-up"
                size="small"
                onClick={() => this.upItem(item.id, index)}
              >
                上移
              </Button>
            )}
            {index !== bannerList.length - 1 && (
              <Button
                icon="arrow-down"
                size="small"
                style={{ marginLeft: 10 }}
                onClick={() => this.downItem(item.id, index)}
              >
                下移
              </Button>
            )}
          </div>}
        </div>
      )
    })
  },

  sortArr(index, type) {
    const { bannerList } = this.state
    const arr = [...bannerList]
    let list = []
    if (type === 'up') {
      if (index === 1) {
        const end = arr.slice(2)
        list = [arr[1], arr[0], ...end]
      } else {
        const s = arr.slice(0, index - 1)
        const m1 = arr.slice(index - 1, index)
        const m2 = arr.slice(index, index + 1)
        const e = arr.slice(index + 1)
        list = [...s, ...m2, ...m1, ...e]
      }
    }
    if (type === 'down') {
      const len = bannerList.length
      if (index === len - 2) {
        const start = arr.slice(0, index)
        list = [...start, arr[len - 1], arr[len - 2]]
      } else {
        const s = arr.slice(0, index)
        const m1 = arr.slice(index, index + 1)
        const m2 = arr.slice(index + 1, index + 2)
        const e = arr.slice(index + 2)
        list = [...s, ...m2, ...m1, ...e]
      }
    }
    return list
  },

  upItem(id, index) {
    if (index == 0) {
      message.warning('已经到顶了！')
      return
    }
    const {bannerList} = this.state
    if(bannerList[index-1]&&bannerList[index-1].is_top){
      return message.warning('无法对强推项目排序')
    }
    const list = this.sortArr(index, 'up')
    const newList = list.map(x => x.id)
    this.setState({ bannerList: list })
    this.sortAnnouncements({ ids: newList })
  },
  downItem(id, index) {
    const { bannerList = [] } = this.state
    if (index == bannerList.length - 1) {
      message.warning('已经到底了！')
      return
    }
    const list = this.sortArr(index, 'down')
    const newList = list.map(x => x.id)
    this.setState({ bannerList: list })
    this.sortAnnouncements({ ids: newList })
  },

  sortAnnouncements(params) {
    api.sortAnnouncements(params).then(res => {
      this.getList()
    })
  },
  StationAnnouncementJsx() {
    const { bannerList } = this.state
    return bannerList.map((item, index) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
            backgroundColor: '#FAFBFC',
            border: '1px solid #DFE2E5',
            borderRadius: 4,
            marginBottom: 20
          }}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <div style={{ marginLeft: 20 }}>
              <span
                style={{
                  padding: '5px 10px',
                  color: '#18A5D6',
                  border: '1px solid #18A5D6',
                  borderRadius: 4,
                  fontSize: 12,
                  fontWeight: 600
                }}
              >
                {ANNOUNCEMENTS_TYPE[item.announcement_type]}
              </span>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#354052',
                  marginTop: 10
                }}
              >
                {item.title}
              </div>
            </div>
          </div>
          <div style={{ minWidth: 160}}>
            <KBButton
              className="c-btn-red"
              onClick={() => this.delBanner(item.id)}
            >
              删除
            </KBButton>
            <KBButton
              className="bordered-btn"
              onClick={() => this.editBanner(item)}
            >
              编辑
            </KBButton>
          </div>
        </div>
      )
    })
  },

  render() {
    const { bannerList, type } = this.state
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div
            className="nav-section-header-title"
            style={{ position: 'relative' }}
          >
            <span>小程序广告及公告设置</span>
            <KBButton
              className="bordered-btn"
              style={{ position: 'absolute', right: 0 }}
              onClick={this.addBanner}
            >
              添加广告及公告
            </KBButton>
          </div>
        </header>
        <div className="kb-form-container">
          <div style={{ marginBottom: '30px' }}>
            <Radio.Group
              value={type}
              buttonStyle="solid"
              onChange={this.changeType}
            >
              <Radio.Button value="announcement">广告</Radio.Button>
              <Radio.Button value="station_announcement">公告</Radio.Button>
            </Radio.Group>
          </div>
          {type === 'station_announcement'
            ? this.StationAnnouncementJsx()
            : this.AnnouncementJsx()}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { id } = props.params
  return {
    groups_id: id
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'GroupSetBanner',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

GroupSetBanner = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBanner)

export default GroupSetBanner
