import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBInput } from 'components'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import * as selectors from 'app/selectors'
import { api } from 'app/services'

var LocationSetLockPermission = React.createClass({
  displayName: 'LocationSetLockPermission',

  getInitialState() {
    return {
      // company: {}
    }
  },

  componentDidMount() {
    // const { loc_id } = this.props
    // api.getOrganizationsWithoutSchemas({
    //   location_id: loc_id,
    //   temporary_auto_auth: true,
    //   connect: true
    // }).then(res => {
    //   if(res.json && res.json.length) {
    //     this.setState({
    //       company: res.json[0]
    //     })
    //   }
    // })
  },

  updateLocationSettings(model) {
    const { updateLocationSetting } = this.props
    return updateLocationSetting(
      {
        lock_settings: model
      },
      {
        key: ['lock_settings']
      }
    )
  },

  render() {
    const {
      fields: { advance_assign_lock_permission_day, temporary_company_lock_permission_day },
      submitting,
      handleSubmit
    } = this.props

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>门禁设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateLocationSettings)}>
            <KBFormError error={this.props.error} />
            <div className="kb-form-container" style={{ padding: 0 }}>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="合同开始前多少天给予门禁权限和租户管理员添加员工的权限"
                  field={advance_assign_lock_permission_day}
                  type="number"
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="临时租户公司员工有效期(天)"
                  field={temporary_company_lock_permission_day}
                  type="number"
                />
              </div>

              {/* <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="临时租户公司"
                  field={{value: this.state.company.name}}
                  disabled
                />
              </div> */}

              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  let lock_settings = locationSetting.lock_settings || {}

  let locationLockSettings = Object.assign({}, lock_settings)
  const { advance_assign_lock_permission_day, temporary_company_lock_permission_day } = locationLockSettings

  return {
    loc_id,
    initialValues: {
      advance_assign_lock_permission_day,
      temporary_company_lock_permission_day,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  const { advance_assign_lock_permission_day, temporary_company_lock_permission_day } = values

  if (valid.required(advance_assign_lock_permission_day)) {
    errors.advance_assign_lock_permission_day = '请输入天数'
  } else if (
    valid.integer(advance_assign_lock_permission_day) ||
    advance_assign_lock_permission_day < 0
  ) {
    errors.advance_assign_lock_permission_day = '请输入正整数'
  }

  if (valid.required(temporary_company_lock_permission_day)) {
    errors.temporary_company_lock_permission_day = '请输入天数'
  } else if (
    valid.integer(temporary_company_lock_permission_day) ||
    temporary_company_lock_permission_day < 0
  ) {
    errors.temporary_company_lock_permission_day = '请输入正整数'
  }

  return errors
}

const formConfig = {
  form: 'LocationSetLockPermission',
  fields: ['advance_assign_lock_permission_day', 'temporary_company_lock_permission_day'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetLockPermission)
