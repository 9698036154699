import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBPopoverTop } from 'components'
import GroupSetAccountHuifuForm from './GroupSetAccountHuifuForm'
import { KBNoData } from 'components'
import { api } from 'app/services'
import { notification } from 'antd'

var GroupSetAccountHuifu = React.createClass({
  displayName: 'GroupSetAccountHuifu',
  getInitialState() {
    return {
      list: []
    }
  },
  async componentDidMount() {
    const { groups_id } = this.props
    const res = await api.getMerchants({ location_group_id: groups_id })
    const { json } = res
    this.setState({ list: json })
  },

  addProviderField(item) {
    return {
      name: item.name,
      hui_mer_cus_id: item.hui_mer_cus_id,
      hui_mer_acct_id: item.hui_mer_acct_id
    }
  },

  changeList(value, type, index) {
    const { groups_id } = this.props
    const { list } = this.state
    let newList = [...list]
    if (type === 'add') {
      newList.push(value)
    } else if (type === 'edit') {
      newList = newList.map((item, idx) => {
        if (idx === index) {
          item = { ...value }
        }
        return item
      })
    } else {
      newList = newList.filter((_, idx) => idx !== index)
    }
    this.setState({ list: newList })
  },

  addLocationProvider() {
    const { groups_id } = this.props
    KBPopoverTop.show(
      <GroupSetAccountHuifuForm type="add" callBack={this.changeList} />
    )
  },

  updateLocationProvider(group, index) {
    this.setState({ currentIndex: index }, () => {
      KBPopoverTop.show(
        <GroupSetAccountHuifuForm
          type="edit"
          group={group}
          index={index}
          callBack={this.changeList}
        />
      )
    })
  },

  updateLocationProviders(model) {
    const { groups_id } = this.props
    const { list } = this.state
    const merchants = list.map(item=>{
      delete item.modified
      return item
    })
    api.updateMerchants({ merchants, location_group_id: groups_id }).then(res=>{
      const { json } = res
      if(json&&Array.isArray(json)){
        this.setState({list:json})
        notification.success({message:'保存成功', duration:1.5})
      }else{
        notification.error({message:'保存失败', duration:1.5})
      }
    })
  },

  render() {
    const {
      fields: { group },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { list } = this.state
    return (
      <form onSubmit={handleSubmit(this.updateLocationProviders)}>
        <KBFormError err={error} />
        <fieldset className="fieldset">
          <div
            className="kb-title m-bottom-sm"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>收款账户信息(汇付)</span>
            <div className="bordered-btn" onClick={this.addLocationProvider}>
              添加账户信息
            </div>
          </div>
          {(list || []).length > 0 ? (
            <div>
              {list.map((item, index) => {
                return (
                  <div className="location-provider-container" key={index}>
                    {item.modified ? (
                      <div className="tip-msg">
                        <div className="icon">
                          <i className="iconfont icon-hint" />
                        </div>
                        <div>
                          请点击下方“保存”按钮以确认保存当前收款账户信息
                        </div>
                      </div>
                    ) : null}
                    <div
                      key={index}
                      className="location-provider"
                      style={item.modified?{ border: '1px dashed #DFE4E7' }:{}}
                    >
                      <span
                        className="operate-provider delete-provider"
                        onClick={() => {
                          this.changeList(item, 'del', index)
                        }}
                      >
                        <i className="iconfont icon-delete" />
                      </span>
                      <span
                        className="operate-provider update-provider"
                        onClick={this.updateLocationProvider.bind(
                          null,
                          item,
                          index
                        )}
                      >
                        <i className="iconfont icon-edit" />
                      </span>
                      <div className="provider-line">
                        <div>
                          <span>名称</span>
                          <span>{item.name || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>商户客户号</span>
                          <span>{item.hui_mer_cus_id || '暂无'}</span>
                        </div>
                        <div>
                          <span>商户账户号</span>
                          <span>{item.hui_mer_acct_id || '暂无'}</span>
                        </div>
                      </div>


                      <div className="provider-line">
                        <div>
                          <span>银行开户名</span>
                          <span>{item.account_name || '暂无'}</span>
                        </div>
                        <div>
                          <span>公司银行账号</span>
                          <span>{item.account_no || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>开户行支行</span>
                          <span>{item.bank_name || '暂无'}</span>
                        </div>
                        <div>
                          <span>支行银联号</span>
                          <span>{item.bank_no || '暂无'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <KBNoData tipMessage="暂无账户信息" />
          )}
        </fieldset>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}

  return errors
}

function mapStateToProps(state, props) {
  return {}
}

const formConfig = {
  form: 'GroupSetAccountHuifu',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(GroupSetAccountHuifu)
