import React from 'react'
import SpaceSettingLockersForm from './SpaceSettingLockersForm'

var SpaceSettingLockers = React.createClass({
  displayName: 'SpaceSettingMeeting',
  render() {
    const { updateLocationSetting, setting } = this.props
    if (!setting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>储物柜设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            {/* <legend>租期时限</legend> */}
            <SpaceSettingLockersForm
              setting={setting}
              updateLocationSetting={updateLocationSetting}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpaceSettingLockers
