import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { api, apiUtils } from 'app/services'
import { apiCallFailure, successState } from 'app/actions'
import { isNull } from 'utils/validate'

var SpacePayFlow = React.createClass({
  getInitialState() {
    const { isWxAtlMode } = this.props
    return {
      slideState: false,
      paySlideState: isWxAtlMode,
      app_id: '',
      app_secret: '',
      isUpdateWechat: false
    }
  },

  componentDidMount() {
    // /const { isWxAtlMode } = this.props
    this.setIdAndSecret(this.props)
  },

  setSettingError(error) {
    const { apiCallFailureActions } = this.props
    let message = (error && error._error && error._error.message) || ''
    apiCallFailureActions({
      status: 'error',
      message: message
    })
  },

  componentWillReceiveProps(nextProps) {
    if (!this.state.status) {
      this.setIdAndSecret(nextProps)
    }
  },

  setIdAndSecret(nextProps, currentBeeCloudSetting) {
    var beeCloudSetting = currentBeeCloudSetting || nextProps.beeCloudSetting
    const app_id = beeCloudSetting ? beeCloudSetting.app_id : ''
    const app_secret = beeCloudSetting ? beeCloudSetting.app_secret : ''
    const status = beeCloudSetting ? beeCloudSetting.state : ''

    this.setState({
      app_id,
      app_secret,
      status,
      slideState: status && status == 'active' ? true : false
    })
  },

  changeSlide() {
    if (this.state.slideState) {
      api.updateBeeCloudSettingState({ state: 'off' }).then(({ json }) => {
        this.setIdAndSecret(null, json.beecloud_setting)
      })
      return
    }

    api.updateBeeCloudSettingState({ state: 'init' }).then(({ json }) => {
      this.setIdAndSecret(null, json.beecloud_setting)
    })
  },

  saveBeeCloud() {
    const beeCloudKey = this.refs.beeCloudKey.value
    const beeCloudApi = this.refs.beeCloudApi.value
    const { apiCallFailureActions } = this.props

    if (isNull(beeCloudKey) || isNull(beeCloudApi)) {
      apiCallFailureActions({
        status: 'error',
        message: 'API KEY 或者 API SECRET 不能为空!'
      })
      return
    }

    this.updateBeeCloudSettingApi(beeCloudKey, beeCloudApi)
  },

  updateBeeCloudSettingApi(key, apiStr) {
    const beeCloudKey = key
    const beeCloudApi = apiStr

    api
      .beeCloudSetting({
        app_id: beeCloudKey,
        app_secret: beeCloudApi
      })
      .then(
        ({ json }) => {
          this.setIdAndSecret(null, json.beecloud_setting)
        },
        error => {}
      )
  },

  beeCloudInitJsx() {
    const { beeCloudSetting } = this.props
    const status = this.state.status

    if (status) {
      return ''
    }

    return (
      <div>
        <div className="kb-title">
          <span>网银支付</span>
        </div>
        <div className="kb-title_num m-top-sm">
          <span>①&nbsp;开通支付</span>
        </div>
        <div style={{ padding: 20 }}>
          开通支付使得账单可以直接通过网银支付完成。点击我们的支付支持伙伴
          <a
            className="bordered-btn"
            href="http://www.BeeCloud.cn"
            target="_blank"
            style={{ marginRight: 18 }}
          >
            BeeCloud.cn
          </a>
          完成注册手续。
        </div>
        <div className="kb-title_num">
          <span>②&nbsp;图文流程</span>
        </div>
        <div className="flow-img">
          <p>
            <a
              style={{ color: '#2ea1f8' }}
              href="https://media-ssl.kuban.io/files/help/BeeCloud%E8%AF%B4%E6%98%8E%E6%96%87%E7%A8%BF.docx"
              download="帮助文档下载"
            >
              BeeCloud帮助文档下载
            </a>
          </p>
        </div>
        <div className="kb-title_num">
          <span>③&nbsp;BeeCloudAPI设置</span>
        </div>
        <div style={{ padding: 20 }}>
          <ul className="region-input-box">
            <li>
              <div className="kb-row">
                <div className="kb-form-group kb-form-210 kb-form-alone">
                  <label>API&nbsp;KEY</label>
                  <div>
                    <input type="text" className="kb-input" ref="beeCloudKey" />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="kb-row">
                <div className="kb-form-group kb-form-210 kb-form-alone">
                  <label>API&nbsp;SECRET</label>
                  <div>
                    <input type="text" className="kb-input" ref="beeCloudApi" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="t-right">
            <button className="certain-btn" onClick={this.saveBeeCloud}>
              保存
            </button>
          </div>
        </div>
      </div>
    )
  },

  beeCloudActiveAuditJsx() {
    const { beeCloudSetting } = this.props
    const { slideState } = this.state
    const status = this.state.status

    if (status != 'init') {
      return ''
    }

    return (
      <div>
        <div className="kb-title">
          <span>支付已提交审核</span>
        </div>
        <div style={{ padding: 20 }}>
          您的空间支付设置正在审核中，请等待审核信息。
        </div>
      </div>
    )
  },

  updateBeeCloudSetting() {
    const { app_id, app_secret } = this.state
    const { apiCallFailureActions } = this.props

    if (isNull(app_id) || isNull(app_secret)) {
      apiCallFailureActions({
        status: 'error',
        message: 'API KEY 或者 API SECRET 不能为空!'
      })
      return
    }

    this.updateBeeCloudSettingApi(app_id, app_secret)
  },

  setAppId(e) {
    this.setState({
      app_id: e.target.value
    })
  },

  setAppSecret(e) {
    this.setState({
      app_secret: e.target.value
    })
  },

  beeCloudActiveJsx() {
    const { slideState } = this.state
    const status = this.state.status

    if (status != 'active' && status != 'off') {
      return ''
    }

    const { app_id, app_secret } = this.state

    return (
      <div>
        <div className="kb-title">
          <span>支付已开通</span>
        </div>
        <div style={{ padding: 20 }}>
          <div
            className="slide-btn d-iblock"
            onClick={this.changeSlide}
            style={{ verticalAlign: 'middle' }}
          >
            <span
              className={`slide-bg ${
                slideState ? 'slide-left' : 'slide-right'
              }`}
              style={{ marginTop: 3 }}
            >
              <span className="slide-move" />
            </span>
          </div>
          <span className="m-left-sm color-nine">
            打开支付功能,入住会员和公司可以通过网银直接支付账单到您指定的银行帐户中。
          </span>
          {slideState ? (
            <div>
              <ul className="region-input-box m-top-sm">
                <li>
                  <div className="kb-row">
                    <div className="kb-form-group kb-form-210 kb-form-alone">
                      <label>API&nbsp;KEY</label>
                      <div>
                        <input
                          type="text"
                          className="kb-input"
                          value={app_id}
                          onChange={this.setAppId}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="kb-row">
                    <div className="kb-form-group kb-form-210 kb-form-alone">
                      <label>API&nbsp;SECRET</label>
                      <div>
                        <input
                          type="password"
                          className="kb-input"
                          value={app_secret}
                          onChange={this.setAppSecret}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="t-right">
                <button
                  className="certain-btn"
                  onClick={this.updateBeeCloudSetting}
                >
                  修改
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  },

  setPayMode() {
    const { updatePayMode } = this.props
    const { paySlideState } = this.state
    let isPaySlide = !paySlideState
    let isWxAtl = isPaySlide ? 'wx_atl' : ''
    this.setState({
      paySlideState: isPaySlide
    })
    updatePayMode && updatePayMode(isWxAtl)
  },

  render() {
    return (
      <div>
        {this.beeCloudInitJsx()}
        {this.beeCloudActiveJsx()}
        {this.beeCloudActiveAuditJsx()}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const beeCloudSetting = selectors.getBeeCloudSetting(state)
  const setting = props.setting || {}
  const { pay_mode } = setting
  let isWxAtlMode = pay_mode && pay_mode == 'wx_atl'
  return {
    beeCloudSetting,
    isWxAtlMode
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successStateAction: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpacePayFlow)
