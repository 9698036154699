import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import * as actions from 'app/actions'
import { successState, apiCallFailure } from 'app/actions'
import {
  KBLoadingContainer,
  KBButton,
  KBTable,
  KBRangeDate,
  KBSearchSelect,
  KBPagination,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import * as constant from 'app/constants'
import { canAccessLocation } from 'app/reducers/role'
import { Tabs, Icon, Typography, Row, Col } from 'antd'
import { getInitialDate } from 'utils/kbData'
import { formatMinuteSecondEN, paginate } from 'utils/kbUtil'

const TYPES = [
  { value: 'property', label: '物业服务' },
  { value: 'business', label: '商务服务' }
]

var ValueAddedIntention = React.createClass({
  displayName: 'ValueAddedIntention',

  getInitialState() {
    return {
      filters: {
        value_added_service_id: '',
        tag: '',
        start_at: '',
        end_at: '',
        keyword: ''
      },
      loading: false,
      dataSource: [],
      paginate: null,
      valueAddedServicesList: []
    }
  },

  componentDidMount() {
    this._loadData()
    this.getValueAddedServicesList()
    this.getValueAddedServiceTagList()
  },

  componentWillReceiveProps(nextProps) {
    const nextPage = nextProps.location.query.page
    const currentPage = this.props.location.query.page
    if (nextPage !== currentPage) {
      this._loadData(nextPage)
    }
  },

  getValueAddedServicesList() {
    const { loc_id } = this.props
    api
      .getValueAddedServicesList({
        location_group_id: loc_id
      })
      .then(({ json }) => {
        this.setState({
          valueAddedServicesList: json.map(i => ({
            ...i,
            label: i.name,
            value: i.id
          }))
        })
      })
  },

  getValueAddedServiceTagList() {
    const { loc_id } = this.props
    api
      .getValueAddedServiceTagList({
        location_group_id: loc_id
      })
      .then(({ json }) => {
        this.setState({
          valueAddedServiceTagList: json.map(i => ({ label: i, value: i }))
        })
      })
  },

  _loadData(currentPage = 1) {
    const { apiCallFailureAction, loc_id } = this.props
    const { filters } = this.state
    const params = Object.assign(
      { page: currentPage || 1, per_page: 20 },
      loc_id ? { location_group_id: loc_id } : {},
      {
        ...filters,
        value_added_service_id: filters.value_added_service_id || undefined,
        tag: filters.tag || undefined,
        keyword: filters.keyword || undefined
      }
    )

    this.setState({ loading: true })
    api
      .getValueAddedServiceApplies(params)
      .then(
        ({ json, response }) => {
          this.setState({
            dataSource: json,
            pagination: paginate(response)
          })
        },
        errors => {
          apiCallFailureAction({
            status: 'error',
            message: errors.message
          })
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })
    this.setState({ filters: newFilters })

    setTimeout(this._loadData)
  },

  onSearchValChange(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        keyword: e.target.value
      }
    })
  },

  changeUpdateRange(rage) {
    let applyDateRange = {}
    applyDateRange.start_at = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    applyDateRange.end_at = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    applyDateRange.start_at &&
      applyDateRange.end_at &&
      this._setFilter(applyDateRange)
  },

  clearSearch() {
    this.setState({ filters: {} }, () => {
      this._loadData()
    })
  },

  exportData() {
    const { loc_id } = this.props
    api
      .exportValueAddedServiceApplies({
        location_group_id: loc_id
      })
      .then(res => {
        const { json: { result } = {} } = res
        if (result.code && result.code === 200) {
          KBPopoverTop.show(
            <KBToReportCenter title="会员导出" message={result.msg} />
          )
        }
      })
  },

  render() {
    const { loc_id } = this.props
    const {
      filters,
      dataSource,
      pagination,
      valueAddedServicesList,
      valueAddedServiceTagList
    } = this.state
    const { tag, start_at, end_at, keyword, value_added_service_id } = filters
    const showClearBtn = Object.values(filters).some(i => i !== '')

    return (
      <section
        className={
          loc_id ? 'kb-content-container' : 'nav-section-container f-right'
        }
      >
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>意向管理</span>
            </div>
            <div className="f-right">
              <KBButton className="bordered-btn" onClick={this.exportData}>
                导出Excel
              </KBButton>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div style={{ marginTop: '20px' }}>
            <KBSearchSelect
              options={valueAddedServicesList}
              value={value_added_service_id}
              placeholder="服务名称"
              onChange={value =>
                this._setFilter({ value_added_service_id: value })
              }
            />
            <KBSearchSelect
              options={valueAddedServiceTagList}
              value={tag}
              placeholder="服务项目"
              style={{
                marginLeft: 20
              }}
              onChange={value => this._setFilter({ tag: value })}
            />
            <KBRangeDate
              from={start_at || ''}
              to={end_at || ''}
              title="意向提交起止范围"
              callback={this.changeUpdateRange}
              leftNone={false}
            />
            <div
              className="add-staff-component"
              style={{
                width: '350px',
                display: 'inline-block',
                marginLeft: 20
              }}
            >
              <input
                type="text"
                placeholder="请输入手机号或备注"
                value={keyword || ''}
                onChange={this.onSearchValChange}
              />
              <div className="currency-symbol" onClick={() => this._loadData()}>
                搜索
              </div>
            </div>
            {showClearBtn && (
              <KBButton type="clear" onClick={this.clearSearch} />
            )}
          </div>

          <KBTable
            columns={[
              {
                title: '姓名',
                key: 'username',
                render(rowItem) {
                  return rowItem.user.name
                }
              },
              {
                title: '电话',
                key: 'phone',
                render(rowItem) {
                  return rowItem.user.secure_phone_num
                }
              },
              {
                title: '服务名称',
                key: 'name',
                render(rowItem) {
                  return rowItem.value_added_service.name
                }
              },
              {
                title: '服务项目',
                key: 'tag_list',
                render(rowItem) {
                  return rowItem.tag_list.join('，')
                }
              },
              { title: '需求备注', key: 'remark' },
              {
                title: '提交意向时间',
                key: 'created_at',
                render(rowItem) {
                  return formatMinuteSecondEN(rowItem.created_at)
                }
              }
            ]}
            dataSource={dataSource}
          />

          <KBPagination
            pagination={pagination}
            template={`/admin/groups/${this.props.loc_id}/valueAddedServices/intention?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  const loc_id = params.id

  return {
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValueAddedIntention)
