import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBASNumberInput, KBButton } from 'components'

var VisitTypeEditNdaForm = React.createClass({
  saveTemplate(model) {
    const { updateCurrentType, currentVisitType } = this.props
    let newVisitType = Object.assign({}, currentVisitType)
    newVisitType.nda.resign_after = model.resign_after
    return updateCurrentType(newVisitType)
  },

  render() {
    const {
      fields: { resign_after },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.saveTemplate)}>
        <div className="kb-form-container visit-setting-block">
          <KBFormError error={error} />
          <div className="title-container">
            <div className="title-and-des">
              <div className="title">多少天后访客需要重新部署保密协议</div>
            </div>
            <KBASNumberInput
              field={resign_after}
              type="number"
              maxValue="100"
            />
          </div>
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    currentVisitType: { nda }
  } = props

  return {
    initialValues: {
      resign_after: (nda && nda.resign_after) || 0
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitTypeEditNdaForm',
  fields: ['resign_after'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitTypeEditNdaForm)
