import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect } from 'components'
import { MERGE_DEPOSIT } from 'app/constants'
import * as selectors from 'app/selectors'

var SpaceSettingMergeDepositForm = React.createClass({
  displayName: 'SpaceSettingMergeDepositForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    model.merge_deposit_into_renting =
      model.merge_deposit_into_renting == 'true' ? true : false
    updateCurrentSpace(
      {
        settings: JSON.stringify({
          subscription_settings: model
        })
      },
      {
        key: 'subscription_settings'
      }
    )
  },
  render() {
    const {
      fields: { merge_deposit_into_renting },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="租赁方式"
              field={merge_deposit_into_renting}
              arrayData={MERGE_DEPOSIT}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let merge_deposit_into_renting =
    setting.subscription_settings.merge_deposit_into_renting

  return {
    initialValues: {
      merge_deposit_into_renting
    }
  }
}

const formConfig = {
  form: 'SpaceSettingMergeDepositForm',
  fields: ['merge_deposit_into_renting'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(SpaceSettingMergeDepositForm)
