import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput, KBPopover } from 'components'
import * as selectors from 'app/selectors'
import { api } from 'app/services'
import { orgActions } from 'app/actions'

var SelectOrganizationForm = React.createClass({
  getInitialState() {
    return {
      location_members: this.props.location_members || []
    }
  },

  componentDidMount() {
    const { orgs } = this.props

    if (orgs.length === 0) {
      this.getOrgs()
    }
  },

  getOrgs() {
    const { loc_id, getOrganizationsAction } = this.props

    api
      .getOrganizations({
        location_id: loc_id,
        per_page: 1000,
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  selectOrg(model) {
    const { callback } = this.props
    callback && callback(model.org_id)
    KBPopover.close()
    return true
  },
  render() {
    const {
      fields: { org_id },
      error,
      submitting,
      handleSubmit,
      hideOther
    } = this.props
    const { orgs } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    return (
      <form onSubmit={handleSubmit(this.selectOrg)}>
        {hideOther ? (
          ''
        ) : (
          <header className="kb-form-header">
            <h2 className="header-title">选择公司</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
        )}
        <KBFormError error={this.props.error} />
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBUserInput
              title="分店公司"
              placeholder={'选择公司'}
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={org_id}
              multi={false}
              users={orgs}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const id = props.loc_id
  let orgs = selectors.getOrgsOfLocation(state, id) || []
  orgs = orgs.filter(o => o.organization_type === 'normal' && !o.is_archived)

  return {
    orgs
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.org_id)) {
    errors.org_id = '请选择公司'
  }

  return errors
}

SelectOrganizationForm.PropTypes = {
  loc_id: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired
}

const formConfig = {
  form: 'SelectOrganizationForm',
  fields: ['org_id'],
  validate: validate,
  touchOnBlur: false
}

SelectOrganizationForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SelectOrganizationForm)

export default SelectOrganizationForm
