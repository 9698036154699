import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBInput, KBPopover } from 'components'
import { POINT_EVENT } from 'app/constants/hint'
import _ from 'lodash'

var SpaceSettingPointForm = React.createClass({
  displayName: 'SpaceSettingPointForm',

  updateSpaceSetting(model) {
    model.time = Number(model.time)
    model.point = Number(model.point)

    const { updateCurrentSpace, eventType, pointSetting, action } = this.props
    const newPointSetting = _.cloneDeep(pointSetting)
    const index = newPointSetting[eventType].findIndex(j => j.action === action)
    newPointSetting[eventType][index] = model
    KBPopover.close()
    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          point_events_settings: {
            [eventType]: newPointSetting[eventType]
          }
        })
      },
      {
        key: 'point_events_settings'
      }
    )
  },

  render() {
    const {
      fields: { time, point },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpaceSetting)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">虚拟货币规则</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              type="number"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={time}
              title="发生次数"
              hint={POINT_EVENT.time}
            />
          </div>
          <div className="kb-row">
            <KBInput
              type="number"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={point}
              title="虚拟货币数"
              hint={POINT_EVENT.point}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { pointSetting, action, eventType } = props
  return {
    eventType,
    action,
    pointSetting
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'SpaceSettingPointForm',
  fields: ['action', 'time', 'point'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingPointForm)
