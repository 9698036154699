import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { STATISTIC_CYCLE, STATISTIC_MODE } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticCrmWinLocationExpectedRate = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate()
    return {
      filters: {
        from,
        to,
        cycle: 'weekly',
        mode: 'location'
      },
      data: [],
      month: ''
    }
  },

  componentDidMount() {
    const { filters, data } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')

    this.mounted = true
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: '项目数目'
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { from, to, cycle, mode } = filters
    var params = {}

    params.cycle = cycle
    params.mode = mode
    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.getCustomerWinOrFailedStateStatistic(params).then(({ json }) => {
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    let data = []

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    // 获取所有的项目集合
    var d = []
    // 所有项目类型的集合
    var type = []

    var time = {}

    var array = Object.values(json)

    array.forEach(json => {
      const name = json.name
      if (!name) {
        return
      }

      type.push(name)

      json.statistics &&
        json.statistics.forEach(t => {
          if (!t) {
            return
          }

          time[t.statistic_date] = t.statistic_date
          d.push({
            name,
            time: t.statistic_date,
            value: t.expected_win_count || 0
          })
        })
    })

    // 将type 转换成数组
    time = Object.values(time)

    time.sort(this.compare)

    type.forEach(json => {
      let series = { name: json, data: [] }
      time.forEach(tm => {
        let obj = d.find(t => t.name == json && t.time == tm)
        let number = obj ? obj.value : 0
        series.data.push(number)
      })
      data.push(series)
    })

    this.fillChart(data, time)
  },

  compare(a, b) {
    const atime = getYMD(a.statistic_date)
    const btime = getYMD(b.statistic_date)
    const aNumber = Date.UTC(atime.year, atime.month, atime.day)
    const bNumber = Date.UTC(btime.year, btime.month, btime.day)

    if (aNumber - bNumber > 0) {
      return 1
    } else if (aNumber - bNumber < 0) {
      return -1
    }

    return 0
  },

  fillChart(data, xUser) {
    this.setSerierDate(data)
    this.setCategories(xUser)
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render() {
    const { filters, data, month } = this.state
    const { from, to, cycle, mode } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>CRM分店赢单总预计人数比较</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCrmWinLocationExpectedRate)
