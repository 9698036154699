import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBButton,
  KBPopoverTop,
  KBRadio,
  KBCheckBox
} from 'components'
import { api } from 'app/services'
import { Icon, Cascader } from 'antd'
import { apiCallFailure } from 'app/actions'
import chinaRegion from 'app/constants/chinaRegion'
import { bindActionCreators } from 'redux'

const types = [
  { id: 'reservation', name: '会议室' },
  { id: 'desk_reservation', name: '工位' },
  { id: 'print_reservation', name: '打印' }
]

var GroupSetAccountHuifuForm = React.createClass({
  getInitialState() {
    return {
      classifyList: [],
      taxList: []
    }
  },

  createLocationProvider(model) {
    const { callBack, index, type, group, apiCallFailureAction } = this.props
    const { classifyList } = this.state
    const data = { ...model }

    // 税收分类信息验证
    if(!classifyList || !classifyList.length) {
      return apiCallFailureAction({
        status: 'error',
        title: '请添加税收分类'
      })
    }
    if (classifyList.some(item => !item.invoice_type)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请选择订单类型'
      })
    }
    if (classifyList.some(item => !item.xforceplus_tax_setting_id)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请选择税收分类'
      })
    }
    if(classifyList.some(item => item.xforceplus_tax_setting_id == 4 && !item.real_estate_place)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请选择不动产地址（省市区）'
      })
    }
    if(classifyList.some(item => item.xforceplus_tax_setting_id == 4 && !item.real_estate_address)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请输入不动产详细地址（例如**街**号）'
      })
    }
    if(classifyList.some(item => item.xforceplus_tax_setting_id == 4 && !item.real_estate_no)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请输入房屋产权证书/不动产权证号码'
      })
    }
    if(classifyList.some(item => item.xforceplus_tax_setting_id == 4 && !item.cross_city_sign)) {
      return apiCallFailureAction({
        status: 'error',
        title: '请选择不动产是否跨城市'
      })
    }

    data.invoicing_xforceplus_settings = classifyList.map(item => ({
      invoice_type: item.invoice_type,
      xforceplus_tax_setting_id: item.xforceplus_tax_setting_id,
      goods_tax_no: item.goods_tax_no,
      name: item.name,
      ...(item.real_estate_address ? {
        real_estate_place: item.real_estate_place.join('/'),
        real_estate_address: item.real_estate_address,
        real_estate_no: item.real_estate_no,
        cross_city_sign: item.cross_city_sign
        } : {})
    }))

    if (group && group.id) {
      data.id = group.id
    }

    delete data.main_invoice_type
    delete data.vote_type
    if(model.main_invoice_type === '1') {
      // 普通发票
      data.invoice_type = ['ce']
    } else {
      // 数电发票
      data.invoice_type = model.vote_type
    }

    if(!data.invoice_type.length) {
      return apiCallFailureAction({
        status: 'error',
        title: '请选择开票类型'
      })
    }
    console.log(data)
    // ce-增值税电子普票发票
    // qs-电子发票（数电增值税专用发票）
    // qc-电子发票（数电普通发票）
    // group.invoice_type

    callBack && callBack(data, type, index)

    // api.createMerchants(params ).then(res=>{
    //   console.log(res)
    // })
    KBPopoverTop.close()
  },

  addSetting() {
    this.setState({
      classifyList: [
        ...this.state.classifyList,
        {
          invoice_type: '',
          xforceplus_tax_setting_id: '',
          goods_tax_no: '',
        }
      ]
    })
  },

  delSetting(idx) {
    this.setState({
      classifyList: this.state.classifyList.filter((i, index) => index !== idx)
    })
  },

  selChange(event, idx, filed) {
    const { classifyList, taxList } = this.state
    const val = event.target.value
    const currentItem = classifyList[idx]
    currentItem[filed] = val
    if (filed === 'xforceplus_tax_setting_id') {
      if (val) {
        const item = taxList.find(i => i.id == val)
        currentItem['goods_tax_no'] = item.goods_tax_no
      } else {
        currentItem['goods_tax_no'] = ''
      }
      currentItem.real_estate_place = ''
      currentItem.real_estate_address = ''
      currentItem.real_estate_no = ''
      currentItem.cross_city_sign = ''
    }
    this.setState({
      classifyList: [...classifyList]
    })
  },

  componentDidMount() {
    console.log(this.props)
    const { type } = this.props
    api.getTaxSettings().then(res => {
      this.setState(
        {
          taxList: res.json || []
        },
        () => {
          if (type === 'edit') {
            const { invoicing_xforceplus_settings } = this.props.group
            console.log(invoicing_xforceplus_settings)
            this.setState({
              classifyList: (invoicing_xforceplus_settings || []).map(item => {
                const currentTax = res.json.find(
                  i => i.id == item.id || i.id == item.xforceplus_tax_setting_id
                )
                return {
                  ...item,
                  real_estate_place: item.real_estate_place ? item.real_estate_place.split('/') : [],
                  // cross_city_sign: item.cross_city_sign !== undefined ? String(item.cross_city_sign) : undefined,
                  xforceplus_tax_setting_id: currentTax
                    ? String(currentTax.id)
                    : '',
                    goods_tax_no: currentTax ? String(currentTax.goods_tax_no) : ''
                }
              })
            })
          }
        }
      )
    })
  },

  mainInvoiceChange(e) {
    const { main_invoice_type, vote_type } = this.props.fields

    main_invoice_type.onChange(e)
    e.persist();
    const value = e.target.value
    if(value === '2') {
      vote_type.onChange(['qc'])
    }

    this.setState({
      classifyList: []
    })
  },

  voteTypeChange(check, type) {
    const { vote_type } = this.props.fields
    const arr = vote_type.value
    let result = []
    if (check) {
      result = [...arr, type]
    } else {
      result = arr.filter(i => i !== type)
    }
    console.log(result)
    vote_type.onChange(result)
  },

  radioChange(e, item) {
    e.persist()
    item.cross_city_sign = e.target.value
    this.forceUpdate()
  },

  cascaderChange(value, item) {
    item.real_estate_place = value
    this.forceUpdate()
  },

  render() {
    const {
      fields: { account_name, terminal_code, sapid, main_invoice_type, vote_type },
      error,
      submitting,
      handleSubmit,
      type
    } = this.props

    const { classifyList, taxList } = this.state
    const filteredTaxList = main_invoice_type.value != 2 ? taxList.filter(i => i.id != 4) : taxList

    return (
      <form onSubmit={handleSubmit(this.createLocationProvider)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'add' ? '添加' : '编辑'}发票信息(税盘)
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={account_name}
                title="开票主体"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={terminal_code}
                title="税盘"
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={sapid}
                title="销方公司编号"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBRadio
                className="kb-form-group kb-div-c178 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                rightLabel="label-rt"
                title="开票类型"
                isRequired
                arrayType={[
                  { title: '电子普票', value: '1' },
                  { title: '数电发票', value: '2' }
                ]}
                name="important"
                field={main_invoice_type}
                onChange={e => this.mainInvoiceChange(e)}
              />
            </div>

            {main_invoice_type.value == '2' && (
              <div className="kb-row" style={{ display: 'flex' }}>
                <KBCheckBox
                  data="qc"
                  label="增值税电子普通发票"
                  callback={this.voteTypeChange}
                  checked={vote_type.value.includes('qc')}
                  style={{ marginRight: '60px' }}
                />

                <KBCheckBox
                  data="qs"
                  label="增值税电子专用发票"
                  callback={this.voteTypeChange}
                  checked={vote_type.value.includes('qs')}
                />
              </div>
            )}

            {classifyList.map((item, idx) => {
              return (
                <div key={idx} className="kb-row location-provider">
                  <div className="f-right">
                    <Icon type="delete" onClick={() => this.delSetting(idx)} />
                  </div>
                  <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                    <label>
                      订单类型
                      <span className="must-fill">*</span>
                    </label>
                    <select
                      value={item.invoice_type}
                      onChange={event =>
                        this.selChange(event, idx, 'invoice_type')
                      }
                    >
                      <option key="-1" value="">
                        请选择
                      </option>
                      {types
                        .filter(
                          t =>
                            !classifyList.find(
                              c =>
                                t.id == c.invoice_type &&
                                c.invoice_type !== item.invoice_type
                            )
                        )
                        .map(op => (
                          <option key={op.id} value={op.id}>
                            {op.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                    <label>
                      税收分类
                      <span className="must-fill">*</span>
                    </label>
                    <select
                      value={item.xforceplus_tax_setting_id}
                      onChange={event =>
                        this.selChange(event, idx, 'xforceplus_tax_setting_id')
                      }
                    >
                      <option key="-1" value="">
                        请选择
                      </option>
                      {filteredTaxList.map(op => (
                        <option key={op.id} value={op.id}>
                          {op.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                    <label>
                      税收分类编码
                      <span className="must-fill">*</span>
                    </label>
                    <input className="kb-input" value={item.goods_tax_no} disabled />
                  </div>

                  {
                    item.xforceplus_tax_setting_id == 4 && (
                      <div>
                        <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                          <label>
                            不动产地址（省市区）
                            <span className="must-fill">*</span>
                          </label>
                          <Cascader value={item.real_estate_place} className="region-cascader" options={chinaRegion} placeholder="请选择" expandTrigger="hover" style={{paddingLeft: 0}} onChange={value => this.cascaderChange(value, item)} />
                        </div>

                        <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                          <label>
                            不动产详细地址（例如**街**号）
                            <span className="must-fill">*</span>
                          </label>
                          <input className="kb-input" value={item.real_estate_address} maxLength="120" onChange={event => {item.real_estate_address = event.target.value;this.forceUpdate()}} />
                        </div>

                        <div className="kb-row kb-form-group kb-div-c48 kb-form-alone">
                          <label>
                            房屋产权证书/不动产权证号码
                            <span className="must-fill">*</span>
                          </label>
                          <input className="kb-input" value={item.real_estate_no} maxLength="40" onChange={event => {item.real_estate_no = event.target.value;this.forceUpdate()}} />
                        </div>

                        <KBRadio
                          isRequired
                          className="kb-form-group kb-form-c178 kb-form-alone"
                          inClassName="kb-from-initial"
                          divClassName="kb-from-radio"
                          rightLabel="label-rt"
                          title="不动产是否跨城市"
                          arrayType={[
                            {title: '是', value: true},
                            {title: '否', value: false},
                          ]}
                          name={idx + 'cross_city_sign'}
                          field={{value: item.cross_city_sign}}
                          onChange={(e) => this.radioChange(e, item)}
                        />
                      </div>
                    )
                  }
                </div>
              )
            })}
            {classifyList.length < 3 && (
              <div className="kb-row">
                <span
                  className="bordered-btn"
                  style={{ marginLeft: 0 }}
                  onClick={this.addSetting}
                >
                  添加税收分类设置
                </span>
              </div>
            )}
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { group } = props

  let main_invoice_type = '1'
  let vote_type = []
  if(group && (group.invoice_type.includes('qc') || group.invoice_type.includes('qs'))) {
    main_invoice_type = '2'
    vote_type = group.invoice_type
  }

  return {
    initialValues: {
      account_name: group && group.account_name,
      terminal_code: group && group.terminal_code,
      sapid: group && group.sapid,
      main_invoice_type,
      vote_type,
      modified: true,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.account_name)) {
    errors.account_name = '请填写开票主体'
  }
  if (valid.required(values.sapid)) {
    errors.sapid = '请填写销方公司编号'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetAccountHuifuForm',
  fields: [
    'account_name',
    'terminal_code',
    'sapid',
    'modified',
    'main_invoice_type',
    'vote_type',
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetAccountHuifuForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetAccountHuifuForm)

export default GroupSetAccountHuifuForm
