import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput, KBASNumberInput, KBTextarea } from 'components'
import KBEditor from 'components/plugins/KBEditor'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { meetingSettingActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { KBSelect } from 'components/views'
import {
  DAY_TIME_HM,
  DAY_TIME_HOUR,
  AREATYPES_STATES,
  AREATYPES_TIPSY
} from 'app/constants'

var SpaceSettingMeetingForm = React.createClass({
  displayName: 'SpaceSettingMeetingForm',
  // componentWillReceiveProps(nextprops) {
  //   if(nextprops.values[this.props.type] != this.state.funcSetting)
  //   this.setState({
  //     funcSetting: nextprops.value[this.props.type]
  //   })
  // },


  updateSpace(model) {
    const { updateLocationSetting, setting_value } = this.props
    // model.send_message_to_operator = this.props.values.send_message_to_operator
    model.reservation_time_range = setting_value.reservation_time_range.map(
      r => {
        if (r.day === -1) {
          return {
            begin: model.reservation_time_range.begin * 60,
            end: model.reservation_time_range.end * 60,
            day: -1,
            nowork: false
          }
        }
        return r
      }
    )

    updateLocationSetting({
      meeting_room_settings: model
    }, {key:'meeting_room_settings'})
  },
  limitationCallBack(value) {
    const {
      fields: { daily_limitation }
    } = this.props
    daily_limitation.onChange(value)
  },
  monthlyLimitationCallBack(value) {
    const {
      fields: { monthly_limitation }
    } = this.props
    monthly_limitation.onChange(value)
  },

  meetingStartHour(value) {
    const {
      fields: { reservation_time_range }
    } = this.props
    reservation_time_range.begin.onChange(parseFloat(value))
  },

  meetingEndHour(value) {
    const {
      fields: { reservation_time_range }
    } = this.props
    reservation_time_range.end.onChange(parseFloat(value))
  },

  changeSlide(key) {
    let active = !this.props.values[key.name]
    if (key.name == 'send_message_to_operator') {
      this.props.values.send_message_to_operator = active
      this.setState({
        send_message_to_operator: active
      })
    } else if (key.name == 'bridge_enabled') {
      this.props.values.bridge_enabled = active
      this.setState({
        bridge_enabled: active
      })
    }
  },

  render() {
    const {
      fields: {
        daily_limitation,
        advance_limitation_types,
        max_day_duration,
        min_day_duration,
        max_duration,
        min_duration,
        overtime_margin,
        overtime_price,
        daily_limitation_value,
        monthly_limitation,
        monthly_limitation_value,
        cancellation_offset,
        lock_permission_allowance,
        reservation_time_range,
        reservation_time_range_error,
        send_message_to_operator,
        create_reservation_note,
        reservation_reminder_note,
        note,
        advance_limitation
      },
      error,
      submitting,
      handleSubmit,
      selectTypes
    } = this.props
    const arrayData = [
      {
        id: 'infinity',
        name: '不限制'
      },
      {
        id: 'number',
        name: '小时'
      },
      {
        id: 'per_desk',
        name: '小时/工位'
      }
    ]
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <div>最多可预订天数</div>
              <p className="color-nine">单次预定会议室最长天数，默认值为10天</p>
            </div>
            <KBASNumberInput
              field={max_day_duration}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>最少可预订天数</div>
              <p className="color-nine">单次预订会议室最短天数，默认值为1天</p>
            </div>
            <KBASNumberInput
              field={min_day_duration}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>最大预定时长</div>
              <p className="color-nine">
                单次预订会议室最长时间数，默认值为4小时
              </p>
            </div>
            <KBASNumberInput
              field={max_duration}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>最小预定时长</div>
              <p className="color-nine">
                单次预订会议室最短时间数，默认值为0.5小时
              </p>
            </div>
            <KBASNumberInput
              field={min_duration}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>公司每日预定的时长为</div>
              <p className="color-nine">
                请输入数字“4”并选择 [小时/工位] 表示公司每日预定的时长为[4]
                [小时/工位]
              </p>
            </div>
            <div className="set-select-box f-right">
              <KBSelect
                arrayData={arrayData}
                value={daily_limitation.value}
                callback={this.limitationCallBack}
              />
            </div>
            <KBASNumberInput
              field={daily_limitation_value}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 10 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>公司每月预定的时长为</div>
              <p className="color-nine">
                请输入数字“4”并选择 [小时/工位] 表示公司每月预定的时长为[4]
                [小时/工位]
              </p>
            </div>
            <div className="set-select-box f-right">
              <KBSelect
                arrayData={arrayData}
                value={monthly_limitation.value}
                callback={this.monthlyLimitationCallBack}
              />
            </div>
            <KBASNumberInput
              field={monthly_limitation_value}
              className="f-right"
              type="number"
              counterStyle={{ marginRight: 10 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>可以提前多久预订</label>
              <p className="color-nine">
                可以提前预订的天数，默认值为30
              </p>
            </div>
            <KBASNumberInput
              field={advance_limitation}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>可以提前多久取消预订</label>
              <p className="color-nine">
                可以提前取消预订的小时数，默认值为0，会议开始后不能取消
              </p>
            </div>
            <KBASNumberInput
              field={cancellation_offset}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>

          <div className="kb-row">
            <div className="f-left">
              <label>可以提前多久进入会议室</label>
              <p className="color-nine">可以提前进会议室的分钟数，默认值为15</p>
            </div>
            <KBASNumberInput
              field={lock_permission_allowance}
              className="f-right"
              type="number"
              maxValue="24"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>超时容许范围</label>
              <p className="color-nine">超时容许范围，单位：分钟，默认5分钟</p>
            </div>
            <KBASNumberInput
              field={overtime_margin}
              className="f-right"
              type="number"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>使用时间超过容许范围后的使用价格</label>
              <p className="color-nine">
                使用时间超过容许范围后的使用价格，单位：n 元/分钟默认0
              </p>
            </div>
            <KBASNumberInput
              field={overtime_price}
              className="f-right"
              type="number"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>预定会议室起止时间</label>
              <p className="color-nine">可以设置会议室预定开始时间和结束时间</p>
            </div>
            <div className="set-select-box f-right">
              <KBSelect
                arrayData={DAY_TIME_HOUR}
                selectStyle={{ padding: '0 39px' }}
                value={reservation_time_range.begin.value || 0}
                callback={this.meetingStartHour}
              />
              &nbsp;-&nbsp;
              <KBSelect
                selectStyle={{ padding: '0 37px' }}
                arrayData={DAY_TIME_HOUR}
                value={reservation_time_range.end.value || 24}
                callback={this.meetingEndHour}
              />
              {reservation_time_range_error.touched &&
                reservation_time_range_error.error && (
                  <p className="lr-error">
                    {reservation_time_range_error.error}
                  </p>
                )}
            </div>
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>会议结束是否通知运营人员</label>
              <p className="color-nine">
                可以决定在每个会议结束之后是否告知运营人员该会议状态
              </p>
            </div>
            <div
              className="slide-btn f-right"
              onClick={() => {
                send_message_to_operator.onChange(
                  !send_message_to_operator.value
                )
              }}
            >
              <span
                className={`slide-bg ${
                  send_message_to_operator.value ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
          </div>

          {/* <div className='kb-row'>
            <div className="f-left">
              <label>奕桥空间</label>
              <p className='color-nine'>是否是奕桥空间</p>
            </div>
            <div className='slide-btn f-right' onClick={ () => { bridge_enabled.onChange(!bridge_enabled.value) }}>
              <span className={`slide-bg ${bridge_enabled.value ? 'slide-left':'slide-right'}`}>
                <span className='slide-move'/>
              </span>
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 25
            }}
          >
            <div>可以提前预定的场地类型</div>
            <KBUserInput
              users={AREATYPES_STATES}
              placeholder="选择类型"
              selectedUsers={selectTypes}
              multi={true}
              field={advance_limitation_types}
              style={{ minWidth: 250, maxWidth: 450 }}
            />
          </div>
          <fieldset className="fieldset">
            <legend>预定须知</legend>
            <div>
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                isRequired={true}
                field={note}
              />
            </div>
          </fieldset>
          <fieldset className="fieldset">
            <legend>预定成功通知</legend>
            <div>
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                isRequired={true}
                field={create_reservation_note}
                placeholder='预定成功通知字符长度需小于20'
              />

            </div>
          </fieldset>
          <fieldset className="fieldset">
            <legend>会议开始提醒</legend>
            <div>
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                isRequired={true}
                field={reservation_reminder_note}
                maxlength="20"
                placeholder='预定成功通知字符长度需小于20'
              />

            </div>
          </fieldset>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.meeting_room_settings||{}
  const reservation_time_range = (setting_value.reservation_time_range||[]).find(
    r => r.day === -1
  )||{}
  const advanceLimitationTypes =
    (setting_value && setting_value.advance_limitation_types) || []
  const advance_limitation_types =
    advanceLimitationTypes &&
    (typeof advanceLimitationTypes === 'string'
      ? advanceLimitationTypes.split(',')
      : advanceLimitationTypes)
  let selectTypes = []
  advance_limitation_types.map(type => {
    selectTypes.push({
      id: type,
      name: AREATYPES_TIPSY[type]
    })
  })
  const begin = (reservation_time_range.begin||0) / 60
  const end = (reservation_time_range.end||0) / 60
  return {
    initialValues: {
      ...setting_value,
      reservation_time_range: {
        begin,
        end
      }
    },
    setting_value,
    selectTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.reservation_time_range.begin >= values.reservation_time_range.end
  ) {
    errors.reservation_time_range_error = '开始时间不能大于结束时间'
  }
  if(values.create_reservation_note&&values.create_reservation_note.length>20){
    errors.create_reservation_note = '预定成功通知长度不能大于20'
  }
  return errors
}

const formConfig = {
  form: 'SpaceSettingMeetingForm',
  fields: [
    'daily_limitation',
    'min_day_duration',
    'max_day_duration',
    'max_duration',
    'min_duration',
    'overtime_margin',
    'overtime_price',
    'daily_limitation_value',
    'monthly_limitation',
    'monthly_limitation_value',
    'cancellation_offset',
    'lock_permission_allowance',
    'reservation_time_range_error',
    'reservation_time_range.begin',
    'reservation_time_range.end',
    'send_message_to_operator',
    'advance_limitation_types',
    'note',
    'create_reservation_note',
    'reservation_reminder_note',
    'advance_limitation'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingMeetingForm)
