import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import * as actions from 'app/actions'
import {
  KBAvatar,
  KBPopover,
  KBButton,
  KBTable,
  KBLoadingContainer
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { canAccessLocation } from 'app/reducers/role'
import { Tabs, Typography } from 'antd'
import { routerActions } from 'react-router-redux'

const { TabPane } = Tabs
const { Title, Text } = Typography
const serviceType = {property: '物业服务', business: '商务服务', sustainable_love: '可持续喜爱 SustainLOVEbility'}

var valueAddedServicesList = React.createClass({
  getInitialState() {
    return {
      enabled: null,
      loading: false,
      isShow: false,
      appModules: [],
      tableColumns: [
        {
          title: '',
          key: 'title',
          width: '100px',
          render: (rowItem) => {
            return (
              <img
                src={rowItem.icon}
                alt="img"
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'block'
                }}
              />
            )
          }
        },
        {
          title: '',
          key: 'app_name',
          render: (rowItem) => {
            return (
              <div>
                <Title level={4}>{rowItem.name}</Title>
                <Text type="secondary">{serviceType[rowItem.category]}</Text>
                <br />
                <Text type="secondary">创建人：{rowItem.creator.name}</Text>
              </div>
            )
          }
        },
        {
          title: '',
          key: 'enabled',
          width: '200px',
          align: 'right',
          render: (rowItem) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  height: '100px'
                }}
              >
                <div>
                  <button className="bordered-btn" onClick={() => this.edit(rowItem.id)}>编辑</button>
                  <button className="c-btn-red" style={{ marginLeft: '20px' }} onClick={() => this.deleteItem(rowItem)}>
                    删除
                  </button>
                </div>
                <div>
                  <img
                    className="table-sort-handle-item"
                    src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/cl/uploads/locations/1ab5dd4dc-f8f6-46fe-9353-af260f9ca4a2no0.png"
                    alt=""
                    style={{
                      width: '30px',
                      cursor: 'move',
                    }}
                  />
                </div>
              </div>
            )
          }
        }
      ]
    }
  },

  componentDidMount() {
    this._loadData()
  },

  _loadData(tabKey = 'property') {
    const { apiCallFailureAction, loc_id } = this.props

    this.setState({ loading: true })
    api
      .getValueAddedServicesList({
        location_group_id: loc_id,
        category: tabKey
      })
      .then(
        json => {
          console.log(json)
          this.setState({
            appModules: json.response.body
          })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  deleteItem(rowItem) {
    const {id, name} = rowItem
    const { apiCallFailureAction, successAction } = this.props
    KBPopoverConfirm({
      name: '删除增值服务',
      context: `确定要删除'${name}'?`,
      callback: () => {
        return api.deleteValueAddedService(id).then(
          json => {
            successAction({ message: '删除成功' })
            this.setState({
              appModules: this.state.appModules.filter(i => i.id !== id)
            })
          },
          errors => {
            apiCallFailureAction({
              status: 'error',
              message: errors.message
            })
          }
        ).finally(() => {
          KBPopover.close()
        })
      }
    })
  },

  edit(id) {
    const { loc_id, routerActions } = this.props
    const idStr = id ? `?editId=${id}` : ''
    routerActions.push(`/admin/groups/${loc_id}/valueAddedServices/edit${idStr}`)
  },

  sortChange(sortedList) {
    const { apiCallFailureAction, successAction } = this.props
    api
    .editValueAddedServiceOrder({
      ids: sortedList.map(i => i.id)
    })
    .then(
      json => {
      },
      error => {
        apiCallFailureAction({
          status: 'error',
          message: error.message
        })
      }
    )
  },

  onTabChange(key) {
    this._loadData(key)
  },

  render() {
    const { loc_id } = this.props
    const { loading, appModules } = this.state

    return (
      <section
        className={
          loc_id
            ? 'kb-content-container value-add-services-container'
            : 'nav-section-container f-right'
        }
      >
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>增值服务</span>
            </div>
            <div className="f-right">
              <KBButton className="bordered-btn" onClick={() => this.edit()}>
                创建增值服务
              </KBButton>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <Tabs defaultActiveKey="property" onChange={this.onTabChange}>
            <TabPane tab="物业服务" key="property">
              <KBLoadingContainer loading={loading}>
                <KBTable
                  columns={this.state.tableColumns}
                  dataSource={appModules}
                  sortable
                  sortChange={this.sortChange}
                />
              </KBLoadingContainer>
            </TabPane>
            <TabPane tab="商务服务" key="business">
              <KBLoadingContainer loading={loading}>
                <KBTable
                  columns={this.state.tableColumns}
                  dataSource={appModules}
                  sortable
                  sortChange={this.sortChange}
                />
              </KBLoadingContainer>
            </TabPane>
            <TabPane tab="可持续喜爱 SustainLOVEbility" key="sustainable_love">
              <KBLoadingContainer loading={loading}>
                <KBTable
                  columns={this.state.tableColumns}
                  dataSource={appModules}
                  sortable
                  sortChange={this.sortChange}
                />
              </KBLoadingContainer>
            </TabPane>
          </Tabs>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const loc_id = params.id
  const { user } = state
  const isManager = canAccessLocation(user, loc_id)

  return {
    loc_id,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(actions.successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(actions.apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(valueAddedServicesList)
