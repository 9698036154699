import React from 'react'
import SpacesSettingManageForm from './SpacesSettingManageForm'
import SpacesSettingCRMForm from './SpacesSettingCRMForm'
import SpacesSettingCRMAdminForm from './SpacesSettingCRMAdminForm'
import SpacesSettingTaskForm from './SpacesSettingTaskForm'
import SpacesSettingInvoiceForm from './SpacesSettingInvoiceForm'
import SpacesSettingFAQForm from './SpacesSettingFAQForm'
import SpacesSettingEngineerForm from './SpacesSettingEngineerForm'
import SpacesSettingHRForm from './SpacesSettingHRForm'
import SpacesSettingEnterpriseForm from './SpacesSettingEnterpriseForm'
import SpacesSettingAppModuleForm from './SpacesSettingAppModuleForm'
import SpacesSettingStatisticForm from './SpacesSettingStatisticForm'
import SpacesSettingAccountBindingForm from './SpacesSettingAccountBindingForm'
import SpacesSettingShopManageForm from './SpacesSettingShopManageForm'
import SpaceSettingTaskFeedbackForm from './SpaceSettingTaskFeedbackForm'
import SpaceSettingPointRechargeForm from './SpaceSettingPointRechargeForm'
import SpaceSettingHelpDeskManger from './SpaceSettingHelpDeskManger'
import { api } from 'app/services'
import { isHideModule } from 'utils/kbUtil'

var SpacesSettingSafe = React.createClass({
  getInitialState() {
    return {
      roleSetting: null
    }
  },
  render() {
    const {
      setting,
      updateCurrentSpace,
      space_id,
      spaceRole,
      updateSpaceRole
    } = this.props
    const isHide = isHideModule(space_id)
    if (!setting.settings || !spaceRole.roles_settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>安全设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <p style={{ marginBottom: 15 }}>设置后台管理各模块的员工权限</p>
          <fieldset className="fieldset">
            <legend>账号设置</legend>
            <SpacesSettingAccountBindingForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>空间管理员</legend>
            <SpacesSettingManageForm
              setting={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>账号权限管理员</legend>
            <SpaceSettingHelpDeskManger
              setting={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>员工管理</legend>
            <SpacesSettingHRForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>客户管理</legend>
            <SpacesSettingCRMForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>
              <span>客户管理员</span>
              <span style={{ fontSize: 12, color: '#999', marginLeft: 5 }}>
                (可以查看空间所有客户数据)
              </span>
            </legend>
            <SpacesSettingCRMAdminForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          {isHide ? (
            ''
          ) : (
            <fieldset className="fieldset">
              <legend>任务管理</legend>
              <SpacesSettingTaskForm
                spaceRole={spaceRole}
                updateSpaceRole={updateSpaceRole}
              />
            </fieldset>
          )}
          <fieldset className="fieldset">
            <legend>意见反馈负责人</legend>
            <SpaceSettingTaskFeedbackForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          {/* <fieldset className="fieldset">
              <legend>财务管理</legend>
              <SpacesSettingInvoiceForm spaceRole={ spaceRole } updateSpaceRole={ updateSpaceRole } />
            </fieldset> */}
          <fieldset className="fieldset">
            <legend>积分充值</legend>
            <SpaceSettingPointRechargeForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          {isHide ? (
            ''
          ) : (
            <fieldset className="fieldset">
              <legend>小程序显示管理</legend>
              <SpacesSettingFAQForm
                spaceRole={spaceRole}
                updateSpaceRole={updateSpaceRole}
              />
            </fieldset>
          )}
          {isHide ? (
            ''
          ) : (
            <fieldset className="fieldset">
              <legend>应用管理</legend>
              <SpacesSettingAppModuleForm
                spaceRole={spaceRole}
                updateSpaceRole={updateSpaceRole}
              />
            </fieldset>
          )}
          {isHide ? (
            ''
          ) : (
            <fieldset className="fieldset">
              <legend>工程部门</legend>
              <SpacesSettingEngineerForm
                spaceRole={spaceRole}
                updateSpaceRole={updateSpaceRole}
              />
            </fieldset>
          )}
          <fieldset className="fieldset">
            <legend>服务商管理</legend>
            <SpacesSettingEnterpriseForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>数据统计查看</legend>
            <SpacesSettingStatisticForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>商城管理</legend>
            <SpacesSettingShopManageForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpacesSettingSafe
