import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBInput, KBASNumberInput } from 'components'
import * as selectors from 'app/selectors'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import _ from 'lodash'

var SpaceSettingInvoiceForm = React.createClass({
  displayName: 'SpaceSettingInvoiceForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    updateCurrentSpace(
      JSON.stringify({
        invoice_settings: model
      }),
      {
        key: 'invoice_settings'
      }
    )
  },
  render() {
    const {
      fields: { //invoice_due_offset,
        deposit_pay_offset },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <div>
                合同签订前&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{deposit_pay_offset.value}</span>
                &nbsp;)&nbsp;&nbsp;天押金到期
              </div>
              <p className="color-nine d-iblock">
                示例：如果设置为5，意味着会在合同开始日之前5天催付押金
              </p>
            </div>
            <KBASNumberInput
              field={deposit_pay_offset}
              className="f-right"
              type="number"
              maxValue="31"
            />
          </div>
          {/* <div className="kb-row">
            <div className="f-left">
              <div>
                缴租日前&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{invoice_due_offset.value}</span>
                &nbsp;)&nbsp;&nbsp;天生成账单
              </div>
              <p className="color-nine d-iblock">
                示例：收款日是31日，设置值为15，则会在16日生成账单
              </p>
            </div>
            <KBASNumberInput
              field={invoice_due_offset}
              className="f-right"
              type="number"
              maxValue="31"
            />
          </div> */}
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  // const loc_id = _.get(props, 'locationId')
  // const locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  const { setting: { invoice_settings } ={} } = props
  return {
    initialValues: invoice_settings
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingInvoiceForm',
  fields: [//'invoice_due_offset',
    'deposit_pay_offset'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingInvoiceForm)
