import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { tasksActions, locationActions, memberActions } from 'app/actions'
import { checkObject } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBSelect,
  KBDateInput,
  KBTextarea
} from 'components'
import * as selectors from 'app/selectors'
import { TASK_PRIORITIES_FORM, TASK_SUBTYPE } from 'app/constants'
import { ADD_TASK } from 'app/constants/hint'
import { DateUtils } from 'react-day-picker'
import moment from 'moment'

var TaskForm = React.createClass({
  displayName: 'TaskForm',
  createTaskForm(model) {
    const { createTasksAction, task_id, state, updateTasksAction } = this.props

    if (state == 'update') {
      return api.updateTask(task_id, model).then(json => {
        updateTasksAction.success(json)
        KBPopover.close()
        return true
      })
    }

    return api
      .createTasks({
        ...model,
        task_type: 'repair',
        source: 'space_member',
        state: 'submit_task'
      })
      .then(json => {
        createTasksAction.success(json)
        KBPopover.close()
        return true
      })
  },

  componentDidMount() {
    const {
      fields: { due_date, location_id },
      loc_id
    } = this.props
    due_date.onChange(moment({ hour: 18, minute: 0, seconds: 0 }).toDate())
    if (loc_id) {
      location_id.onChange(loc_id)
    }
  },

  render() {
    const {
      fields: {
        subject,
        priority,
        task_type,
        subtype,
        source,
        state,
        object_type,
        obj_id,
        assigned_to,
        due_date,
        completed_at,
        location_id,
        attachments,
        followers
      },
      error,
      submitting,
      handleSubmit,
      locations,
      users,
      loc_id,
      selectedLoc
    } = this.props
    const hint = ADD_TASK

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    const taskPriority = TASK_PRIORITIES_FORM

    return (
      <form onSubmit={handleSubmit(this.createTaskForm)}>
        <div onClick={this.callBack}>
          <header className="kb-form-header">
            <h2 className="header-title">新建报修</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
          <KBFormError err={this.props.error} />
          <div className="kb-form-container">
            <fieldset className="fieldset">
              <legend>基本信息</legend>
              <div className="kb-row">
                <KBTextarea
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  hint={hint.title}
                  fistFoucs={true}
                  isRequired={true}
                  field={subject}
                  title="详情"
                />
              </div>
              <div className="kb-row kb-form-66">
                <KBSelect
                  {...halfClass}
                  isRequired={true}
                  title="优先级"
                  field={priority}
                  arrayData={taskPriority}
                />
                <KBDateInput
                  {...halfClass}
                  field={due_date}
                  titleClass="label-rt"
                  title="到期"
                  disabledDays={DateUtils.isPastDay}
                />
              </div>
              <div className="kb-row">
                <KBUserInput
                  title="分店"
                  placeholder="选择分店"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={location_id}
                  isRequired={true}
                  multi={false}
                  users={locations}
                  selectedUsers={selectedLoc}
                  disabled={loc_id}
                />
              </div>
              <div className="kb-row">
                <KBSelect
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  isRequired={true}
                  field={subtype}
                  arrayData={TASK_SUBTYPE}
                  title="报修类型"
                />
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>公司</legend>
              <p style={{ color: '#c8c8c8', fontSize: 12, marginBottom: 20 }}>
                {hint.team}
              </p>
              <div className="kb-row">
                <KBUserInput
                  title="负责人"
                  hint={hint.principal}
                  isRequired={true}
                  placeholder="选择负责人"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={assigned_to}
                  multi={false}
                  users={users}
                  selectedUsers={[users[0]]}
                />
              </div>
              <div className="kb-row">
                <KBUserInput
                  title="参与者"
                  hint={hint.participator}
                  placeholder="选择员工"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={followers}
                  multi={true}
                  users={users}
                />
              </div>
            </fieldset>
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <KBButton
                className="certain-btn"
                style={{ marginRight: 0 }}
                type="submit"
                submitting={submitting}
              >
                保存
              </KBButton>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.location_id
  const locations = selectors.getSerializeLocation(state) || []
  const selectedLoc = locations.filter(loc => loc.id == loc_id)
  const users = selectors.getSpaceUsers(state)

  return {
    loc_id,
    locations,
    selectedLoc,
    users,
    initialValues: {
      priority: 'normal',
      assigned_to: users[0].id,
      subtype: 'it'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createTasksAction: bindActionCreators(tasksActions.create, dispatch),
    updateTasksAction: bindActionCreators(tasksActions.delete, dispatch),
    getLocationsAction: bindActionCreators(locationActions.all, dispatch),
    getMembersAction: bindActionCreators(memberActions.all, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.subject)) {
    errors.subject = '请输入标题'
  }

  if (valid.required(values.location_id)) {
    errors.location_id = '请选择分店'
  }

  if (valid.required(values.subtype)) {
    errors.subtype = '请选择报修类型'
  }

  return errors
}

const formConfig = {
  form: 'ChanceForm',
  fields: [
    'subject',
    'priority',
    'task_type',
    'subtype',
    'source',
    'state',
    'object_type',
    'obj_id',
    'assigned_to',
    'due_date',
    'completed_at',
    'location_id',
    'attachments',
    'followers'
  ],
  touchOnBlur: false,
  validate: validate
}

TaskForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(TaskForm)

export default TaskForm
