import React, { Component, PropTypes } from 'react'
import { getTaskNoteUpdate } from 'app/utils/CommonUtils'
import moment from 'moment'
import { TASK_UDPATE_ICONS, TASK_UPDATE_TYPES } from 'app/constants'
import { KBPopover } from 'components'
import { PopoverShowPicture } from 'components/views'

var TaskNote = React.createClass({
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.note.id === nextProps.note.id) {
      return false
    }
    return true
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  render() {
    const { note, task } = this.props
    let { body, media, generate_operation_notes } = note
    let update = note && getTaskNoteUpdate(note, task)
    let updateType = update.updateType
    let iconClass = TASK_UDPATE_ICONS[updateType] || 'icon-edit'
    let isShowUser = note.is_show
    return (
      <li key={note.id}>
        <span className="section-title-content color-six">
          <i className={`iconfont ${iconClass}`} />
          {generate_operation_notes}
        </span>
        {isShowUser ? (
          <span
            className="record-user"
            style={{ marginLeft: 10, padding: '3px 8px' }}
          >
            用户可见
          </span>
        ) : (
          <span
            className="record-inside"
            style={{ marginLeft: 10, padding: '3px 8px' }}
          >
            内部可见
          </span>
        )}
        <span className="renewal-time">
          {moment.unix(note.created_at).fromNow()}
        </span>
        {body || (media && media.length > 0) ? (
          <div className="renewal-box">
            {/** <div className='status-box'>
             <span className='status-content'>{body}</span>
            </div> */}
            {media && media.length ? (
              <div className="photo-box">
                {media.map((mediaItem, index) => (
                  <div className="upload-photo" key={index}>
                    <img
                      onClick={this.clickPhoto.bind(null, mediaItem.url)}
                      src={mediaItem.url}
                      alt="photo"
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </li>
    )
  }
})

TaskNote.propTypes = {
  note: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
}
export default TaskNote
