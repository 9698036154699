import React, { Component, PropTypes } from 'react'
import StatisticDeskReceive from './invoice/StatisticDeskReceive'
import StatisticDeskPaidReceivable from './invoice/StatisticDeskPaidReceivable'
import StatisticInvoiceAmountPrediction from './invoice/StatisticInvoiceAmountPrediction'
import StatisticInvoiceDueDate from './invoice/StatisticInvoiceDueDate'
import StatisticSubscriptionCycleStatistic from './org/StatisticSubscriptionCycleStatistic'
import moment from 'moment'

/**
 * 收款统计
 * @type {[type]}
 */
var StatisticInvoiceBox = React.createClass({
  displayName: 'StatisticInvoiceBox',

  formatStatisticToArray(obj, date_type) {
    if (!obj) {
      return []
    }
    let keys = Object.keys(obj)
    let values = Object.values(obj)
    let newArr = []
    keys.map((key, index) => {
      let singleValue = {}
      let date = ''
      let formatedDateStr = String(key)
        .replace(/(.{6})/, '$1-')
        .replace(/(.{4})/, '$1-')
      if (date_type === 'month') {
        date = moment(formatedDateStr).month() + 1 + '月'
      } else if (date_type === 'day') {
        date = moment(formatedDateStr).date() + '日'
      } else {
        date = formatedDateStr
      }
      singleValue.formated_date = date
      singleValue.value = values[index]
      singleValue.date = formatedDateStr
      newArr.push(singleValue)
      return key
    })
    return newArr
  },

  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticDeskReceive
            group_id={this.props.group_id}
            formatStatisticToArray={this.formatStatisticToArray}
          />
          <StatisticDeskPaidReceivable
            group_id={this.props.group_id}
            formatStatisticToArray={this.formatStatisticToArray}
          />
          <StatisticInvoiceAmountPrediction
            group_id={this.props.group_id}
            formatStatisticToArray={this.formatStatisticToArray}
          />
          <StatisticInvoiceDueDate
            group_id={this.props.group_id}
            formatStatisticToArray={this.formatStatisticToArray}
          />
          <StatisticSubscriptionCycleStatistic group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticInvoiceBox
