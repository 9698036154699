import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput, KBSelect } from 'components'
import * as selectors from 'app/selectors'
import { CONTRACTAPPROVALSETTING } from 'app/constants'
import { APPROVE_CONTRACT } from 'app/constants/hint'
import * as role from 'app/reducers/role'

var SpaceSettingLowPriceContractForm = React.createClass({
  displayName: 'SpaceSettingLowPriceContractForm',
  updateRoles({
    abnormal_contract_role_setting,
    abnormal_contract_role_setting_users
  }) {
    const { updateSpaceRole } = this.props
    return updateSpaceRole({
      role: 'abnormal_contract',
      range: abnormal_contract_role_setting,
      user_ids:
        typeof abnormal_contract_role_setting_users === 'string' &&
        abnormal_contract_role_setting_users
          ? abnormal_contract_role_setting_users.split(',')
          : []
    })
  },
  render() {
    const {
      fields: { abnormal_contract_role_setting_users },
      error,
      submitting,
      handleSubmit,
      users,
      selectUsers
    } = this.props
    const hint = APPROVE_CONTRACT
    return (
      <form onSubmit={handleSubmit(this.updateRoles)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <KBUserInput
              className={'kb-form-group kb-form-c18 kb-form-alone'}
              hint={hint.approver}
              title={'审批人'}
              users={users}
              selectedUsers={selectUsers}
              multi={true}
              field={abnormal_contract_role_setting_users}
            />
          </div>

          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { roles_settings, roles } = props.spaceRole
  const setting_value = roles_settings.abnormal_contract_role_setting
  let users = selectors.getSpaceUsers(state)
  let user_group = role.getRoleUserByKey(roles, 'abnormal_contract')
  let selectUsers = selectors.getUsersByUserIds(state, user_group) || []

  return {
    users,
    selectUsers,
    initialValues: {
      abnormal_contract_role_setting: setting_value || 'group',
      abnormal_contract_role_setting_users: user_group || []
    }
  }
}

const formConfig = {
  form: 'SpaceSettingLowPriceContractForm',
  fields: [
    'abnormal_contract_role_setting',
    'abnormal_contract_role_setting_users'
  ],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(SpaceSettingLowPriceContractForm)
