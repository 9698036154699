import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBSelect } from 'components'
import { RENTING_PHASES_RECURRING_DATE_COMPUTE_DAY } from 'app/constants'

var SpaceSettingPhaseComputeWayForm = React.createClass({
  displayName: 'SpaceSettingPhaseComputeWayForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          subscription_settings: {
            renting_phase_recurring_date_compute_way: model
          }
        })
      },
      {
        key: 'subscription_settings'
      }
    )
  },

  render() {
    const {
      fields: { type, default_advanced, default_fixed },
      error,
      submitting,
      handleSubmit
    } = this.props
    const isAdvance = type.value === 'advance'
    let DAYS = []
    const onChange = isAdvance
      ? default_advanced.onChange
      : default_fixed.onChange
    for (let i = 1; i <= 31; i++) {
      DAYS.push(i)
    }

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="计算方式"
              field={type}
              arrayData={RENTING_PHASES_RECURRING_DATE_COMPUTE_DAY}
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>缴租日</label>
              <div>
                {
                  <select
                    name="dueday"
                    value={
                      isAdvance
                        ? default_advanced.value || null
                        : default_fixed.value || null
                    }
                    onChange={onChange}
                  >
                    {DAYS.map(day => {
                      return (
                        <option value={day} key={day}>{`${day} ${
                          isAdvance ? '天' : '号'
                        }`}</option>
                      )
                    })}
                  </select>
                }
                <p
                  style={{
                    color: '#c8c8c8',
                    fontSize: 12,
                    paddingTop: 2,
                    marginTop: 8
                  }}
                >
                  {isAdvance
                    ? `会员费周期开始日前${default_advanced.value}天`
                    : `会员费周期开始日前一个月的${default_fixed.value}号`}
                </p>
              </div>
            </div>
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let {
    type,
    default_advanced,
    default_fixed
  } = setting.subscription_settings.renting_phase_recurring_date_compute_way||{}

  return {
    initialValues: {
      type: type || 'fixed',
      default_advanced: default_advanced || 7,
      default_fixed: default_fixed || 1
    }
  }
}

const formConfig = {
  form: 'SpaceSettingPhaseComputeWayForm',
  fields: ['type', 'default_advanced', 'default_fixed'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(SpaceSettingPhaseComputeWayForm)
