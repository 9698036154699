import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBTextarea,
  KBImageCropperInput
} from 'components'
import { SPACE_FORM } from 'app/constants/hint'
import { toImageProxyDirect } from 'utils/kbUtil'
import { notification } from 'antd'
/**
 * 修改分店 基本设置
 */
var SpacesSettingBaseForm = React.createClass({
  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: { name, full_name, image, one_liner, telephone, devices_list },
      error,
      submitting,
      handleSubmit,
      updateSpace,
      space
    } = this.props

    return (
      <form onSubmit={handleSubmit(updateSpace)}>
        <KBFormError error={error} />
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={name}
            title="空间名称"
            hint={SPACE_FORM.short_name}
          />
        </div>
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={full_name}
            title="空间全称"
            hint={SPACE_FORM.full_name}
          />
        </div>
        {/* <div className='kb-row'>
          <KBInput className='kb-form-group kb-form-c18 kb-form-alone' field={devices_list} title='设备' hint={SPACE_FORM.devices_list}/>
        </div> */}
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={telephone}
            title="联系电话"
            hint={SPACE_FORM.contact_phone}
          />
        </div>
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={one_liner}
            title="一句话描述"
            hint={SPACE_FORM.one_liner}
          />
        </div>
        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>域名</label>
            <div style={{ height: '36px', lineHeight: '36px' }}>
              <span>https://</span>
              <b>{space.subdomain}</b>
              <span>.kuban.io</span>
            </div>
          </div>
        </div>
        <div className="kb-row" style={{ textAlign: 'left' }}>
          <KBImageCropperInput
            title="空间图片"
            aspect={false}
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={image}
            type="locations"
            accept=".jpg,.png"
            failureCallBack={this.failureCallBack}
            isPublic
          >
            <div className="upload-portrait">
              {image && image.value ? (
                <img
                  src={toImageProxyDirect(image.value, 232, 134)}
                  style={{
                    width: '232px',
                    height: '134px',
                    display: 'inline-block',
                    marginLeft: 0,
                    borderRadius: 0
                  }}
                />
              ) : (
                ''
              )}
              <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                <button className="bordered-btn" type="button">
                  {image.dirty ? '图片已上传' : '上传图片'}
                </button>
                <p
                  className="color-nine"
                  style={{ paddingLeft: 18, paddingTop: 10, fontSize: 12 }}
                >
                  图片格式为png或jpg,尺寸大小不小于200x150,文件大小不大于1M
                </p>
              </div>
            </div>
          </KBImageCropperInput>
        </div>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '空间名称不能为空'
  }

  if (valid.required(values.full_name)) {
    errors.full_name = '空间全称不能为空'
  }

  if (valid.required(values.telephone) && valid.mobile(values.telephone)) {
    errors.telephone = '请输入正确的手机号码'
  }

  return errors
}

function mapStateToProps(state, props) {
  const { space } = props
  return {
    space,
    initialValues: space
  }
}

const formConfig = {
  form: 'SpacesSettingBaseForm',
  fields: [
    'name',
    'full_name',
    'one_liner',
    'telephone',
    'image',
    'devices_list'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpacesSettingBaseForm)
