import React from 'react'
import GroupSetActivityForm from './GroupSetActivityForm'

var GroupSetActivity = React.createClass({
  displayName: 'GroupSetActivity',
  render() {
    const { updateLocationSetting, setting } = this.props
    if (!setting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>活动设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            {/* <legend>租期时限</legend> */}
            <GroupSetActivityForm updateLocationSetting={updateLocationSetting}/>
          </fieldset>
        </div>
      </div>
    )
  }
})

export default GroupSetActivity
