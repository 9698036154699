import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBPopover, KBButton, KBMorePhotoUpload } from 'components'
import config from 'app/config'

var LocationImagesUploadForm = React.createClass({
  componentDidMount() {
    const {
      fields: { images },
      defaultImages
    } = this.props
    images.onChange(defaultImages)
  },

  render() {
    const {
      fields: { images },
      handleSubmit,
      submitting,
      defaultImages,
      imgSubmit,
      loc_id
    } = this.props

    return (
      <form onSubmit={handleSubmit(imgSubmit)}>
        <div className="kb-row">
          <KBMorePhotoUpload
            defaultImages={defaultImages}
            uploadedText="上传图片"
            photoShow={true}
            sort={true}
            className="kb-form-group kb-form-alone"
            field={images}
            img_width={232}
            img_height={134}
            hint="图片格式为png或jpg,尺寸大小不小于200x150,文件大小不大于1M"
            text="上传图片"
            accept=".png,.jpg"
            imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
            prefix={`${config.uploadDir}locations/${loc_id}`}
            isPublic
          />
        </div>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存图片
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { defaultImages } = props
  return {
    defaultImages
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const formConfig = {
  form: 'LocationImagesUploadForm',
  fields: ['images'],
  touchOnBlur: false
}

LocationImagesUploadForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationImagesUploadForm)

export default LocationImagesUploadForm
