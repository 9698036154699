import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { locationGroupsAction } from 'app/actions'
import { getLocationGroups } from 'app/selectors'
import { KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import SpacesSettingLocationGroupForm from './SpacesSettingLocationGroupForm'

var SpaceSettingLocationGroups = React.createClass({
  displayName: 'SpaceSettingLocationGroups',

  componentDidMount() {
    const { getLocationGroupsAction } = this.props

    api.getLocationGroups().then(
      json => {
        getLocationGroupsAction.success(json)
      },
      error => {
        getLocationGroupsAction.failure(error)
      }
    )
  },

  addLocationGroups() {
    KBPopover.show(<SpacesSettingLocationGroupForm />)
  },

  editLocationGroup(group) {
    const { locations } = group
    const location_ids = locations.map(location => location.id).join(',')

    KBPopover.show(
      <SpacesSettingLocationGroupForm
        type="update"
        selectLocations={locations}
        group={group}
        initialValues={{
          name: group.name,
          location_ids
        }}
      />
    )
  },

  deleteLocationGroup(group) {
    const { deleteLocationGroupsAction } = this.props

    KBPopoverConfirm({
      name: '删除分店组',
      context: `是否确定要删除 ${group.name} 分店组`,
      callback: () => {
        return api.deleteLocationGroup(group.id).then(
          json => {
            deleteLocationGroupsAction.success(json, {
              id: group.id,
              key: 'locationGroups'
            })
            KBPopover.close()
          },
          error => {
            deleteLocationGroupsAction.failure(error)
          }
        )
      }
    })
  },

  render() {
    const { locationGroups } = this.props

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title f-left">
            <span>分店组设置</span>
          </div>
          <button
            className="bordered-btn f-right"
            onClick={this.addLocationGroups}
          >
            添加分店组
          </button>
        </header>
        <div style={{ padding: 30 }}>
          {locationGroups &&
            locationGroups.map((group, index_g) => {
              return (
                <div
                  className="space-role-ctn"
                  key={index_g}
                  style={{ marginBottom: 30 }}
                >
                  <div className="ctn-title vf-center">
                    <div className="iconfont icon-img">
                      <i
                        className="iconfont icon-tile"
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    <div className="content">
                      <div className="title">{group.name}</div>
                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                      <i
                        className="iconfont icon-edit"
                        onClick={() => {
                          this.editLocationGroup(group)
                        }}
                        style={{ marginRight: 10 }}
                      />
                      <i
                        className="iconfont icon-delete"
                        onClick={() => {
                          this.deleteLocationGroup(group)
                        }}
                      />
                    </div>
                  </div>
                  <div className="ctn-content">
                    {group.locations.map((location, index_l) => {
                      return (
                        <div
                          className="space-role vf-center"
                          key={index_l}
                          style={{
                            display: 'inline-flex',
                            width: '50%',
                            marginTop: 7,
                            marginBottom: 7
                          }}
                        >
                          <div
                            className="kb-checked-style-circle"
                            style={{ marginRight: 14 }}
                          />
                          <div className="role-name">{location.name}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const locationGroups = getLocationGroups(state)
  return {
    locationGroups
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocationGroupsAction: bindActionCreators(
      locationGroupsAction.get,
      dispatch
    ),
    deleteLocationGroupsAction: bindActionCreators(
      locationGroupsAction.delete,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingLocationGroups)
