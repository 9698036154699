import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { apiCallFailure } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBSelect,
  KBFormError,
  KBButton,
  KBUserInput,
  KBDepartmentInput,
  KBPopover,
  KBPopoverTop
} from 'components'
import * as selectors from 'app/selectors'
import { SPACE_MEMBER_LOCATION_ROLES } from 'app/constants'

const ENABLED_ACTIVE_STATUS = [
  {
    id: 'true',
    name: '激活'
  },
  {
    id: 'false',
    name: '关闭'
  }
]

var MemberLocationRolesForm = React.createClass({
  getInitialState() {
    return {
      settings: null
    }
  },

  componentDidMount() {},

  addMemberLocationRoles(model) {
    //const { locationsData, apiCallFailureActions } = this.props
    //const { location_id, role} = model
    // if(role == 'location_manager'){
    //   let nowLocation = locationsData.filter( location => {
    //     return location.id == location_id
    //   })
    //   let hasLocationManager = nowLocation && nowLocation[0].team.find(team => team.is_manager == true )
    //   if(hasLocationManager){
    //     apiCallFailureActions({
    //       status: 'error',
    //       message :'此分店已经设置负责人,如需更改请到分店设置中进行修改!'
    //     })
    //     return
    //   }
    // }

    const { callBack } = this.props
    model.enabled = 'true'
    KBPopoverTop.close()
    callBack && callBack(model)
  },

  render() {
    const {
      fields: { role, location_id },
      error,
      submitting,
      handleSubmit,
      locations
    } = this.props
    let spaceMemberRoles = SPACE_MEMBER_LOCATION_ROLES

    return (
      <form onSubmit={handleSubmit(this.addMemberLocationRoles)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">添加分店权限</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBUserInput
              title="分店"
              placeholder="选择分店"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={location_id}
              isRequired={true}
              multi={false}
              users={locations}
              selectedUsers={[]}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue=" "
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="角色"
              isRequired={true}
              field={role}
              arrayData={spaceMemberRoles}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const locations = selectors.getSerializeLocation(state)
  //const locationsData = selectors.getLocations(state) || []

  return {
    user,
    locations
    //locationsData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.role)) {
    errors.role = '请选择分店角色'
  }

  if (valid.required(values.location_id)) {
    errors.location_id = '请选择分店'
  }
  return errors
}

const formConfig = {
  form: 'MemberLocationRolesForm',
  fields: ['role', 'location_id'],
  touchOnBlur: false,
  validate: validate
}

MemberLocationRolesForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MemberLocationRolesForm)

export default MemberLocationRolesForm
