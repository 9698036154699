import React from 'react'
import SpaceSettingPointForm from './SpaceSettingPointForm'
import { api } from 'app/services'
import { KBPopover } from 'components'
import { POINT_EVENT } from 'app/constants'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'

var SpaceSettingPoint = React.createClass({
  displayName: 'SpaceSettingPoint',
  getInitialState() {
    return {
      pointSetting: {}
    }
  },
  updatePointEvent(pointEvent, eventType) {
    const { updateCurrentSpace } = this.props
    const { action, time, point } = pointEvent
    KBPopover.show(
      <SpaceSettingPointForm
        action={action}
        initialValues={{
          action: action,
          time: time,
          point: point
        }}
        eventType={eventType}
        updateCurrentSpace={updateCurrentSpace}
        pointSetting={this.props.pointSetting}
      />
    )
  },
  render() {
    const { virtual_currency_name, pointSetting } = this.props
    const {
      lock_event,
      post,
      post_vote,
      reservation,
      feadback,
      user
    } = pointSetting
    return (
      <table className="content-table" style={{ marginTop: 0 }}>
        <thead>
          <tr>
            <th>行为</th>
            <th>规定发生次数</th>
            <th>获得{virtual_currency_name}数</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {lock_event &&
            lock_event.map((lock, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[lock.action]}</td>
                  <td>{lock.time}</td>
                  <td>{lock.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(lock, 'lock_event')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          {post &&
            post.map((p, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[p.action]}</td>
                  <td>{p.time}</td>
                  <td>{p.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(p, 'post')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          {post_vote &&
            post_vote.map((pv, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[pv.action]}</td>
                  <td>{pv.time}</td>
                  <td>{pv.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(pv, 'post_vote')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          {reservation &&
            reservation.map((r, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[r.action]}</td>
                  <td>{r.time}</td>
                  <td>{r.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(r, 'reservation')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          {feadback &&
            feadback.map((f, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[f.action]}</td>
                  <td>{f.time}</td>
                  <td>{f.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(f, 'feadback')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          {user &&
            user.map((u, index) => {
              return (
                <tr key={index}>
                  <td>{POINT_EVENT[u.action]}</td>
                  <td>{u.time}</td>
                  <td>{u.point}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={() => {
                        this.updatePointEvent(u, 'user')
                      }}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }
})

function mapStateToProps(state, props) {
  // const state
  const spaceSetting = selectors.getSpaceSetting(state)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  let pointSetting = spaceSetting.point_events_settings
  return {
    pointSetting,
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceSettingPoint)
