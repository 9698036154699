import React, { Component, PropTypes } from 'react'
import StatisticLockRecord from './lock/StatisticLockRecord'
import StatisticIndependentLockRecord from './lock/StatisticIndependentLockRecord'

var StatisticLockBox = React.createClass({
  displayName: 'StatisticLockBox',
  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticLockRecord group_id={this.props.group_id} />
          <StatisticIndependentLockRecord group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticLockBox
