import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'

var LocationSettingFinanceForm = React.createClass({
  setLocation(model) {
    const { updateLocation } = this.props
    const { finance_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'finance',
          range: 'all',
          user_ids: finance_ids ? finance_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { finance_ids },
      error,
      submitting,
      handleSubmit,
      users,
      financeManager
    } = this.props
    const hint = FIX_LOCATION
    return (
      <form onSubmit={handleSubmit(this.setLocation)}
      >
        <div>
          <div className="kb-row">
            <KBUserInput
              title="财务人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.finance}
              field={finance_ids}
              multi={true}
              users={users}
              selectedUsers={financeManager}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation
  const financeManager = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'finance'
  )

  return {
    updateLocation,
    users,
    financeManager
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.finance_ids)) {
    errors.finance_ids = '请选择至少一位财务人员'
  }

  return errors
}

const formConfig = {
  form: 'LocationSettingFinanceForm',
  fields: ['finance_ids'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(LocationSettingFinanceForm)
