import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect, KBInput } from 'components'
import * as selectors from 'app/selectors'

var SpaceSettingPrepayCard = React.createClass({
  displayName: 'SpaceSettingPrepayCard',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          invoice_settings: model
        })
      },
      {
        key: 'invoice_settings'
      }
    )
  },

  render() {
    const {
      fields: { prepay_card_mchid },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <KBInput
              title="商户号"
              className="kb-form-group kb-form-c39 kb-form-alone"
              field={prepay_card_mchid}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  const {prepay_card_mchid = ''} = (setting.invoice_settings || {})
  return {
    // input组件不展示number类型的数据？？？这里转成字符串
    initialValues: { prepay_card_mchid: String(prepay_card_mchid) }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const formConfig = {
  form: 'SpaceSettingPrepayCard',
  fields: ['prepay_card_mchid'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(SpaceSettingPrepayCard)
