import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { STATISTIC_CYCLE, STATISTIC_MODE } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticCrmWinUserExpectedRate = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate()
    return {
      filters: {
        from,
        to,
        cycle: 'weekly',
        mode: 'user'
      },
      data: [],
      month: ''
    }
  },

  componentDidMount() {
    const { filters, data } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')

    this.mounted = true
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'spline'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: '时间'
          },
          labels: {
            format: '{value:%Y-%m-%d}'
          }
        },
        yAxis: {
          title: {
            text: '预计数量'
          }
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '日期：{point.x:%Y-%m-%d}, 数量：{point.y}'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        series: data
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { from, to, cycle, mode } = filters
    var params = {}

    params.cycle = cycle
    params.mode = mode
    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.getCustomerWinOrFailedStateStatistic(params).then(({ json }) => {
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    var contain = {}
    let data = []
    const {
      filters: { cycle }
    } = this.state

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    var array = Object.values(json)

    array.forEach((json, index) => {
      let dd = {}
      // let dr = {}
      dd.data = []
      // dr.data = []
      dd.name = `${json.name}`
      // dr.name = `${json.name}赢单数／总客户数`

      dd.visible = false

      if (index == 0) {
        dd.visible = true
      }

      json.statistics &&
        json.statistics.forEach(t => {
          if (!t) {
            return
          }

          let { year, month, day } = getYMD(t.statistic_date)
          dd.data.push([Date.UTC(year, month, day), t.expected_win_count || 0])
          // if (t.active_count) {
          //   dr.data.push([Date.UTC(year, month, day), parseFloat(percent(t.active_count, t.win_count))])
          // }
        })
      dd.data.sort(this.compare)
      // dr.data.sort(this.compare)
      data.push(dd)
      // data.push(dr)
    })

    this.fillChart(data)
  },

  compare(a, b) {
    if (a[0] - b[0] > 0) {
      return 1
    } else if (a[0] - b[0] < 0) {
      return -1
    }

    return 0
  },

  fillChart(data) {
    this.setSerierDate(data)
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  cycleChange(data) {
    this._setFilters({
      cycle: data
    })
  },

  modeChange(data) {
    this._setFilters({
      mode: data
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render() {
    const { filters, data, month } = this.state
    const { from, to, cycle, mode } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>CRM用户赢单总预计数比较</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCrmWinUserExpectedRate)
