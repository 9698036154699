import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBPopoverTop } from 'components'
import LocationProviderForm from './LocationProviderForm'
import { KBNoData } from 'components'
import { api } from 'app/services'
import { notification } from 'antd'

var LocationProviderSetForm = React.createClass({
  displayName: 'LocationProviderSetForm',
  componentDidMount() {
    const {
      fields: { location_providers },
      location,
      groups_id
    } = this.props
    api.getGroupProviders({location_group_id:groups_id}).then(res=>{
      const { json } = res
      if(json&&Array.isArray(json)){
        json.forEach(item=>{
          location_providers.addField(this.addProviderField(item))
        })
      }
    })
    // location.location_providers &&
    //   location.location_providers.length > 0 &&
    //   location.location_providers.map(provider => {
    //     location_providers.addField(this.addProviderField(provider))
    //   })
  },

  addProviderField(provider) {
    return {
      id: provider.id,
      info: provider.info,
      contact_name: provider.contact_name,
      contact_phone: provider.contact_phone,
      contact_email: provider.contact_email,
      account_bank: provider.account_bank,
      account_name: provider.account_name,
      account_number: provider.account_number,
      physical_address: provider.physical_address,
      business_license_number: provider.business_license_number,
      swift_code: provider.swift_code
    }
  },

  providerField(provider) {
    const {
      fields: { location_providers }
    } = this.props
    location_providers.addField({
      info: provider.info,
      contact_name: provider.contact_name,
      contact_phone: provider.contact_phone,
      contact_email: provider.contact_email,
      account_bank: provider.account_bank,
      account_name: provider.account_name,
      account_number: provider.account_number,
      physical_address: provider.physical_address,
      business_license_number: provider.business_license_number,
      swift_code: provider.swift_code,
      modified: true
    })
  },

  providerFieldUpdate(provider, index) {
    const {
      fields: { location_providers }
    } = this.props
    location_providers[index].info.onChange(provider.info)
    location_providers[index].contact_name.onChange(provider.contact_name)
    location_providers[index].contact_phone.onChange(provider.contact_phone)
    location_providers[index].contact_email.onChange(provider.contact_email)
    location_providers[index].account_bank.onChange(provider.account_bank)
    location_providers[index].account_name.onChange(provider.account_name)
    location_providers[index].account_number.onChange(provider.account_number)
    location_providers[index].physical_address.onChange(
      provider.physical_address
    )
    location_providers[index].business_license_number.onChange(
      provider.business_license_number
    )
    location_providers[index].swift_code.onChange(provider.swift_code)
    location_providers[index].modified.onChange(true)
  },

  addLocationProvider() {
    KBPopoverTop.show(<LocationProviderForm callBack={this.providerField} />)
  },

  updateLocationProvider(provider, index) {
    KBPopoverTop.show(
      <LocationProviderForm
        provider={provider}
        index={index}
        callBack={this.providerFieldUpdate}
      />
    )
  },

  updateLocationProviders(model) {
    const {
      fields: { location_providers },
      groups_id
    } = this.props
    let newProviders = model.location_providers || []
    newProviders.map((pro, index) => {
      delete pro.modified
      location_providers[index].modified.onChange(false)
      return pro
    })
    api.undateGroupProviders({location_group_id:groups_id, location_providers:newProviders}).then(res=>{

      const { json } = res
      if(json&&Array.isArray(json)){
        notification.success({message:'保存成功', duration:1.5})
      }else{
        notification.error({message:'保存失败', duration:1.5})
      }
    })
    // return (
    //   updateLocation && updateLocation({ location_providers: newProviders })
    // )
  },

  render() {
    const {
      fields: { location_providers },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateLocationProviders)}>
        <KBFormError err={error} />
        <fieldset className="fieldset">
          <div
            className="kb-title m-bottom-sm"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>收款账户信息(银行)</span>
            <div className="bordered-btn" onClick={this.addLocationProvider}>
              添加账户信息
            </div>
          </div>
          {location_providers.length > 0 ? (
            <div>
              {location_providers.map((provider, index) => {
                const modified = provider.modified.value
                return (
                  <div className="location-provider-container">
                    {modified ? (
                      <div className="tip-msg">
                        <div className="icon">
                          <i className="iconfont icon-hint" />
                        </div>
                        <div>
                          请点击下方“保存”按钮以确认保存当前收款账户信息
                        </div>
                      </div>
                    ) : null}
                    <div
                      key={index}
                      className="location-provider"
                      style={modified ? { border: '1px dashed #DFE4E7' } : {}}
                    >
                      <span
                        className="operate-provider delete-provider"
                        onClick={() => {
                          location_providers.removeField(index)
                        }}
                      >
                        <i className="iconfont icon-delete" />
                      </span>
                      <span
                        className="operate-provider update-provider"
                        onClick={this.updateLocationProvider.bind(
                          null,
                          provider,
                          index
                        )}
                      >
                        <i className="iconfont icon-edit" />
                      </span>
                      <div className="provider-line">
                        <div>
                          <span>账户信息</span>
                          <span>{provider.info.value || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户开户行</span>
                          <span>{provider.account_bank.value || '暂无'}</span>
                        </div>
                        <div>
                          <span>账户地址</span>
                          <span>
                            {provider.physical_address.value || '暂无'}
                          </span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户名</span>
                          <span>{provider.account_name.value || '暂无'}</span>
                        </div>
                        <div>
                          <span>账号</span>
                          <span>{provider.account_number.value || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>联系人</span>
                          <span>{provider.contact_name.value || '暂无'}</span>
                        </div>
                        <div>
                          <span>联系方式</span>
                          <span>{provider.contact_phone.value || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>统一社会信用代码</span>
                          <span>
                            {(provider.business_license_number &&
                              provider.business_license_number.value) ||
                              '暂无'}
                          </span>
                        </div>
                        <div>
                          <span>SWIFT代码</span>
                          <span>
                            {(provider.swift_code &&
                              provider.swift_code.value) ||
                              '暂无'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <KBNoData tipMessage="暂无账户信息" />
          )}
        </fieldset>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}

  return errors
}

function mapStateToProps(state, props) {
  return {}
}

const formConfig = {
  form: 'LocationProviderSetForm',
  fields: [
    'location_providers[].info',
    'location_providers[].contact_name',
    'location_providers[].contact_phone',
    'location_providers[].contact_email',
    'location_providers[].account_bank',
    'location_providers[].account_name',
    'location_providers[].account_number',
    'location_providers[].physical_address',
    'location_providers[].id',
    'location_providers[].modified',
    'location_providers[].business_license_number',
    'location_providers[].swift_code'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(LocationProviderSetForm)
