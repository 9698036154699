import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'
import { getDataOfSourceByIDS } from 'utils/kbUtil'

var LocationSettingInvoiceVerifyForm = React.createClass({
  updateInvoiceVerify(model) {
    const { updateLocation } = this.props
    const { check_users, confirm_users, invoice_paid_checker } = model
    return updateLocation({
      roles: [
        {
          role: 'invoice_check',
          range: 'all',
          user_ids: check_users ? check_users.split(',') : []
        },
        {
          role: 'invoice_confirm',
          range: 'all',
          user_ids: confirm_users ? confirm_users.split(',') : []
        },
        {
          role: 'invoice_check_notify',
          range: 'all',
          user_ids: invoice_paid_checker ? invoice_paid_checker.split(',') : []
        }
      ]
    })
  },

  render() {
    const {
      fields: { check_users, confirm_users, invoice_paid_checker },
      error,
      submitting,
      handleSubmit,
      users,
      checkUsers,
      confirmUsers,
      paidUsers
    } = this.props
    const hint = FIX_LOCATION

    return (
      <form onSubmit={handleSubmit(this.updateInvoiceVerify)}>
        <div>
          <div className="kb-row">
            <KBUserInput
              title="核销人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.paid_checker}
              field={check_users}
              multi={true}
              users={users}
              selectedUsers={checkUsers}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="核销通知人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint="可在核销完成后接收到通知信息的人员"
              field={invoice_paid_checker}
              multi={true}
              users={users}
              selectedUsers={paidUsers}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="确认核销人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.confirmed_checker}
              field={confirm_users}
              multi={true}
              users={users}
              selectedUsers={confirmUsers}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const checkUsers = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'invoice_check'
  )
  const confirmUsers = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'invoice_confirm'
  )
  const paidUsers = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'invoice_check_notify'
  )
  const users = selectors.getSpaceUsers(state)

  return {
    users,
    loc_id,
    checkUsers,
    confirmUsers,
    paidUsers
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.check_users)) {
    errors.check_users = '请选择至少一位核销人员'
  }

  if (valid.required(values.invoice_paid_checker)) {
    errors.invoice_paid_checker = '请选择至少一位接收核销通知的人员'
  }

  if (valid.required(values.confirm_users)) {
    errors.confirm_users = '请选择至少一位确认核销人员'
  }

  return errors
}

const formConfig = {
  form: 'LocationSettingInvoiceVerifyForm',
  fields: ['check_users', 'confirm_users', 'invoice_paid_checker'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSettingInvoiceVerifyForm)
