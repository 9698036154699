import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { KBPopover, KBTipsy } from 'components'
import {
  SUBSCRIPTION_STATE_OBJ,
  SUBSCRIPTION_STATE_CLASSNAME
} from 'app/constants'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { formatYearDayEN } from 'utils/kbUtil'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

var SpaceTempleteList = React.createClass({
  displayName: 'SpaceTempleteList',
  getInitialState() {
    const { setting_value } = this.props
    return {
      copyTemplete: null,
      isActive: setting_value
    }
  },
  createTemplete() {
    const { copyTemplete } = this.state
    const { routerActions } = this.props

    if (copyTemplete) {
      routerActions.push(
        `/admin/spaces_setting/templete/edit?type=copy&token=${copyTemplete}`
      )
      return
    }
    routerActions.push('/admin/spaces_setting/templete/edit?type=create')
  },

  deleteTemplete(id) {
    const { deleteTempletesActions, successAction } = this.props
    KBPopover.plugins.confirm('删除模板', '您是否删除该模板?', {
      confirm: () => {
        api.deleteSubscriptionTempletes(id).then(
          json => {
            deleteTempletesActions.success(json, {
              id,
              key: 'subscriptionTempletes'
            })
            successAction({ message: '删除模板成功!' })
            KBPopover.close()
          },
          e => {
            deleteTempletesActions.failure(e)
          }
        )
      }
    })
  },
  disabledTemplete(id, status = 'disabled') {
    const { updateTempletesActions, successAction } = this.props
    let changeStatus
    if (status == 'disabled') {
      changeStatus = 'enabled'
    } else if (status == 'enabled') {
      changeStatus = 'disabled'
    } else if (status == 'draft') {
      changeStatus = 'enabled'
    }
    const isDisabled = status != 'disabled' && status != 'draft'
    KBPopover.plugins.confirm(
      isDisabled ? '禁用模板' : '启用模板',
      isDisabled ? '您是否禁用该模板?' : '您是否启用该模板',
      {
        confirm: () => {
          api
            .putSubscriptionTemplete(id, {
              status: changeStatus
            })
            .then(
              json => {
                updateTempletesActions.success(json, {
                  id,
                  key: 'subscriptionTempletes'
                })
                successAction({
                  message: isDisabled ? '禁用模板成功!' : '启用模板成功!'
                })
                KBPopover.close()
              },
              e => {
                updateTempletesActions.failure(e)
              }
            )
        }
      }
    )
  },
  editTemplete(id) {
    const { routerActions } = this.props
    routerActions.push(
      '/admin/spaces_setting/templete/edit?type=edit&token=' + id
    )
  },
  renderEditStateJSX(templete) {
    const { name, status, id, created_at, updated_at, template_url } = templete

    return (
      <td className="templete">
        <KBDownloadPrivacy url={template_url}>
          <KBTipsy content={template_url ? '下载' : '无'}>
            {template_url ? (
                <span className="templete-download a-hover" />
            ) : (
              <span className="templete-empty">-</span>
            )}
          </KBTipsy>
        </KBDownloadPrivacy>

        <KBTipsy content="编辑">
          <i
            className="iconfont icon-edit m-right-sm"
            onClick={this.editTemplete.bind(null, id)}
          />
        </KBTipsy>
        <KBTipsy content="删除">
          <i
            className="iconfont icon-delete"
            onClick={this.deleteTemplete.bind(null, id)}
          />
        </KBTipsy>
      </td>
    )
  },
  settingTempleteValue(state) {
    const { updateCurrentSpace } = this.props
    let templetState = state ? true : false
    this.setState({
      isActive: templetState
    })
    updateCurrentSpace(
      {
        settings: JSON.stringify({
          subscription_settings: {
            need_subscription_templete: templetState
          }
        })
      },
      {
        key: 'subscription_settings'
      }
    )
  },
  renderSpaceTemplate() {
    const { isActive } = this.state

    return (
      <div className="clear-fix">
        <span style={{ fontSize: 14 }}>在创建合同时，是否必须使用合同模板</span>
        <div
          className="slide-btn f-right"
          onClick={this.settingTempleteValue.bind(null, !isActive)}
        >
          <span
            className={`slide-bg ${isActive ? 'slide-left' : 'slide-right'}`}
            style={{ marginTop: 3 }}
          >
            <span className="slide-move" />
          </span>
        </div>
      </div>
    )
  },
  render() {
    const { subscriptionTempletes } = this.props

    return (
      <div
        className="kb-form-container"
        style={{
          padding: '30px'
        }}
      >
        <p style={{ marginBottom: 15 }}>
          为保证合同格式的统一性，可以创建合同模版。运营人员根据输入的合同详情选择模版自动创建合同文件。如果需要临时修改合同文本，可以在合同创建过程中通过上传补充协议的方式来实现。只有启用的合同模版才会用于自动生成合同。
        </p>
        <fieldset className="fieldset">
          {this.renderSpaceTemplate()}
          <div>
            <div
              className="m-top-sm border-bottom"
              style={{ paddingBottom: 15 }}
            >
              <button
                className="bordered-btn"
                style={{ marginLeft: 0 }}
                onClick={this.createTemplete}
              >
                <i className="iconfont icon-add" />
                新增模板
              </button>
            </div>
            <div>
              <table className="content-table" style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th>模板名称</th>
                    <th className="t-center">模板状态</th>
                    <th>创建日期</th>
                    <th>更新日期</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionTempletes.map((templete, index) => {
                    const {
                      name,
                      status,
                      id,
                      created_at,
                      updated_at
                    } = templete
                    return (
                      <tr key={index}>
                        <td>{name}</td>
                        <td className="t-center">
                          {
                            <KBTipsy content="点击修改当前模板状态">
                              <span
                                className={SUBSCRIPTION_STATE_CLASSNAME[status]}
                                onClick={this.disabledTemplete.bind(
                                  null,
                                  id,
                                  status
                                )}
                              >
                                {SUBSCRIPTION_STATE_OBJ[status]}
                              </span>
                            </KBTipsy>
                          }
                        </td>
                        <td>{formatYearDayEN(created_at)}</td>
                        <td>{formatYearDayEN(updated_at)}</td>
                        {this.renderEditStateJSX(templete)}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.subscription_settings.need_subscription_templete
  const subscriptionTempletes = selectors.getSubscriptionTempletes(state)
  return {
    setting_value,
    subscriptionTempletes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteTempletesActions: bindActionCreators(
      actions.subscriptionTempletesAction.delete,
      dispatch
    ),
    updateTempletesActions: bindActionCreators(
      actions.subscriptionTempletesAction.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceTempleteList)
