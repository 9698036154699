import React from 'react'
import SpaceSettingServiceForm from './SpaceSettingServiceForm'

var SpaceSettingService = React.createClass({
  displayName: 'SpaceSettingService',
  render() {
    const { updateCurrentSpace, setting } = this.props

    if (!setting.settings) {
      return null
    }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>服务商设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>服务申请</legend>
            <SpaceSettingServiceForm
              setting={setting}
              updateCurrentSpace={updateCurrentSpace}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpaceSettingService
