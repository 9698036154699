import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'

var VisitNotifySet = React.createClass({
  changeSlidesVisiblity(field) {
    const { updateVisitorSettings, config } = this.props
    let value = !this.props.notify[field]
    let { notify } = config
    notify[field] = value
    config.notify = notify
    updateVisitorSettings(config)
  },

  render() {
    const { enable_host, wechat, email, sms, dingding } = this.props.notify

    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>访客通知</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">常规设置</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">访客到访通知</div>
                  <div className="des">当访客到访时通知相关员工</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(null, 'enable_host')}
                >
                  <span
                    className={`slide-bg ${
                      enable_host ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%', marginTop: 10 }}
            >
              <span className="color-six">通知方式</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">微信推送通知</div>
                  <div className="des">当访客到达时微信向员工发送推送通知</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(null, 'wechat')}
                >
                  <span
                    className={`slide-bg ${
                      wechat ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">邮箱推送通知</div>
                  <div className="des">当访客到达时邮件向员工发送推送通知</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(null, 'email')}
                >
                  <span
                    className={`slide-bg ${
                      email ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">短信推送通知</div>
                  <div className="des">当访客到达时短信向员工发送推送通知</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(null, 'sms')}
                >
                  <span
                    className={`slide-bg ${sms ? 'slide-left' : 'slide-right'}`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">钉钉通知</div>
                  <div className="des">
                    当访客到达时钉钉APP向员工发送推送通知,
                    钉钉通知需要使用酷办企业平台
                  </div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(null, 'dingding')}
                >
                  <span
                    className={`slide-bg ${
                      dingding ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config } = props
  let notify = (config && config.notify) || {}

  return {
    notify,
    config
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitNotifySet)
