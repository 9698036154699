import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBInput, KBButton, KBPopoverTop } from 'components'

var LocationProviderForm = React.createClass({
  getInitialState() {
    return {}
  },

  createLocationProvider(model) {
    const { callBack, index } = this.props
    callBack && callBack(model, index)
    KBPopoverTop.close()
  },

  render() {
    const {
      fields: {
        info,
        contact_name,
        contact_phone,
        account_bank,
        account_name,
        account_number,
        contact_email,
        physical_address,
        business_license_number,
        swift_code
      },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.createLocationProvider)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加收款账户信息(银行)</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={info}
                title="账户信息"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={physical_address}
                title="账户地址"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={account_bank}
                title="账户开户银行"
                isRequired={true}
              />
            </div>
            <div className="kb-row kb-form-66">
              <KBInput
                className="kb-form-group kb-div-48"
                field={account_name}
                title="账户名"
                isRequired={true}
              />
              <KBInput
                className="kb-form-group kb-div-48"
                field={account_number}
                title="账号"
                titleClass="label-rt"
                isRequiredR={true}
              />
            </div>
            <div className="kb-form-66">
              <KBInput
                className="kb-form-group kb-div-48"
                field={contact_name}
                title="联系人"
                isRequired={true}
              />
              <KBInput
                className="kb-form-group kb-div-48"
                field={contact_phone}
                title="联系方式"
                titleClass="label-rt"
                isRequiredR={true}
              />
            </div>
            <div className="kb-form-66">
              <KBInput
                className="kb-form-group kb-div-48"
                field={business_license_number}
                title="统一社会信用代码"
                isRequired={true}
              />
              <KBInput
                className="kb-form-group kb-div-48"
                field={swift_code}
                title="SWIFT代码"
                titleClass="label-rt"
                isRequiredR={true}
              />
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { provider } = props

  return {
    initialValues: {
      info: provider && provider.info.value,
      contact_name: provider && provider.contact_name.value,
      contact_phone: provider && provider.contact_phone.value,
      account_bank: provider && provider.account_bank.value,
      account_name: provider && provider.account_name.value,
      account_number: provider && provider.account_number.value,
      physical_address: provider && provider.physical_address.value,
      business_license_number:
        provider && provider.business_license_number.value,
      swift_code: provider && provider.swift_code.value
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.info)) {
    errors.info = '请填写收款账户信息'
  }
  if (valid.required(values.contact_name)) {
    errors.contact_name = '请填写账户联系人'
  }
  if (valid.required(values.physical_address)) {
    errors.physical_address = '请填写账户联系地址'
  }
  if (valid.required(values.contact_phone)) {
    errors.contact_phone = '请填写账户联系电话'
  }
  if (valid.required(values.account_bank)) {
    errors.account_bank = '请填写账户开户银行'
  }
  if (valid.required(values.account_name)) {
    errors.account_name = '请填写账户名'
  }
  if (valid.required(values.account_number)) {
    errors.account_number = '请填写账号'
  }
  if (valid.required(values.business_license_number)) {
    errors.business_license_number = '请填写统一社会信用代码'
  }
  if (valid.required(values.swift_code)) {
    errors.swift_code = '请填写SWIFT代码'
  }
  return errors
}

const formConfig = {
  form: 'LocationProviderForm',
  fields: [
    'info', // 甲方信息
    'contact_name', // 甲方联系人
    'contact_phone', // 甲方联系方式
    'account_bank', // 甲方开户银行
    'account_name', // 甲方账户名
    'account_number', // 甲方账户号
    'contact_email', // 甲方邮箱地址
    'physical_address', // 甲方地址,
    'business_license_number', //统一社会信用代码
    'swift_code' //SWIFT代码
  ],
  validate: validate,
  touchOnBlur: false
}

LocationProviderForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationProviderForm)

export default LocationProviderForm
