import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBInput,
  KBLoadingContainer,
  KBSelect
} from 'components'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { ENTER_RATE_CALC_TYPE, AREA_CONTRACT_ENTERING_WAO } from 'app/constants'
import * as selectors from 'app/selectors'

var LocationSetArea = React.createClass({
  displayName: 'LocationSetArea',

  getInitialState() {
    return {}
  },

  componentDidMount() {
    const { locationAreaSettings } = this.props
    this.updateFileds(locationAreaSettings)
  },

  updateFileds(setting = {}) {
    const {
      fields: {
        m2_per_desk,
        house_fee_per_m2,
        entering_rate_calc_type,
        entering_subscription_compute_way,
        area_physical_rate,
        area_rentable_rate
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    m2_per_desk.onChange((setting && setting.m2_per_desk) || 0)
    house_fee_per_m2.onChange((setting && setting.house_fee_per_m2) || 0)
    entering_rate_calc_type.onChange(setting && setting.entering_rate_calc_type)
    entering_subscription_compute_way.onChange(
      setting && setting.entering_subscription_compute_way
    )
    area_physical_rate.onChange(
      Math.floor(
        parseFloat((setting && setting.area_physical_rate) || 0) * 1000000
      ) / 10000
    )
    area_rentable_rate.onChange(
      Math.floor(
        parseFloat((setting && setting.area_rentable_rate) || 0) * 1000000
      ) / 10000
    )
  },

  updateLocationSettings(model) {
    const { updateLocationSetting } = this.props
    model.area_physical_rate =
      Math.floor(model.area_physical_rate * 10000) / 1000000
    model.area_rentable_rate =
      Math.floor(model.area_rentable_rate * 10000) / 1000000
    let newLocationSettings = {}
    newLocationSettings.entering_rate_calc_type = model.entering_rate_calc_type
    newLocationSettings.entering_subscription_compute_way =
      model.entering_subscription_compute_way
    delete model.entering_rate_calc_type
    delete model.entering_subscription_compute_way
    return updateLocationSetting(
      {
        area_settings: model,
        location_settings: newLocationSettings
      },
      {
        key: ['area_settings', 'location_settings']
      }
    )
  },

  render() {
    const {
      fields: {
        m2_per_desk,
        house_fee_per_m2,
        entering_rate_calc_type,
        entering_subscription_compute_way,
        area_physical_rate,
        area_rentable_rate
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    const isScale = entering_rate_calc_type.value === 'by_scale'

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>工位设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateLocationSettings)}>
            <KBFormError error={this.props.error} />
            <div
              className="kb-form-container"
              style={{ padding: '0 400px 0 0' }}
            >
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="平方米/工位"
                  field={m2_per_desk}
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="月物业费面积单价"
                  field={house_fee_per_m2}
                />
              </div>
              <div className="kb-row">
                <KBSelect
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  field={entering_rate_calc_type}
                  title="入驻率计算方式"
                  arrayData={ENTER_RATE_CALC_TYPE}
                />
              </div>
              {isScale ? (
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-div-c48 kb-form-alone"
                    title="总物理面积比率"
                    field={area_physical_rate}
                    inputClass="input-percent"
                    hint="使用率"
                    type="number"
                  />
                </div>
              ) : null}
              {isScale ? (
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-div-c48 kb-form-alone"
                    title="可出租面积比率"
                    field={area_rentable_rate}
                    inputClass="input-percent"
                    hint="签约得房率"
                    type="number"
                  />
                </div>
              ) : null}
              <div className="kb-row">
                <KBSelect
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  field={entering_subscription_compute_way}
                  title="合同入驻计算方式"
                  arrayData={AREA_CONTRACT_ENTERING_WAO}
                />
              </div>
              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  let area_settings = locationSetting.area_settings || {}
  let location_settings = locationSetting.location_settings || {}

  let locationAreaSettings = Object.assign({}, area_settings, location_settings)
  const {
    m2_per_desk,
    house_fee_per_m2,
    area_physical_rate,
    area_rentable_rate,
    entering_rate_calc_type,
    entering_subscription_compute_way
  } = locationAreaSettings

  return {
    locationAreaSettings,
    initialValues: {
      m2_per_desk,
      house_fee_per_m2,
      area_physical_rate:
        Math.floor(parseFloat(area_physical_rate || 0) * 1000000) / 10000,
      area_rentable_rate:
        Math.floor(parseFloat(area_rentable_rate || 0) * 1000000) / 10000,
      entering_rate_calc_type,
      entering_subscription_compute_way
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.Float(values.m2_per_desk) || values.m2_per_desk < 0) {
    errors.m2_per_desk = '请输入正确格式'
  }

  if (valid.Float(values.house_fee_per_m2) || values.house_fee_per_m2 < 0) {
    errors.house_fee_per_m2 = '请输入正确格式'
  }

  return errors
}

const formConfig = {
  form: 'LocationSetArea',
  fields: [
    'm2_per_desk',
    'house_fee_per_m2',
    'entering_rate_calc_type',
    'entering_subscription_compute_way',
    'area_physical_rate',
    'area_rentable_rate'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetArea)
