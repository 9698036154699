import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBASNumberInput, KBCheckBox } from 'components'

var SpacesCrmForm = React.createClass({
  displayName: 'SpacesCrmForm',
  getInitialState() {
    return {
      crmMessageChecked: !this.props.isDisabled
    }
  },

  updateCRMSpace(model) {
    const { updateCurrentSpace } = this.props
    const { crmMessageChecked } = this.state

    model.crm_message_setting_is_disabled =
      crmMessageChecked == true ? false : true

    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          crm_settings: model
        })
      },
      {
        key: 'crm_settings'
      }
    )
  },

  crmMessageSetting(status) {
    this.setState({
      crmMessageChecked: status
    })
  },

  render() {
    const {
      fields: { crm_message_setting, crm_message_setting_is_disabled },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { crmMessageChecked } = this.state

    return (
      <form onSubmit={handleSubmit(this.updateCRMSpace)}>
        <KBFormError error={this.props.error} />
        <div className="clear-fix">
          <div className="f-left">
            <KBCheckBox
              checked={crmMessageChecked}
              callback={this.crmMessageSetting}
              style={{ display: 'inline-block' }}
            />
            <span style={{ verticalAlign: 'middle' }}>
              &nbsp;&nbsp;当销售任务在&nbsp;&nbsp;(&nbsp;
              <span className="color-link">{crm_message_setting.value}</span>
              &nbsp;)&nbsp;&nbsp;个工作日内未更新则自动发送通知提醒负责人更新状态
            </span>
          </div>
          <KBASNumberInput
            className="f-right"
            field={crm_message_setting}
            type="number"
          />
        </div>
        <div className="kb-row m-top-sm">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            确定
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting.crm_settings
  let crm_message = setting.crm_message_setting
  if (crm_message == 'admin') {
    crm_message = 0
  }
  let isDisabled = setting.crm_message_setting_is_disabled

  return {
    setting,
    crm_message,
    isDisabled,
    initialValues: {
      crm_message_setting: crm_message,
      crm_message_setting_is_disabled: isDisabled
    }
  }
}

const formConfig = {
  form: 'SpacesCrmForm',
  fields: ['crm_message_setting', 'crm_message_setting_is_disabled'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpacesCrmForm)
