import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import reducers from '../app/reducers'

// adds action.globalState() function to retrieve global redux state from tree
function globalState({ getState }) {
  return next => action => {
    next({ ...action, getGlobaState: getState })
  }
}

export default (initialState = {}, history) => {
  let middleware = applyMiddleware(routerMiddleware(history), globalState)

  const store = createStore(reducers(), initialState, middleware)

  store.asyncReducers = {}

  return store
}
