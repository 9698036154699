import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { dynamicAttributesActions } from 'app/actions'
import { OPPORTUNITIES_SOURCE } from 'app/constants'
import {
  KBFormError,
  KBButton,
  KBInput,
  KBRadio,
  KBSelect,
  KBPopover
} from 'components'

var SpacesCrmDynamicAttributesForm = React.createClass({
  displayName: 'SpacesCrmDynamicAttributesForm',
  addDynamicAttribute(model) {
    const { createDynamicAttributesActions, type, add } = this.props
    model.type_scoped = 'Opportunity'
    model.datatype = type.id
    model.default = model._default
    delete model._default
    return api.createDynamicAttributes(model).then(
      json => {
        add(json.response.body)
        KBPopover.close()
      },
      err => {
        createDynamicAttributesActions.failure(err)
      }
    )
  },
  render() {
    const {
      fields: { name, pretty_name_cn, _default, is_required },
      error,
      submitting,
      handleSubmit,
      type
    } = this.props
    const boolData = [
      { title: '是', value: true },
      { title: '否', value: false }
    ]

    return (
      <form onSubmit={handleSubmit(this.addDynamicAttribute)}>
        <header className="kb-form-header">
          <h2 className="header-title">新增{type.name}字段</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="popup-left-6">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-alone"
                title="字段名称"
                field={pretty_name_cn}
                isRequired={true}
                fistFoucs={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-alone"
                title="API名称"
                field={name}
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-alone"
                title="默认值"
                field={_default}
                isRequired={false}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-alone"
                title="是否必填"
                field={is_required}
                isRequired={false}
              />
            </div>
          </div>

          <div className="popup-right-4">
            <div className="kb-title" style={{ border: 0 }}>
              <span className="color-six">示例</span>
            </div>
            <div className="kb-row kb-form-group">
              <input className="kb-input" type="text" value={type.id} />
            </div>
          </div>

          <div className="kb-row t-right" style={{ marginTop: '250px' }}>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              style={{ marginRight: 0 }}
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createDynamicAttributesActions: bindActionCreators(
      dynamicAttributesActions.create,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入API名称'
  }

  if (valid.required(values.pretty_name_cn)) {
    errors.pretty_name_cn = '请输入字段名称'
  }

  return errors
}

const formConfig = {
  form: 'SpacesCrmDynamicAttributesForm',
  fields: ['name', 'pretty_name_cn', '_default', 'is_required'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpacesCrmDynamicAttributesForm)
