import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import * as selectors from 'app/selectors'
import { api } from 'app/services'
import {
  locationActions,
  successState,
  locationSettingAction,
  apiCallFailure
} from 'app/actions'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
var VisitAutomaticCheck = React.createClass({
  changeSlidesVisiblity(visitorStatus) {
    const { location_id, updateLocationAction, successAction } = this.props
    const newVisitorStatus = !visitorStatus
    api
      .updateLocation(location_id, {
        visitor_require_approval: newVisitorStatus
      })
      .then(
        json => {
          updateLocationAction.success(json)
          newVisitorStatus
            ? successAction({ message: '已关闭!' })
            : successAction({ message: '已开启!' })
        },
        errors => {
          updateLocationAction.failure(errors)
        }
      )
  },

  updateLocationSetting(visitorStatus, { key }) {
    const {
      locationSettingActions,
      location_id,
      successAction,
      location_settings,
      apiCallFailureAction
    } = this.props
    const newVisitorStatus = !visitorStatus
    const model = location_settings
    model.visitor_require_approval = newVisitorStatus
    return api
      .putSpaceSetting({
        target_type: 'Location',
        target_id: location_id,
        settings: { location_settings: model }
      })
      .then(
        json => {
          locationSettingActions.success(json, { location_id, key })
          !newVisitorStatus
            ? successAction({ message: '已开启!' })
            : successAction({ message: '已关闭!' })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message || ''
          })
          locationSettingActions.failure(error)
        }
      )
  },

  render() {
    // const { allow_sign_out, auto_sign_out } = this.props.signout
    const { visitorRequireApproval } = this.props
    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>自动审核</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">自动审核</div>
                  <div className="des">
                    打开后，所有访客(除了黑名单中访客)的申请会被自动通过，记录中的“审核人”为分店的“物业负责人”
                  </div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.updateLocationSetting.bind(
                    null,
                    visitorRequireApproval,
                    { key: ['location_settings'] }
                  )}
                >
                  <span
                    className={`slide-bg ${
                      !visitorRequireApproval ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config, params } = props
  const { locationSetting } = state
  let signout = (config && config.signout) || {}
  const location_id = params.id
  // const currentLocation = selectors.getCurrentLocation(state,location_id)
  // let visitorVisible = currentLocation && currentLocation.visitor_visible
  const location_settings = get(
    locationSetting,
    `${location_id}.location_settings`,
    {}
  )
  const visitorRequireApproval = get(
    location_settings,
    'visitor_require_approval',
    true
  )
  return {
    signout,
    config,
    location_id,
    location_settings,
    visitorRequireApproval
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    locationSettingActions: bindActionCreators(
      locationSettingAction.update,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitAutomaticCheck)
