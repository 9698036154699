import React from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { KBFormError, KBButton, KBUserInput, KBASNumberInput } from 'components'
import { KBSelect } from 'components/views'
import { meetingLocationSettingActions, successState } from 'app/actions'
import { api } from 'app/services'
import {
  DAY_TIME_HM,
  DAY_TIME_HOUR,
  AREATYPES_STATES,
  AREATYPES_TIPSY
} from 'app/constants'
import * as selectors from 'app/selectors'

var LocationSetMeeting = React.createClass({
  displayName: 'LocationSetMeeting',

  componentDidMount() {
    const { locationSetting } = this.props
    this.updateFileds(locationSetting)
  },

  updateFileds(setting = {}) {
    const {
      fields: {
        daily_limitation,
        max_duration,
        overtime_margin,
        overtime_price,
        daily_limitation_value,
        monthly_limitation,
        monthly_limitation_value,
        cancellation_offset,
        reservation_time_range,
        reservation_time_range_error,
        send_message_to_operator,
        only_organization_coin
      }
    } = this.props
    if (!setting.meeting_room_settings) {
      return
    }
    const setting_value = setting.meeting_room_settings
    const reservation_time_range_obj = setting_value.reservation_time_range.find(
      r => r.day === -1
    )

    daily_limitation.onChange(setting.meeting_room_settings.daily_limitation)
    monthly_limitation.onChange(
      setting.meeting_room_settings.monthly_limitation
    )
    max_duration.onChange(setting.meeting_room_settings.max_duration)
    overtime_margin.onChange(setting.meeting_room_settings.overtime_margin)
    overtime_price.onChange(setting.meeting_room_settings.overtime_price)
    daily_limitation_value.onChange(
      setting.meeting_room_settings.daily_limitation_value
    )
    monthly_limitation_value.onChange(
      setting.meeting_room_settings.monthly_limitation_value
    )
    cancellation_offset.onChange(
      setting.meeting_room_settings.cancellation_offset
    )
    reservation_time_range.begin.onChange(reservation_time_range_obj.begin / 60)
    reservation_time_range.end.onChange(reservation_time_range_obj.end / 60)

    send_message_to_operator.onChange(
      setting.meeting_room_settings.send_message_to_operator
    )
    only_organization_coin.onChange(
      setting.meeting_room_settings.only_organization_coin
    )
  },

  updateSpaceSetting(model) {
    const { locationSetting } = this.props
    const { updateLocationSetting } = this.props
    const setting_value = locationSetting.meeting_room_settings
    model.send_message_to_operator = this.props.values.send_message_to_operator
    model.only_organization_coin = this.props.values.only_organization_coin

    model.reservation_time_range = setting_value.reservation_time_range.map(
      r => {
        if (r.day === -1) {
          return {
            begin: model.reservation_time_range.begin * 60,
            end: model.reservation_time_range.end * 60,
            day: -1,
            nowork: false
          }
        }
        return r
      }
    )
    return updateLocationSetting(
      {
        meeting_room_settings: model
      },
      {
        key: 'meeting_room_settings'
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    const { locationSetting } = nextProps
    if (
      locationSetting &&
      locationSetting.settings &&
      locationSetting != this.props.locationSetting
    ) {
      this.updateFileds(locationSetting)
    }
  },

  limitationCallBack(value) {
    const {
      fields: { daily_limitation }
    } = this.props
    daily_limitation.onChange(value)
  },
  monthlyLimitationCallBack(value) {
    const {
      fields: { monthly_limitation }
    } = this.props
    monthly_limitation.onChange(value)
  },

  meetingStartHour(value) {
    const {
      fields: { reservation_time_range }
    } = this.props
    reservation_time_range.begin.onChange(parseFloat(value))
  },

  meetingEndHour(value) {
    const {
      fields: { reservation_time_range }
    } = this.props
    reservation_time_range.end.onChange(parseFloat(value))
  },

  changeSlide(key) {
    let active = !this.props.values[key.name]
    if (key.name == 'send_message_to_operator') {
      this.props.values.send_message_to_operator = active
      this.setState({
        send_message_to_operator: active
      })
    } else if (key.name == 'only_organization_coin') {
      this.props.values.only_organization_coin = active
      this.setState({
        only_organization_coin: active
      })
    }
  },

  render() {
    const {
      fields: {
        daily_limitation,
        max_duration,
        advance_limitation_types,
        overtime_margin,
        overtime_price,
        daily_limitation_value,
        monthly_limitation,
        monthly_limitation_value,
        cancellation_offset,
        reservation_time_range,
        reservation_time_range_error,
        send_message_to_operator,
        only_organization_coin
      },
      error,
      submitting,
      handleSubmit,
      selectTypes,
      virtual_currency_name
    } = this.props

    const arrayData = [
      {
        id: 'infinity',
        name: '不限制'
      },
      {
        id: 'number',
        name: '小时'
      },
      {
        id: 'per_desk',
        name: '小时/工位'
      }
    ]

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>会议室设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateSpaceSetting)}>
            <KBFormError error={this.props.error} />
            <div className="kb-form-container" style={{ padding: 0 }}>
              <div className="kb-row">
                <div className="f-left">
                  <div>时间限制</div>
                  <p className="color-nine">
                    单次预订会议室最长时间数，默认值为4小时
                  </p>
                </div>
                <KBASNumberInput
                  field={max_duration}
                  className="f-right"
                  type="number"
                  maxValue="24"
                  counterStyle={{ marginRight: 130 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <div>公司每日预定的时长为</div>
                  <p className="color-nine">
                    请输入数字“4”并选择 [小时/工位] 表示公司每日预定的时长为[4]
                    [小时/工位]
                  </p>
                </div>
                <div className="set-select-box f-right">
                  <KBSelect
                    arrayData={arrayData}
                    value={daily_limitation.value}
                    callback={this.limitationCallBack}
                  />
                </div>
                <KBASNumberInput
                  field={daily_limitation_value}
                  className="f-right"
                  type="number"
                  maxValue="24"
                  counterStyle={{ marginRight: 10 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <div>公司每月预定的时长为</div>
                  <p className="color-nine">
                    请输入数字“4”并选择 [小时/工位] 表示公司每月预定的时长为[4]
                    [小时/工位]
                  </p>
                </div>
                <div className="set-select-box f-right">
                  <KBSelect
                    arrayData={arrayData}
                    value={monthly_limitation.value}
                    callback={this.monthlyLimitationCallBack}
                  />
                </div>
                <KBASNumberInput
                  field={monthly_limitation_value}
                  className="f-right"
                  type="number"
                  counterStyle={{ marginRight: 10 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>可以提前多久取消预订</label>
                  <p className="color-nine">
                    可以提前取消预订的小时数，默认值为0，会议开始后不能取消
                  </p>
                </div>
                <KBASNumberInput
                  field={cancellation_offset}
                  className="f-right"
                  type="number"
                  maxValue="24"
                  counterStyle={{ marginRight: 130 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>超时容许范围</label>
                  <p className="color-nine">
                    超时容许范围，单位：分钟，默认5分钟
                  </p>
                </div>
                <KBASNumberInput
                  field={overtime_margin}
                  className="f-right"
                  type="number"
                  counterStyle={{ marginRight: 130 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>使用时间超过容许范围后的使用价格</label>
                  <p className="color-nine">
                    使用时间超过容许范围后的使用价格，单位：n 元/分钟默认0
                  </p>
                </div>
                <KBASNumberInput
                  field={overtime_price}
                  className="f-right"
                  type="number"
                  counterStyle={{ marginRight: 130 }}
                />
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>预定会议室起止时间</label>
                  <p className="color-nine">
                    可以设置会议室预定开始时间和结束时间
                  </p>
                </div>
                <div className="set-select-box f-right">
                  <KBSelect
                    arrayData={DAY_TIME_HOUR}
                    selectStyle={{ padding: '0 39px' }}
                    value={reservation_time_range.begin.value || 0}
                    callback={this.meetingStartHour}
                  />
                  &nbsp;-&nbsp;
                  <KBSelect
                    selectStyle={{ padding: '0 37px' }}
                    arrayData={DAY_TIME_HOUR}
                    value={reservation_time_range.end.value || 24}
                    callback={this.meetingEndHour}
                  />
                  {reservation_time_range_error.touched &&
                    reservation_time_range_error.error && (
                      <p className="lr-error">
                        {reservation_time_range_error.error}
                      </p>
                    )}
                </div>
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>会议结束是否通知运营人员</label>
                  <p className="color-nine">
                    可以决定在每个会议结束之后是否告知运营人员该会议状态
                  </p>
                </div>
                <div
                  className="slide-btn f-right"
                  onClick={() => {
                    this.changeSlide(send_message_to_operator)
                  }}
                >
                  <span
                    className={`slide-bg ${
                      this.props.values.send_message_to_operator
                        ? 'slide-left'
                        : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
              <div className="kb-row">
                <div className="f-left">
                  <label>
                    是否只能使用公司{virtual_currency_name}预定会议室
                  </label>
                  <p className="color-nine">
                    打开后则只能使用公司{virtual_currency_name}进行会议室的预定
                  </p>
                </div>
                <div
                  className="slide-btn f-right"
                  onClick={() => {
                    this.changeSlide(only_organization_coin)
                  }}
                >
                  <span
                    className={`slide-bg ${
                      this.props.values.only_organization_coin
                        ? 'slide-left'
                        : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 25
                }}
              >
                <div>可以提前预定的场地类型</div>
                <KBUserInput
                  users={AREATYPES_STATES}
                  placeholder="选择类型"
                  selectedUsers={selectTypes}
                  multi={true}
                  field={advance_limitation_types}
                  style={{ minWidth: 250, maxWidth: 450 }}
                />
              </div>
              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id)
  const advanceLimitationTypes =
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.advance_limitation_types
  const advance_limitation_types =
    advanceLimitationTypes &&
    (typeof advanceLimitationTypes === 'string'
      ? advanceLimitationTypes.split(',')
      : advanceLimitationTypes)
  let selectTypes = []
  advance_limitation_types &&
    advance_limitation_types.map(type => {
      selectTypes.push({
        id: type,
        name: AREATYPES_TIPSY[type]
      })
    })
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  return {
    locationSetting,
    selectTypes,
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    getMeetingLocationSettingActions: bindActionCreators(
      meetingLocationSettingActions.get,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (
    values.reservation_time_range.begin >= values.reservation_time_range.end
  ) {
    errors.reservation_time_range_error = '开始时间不能大于结束时间'
  }
  return errors
}

const formConfig = {
  form: 'LocationSetMeeting',
  fields: [
    'daily_limitation',
    'max_duration',
    'overtime_margin',
    'overtime_price',
    'daily_limitation_value',
    'monthly_limitation',
    'monthly_limitation_value',
    'cancellation_offset',
    'reservation_time_range_error',
    'reservation_time_range.begin',
    'reservation_time_range.end',
    'send_message_to_operator',
    'advance_limitation_types',
    'only_organization_coin'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetMeeting)
