import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { getProvincesAction, successState } from 'app/actions'
import {
  KBInput,
  KBButton,
  KBUserInput,
  KBForm,
  KBPopover,
  KBTextarea,
  KBSelect,
  KBMoreFilesUpload,
  KBImageCropperInput,
  KBPopoverTop,
  KBAvatar,
  KBMoreImageCropperInput
} from 'components'
import { Icon, notification, Select } from 'antd'
import * as selectors from 'app/selectors'
import _ from 'lodash'
import {
  arrayToObject,
  objectToArray,
  groupLocationsByLocationGroup
} from 'utils/kbUtil'
import config from 'app/config'
import GroupSetBaseAddProductForm from './GroupSetBaseAddProductForm'
import GroupSetBaseAddFacilitiesForm from './GroupSetBaseAddFacilities'
import GroupSetBridgePlusAddProductForm from './GroupSetBridgePlusAddProductForm'

const priceType = {
  day: '天',
  hour: '小时',
  month: '月'
}

const { Option } = Select

var GroupSetBase = React.createClass({
  getInitialState() {
    return {
      cityData: [],
      product_tags: [],
      spaceProducts: [],
      devices: [],
      selectDevices: [],
      space_products: [],
      currentProductIndex: 0,
      fileList: [],
      location_group_facilities: [],
      loading: false
    }
  },

  componentDidMount() {
    const {
      fields: {
        name,
        description,
        province_id,
        city_id,
        person_code,
        lg_code,
        bridge_enabled,
        traffic,
        bridgego_visible,
        bpsite_visible,
        contact_phone,
        contact_email,
        latitude,
        longitude,
        physical_address,
        size,
        trading_area,
        image,
        // feature_tags,
        logo,
        bpsite_pc_image
      }
    } = this.props
    api.getGroupsInfo(this.props.params.id).then(
      json => {
        let group = _.get(json, 'response.body')
        const selectDevices = _.get(group, 'devices', []).map(x => ({
          id: x.id,
          name: x.name
        }))
        name.onChange(_.get(group, 'name', ''))
        description.onChange(_.get(group, 'description', ''))
        province_id.onChange(_.get(group, 'city.province_id'))
        city_id.onChange(_.get(group, 'city.id'))
        person_code.onChange(_.get(group, 'person_code'))
        lg_code.onChange(_.get(group, 'lg_code'))
        bridge_enabled.onChange(_.get(group, 'bridge_enabled'))
        traffic.onChange(_.get(group, 'traffic'))
        bridgego_visible.onChange(_.get(group, 'bridgego_visible'))
        bpsite_visible.onChange(_.get(group, 'bpsite_visible'))
        contact_phone.onChange(_.get(group, 'contact_phone'))
        contact_email.onChange(_.get(group, 'contact_email'))
        latitude.onChange(_.get(group, 'latitude'))
        longitude.onChange(_.get(group, 'longitude'))
        physical_address.onChange(_.get(group, 'physical_address'))
        size.onChange(_.get(group, 'size'))
        // feature_tags.onChange(_.get(group,'feature_tags'))
        logo.onChange(_.get(group, 'logo'))
        if(group.bpsite_pc_image && group.bpsite_pc_image.length) {
          bpsite_pc_image.onChange(group.bpsite_pc_image[0])
        }
        trading_area.onChange(_.get(group, 'trading_area'))
        const product_tags = _.get(group, 'product_tags', [])
        const space_products = _.get(group, 'space_products', [])
        const location_group_facilities = _.get(
          group,
          'location_group_facilities',
          []
        )
        const fileList = _.get(group, 'image', [])
        image.onChange(fileList)
        this.setState({
          space_products,
          fileList,
          location_group_facilities,
          selectDevices,
          product_tags
        })
      },
      errors => {}
    )

    // 城市列表
    api.getCities().then(
      json => {
        let citiy = _.get(json, 'response.body')
        let cityData = {}
        _.forEach(citiy, item => {
          cityData[item.id] = item
        })
        this.setState({
          cityData
        })
        // this.refs.LocationBaseForm.changecity(cityData);
      },
      error => {
        this.getCitiesAction.failure(error)
      }
    )
    // 省份列表
    api.getProvinces().then(
      json => {
        this.props.getProvincesAction.success(json)
      },
      errors => {
        this.props.getProvincesAction.failure(errors)
      }
    )

    // 获取设备列表
    api.getDevices().then(json => {
      const devices = _.get(json, 'response.body')
      this.setState({
        devices
      })
    })

    //空间产品和产品类别列表
    api.getCtags().then(
      json => {
        const tags = _.get(json, 'response.body')
        const productTags = tags.filter(x => x.tag_type === 'product_tag')
        const spaceProducts = tags
          .filter(x => x.tag_type === 'space_product')
          .map(x => ({ disabled: false, ...x }))
        this.setState({
          productTags,
          spaceProducts
        })
      },
      errors => {
        console.log(errors)
      }
    )
  },
  componentWillReceiveProps(nextProps) {},
  submitLocationGroup(model) {
    const { updateLocation } = this.props
    updateLocation && updateLocation({
      ...model,
      bpsite_pc_image: model.bpsite_pc_image ? [model.bpsite_pc_image] : null
    })
  },

  updateProductList() {
    const { updateLocation } = this.props
    const { space_products } = this.state
    updateLocation && updateLocation({ space_products })
  },

  addProductList(value) {
    const { space_products = [] } = this.state
    const list = [...space_products]
    list.push(value)
    this.setState({ space_products: list }, () => {
      KBPopover.close()
    })
  },

  addProduct() {
    const { spaceProducts, groupId } = this.state
    const list = [...spaceProducts]
    list.unshift({ id: '', name: '请选择类别' })
    KBPopover.show(
      <GroupSetBaseAddProductForm
        type="create"
        scene="pro"
        groupId={groupId}
        allProduct={list}
        callBack={this.addProductList}
      />
    )
  },

  updateProduct(value) {
    const { space_products = [], currentProductIndex } = this.state
    const list = [...space_products]
    list[currentProductIndex] = value
    this.setState({ space_products: list }, () => {
      KBPopover.close()
    })
  },

  editProduct(product, index) {
    const { spaceProducts, groupId } = this.state
    this.setState({ currentProductIndex: index })
    const list = [...spaceProducts]
    list.unshift({ id: '', name: '请选择类别' })
    KBPopover.show(
      <GroupSetBaseAddProductForm
        type="edit"
        scene="pro"
        groupId={groupId}
        allProduct={list}
        product={product}
        callBack={this.updateProduct}
      />
    )
  },

  delProduct(id) {
    const { space_products = [] } = this.state
    const list = space_products.filter(x => x.id != id)
    this.setState({ space_products: list })
  },

  updateFacilitiesList() {
    const { updateLocation } = this.props
    const { location_group_facilities } = this.state
    updateLocation && updateLocation({ location_group_facilities })
  },

  addFacilitiesList(value) {
    const { location_group_facilities = [] } = this.state
    const list = [...location_group_facilities]
    list.push(value)
    this.setState({ location_group_facilities: list }, () => {
      KBPopover.close()
    })
  },

  addFacilities() {
    const { groupId } = this.state
    KBPopover.show(
      <GroupSetBaseAddFacilitiesForm
        type="create"
        scene="fac"
        groupId={groupId}
        callBack={this.addFacilitiesList}
      />
    )
  },

  updateFacilities(value) {
    const {
      location_group_facilities = [],
      currentFacilitiesIndex
    } = this.state
    const list = [...location_group_facilities]
    list[currentFacilitiesIndex] = value
    this.setState({ location_group_facilities: list }, () => {
      KBPopover.close()
    })
  },

  editFacilities(facilities, index) {
    const { groupId } = this.state
    this.setState({ currentFacilitiesIndex: index })
    KBPopover.show(
      <GroupSetBaseAddProductForm
        type="edit"
        scene="fac"
        groupId={groupId}
        product={facilities}
        callBack={this.updateFacilities}
      />
    )
  },

  delFacilities(id) {
    const { location_group_facilities = [] } = this.state
    const list = location_group_facilities.filter(x => x.id != id)
    this.setState({ location_group_facilities: list })
  },

  getProductName(id) {
    const { spaceProducts } = this.state
    const product = spaceProducts.find(x => x.id == id) || {}
    const name = product.name || ''
    return name
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({ message: '上传失败', description: msg, top: 100 })
    }
  },

  removeFile(index) {
    const {
      fields: { image }
    } = this.props
    const { fileList } = this.state
    let newFileList = [...fileList]
    newFileList.splice(index, 1)
    image.onChange(newFileList)
    this.setState({ fileList: newFileList })
  },

  updateBridgePlusProduct(value) {
    this.setState({ product_tags: value }, () => {
      KBPopover.close()
    })
  },

  addBridgePlusProduct(value) {
    const { product_tags } = this.state
    const { groupId } = this.props
    const list = [...product_tags]
    list.unshift({ id: '', name: '请选择类别' })
    KBPopover.show(
      <GroupSetBridgePlusAddProductForm
        modalType="create"
        groupId={groupId}
        callBack={this.updateBridgePlusProduct}
      />
    )
  },

  delBridgePlusProduct(id, name) {
    KBPopover.plugins.confirm('删除产品', '确定要删除该产品?', {
      confirm: () => {
        const { groupId, successAction } = this.props
        const { product_tags } = this.state
        api.delBridgePlusProduct(groupId, { ctag_id: id }).then(res => {
          if (res && res.json && res.json.status === 'ok') {
            const list = product_tags.filter(item => item.ctag_id !== id)
            this.setState({ product_tags: list })
          }
          successAction({ message: '保存成功!' })
          KBPopover.close()
        })
      }
    })
  },

  editBridgePlusProduct(item) {
    const { groupId } = this.props
    KBPopover.show(
      <GroupSetBridgePlusAddProductForm
        modalType="edit"
        groupId={groupId}
        product={item}
        callBack={this.updateBridgePlusProduct}
      />
    )
  },

  // dragulaDecorator(componentBackingInstance){
  //   // console.log(this.videoRef);
  //   this.setState({
  //     videoHTML:componentBackingInstance
  //   })
  //   // console.log(componentBackingInstance,'componentBackingInstance');
  //   if (componentBackingInstance) {
  //     let options = { };
  //     Dragula([componentBackingInstance], options);
  //   }
  // },

  // saveVideo(){
  //   const { videoHTML } = this.state
  //   const videoHtml = document.querySelectorAll('.video-list')
  //   const arr = []

  //   console.log(videoHTML,'=====');
  //   console.log('videoHtml:',videoHtml, videoHtml.length);
  //   (videoHtml||[]).forEach(item=>{
  //     const cla = item.className
  //     console.log('key:', item.key);
  //     console.log('className:',cla);
  //     arr.push(cla)
  //   })
  //   console.log(arr,'arr');
  // },

  // handleChange(val){
  //   const { fields: { feature_tags } } = this.props
  //   feature_tags.onChange(val)
  // },

  loadingCallBack(loading) {
    this.setState({ loading })
  },

  render() {
    const {
      fields: {
        name,
        location_ids,
        description,
        province_id,
        city_id,
        lg_code,
        person_code,
        bridge_enabled,
        traffic,
        bridgego_visible,
        bpsite_visible,
        device_ids,
        ctag_ids,
        contact_phone,
        contact_email,
        image,
        latitude,
        longitude,
        physical_address,
        size,
        trading_area,
        video,
        // feature_tags,
        logo,
        bpsite_pc_image
      },
      submitting,
      handleSubmit,
      locations,
      group,
      defaultImages,
      groupId
    } = this.props
    const {
      product_tags,
      devices,
      selectDevices,
      space_products,
      fileList,
      location_group_facilities,
      loading
    } = this.state
    const selectLocations = _.get(group, 'locations', [])
    const citiesArray = objectToArray(this.state.cityData).filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)
    const provinces = this.props.provinces
    let groups = groupLocationsByLocationGroup(locations)
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.submitLocationGroup)}
        fields={this.props.fields}
      >
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={name}
              title="项目名称"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-48"
              isRequired={true}
              title="省份"
              field={province_id}
              arrayData={provinces}
            />
            <KBSelect
              defaultValue={'-1'}
              onChange={this.cityChange}
              className="kb-form-group kb-div-48"
              isRequiredR={true}
              title="城市"
              titleClass="label-rt"
              field={city_id}
              arrayData={cities}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-48"
              isRequired={true}
              field={lg_code}
              title="项目代号"
            />
            <KBInput
              className="kb-form-group kb-div-48"
              isRequired={true}
              field={person_code}
              title="人员代号"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-48"
              // isRequired={true}
              field={contact_phone}
              title="手机号码"
            />
            <KBInput
              className="kb-form-group kb-div-48"
              // isRequired={true}
              field={contact_email}
              title="邮箱"
            />
          </div>
          <div className="kb-row kb-div-66">
            <KBInput
              className="kb-form-group kb-div-48"
              isRequired={true}
              title="经度"
              field={longitude}
            />
            <KBInput
              className="kb-form-group kb-div-48"
              title="纬度"
              isRequired={true}
              field={latitude}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-48"
              title="地址"
              isRequired={true}
              field={physical_address}
              titleClass="label-rt"
            />
            <KBInput
              className="kb-form-group kb-div-48"
              inputClass="input-area"
              isRequiredR={true}
              titleClass="label-rt"
              field={size}
              title="面积"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="商圈"
              isRequired={true}
              field={trading_area}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="分店"
              multi={true}
              placeholder="选择分店"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={location_ids}
              selectedUsers={selectLocations}
              users={groups.no_group}
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="设备"
              multi={true}
              placeholder="选择设备"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_ids}
              selectedUsers={selectDevices}
              users={devices}
            />
          </div>
          {/* <div className='kb-row'>
            <div className='kb-form-group kb-form-c18 kb-form-alone kb-input'>
              <label>项目特色</label>
              <Select mode="tags" style={{ width: '100%' }} value={feature_tags.value||[]} placeholder="请输入项目特色" onChange={this.handleChange}>
                {(feature_tags.value||[]).map((tag,index)=><Option key={index} value={tag}>{tag}</Option>)}
              </Select>
            </div>
          </div> */}
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={traffic}
              title="交通"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={description}
              title="项目介绍"
            />
          </div>

          <div className="kb-row">
            <div className="f-left">
              <label>奕桥空间</label>
              <p className="color-nine">是否是奕桥空间</p>
            </div>
            <div
              className="slide-btn f-right"
              onClick={() => {
                bridge_enabled.onChange(!bridge_enabled.value)
              }}
            >
              <span
                className={`slide-bg ${
                  bridge_enabled.value ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
          </div>

          <div className="kb-row">
            <div className="f-left">
              <label>奕桥小程序</label>
              <p className="color-nine">是否在小程序展示</p>
            </div>
            <div
              className="slide-btn f-right"
              onClick={() => {
                bridgego_visible.onChange(!bridgego_visible.value)
              }}
            >
              <span
                className={`slide-bg ${
                  bridgego_visible.value ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
          </div>

          {(bridge_enabled.value==='true'||bridge_enabled.value===true)&&<div className="kb-row">
            <div className="f-left">
              <label>奕桥官网</label>
              <p className="color-nine">是否在奕桥官网展示</p>
            </div>
            <div
              className="slide-btn f-right"
              onClick={() => {
                bpsite_visible.onChange(!bpsite_visible.value)
              }}
            >
              <span
                className={`slide-bg ${
                  bpsite_visible.value ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
          </div>}

          {
            bpsite_visible.value && <fieldset className="fieldset">
              <label
                style={{
                  position: 'relative'
              }}>
                <span className="must-fill">*</span>
                官网项目头图
              </label>
              <div style={{
                marginTop: '6px'
              }}>
                <KBImageCropperInput
                  // callback={this.callback}
                  // failureCallBack={this.failureCallBack}
                  field={bpsite_pc_image}
                  style={{ display: 'inline-block' }}
                  type={`groups/${groupId}`}
                  loadingCallBack={this.loadingCallBack}
                  imageSize={1024 * 2}
                  failureCallBack={this.UploadFailureCallBack}
                  width={450}
                  height={283}
                  aspect={450 / 283}
                  accept=".png,.jpg,.jpeg"
                  isPublic
                >
                  <div className="upload-portrait">
                    <button
                      className="bordered-btn"
                      type="button"
                      style={{ marginLeft: 0, marginBottom: 20, width: 200 }}
                    >
                      {loading
                        ? '上传中...'
                        : bpsite_pc_image.value
                        ? '上传新图片'
                        : '上传图片'}
                    </button>
                    <br />
                    {bpsite_pc_image.value ? (
                      <KBAvatar
                        imgSize={{ width: 450, height: 283 }}
                        user={{ avatar: bpsite_pc_image.value }}
                        imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
                        style={{ display: 'inline-block', marginLeft: 0 }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </KBImageCropperInput>
              </div>
            </fieldset>
          }

          <div className="kb-row border-bottom" style={{ paddingBottom: 30 }}>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确认保存
            </KBButton>
          </div>

          <fieldset className="fieldset">
            <legend>项目logo</legend>
            <div>
              <KBImageCropperInput
                // callback={this.callback}
                // failureCallBack={this.failureCallBack}
                field={logo}
                style={{ display: 'inline-block' }}
                type={`groups/${groupId}`}
                loadingCallBack={this.loadingCallBack}
                imageSize={1024}
                failureCallBack={this.UploadFailureCallBack}
                width={150}
                height={150}
                aspect={23 / 12}
                accept=".png,.jpg,.jpeg"
                isPublic
              >
                <div className="upload-portrait">
                  <button
                    className="bordered-btn"
                    type="button"
                    style={{ marginLeft: 0, marginBottom: 20, width: 100 }}
                  >
                    {loading
                      ? '上传中...'
                      : logo.value
                      ? '上传新图片'
                      : '上传图片'}
                  </button>
                  <br />
                  {logo.value ? (
                    <KBAvatar
                      imgSize={{ width: 250, height: 150 }}
                      user={{ avatar: logo.value }}
                      imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
                      style={{ display: 'inline-block', marginLeft: 0 }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </KBImageCropperInput>
            </div>
          </fieldset>

          <fieldset className="fieldset">
            <legend>项目图片</legend>
            <div>
              <KBMoreImageCropperInput
                title={false}
                aspect={1125 / 750}
                accept=".png,.jpg,.jpeg"
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={image}
                type="groups"
                prefix={`groups/${groupId}/`}
                width={225}
                height={150}
                imageSize={1024 * 2}
                failureCallBack={this.UploadFailureCallBack}
                callback={v => this.setState({ fileList: v })}
                isPublic
              >
                <div className="upload-portrait">
                  <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                    <button className="bordered-btn m-none" type="button">
                      {fileList && fileList.length
                        ? '继续上传图片'
                        : '上传图片'}
                    </button>
                    <p
                      className="color-nine"
                      style={{ paddingTop: 20, fontSize: 12 }}
                    >
                      图片格式为png, jpeg或jpg,文件大小不大于2M
                    </p>
                  </div>
                </div>
              </KBMoreImageCropperInput>
              <div>
                {fileList && fileList.map ? (
                  <ul className="kb-more-file" style={{ margin: 0 }}>
                    {fileList.map((file, index) => {
                      return (
                        <li key={index} style={{ cursor: 'pointer' }}>
                          <img
                            src={file}
                            alt="loading"
                            style={{
                              width: '225px',
                              height: '150px',
                              marginBottom: 10
                            }}
                          />

                          <div className="delete">
                            <i
                              className="iconfont icon-close"
                              onClick={this.removeFile.bind(null, index)}
                            />
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  ''
                )}
              </div>
              {/* <KBMorePhotoUpload
                defaultImages={defaultImages}
                uploadedText="上传图片"
                photoShow={true}
                sort={true}
                className="kb-form-group kb-form-alone"
                field={image}
                img_width={232}
                img_height={134}
                hint="图片格式为png或jpg,尺寸大小不小于1125x1050,文件大小不大于1M"
                text="上传图片"
                imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
                prefix={`${config.uploadDir}groups/${groupId}`}
              /> */}
            </div>
          </fieldset>

          <div className="kb-row border-bottom" style={{ paddingBottom: 30 }}>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确认保存
            </KBButton>
          </div>
        </div>

        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>微信小程序奕桥产品展示内容维护</legend>
            <div className="kb-row">
              <KBButton
                type="button"
                className="bordered-btn"
                onClick={this.addProduct}
              >
                <i className="iconfont icon-add" />
                添加奕桥产品
              </KBButton>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              }}
            >
              {space_products.map((product, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      width: '45%',
                      background: '#FAFBFC',
                      border: '1px solid #DFE2E5',
                      borderRadius: 4,
                      padding: 20,
                      margin: '0 30px 30px 0'
                    }}
                  >
                    <div>
                      <img
                        src={product.image}
                        alt=""
                        style={{ width: 60, height: 60, borderRadius: 60 }}
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 15 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <div style={{ fontSize: 15, fontWeight: 600 }}>
                          {this.getProductName(product.id)}
                        </div>
                        <div>
                          <Icon
                            type="delete"
                            onClick={() => this.delProduct(product.id)}
                          />
                          <Icon
                            type="form"
                            style={{ marginLeft: 25 }}
                            onClick={() => this.editProduct(product, index)}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: 'rgba(237, 28, 36, 0.8)'
                        }}
                      >
                        {product.price}元/{priceType[product.unit]}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: 'rgba(53, 64, 82, 0.5)'
                        }}
                      >
                        {product.description}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="kb-row" style={{ marginTop: 30 }}>
              <KBButton
                className="certain-btn"
                type="button"
                onClick={this.updateProductList}
              >
                确认保存
              </KBButton>
            </div>
          </fieldset>
        </div>

        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>奕桥官网奕桥产品展示内容维护</legend>
            <div className="kb-row">
              <KBButton
                type="button"
                className="bordered-btn"
                onClick={this.addBridgePlusProduct}
              >
                <i className="iconfont icon-add" />
                添加奕桥产品
              </KBButton>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              }}
            >
              {product_tags.map((product, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      width: '45%',
                      background: '#FAFBFC',
                      border: '1px solid #DFE2E5',
                      borderRadius: 4,
                      padding: 20,
                      margin: '0 30px 30px 0'
                    }}
                  >
                    <div>
                      <img
                        src={product.images ? product.images[0] : ''}
                        alt=""
                        style={{ width: 60, height: 60, borderRadius: 60 }}
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 15 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <div style={{ fontSize: 15, fontWeight: 600 }}>
                          {product.ctag_name}
                        </div>
                        <div>
                          <Icon
                            type="delete"
                            onClick={() =>
                              this.delBridgePlusProduct(product.ctag_id, product.ctag_name)
                            }
                          />
                          <Icon
                            type="form"
                            style={{ marginLeft: 25 }}
                            onClick={() => this.editBridgePlusProduct(product)}
                          />
                        </div>
                      </div>
                      {/* <div
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: 'rgba(237, 28, 36, 0.8)'
                        }}
                      >
                        {product.price}元/{priceType[product.unit]}
                      </div> */}
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: 'rgba(53, 64, 82, 0.5)'
                        }}
                      >
                        {product.description}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </fieldset>
        </div>

        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>社区介绍</legend>
            <div className="kb-row">
              <KBButton
                type="button"
                className="bordered-btn"
                onClick={this.addFacilities}
              >
                <i className="iconfont icon-add" />
                添加社区介绍
              </KBButton>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              }}
            >
              {location_group_facilities.map((facilities, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      width: '45%',
                      background: '#FAFBFC',
                      border: '1px solid #DFE2E5',
                      borderRadius: 4,
                      padding: 20,
                      margin: '0 30px 30px 0'
                    }}
                  >
                    <div>
                      <img
                        src={facilities.image}
                        alt=""
                        style={{ height: 60, borderRadius: 10 }}
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 15 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <div style={{ fontSize: 15, fontWeight: 600 }}>
                          {facilities.name}
                        </div>
                        <div>
                          <Icon
                            type="delete"
                            onClick={() => this.delFacilities(facilities.id)}
                          />
                          <Icon
                            type="form"
                            style={{ marginLeft: 25 }}
                            onClick={() =>
                              this.editFacilities(facilities, index)
                            }
                          />
                        </div>
                      </div>
                      {/* {facilities.price&&<div
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: 'rgba(237, 28, 36, 0.8)'
                        }}
                      >
                        {facilities.price}元/{priceType[facilities.unit]}
                      </div>} */}
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: 'rgba(53, 64, 82, 0.5)'
                        }}
                      >
                        {facilities.description}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="kb-row" style={{ marginTop: 30 }}>
              <KBButton
                className="certain-btn"
                type="button"
                onClick={this.updateFacilitiesList}
              >
                确认保存
              </KBButton>
            </div>
          </fieldset>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const updateLocation = props.updateLocation
  let locations = selectors.getLocations(state)
  let groupId = props.params.id
  const group = selectors.getCurrentGroup(state, groupId) || {}
  const cities = selectors.getCities(state)
  const provinces = selectors.getProvinces(state)
  const defaultImages = group.image
  return {
    locations,
    group,
    groupId,
    updateLocation,
    cities,
    provinces,
    defaultImages
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProvincesAction: bindActionCreators(getProvincesAction, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入分店名称'
  }

  if (valid.required(values.location_ids)) {
    errors.location_ids = '请选择分店'
  }

  if (valid.required(values.lg_code)) {
    errors.lg_code = '请填写项目代号'
  }

  if (valid.required(values.person_code)) {
    errors.person_code = '请填写人员代号'
  }

  if (valid.required(values.province_id)) {
    errors.province_id = '请选择省份'
  }
  if (valid.required(values.city_id)) {
    errors.city_id = '请选择城市'
  }

  if (valid.required(values.longitude)) {
    errors.longitude = '请填写经度'
  }
  if (valid.required(values.latitude)) {
    errors.latitude = '请填写纬度'
  }
  if (valid.required(values.size)) {
    errors.size = '请填写项目面积'
  }
  if (valid.required(values.physical_address)) {
    errors.physical_address = '请填写项目地址'
  }
  if (valid.required(values.trading_area)) {
    errors.trading_area = '请填写项目商圈'
  }
  if(values.bpsite_visible && valid.required(values.bpsite_pc_image)) {
    errors.bpsite_pc_image = '请上传官网项目头图'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetBase',
  fields: [
    'name',
    'location_ids',
    'description',
    'province_id',
    'city_id',
    'lg_code',
    'person_code',
    'bridge_enabled',
    'traffic',
    'bridgego_visible',
    'bpsite_visible',
    'device_ids',
    'ctag_ids',
    'contact_email',
    'contact_phone',
    'image',
    'longitude',
    'latitude',
    'size',
    'physical_address',
    'trading_area',
    'video',
    // 'feature_tags',
    'logo',
    'bpsite_pc_image'
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetBase = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBase)

export default GroupSetBase
