import React, { Component, PropTypes } from 'react'
import StatisticDeskEnter from './desk/StatisticDeskEnter'
import StatisticOperationalDeskEnter from './desk/StatisticOperationalDeskEnter'
import StatisticDeskEnterFinancial from './desk/StatisticDeskEnterFinancial'

/**
 * 工位统计box
 * @type {[type]}
 */
var StatisticDeskBox = React.createClass({
  displayName: 'StatisticDeskBox',
  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticDeskEnter group_id={this.props.group_id} />
          <StatisticOperationalDeskEnter group_id={this.props.group_id} />
          <StatisticDeskEnterFinancial group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticDeskBox
