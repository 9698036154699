import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBFormError, KBButton, KBPopover } from 'components'
import { customAction, successState } from 'app/actions'

var SpaceSettingUpdateBillTypeForm = React.createClass({
  componentDidMount() {},

  createTax(model) {
    const {
      state,
      successAction,
      taxId,
      getSelfDefinedLineItemTypes,
      createcustomAction,
      updatecustomAction
    } = this.props
    if (state) {
      return api.updateSelfDefinedLineItemTypes(taxId, model).then(json => {
        updatecustomAction.success(json)
        successAction({ message: '自定义账单类型修改成功!' })
        KBPopover.close()
        getSelfDefinedLineItemTypes()
        return true
      })
    }

    return api.createSelfDefinedLineItemTypes(model).then(json => {
      createcustomAction.success(json)
      successAction({ message: '自定义账单类型创建成功!' })
      KBPopover.close()
      getSelfDefinedLineItemTypes()
      return true
    })
  },

  render() {
    const {
      fields: { name, charge_code },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { state } = this.props

    return (
      <form onSubmit={handleSubmit(this.createTax)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '修改自定义账单类型' : '创建自定义账单类型'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="账单名"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={charge_code}
              title="财务编码"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createcustomAction: bindActionCreators(customAction.create, dispatch),
    updatecustomAction: bindActionCreators(customAction.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入账单名'
  }

  if (valid.required(values.charge_code)) {
    errors.charge_code = '请输入财务编码'
  }

  return errors
}

const formConfig = {
  form: 'SpaceSettingUpdateBillTypeForm',
  fields: ['name', 'charge_code'],
  validate: validate,
  touchOnBlur: false
}

SpaceSettingUpdateBillTypeForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingUpdateBillTypeForm)

export default SpaceSettingUpdateBillTypeForm
