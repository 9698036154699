import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBInput } from 'components'

var SpaceSettingPrintPriceForm = React.createClass({
  displayName: 'SpaceSettingPrintPriceForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          printer_settings: {
            print_price_setting: model
          }
        })
      },
      {
        key: 'printer_settings'
      }
    )
  },

  render() {
    const {
      fields: { A4_black_white, A4_colour, A3_black_white, A3_colour },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />

          <div className="kb-row">
            <div className="kb-form-66">
              <KBInput
                className="kb-form-group kb-div-c48"
                field={A4_black_white}
                title="A4（黑白）价格"
              />
              <KBInput
                className="kb-form-group kb-div-c48"
                field={A4_colour}
                title="A4（彩色）价格"
                titleClass="label-rt"
              />
            </div>
          </div>
          <div className="kb-row">
            <div className="kb-form-66">
              <KBInput
                className="kb-form-group kb-div-c48"
                field={A3_black_white}
                title="A3（黑白）价格"
              />
              <KBInput
                className="kb-form-group kb-div-c48"
                field={A3_colour}
                title="A3（彩色）价格"
                titleClass="label-rt"
              />
            </div>
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let { A4_black_white, A4_colour, A3_black_white, A3_colour } =
    setting.printer_settings.print_price_setting || {}
  return {
    initialValues: { A4_black_white, A4_colour, A3_black_white, A3_colour }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const formConfig = {
  form: 'SpaceSettingPrintPriceForm',
  fields: ['A4_black_white', 'A4_colour', 'A3_black_white', 'A3_colour'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingPrintPriceForm)
