import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { InitialHighCharts } from 'components/mixins'
import { percent } from 'utils/kbUtil'
import { OPPORTUNITY_STATES } from 'app/constants'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticCustomerStateHistory = React.createClass({
  displayName: 'StatisticCustomerStateHistory',
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate()
    return {
      filters: {
        from,
        to
      },
      dataOut: [],
      month: ''
    }
  },
  componentDidMount() {
    const { filters, dataOut } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'funnel',
          marginRight: 100
        },
        title: {
          text: null,
          x: -50
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b> ({point.y:,.0f})',
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                'black',
              softConnector: true
            },
            neckWidth: '30%',
            neckHeight: '25%'
          }
        },
        legend: {
          enabled: false
        }
      })
    )
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { from, to } = filters
    var params = {}

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.getCustomerStateHistoryStatistic(params).then(({ json }) => {
      if (!json.statistic) {
        return
      }
      this.parseJSON(json.statistic)
    })
  },

  parseJSON(json) {
    var contain = {}
    let dataOut = []

    var array = json

    array.forEach(t => {
      if (t.state == 'failed') {
        return
      }
      dataOut.push([OPPORTUNITY_STATES[t.state], t.customer_count])
    })

    dataOut.sort(this.compare)

    this.parseHightChartData(dataOut)
  },

  compare(a, b) {
    if (!a || !b || !a[1] || !b[1]) {
      return 0
    }

    if (a[1] - b[1] > 0) {
      return -1
    } else if (a[1] - b[1] < 0) {
      return 1
    }

    return 0
  },

  parseHightChartData(data) {
    let dt = {
      name: '数量',
      data
    }

    this.fillChart([dt])
  },

  fillChart(dataOut) {
    this.setSerierDate(dataOut)
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render: function() {
    const { filters, month } = this.state
    const { from, to } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>CRM客户漏斗图</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCustomerStateHistory)
