import React from 'react'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import { KBFormError, KBButton, KBInput } from 'components'

var LocationSetSubscription = React.createClass({
  displayName: 'LocationSetSubscription',

  getInitialState() {
    return {
      openfuncs: {}
    }
  },

  componentWillMount() {
    const { locationSetting } = this.props
    this.updateFileds(locationSetting)
  },

  updateFileds(setting = {}) {
    const {
      fields: { serial_rule }
    } = this.props
    let rule =
      (setting &&
        setting.subscription_settings &&
        setting.subscription_settings.serial_rule) ||
      ''
    // rule = rule.slice(0, rule.indexOf('-'))
    serial_rule.onChange(rule || '')
  },

  componentWillReceiveProps(nextProps) {
    const { locationSetting } = nextProps
    if (
      locationSetting &&
      locationSetting.settings &&
      locationSetting != this.props.locationSetting
    ) {
      this.updateFileds(locationSetting)
    }
  },

  updateLocationSettings(model) {
    const { updateLocationSetting } = this.props
    model.serial_rule = model.serial_rule
    return updateLocationSetting(
      {
        subscription_settings: model
      },
      {
        key: ['subscription_settings']
      }
    )
  },

  render() {
    const {
      fields: { serial_rule },
      updateLocationSetting,
      handleSubmit,
      submitting
    } = this.props
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>合同设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateLocationSettings)}>
            <KBFormError error={this.props.error} />
            <div
              className="kb-form-container"
              style={{ padding: '0px 400px 0px 0px' }}
            >
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-div-c48 kb-form-alone"
                  title="合同前缀规则"
                  field={serial_rule}
                />
              </div>
            </div>
            <div className="kb-row m-top">
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                保存
              </KBButton>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id)
  return {
    locationSetting
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'LocationSetSubscription',
  fields: ['serial_rule'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetSubscription)
