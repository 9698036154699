import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBInput, KBButton, KBUserInput } from 'components'
import _ from 'lodash'

const FIELD_TYPES = [
  {
    id: 'text',
    name: '文本'
  },
  {
    id: 'number',
    name: '数字'
  },
  {
    id: 'phone',
    name: '电话'
  },
  {
    id: 'email',
    name: '邮箱'
  },
  {
    id: 'options',
    name: '多选项'
  }
]

var VisitTypeEditFieldsForm = React.createClass({
  saveFields(model) {
    const { updateCurrentType, currentVisitType } = this.props
    let fields = _.cloneDeep(model.sign_fields || [])
    fields = fields.map(field => {
      let newOptions = []
      field.options &&
        field.options.map(opt => {
          return newOptions.push(opt.name)
        })
      field.options = newOptions
      return field
    })
    let newVisitType = Object.assign({}, currentVisitType)
    newVisitType.fields = fields
    return updateCurrentType(newVisitType)
  },

  changeSlidesVisiblity(index, new_value) {
    const {
      fields: { sign_fields }
    } = this.props
    sign_fields[index].required.onChange(new_value)
  },

  movePosition(index, type) {
    const {
      fields: { sign_fields }
    } = this.props
    let newIndex =
      type === 'up' ? index - 1 : type === 'down' ? index + 1 : index
    sign_fields.swapFields(index, newIndex)
  },

  addNewField(value) {
    const field_type = value.id
    const {
      fields: { sign_fields }
    } = this.props
    sign_fields.addField({
      name: '',
      field_name: field_type + new Date().getTime(),
      field_type,
      custom: true,
      deletable: true,
      modifable: true,
      required: false,
      options: []
    })
  },

  addNewFieldOption(index) {
    const {
      fields: { sign_fields }
    } = this.props
    sign_fields[index].options.addField({
      name: ''
    })
  },

  render() {
    const {
      fields: { sign_fields, temp_new_field },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.saveFields)}>
        <div className="kb-form-container visit-setting-block">
          <KBFormError error={error} />
          <div className="visit-type-list">
            {sign_fields &&
              sign_fields.map((field, index) => {
                const modifable = field.modifable.value
                const deletable = field.deletable.value
                const required = field.required.value
                const options = field.options
                return (
                  <div className="visit-type-block" key={index}>
                    <div className="type-info" style={{ display: 'block' }}>
                      <div className="flex-align-ends-center">
                        {modifable ? (
                          <div style={{ width: '70%' }}>
                            <KBInput
                              title="标题："
                              className="kb-form-group kb-div-c18 kb-form-alone"
                              field={field.name}
                            />
                          </div>
                        ) : (
                          <div className="name" style={{ marginBottom: 0 }}>
                            {field.name.value}
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            className={`slide-btn${
                              modifable ? '' : ' slide-btn-disabled'
                            }`}
                            onClick={
                              modifable
                                ? this.changeSlidesVisiblity.bind(
                                    null,
                                    index,
                                    !required
                                  )
                                : null
                            }
                          >
                            <span
                              className={`slide-bg ${
                                required ? 'slide-left' : 'slide-right'
                              }`}
                            >
                              <span className="slide-move" />
                            </span>
                          </div>
                          <div style={{ marginLeft: 5 }}>必填</div>
                        </div>
                      </div>
                      {field.field_type.value === 'options' ? (
                        <div className="field_options">
                          {options &&
                            options.map((option, index) => {
                              return (
                                <div
                                  className="flex-align-ends-center"
                                  key={index}
                                  style={{ width: '65%' }}
                                >
                                  <div style={{ width: '91%' }}>
                                    <KBInput
                                      title={`选项${index + 1}：`}
                                      className="kb-form-group kb-div-210 kb-form-alone"
                                      field={option.name}
                                    />
                                  </div>
                                  <i
                                    className="iconfont icon-delete"
                                    onClick={() => {
                                      options.removeField(index)
                                    }}
                                  />
                                </div>
                              )
                            })}
                          <span
                            className="certain-btn"
                            onClick={this.addNewFieldOption.bind(null, index)}
                          >
                            添加新选项
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {modifable ? (
                      <div
                        className="type-sort"
                        style={{ padding: '0 0 0 24px' }}
                      >
                        {index === 0 ? null : (
                          <i
                            className="iconfont icon-arrow_drop_down_circle arrow-up"
                            onClick={this.movePosition.bind(null, index, 'up')}
                          />
                        )}
                        {index === sign_fields.length - 1 ? null : (
                          <i
                            className="iconfont icon-arrow_drop_down_circle arrow-down"
                            onClick={this.movePosition.bind(
                              null,
                              index,
                              'down'
                            )}
                          />
                        )}
                        {deletable ? (
                          <i
                            className="iconfont icon-delete"
                            onClick={() => {
                              sign_fields.removeField(index)
                            }}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="type-sort" />
                    )}
                  </div>
                )
              })}
            <div className="visit-type-block">
              <div style={{ width: '100%' }}>
                <KBUserInput
                  placeholder="选择要添加的信息类型"
                  className="kb-form-group kb-form-100 kb-form-alone"
                  field={temp_new_field}
                  multi={false}
                  users={FIELD_TYPES}
                  callback={this.addNewField}
                />
              </div>
              <div className="type-sort" />
            </div>

            <div className="visit-type-block">
              <div className="tip-msg">
                <i className="iconfont icon-hint" />
                <div className="title">提示</div>
                <div className="text">
                  访客信息字段是临时访客(非邀请访客和申请被批准的访客)来访时需要填写的信息
                </div>
              </div>
              <div className="type-sort" />
            </div>
          </div>
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    currentVisitType: { fields }
  } = props
  let valueFields = _.cloneDeep(fields || [])
  valueFields = valueFields.map(field => {
    let newOptions = []
    field.options &&
      field.options.map(opt => {
        return newOptions.push({ name: opt })
      })
    field.options = newOptions
    return field
  })

  return {
    valueFields,
    initialValues: {
      sign_fields: valueFields || []
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitTypeEditFieldsForm',
  fields: [
    'sign_fields[].name',
    'sign_fields[].field_name',
    'sign_fields[].field_type',
    'sign_fields[].required',
    'sign_fields[].modifable',
    'sign_fields[].deletable',
    'sign_fields[].custom',
    'sign_fields[].options[].name',
    'temp_new_field'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitTypeEditFieldsForm)
