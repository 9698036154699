import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_GROUP } from 'app/constants/hint'

var GroupSettingManageForm = React.createClass({
  setLocation(model) {
    const { updateLocation, group } = this.props
    const { operator_ids, manager_id, content_ids } = model
    const locationIds = []
    group && group.locations.map(item => locationIds.push(item.id))
    return updateLocation({
      id: group.id,
      name: group.name,
      location_ids: locationIds,
      roles: [
        {
          role: 'location_group_wechat_manager',
          range: 'all',
          user_ids: manager_id? manager_id.split(','):[]
        },
        {
          role: 'location_group_operator',
          range: 'all',
          user_ids: operator_ids ? operator_ids.split(',') : []
        },
        {
          role: 'location_group_content_manager',
          range: 'all',
          user_ids: content_ids ? content_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { operator_ids, manager_id, content_ids },
      error,
      group,
      submitting,
      handleSubmit,
      users,
      manager,
      operators,
      contentManager
    } = this.props
    const hint = FIX_GROUP
    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <p style={{ color: '#c8c8c8', fontSize: 12, marginBottom: 20 }}>
            {hint.manage_team}
          </p>
          <div className="kb-row">
            <KBUserInput
              title="运营人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.staff}
              field={operator_ids}
              multi={true}
              users={users}
              selectedUsers={operators}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="活动负责人"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={manager_id}
              multi={true}
              users={users}
              selectedUsers={manager}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="项目内容管理员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={content_ids}
              multi={true}
              users={users}
              selectedUsers={contentManager}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const operators = selectors.getTeamRolesOfGroup(
    state,
    loc_id,
    'location_group_operator'
  )
  const manager = selectors.getTeamRolesOfGroup(
    state,
    loc_id,
    'location_group_wechat_manager'
  )
  const contentManager = selectors.getTeamRolesOfGroup(
    state,
    loc_id,
    'location_group_content_manager'
  )
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation
  const group = selectors.getCurrentGroup(state, loc_id)

  return {
    operators,
    updateLocation,
    manager,
    users,
    group,
    contentManager
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.operator_ids)) {
    errors.operator_ids = '请选择至少一位运营成员'
  }
  if (valid.required(values.manager_id)) {
    errors.manager_id = '请选择至少一位活动负责人'
  }
  if (valid.required(values.content_ids)) {
    errors.content_ids = '请选择至少一位项目内容管理员'
  }
  return errors
}

const formConfig = {
  form: 'GroupSettingManageForm',
  fields: ['operator_ids', 'manager_id', 'content_ids'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(GroupSettingManageForm)
