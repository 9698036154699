import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBInput,
  KBMorePhotoUpload,
  KBTextarea
} from 'components'
import {
  CAN_BINDING_CARD,
  CAN_SET_TOUCH_PASSWORD,
  DISPLAY_BLUETOOTH_LOCK
} from 'app/constants'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { SPACE_CURRENCY } from 'app/constants/hint'

const RATE = [0.01, 0.1, 1, 10, 100]

var SpaceSettingCurrencyForm = React.createClass({
  displayName: 'SpaceSettingCurrencyForm',

  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          virtual_currency_settings: model
        })
      },
      {
        key: 'virtual_currency_settings'
      }
    )
  },

  setRate(rate) {
    const {
      fields: { point_show_rate_setting }
    } = this.props
    point_show_rate_setting.onChange(rate)
  },

  render() {
    const {
      fields: {
        virtual_currency_name,
        point_show_rate_setting,
        point_rule_doc_url,
        desc
      },
      error,
      submitting,
      handleSubmit,
      pointRuleDocUrl
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: '0 400px 0 0' }}>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-div-210 kb-form-alone"
              field={virtual_currency_name}
              title="虚拟币名"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-div-210 kb-form-alone"
              field={point_show_rate_setting}
              title="兑换比例"
              hint={SPACE_CURRENCY.rate}
            />
            <div className="rate-option">
              {RATE.map((rate, index) => {
                return (
                  <span key={index} onClick={this.setRate.bind(null, rate)}>
                    {rate}
                  </span>
                )
              })}
            </div>
          </div>
          <div className="kb-row">
            <KBTextarea
              label="true"
              title="办公劵说明"
              className="kb-form-group kb-form-alone kb-div-c18"
              field={desc}
            />
          </div>
          <div className="kb-row m-top">
            <p style={{ marginBottom: 15 }}>设置虚拟货币使用规则</p>
            <KBMorePhotoUpload
              uploadedText="上传规则文档（PDF文件）"
              photoShow={false}
              sort={false}
              className="kb-form-group kb-form-alone"
              field={point_rule_doc_url}
              text="上传规则文档（PDF文件）"
              accept=".pdf"
            />
            <p>{pointRuleDocUrl || point_rule_doc_url.value}</p>
            <KBButton
              className="certain-btn"
              style={{ marginTop: 15 }}
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.virtual_currency_settings

  return {
    pointRuleDocUrl: setting_value.point_rule_doc_url,
    initialValues: setting_value
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.virtual_currency_name)) {
    errors.virtual_currency_name = '请输入虚拟币名'
  }
  if (
    valid.isNumber(values.point_show_rate_setting) ||
    values.point_show_rate_setting < 0
  ) {
    errors.point_show_rate_setting = '请输入正确格式'
  }
  if (values.desc && values.desc.length > 150) {
    errors.desc = '办公劵说明不能超过150个字'
  }
  return errors
}

const formConfig = {
  form: 'SpaceSettingCurrencyForm',
  fields: [
    'virtual_currency_name',
    'point_show_rate_setting',
    'point_rule_doc_url',
    'desc'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingCurrencyForm)
