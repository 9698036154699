import React from 'react'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { KBButton, KBPopover } from 'components'
import { getDynamicAttributes } from 'app/selectors'
import { dynamicAttributesActions, successState } from 'app/actions'
import { DYNAMIC_ATTRIBUTES_TYPE } from 'app/constants'
import SpacesCrmDynamicAttributesForm from './SpacesCrmDynamicAttributesForm'

var SpacesCrmDynamicAttributes = React.createClass({
  getInitialState() {
    return {
      dynamicAttributes: []
    }
  },

  componentDidMount() {
    let da
    api
      .getDynamicAttributes()
      .then(json => {
        da = getDynamicAttributes(json.json)
      })
      .finally(() => {
        this.setState({
          dynamicAttributes: da
        })
      })
  },

  add(obj) {
    this.state.dynamicAttributes.push(obj)
    this.setState({
      dynamicAttributes: this.state.dynamicAttributes
    })
  },

  createDynamicAttributes(type) {
    KBPopover.show(
      <SpacesCrmDynamicAttributesForm type={type} add={this.add} />
    )
  },

  deleteDynamicAttribute(da) {
    const { deleteDynamicAttributesActions, successAction } = this.props
    KBPopover.plugins.confirm(
      '删除自定义字段',
      '是否要删除自定义字段 ' + da.pretty_name_cn + ' !',
      {
        confirm: () => {
          api
            .deleteDynamicAttributes(da.id)
            .then(json => {
              deleteDynamicAttributesActions.success(json, {
                id: da.id,
                key: 'dynamicAttributes'
              })
              KBPopover.close()
            })
            .finally(() => {
              this.state.dynamicAttributes.splice(
                this.state.dynamicAttributes.findIndex(ele => {
                  return ele.id == da.id
                }),
                1
              )
              this.setState({
                dynamicAttributes: this.state.dynamicAttributes
              })
            })
        }
      }
    )
  },
  render() {
    const { dynamicAttributes } = this.state
    return (
      <div>
        <table className="content-table edit-table">
          <thead>
            <tr>
              <th>字段名称</th>
              <th>API名称</th>
              <th>类型</th>
              <th className="t-right" style={{ paddingRight: 40 }}>
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {dynamicAttributes &&
              dynamicAttributes.map((da, index) => {
                return (
                  <tr key={index}>
                    <td>{da.pretty_name_cn}</td>
                    <td>{da.name}</td>
                    <td>{da.datatype}</td>
                    <td className="t-right">
                      <i
                        className="iconfont icon-delete"
                        onClick={this.deleteDynamicAttribute.bind(null, da)}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {DYNAMIC_ATTRIBUTES_TYPE.map(type => {
          return (
            <button
              className="bordered-btn"
              onClick={() => this.createDynamicAttributes(type)}
              style={{ marginLeft: 0, marginRight: 10, marginTop: 30 }}
            >
              新增{type.name}字段
            </button>
          )
        })}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    deleteDynamicAttributesActions: bindActionCreators(
      dynamicAttributesActions.delete,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpacesCrmDynamicAttributes)
