import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPopover,
  UserLink,
  KBTipsy
} from 'components'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import * as selector from 'app/selectors'
import { percent } from 'utils/kbUtil'
import {
  ORG_RENT_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_STATE
} from 'app/constants'
import moment from 'moment'

var LocationAreas = React.createClass({
  displayName: 'LocationAreas',
  render() {
    const { location, loc_id, team, areas } = this.props

    return (
      <KBLoadingContainer loading={!location}>
        <section className="kb-content">
          <div className="kb-content-container clear-fix">
            <header className="nav-header">
              <div className="nav-section-header-title">分区列表</div>
            </header>
            <div className="nav-section-content-container">
              <table className="content-table">
                <thead>
                  <tr>
                    <th>分区</th>
                    <th>容纳人数</th>
                    <th>实际容纳人数</th>
                    <th>已入住人数</th>
                  </tr>
                </thead>
                <tbody>
                  {areas &&
                    areas.map((area, index) => {
                      return (
                        <tr key={index}>
                          <td>{area.name}</td>
                          <td>{area.capacity}</td>
                          <td>{area.desks_count}</td>
                          <td>{area.occupied_desks_count}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { id } = params
  var areas = selector.getAreasOfLocation(state, id)

  return {
    areas
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationAreas)
