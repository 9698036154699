import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { KBAvatar, KBPopover } from 'components'
import { UserLink, KBDropdown, KBTipsy } from 'components'
import { successState, memberActions } from 'app/actions'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MemberForm from './MemberForm'
import { getDepartmentsOfUser } from 'app/selectors'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'

var MemberRow = React.createClass({
  getInitialState() {
    return {
      member: this.props.member,
      confirming: false
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.confirming !== nextState.confirming) {
      return true
    }
    if (
      _.isEqual(nextProps.member, this.props.member) &&
      _.isEqual(nextProps.departments, this.props.departments)
    ) {
      return false
    }
    return true
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.member, this.props.member)) {
      this.setState({
        member: nextProps.member
      })
    }
  },

  resetEmail(member) {
    const { successAction } = this.props

    api
      .confirmations({ email: member.email })
      .then(
        json => {
          this.clickCfmtion()
          successAction({ message: '发送邮件成功!' })
        },
        error => {}
      )
      .finally(() => {
        this.setState({
          confirming: false
        })
      })
  },

  editMember(member) {
    const {
      org,
      location,
      departments,
      members,
      currentDepartments,
      refreshData,
      refreshMember
    } = this.props

    let m = Object.assign({}, member, {
      parent_id: (member.parent && member.parent.id) || '',
      department_ids:
        (member.department_ids && member.department_ids.join(',')) || ''
    })

    KBPopover.show(
      <MemberForm
        member={m}
        type="update"
        initialValues={m}
        parent={[m.parent]}
        currentDepartments={currentDepartments}
        departments={departments}
        members={members}
        refreshMember={refreshMember}
      />
    )
  },

  sendResetPassword(member) {
    const { successAction } = this.props

    return api
      .sendPassWordEmail({ email: member.email })
      .then(json => {
        this.clickCfmtion()
        successAction({ message: '发送重置密码邮件成功!' })
      })
      .finally(() => {
        this.setState({
          confirming: false
        })
      })
  },

  clickCfmtion() {
    this.confirmation.click()
  },

  renderResetEmail() {
    const { member, confirming } = this.state
    const waitConfirm = member.state == 'init' && member.confirmed == true
    return (
      <td ref={r => (this.confirmation = r)}>
        {member.state == 'active' ? (
          <span
            className="certain-btn-ms"
            style={{ backgroundColor: '#73bb4b', cursor: 'default' }}
          >
            已经激活
          </span>
        ) : (
          <KBDropdown isHover={true}>
            <KBDropdown.KBDropdownTrigger>
              <span
                className={`certain-btn-ms ${
                  confirming ? 'certain-btn-disabled' : ''
                }`}
              >
                {confirming
                  ? '发送邮件中'
                  : waitConfirm
                  ? '已经确认'
                  : '等待确认'}
              </span>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent
              style={{ left: '50%', transform: 'translateX(-50%)' }}
            >
              <ul className="task-type">
                <li
                  onClick={e => {
                    e.stopPropagation()
                    if (waitConfirm) {
                      this.sendResetPassword(member)
                    } else {
                      this.resetEmail(member)
                    }
                    this.setState({
                      confirming: true
                    })
                  }}
                >
                  <i className="iconfont icon-email" />
                  {waitConfirm ? '重置密码邮件' : '重新发送邮件'}
                </li>
              </ul>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        )}
      </td>
    )
  },

  toMemberInfo(id) {
    const { routerActions } = this.props
    routerActions.push(getUserInfoUrl(id))
  },

  render() {
    const { departments, dismissMember } = this.props
    const { member, confirming } = this.state
    let memberDepartments =
      departments &&
      departments.length > 0 &&
      member &&
      member.department_ids &&
      member.department_ids.map(id => departments.find(dept => dept.id === id))

    return (
      <tr key={member.id}>
        <td className="vf-center">
          <KBAvatar user={member} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: 5
            }}
          >
            <div className="t-over" style={{ maxWidth: 100 }}>
              <span
                className="color-link a-hover"
                onClick={this.toMemberInfo.bind(null, member.id)}
              >
                {member && member.name}
              </span>
            </div>
            <div style={{ marginTop: 7 }}>
              {member.gender == 'male' ? '男' : '女'}
            </div>
          </div>
        </td>
        <td>
          <div>
            <i className="iconfont icon-phone" style={{ marginRight: 5 }} />
            {member.phone_num}
          </div>
          <div style={{ marginTop: 7 }}>
            <i className="iconfont icon-E-mail" style={{ marginRight: 5 }} />
            {member.email}
          </div>
        </td>
        <td>
          <div>
            <div className="t-over" style={{ marginBottom: 10, width: 140 }}>
              <KBTipsy
                content={
                  memberDepartments &&
                  memberDepartments.map(dept => dept && dept.name).join(' ')
                }
              >
                <span className="t-over d-iblock" style={{ maxWidth: 120 }}>
                  {memberDepartments &&
                    memberDepartments.map(dept => dept && dept.name).join(' ')}
                </span>
              </KBTipsy>
            </div>
            <span className="color-nine">职务:{member.space_title}</span>
          </div>
        </td>
        {this.renderResetEmail()}
        <td style={{ whiteSpace: 'nowrap' }}>
          <i
            className="iconfont icon-edit"
            alt="编辑员工"
            onClick={e => {
              e.stopPropagation()
              this.editMember(member)
            }}
            style={{ marginRight: '15px ' }}
          />
          <i
            className="iconfont icon-delete"
            onClick={e => {
              e.stopPropagation()
              this.props.deleteMember(member)
            }}
          />
        </td>
      </tr>
    )
  }
})

MemberRow.PropTypes = {
  member: PropTypes.object.isRequired,
  departments: PropTypes.object.isRequired,
  deleteMember: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const currentDepartments = getDepartmentsOfUser(state, props.member.id)
  return {
    currentDepartments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    updateMemberActions: bindActionCreators(memberActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberRow)
