import React, { PropTypes } from 'react'
import { Link } from 'react-router'
import { KBQiniuImage } from 'components'
import { setCurrentLocation, toImageProxyDirect } from 'utils/kbUtil'
import { onlyCanAdminLocationOfWeChat, canAccessSpaceRoles, canAccessLocationOfRoles, canAccessSpaceFinance, canAccessLocationRoles, isGroupContentManager } from 'app/reducers/role'

var LocationBlock = React.createClass({
  getRandom(id) {
    let random = id % 10

    if (random < 10) {
      random = '0' + random
    } else if (random > 12) {
      random = 11
    }

    return random
  },

  getBlockLink(location) {
    let { user } = this.props
    const locationuRL = `/admin/locations/${location.id}`
    if(canAccessLocationOfRoles(user, location.id)) {
      return locationuRL
    } else if(canAccessSpaceFinance(user) || canAccessLocationRoles(user, location.id, ['invoice_check', 'invoice_confirm', 'invoice_check_notify'])) {
      // 财务管理和账单核销那些
      // return `${locationuRL}/invoices`
      return `/admin/invoices`
    } else if(onlyCanAdminLocationOfWeChat(user, location.id)) {
      return `${locationuRL}/organizations?page=1&per_page=100`
    } else if(canAccessLocationRoles(user, location.id, ['repair', 'task_feedback'])) {
      // 维修
      return `${locationuRL}/tasks/repair/type/my`
    }else if(isGroupContentManager(user, location.id)) {
      // 只有内容管理员
      return `${locationuRL}/activities`
    } else if(canAccessLocationRoles(user, location.id, ['landlord'])) {
      // 只有项目外部管理人员
      return `${locationuRL}/organizations`
    }
    return `${locationuRL}`
  },

  // shouldComponentUpdate (nextProps, nextState) {
  //   if (this.props.location.id === nextProps.location.id) {
  //     return false
  //   }
  //   return true
  // },

  render() {
    let { enteringRate, user } = this.props

    let location = Object.assign(
      {},
      {
        entering_rate: {
          financial_entering_rate: 0,
          total_capacity: 0,
          occupied_desks_count: 0,
          entering_rate_calc_type: 'by_desk'
        }
      },
      this.props.location
    )
    const {
      entering_rate: { entering_rate_calc_type }
    } = location
    let {
      financial_entering_rate,
      total_capacity,
      occupied_desks_count
    } = enteringRate
    let occupancy_rate = ''
    if (isContains(financial_entering_rate, '%')) {
      occupancy_rate = financial_entering_rate
    } else {
      occupancy_rate = financial_entering_rate + '%'
      if (isContains(occupancy_rate, 'undefined')) {
        occupancy_rate = '0%'
      }
    }
    const showDeskWay = entering_rate_calc_type === 'by_desk'
    let isWeChatManger = false
    if(onlyCanAdminLocationOfWeChat(user, location.id)){
      isWeChatManger = true
    }

    return (
      <div
        className="subbranch-message"
        onClick={e => {
          e.stopPropagation()
          setCurrentLocation(location)
        }}
      >
        <Link to={this.getBlockLink(location)}>
          {location.images && location.images.length > 0 ? (
            <img
              src={toImageProxyDirect(location.images[0], 644, 410)}
              alt="loading"
              style={{ width: 322, height: 205 }}
            />
          ) : (
            <KBQiniuImage
              type="src"
              src={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/location_subbranch${this.getRandom(
                location.id
              )}.jpg`}
              width={322}
              height={205}
              alt={location.name}
            />
          )}
          <div className="space_location">
            <p>{location.name}</p>
            <div className="location_info">
              <div
                className="loc_info_list"
                style={{ width: 110, borderRight: '1px solid #e5e5e5' }}
              >
                <p>
                  {showDeskWay ? '工位' : '面积'}
                  总数
                </p>
                <p>
                  <span>
                    {showDeskWay
                      ? total_capacity || '0'
                      : parseFloat(total_capacity).toFixed(2)}
                    <span style={{ fontSize: 10 }}>
                      {showDeskWay ? '个' : '㎡'}
                    </span>
                  </span>
                </p>
              </div>
              <div
                className="loc_info_list"
                style={{
                  width: 110,
                  borderRight: '1px solid #e5e5e5',
                  paddingLeft: 15
                }}
              >
                <p>已出租</p>
                <p>
                  <span>
                    {showDeskWay
                      ? occupied_desks_count || '0'
                      : parseFloat(occupied_desks_count).toFixed(2)}
                    <span style={{ fontSize: 10 }}>
                      {showDeskWay ? '个' : '㎡'}
                    </span>
                  </span>
                </p>
              </div>
              <div className="loc_info_list" style={{ paddingLeft: 15 }}>
                <p>入驻率</p>
                <p>
                  <span>
                    {occupancy_rate.substring(0, occupancy_rate.length - 1) ||
                      '0'}
                    <span style={{ fontSize: 10 }}>%</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
})

function isContains(str, substr) {
  return new RegExp(substr).test(str)
}

LocationBlock.propTypes = {
  location: PropTypes.object.isRequired
}

export default LocationBlock
