import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'

var Organization = React.createClass({
  displayName: 'Organization',

  render() {
    const { setting, updatePayMode } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        setting: setting,
        updatePayMode: updatePayMode
      })
    )
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>支付设置</span>
          </div>
        </header>
        <div className="kb-form-container">{childrenWithProps}</div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  return {
    setting
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
