import React from 'react'
import ReactDOM from 'react-dom'
import createBrowserHistory from 'history/lib/createBrowserHistory'
// import * as serviceWorker from './serviceWorker';
import { Router, useRouterHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import createStore from './store/createStore'
import { Provider } from 'react-redux'
import routes from './routes'
import {apiUtils} from 'app/services'
import intl from 'react-intl-universal'
import { SUPPOER_LOCALES, localeIndex } from 'app/constants'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const MOUNT_ELEMENT = document.getElementById('root')

if (process.env.NODE_ENV == 'production') {
  // 添加sentry错误监听
  // Raven.config('https://e8e35dd1a9af4f28b4e3483e5c32eae0@sentry.io/1269923').install()
}

// Configure history for react-router
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename : JSON.stringify(process.env.BASENAME)
})

// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the key "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.

const getCurrentUser = () => {
  try{
    var localUser = JSON.parse(localStorage.getItem('current_user'))
    var sessionUser = JSON.parse(sessionStorage.getItem('current_user'))

    return localUser || sessionUser || null
  }catch (e){
    apiUtils.removeCurrentUser()
  }
}

function initLocale(localeIndex) {
  const currentLocale = SUPPOER_LOCALES[localeIndex].value; // Determine user's locale here
  intl.init({
    currentLocale,
    locales: {
      [currentLocale]: require(`./locales/${currentLocale}.js`)
    }
  })
}

initLocale(localeIndex)

const currentUser = getCurrentUser() || {}
apiUtils.setCurrentUser(currentUser || {})
const initialState = {
  user: currentUser,
  entities: {
    cities: currentUser.cities,
    // locations: currentUser.locations,
    spaces: currentUser.spaces
  }
}
const store = createStore(initialState, browserHistory)

const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: (state) => state.router
})

let render = (key = null) => {
  const App = (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router history={history} children={routes} key={key} />
      </ConfigProvider>
    </Provider>
  )
  ReactDOM.render(App, MOUNT_ELEMENT)
}

render()

// serviceWorker.unregister();
