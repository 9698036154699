import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import {
  usersActions,
  apiCallFailure,
  orgUserActions,
  successState
} from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBRadio,
  KBForm,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBTextarea,
  KBMoreImageCropperInput,
  KBImageCropperInput,
  KBAvatar
} from 'components'
import { notification } from 'antd'
import config from 'app/config'

const priceType = [
  {
    id:'',
    name:'请选择计价单位'
  },
  {
    id:'hour',
    name: '小时'
  },
  {
    id:'day',
    name: '天'
  },
  {
    id:'month',
    name: '月'
  }
]

let SCENE = null

var GroupSetBaseAddProductForm = React.createClass({
  getInitialState() {
    return {
      loading: false,
      fileList: []
    }
  },

  componentDidMount() {
    const {
      fields: { id, price, description, image, unit, name },
      type,
      product,
      scene
    } = this.props
    SCENE = scene
    if (type === 'edit') {
      id.onChange(product.id)
      description.onChange(product.description)
      image.onChange(product.image)
      name.onChange(product.name)
      if(scene==='pro'){

        price.onChange(product.price)
        unit.onChange(product.unit)
      }
      // if(scene === 'fac'){
      //   // image.onChange(Array.isArray(product.image)?product.image:[product.image])
      //   this.setState({fileList:Array.isArray(product.image)?product.image:[product.image]})
      // }
    }
  },

  addProduct(model) {
    const { scene } = this.props
    if(scene==='fac') {
      delete model.price
      delete model.unit
    }
    const { callBack } = this.props
    callBack && callBack(model)
  },

  loadingCallBack(loading) {
    this.setState({ loading })
  },

  UploadFailureCallBack(msg){
    if(msg){
      notification.error({message: '上传失败', description:msg,top:100 })
    }
  },

  removeFile(index) {
    const {
      fields: { image }
    } = this.props
    const { fileList } = this.state
    let newFileList = [...fileList]
    newFileList.splice(index, 1)
    image.onChange(newFileList)
    this.setState({ fileList: newFileList })
  },


  render() {
    const {
      fields: { id, price, description, image, unit, name },
      allProduct,
      handleSubmit,
      type,
      submitting,
      groupId,
      scene
    } = this.props
    const { loading, fileList } = this.state
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.addProduct)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'create' ? '添加' : '编辑'}{scene==='pro'?'项目产品类别':'社区介绍'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          {scene==='fac'&&<div className='kb-row'>
            <KBInput
              title="社区名称"
              field={name}
              className="kb-form-group kb-div-48 kb-form-alone"
              isRequiredR={true}
            />
          </div>}
          <div className="kb-row">
            <fieldset className="fieldset">
              <legend>{scene==='pro'?'项目':'社区'}图片</legend>
              {/* {scene==='pro'? */}
              <div>
                <KBImageCropperInput
                  // callback={this.callback}
                  // failureCallBack={this.failureCallBack}
                  field={image}
                  style={{ display: 'inline-block' }}
                  type={`groups/${groupId}`}
                  loadingCallBack={this.loadingCallBack}
                  imageSize={1024}
                  failureCallBack={this.UploadFailureCallBack}
                  width={scene==='pro'?150:225}
                  height={150}
                  aspect={scene==='pro'?1:1125 / 750}
                  previewBorderRadius={scene==='pro'?150:0}
                  accept=".png,.jpg,.jpeg"
                  isPublic
                >
                  <div className="upload-portrait">
                    <button
                      className="bordered-btn"
                      type="button"
                      style={{ marginLeft: 0, marginBottom: 20, width: 100 }}
                    >
                      {loading
                        ? '上传中...'
                        : image.value
                        ? '上传新图片'
                        : '上传图片'}
                    </button>
                    <br />
                    {image.value && (
                      <KBAvatar
                        imgSize={{ width: scene==='pro'?150:225, height: scene==='pro'?150:150 }}
                        user={{ avatar: image.value }}
                        imgStyle={{ marginLeft: 0, verticalAlign: 'middle', borderRadius: scene==='pro'?150:0 }}
                        style={{ display: 'inline-block', marginLeft: 0 }}
                      />
                    )}
                  </div>
                </KBImageCropperInput>
              </div>
              {/* :
              <div>
                <KBMoreImageCropperInput
                  title={false}
                  aspect={1125 / 750}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={image}
                  type="groups"
                  prefix={`groups/${groupId}/`}
                  width={225}
                  height={150}
                  imageSize={1024}
                  failureCallBack={this.UploadFailureCallBack}
                  callback={v => {
                    console.log(v,'callback');
                    this.setState({ fileList: v })}}
                >
                  <div className="upload-portrait">
                    <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                      <button className="bordered-btn m-none" type="button">
                        {fileList&&fileList.length ? '继续上传图片' : '上传图片'}
                      </button>
                      <p
                        className="color-nine"
                        style={{ paddingTop: 20, fontSize: 12 }}
                      >
                        图片格式为png, jpeg或jpg,文件大小不大于1M
                      </p>
                    </div>
                  </div>
                </KBMoreImageCropperInput>
                <div>
                {fileList ? (
                  <ul className="kb-more-file" style={{margin:0}}>
                    {fileList.map((file, index) => {
                      return (
                        <li key={index} style={{ cursor: 'pointer' }}>
                          <img
                            src={file}
                            alt="loading"
                            style={{
                              width: '225px',
                              height: '150px',
                              marginBottom: 10
                            }}
                          />

                          <div className="delete">
                            <i
                              className="iconfont icon-close"
                              onClick={this.removeFile.bind(null, index)}
                            />
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  ''
                )}
              </div>
              </div>} */}
            </fieldset>
          </div>
          {scene==='pro'&&<div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="选择类别"
              field={id}
              isRequired={true}
              arrayData={allProduct}
            />
          </div>}
          {scene==='pro'&&<div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-48"
              isRequired={scene==='pro'?true:false}
              field={price}
              title="价格"
            />
            <KBSelect
              className="kb-form-group kb-div-48"
              title="计价单位"
              field={unit}
              isRequired={scene==='pro'?true:false}
              arrayData={priceType}
            />
          </div>}
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={description}
              title="描述"
            />
          </div>
        </div>
        <div className="kb-row fixed-form-btn">
          <span className="cancel-btn" onClick={KBPopover.close}>
            取消
          </span>
          <KBButton
            className="certain-btn"
            type="submit"
            style={{ marginLeft: 20 }}
            submitting={submitting}
          >
            {type === 'create' ? '添加' : '保存'}
          </KBButton>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if(!values.name&&SCENE==='fac'){
    errors.name = '请输入社区名称'
  }
  if (!values.id&&SCENE==='pro') {
    errors.id = '请选择产品类别'
  }
  if (!values.price&&SCENE==='pro') {
    errors.price = '请填写价格'
  }
  if (!values.description) {
    errors.description = '请填写描述'
  }
  if (!values.image) {
    errors.image = '请上传图片'
  }

  if(!values.unit&&SCENE==='pro'){
    errors.unit = '请选择计价单位'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetBaseAddProductForm',
  fields: ['id', 'price', 'description', 'image', 'unit', 'name', 'fac_image'],
  validate: validate,
  touchOnBlur: false
}

GroupSetBaseAddProductForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBaseAddProductForm)

export default GroupSetBaseAddProductForm
