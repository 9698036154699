import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { KBPopoverConfirm } from 'components/tools'
import { KBPopover } from 'components'
import { toImageProxyDirect } from 'utils/kbUtil'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'

var VisitDeviceManage = React.createClass({
  getInitialState() {
    return {
      devices: []
    }
  },

  componentDidMount() {
    this.getDevices()
  },

  getDevices() {
    const { location_id } = this.props
    api
      .getPads({ location_id, per_page: 50, subtype: 'front_desk' })
      .then(json => {
        const devices = json.json || []
        this.setState({
          devices
        })
      })
  },

  deleteDevice(device) {
    KBPopoverConfirm({
      name: '删除平板',
      context: `您确定要删除平板 ${device.name} 吗?`,
      callback: () => {
        return api.deletePad(device.id).then(
          json => {
            this.getDevices()
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  toPadAddPage() {
    const { location_id, routerActions } = this.props
    routerActions.push(`/admin/locations/${location_id}/visit/devices/add`)
  },

  render() {
    const { devices } = this.state
    const { isManager, isLandlord } = this.props

    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>设备管理</span>
            </div>
            {isManager || isLandlord ? (
              <button
                className="bordered-btn f-right"
                onClick={this.toPadAddPage}
              >
                添加平板
              </button>
            ) : null}
          </header>

          <div
            className="nav-section-content-container"
            style={{ paddingTop: 30 }}
          >
            {(devices.length > 0 &&
              devices.map((device, index) => {
                return (
                  <div className="visit-setting-block">
                    <div
                      className="title-container"
                      style={{ marginBottom: 0, alignItems: 'center' }}
                    >
                      <div className="vf-center">
                        <img
                          src={toImageProxyDirect(
                            'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/pad_active.png',
                            28,
                            34
                          )}
                          alt=""
                          style={{ display: 'inline-block', marginRight: 10 }}
                        />
                        <div className="title-and-des">
                          <div className="title">{device.name || ''}</div>
                          <div
                            className="des vf-center"
                            style={{ marginTop: 5 }}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                backgroundColor: '#0bd377',
                                marginRight: 5
                              }}
                            />
                            在线
                          </div>
                        </div>
                      </div>
                      <button
                        className="certain-delete-btn"
                        onClick={this.deleteDevice.bind(null, device)}
                      >
                        删除
                      </button>
                    </div>
                  </div>
                )
              })) ||
              '暂时没有设备哦~'}
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  let location_id = params && params.id
  const { user } = state
  let isManager = canAccessLocation(user, location_id)
  const isLandlord = canAccessLocationRoles(user, location_id, ['landlord'])

  return {
    location_id,
    isManager,
    isLandlord
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitDeviceManage)
