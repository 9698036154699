import React from 'react'
import { bindActionCreators } from 'redux'
import * as actions from 'app/actions'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import LocationSetPrinterForm from './LocationSetPrinterForm'
import { api } from 'app/services'
import { KBPopover, KBTableWithoutData } from 'components'
import { PRINTER_STATUS_OBJ } from 'app/constants'
import { Link } from 'react-router'
import { getUserInfoUrl } from 'utils/kbUrl'

var LocationSetPrinterManage = React.createClass({
  displayName: 'LocationSetPrinterManage',

  getInitialState() {
    return {}
  },

  componentWillMount() {
    const { location, getPrintersActions } = this.props
    const location_id = location.id

    api.getPrinters({ location_id }).then(
      json => {
        getPrintersActions.success(json)
      },
      errors => {
        getPrintersActions.failure(errors)
      }
    )
  },

  createPrinter() {
    const { space_id, loc_id } = this.props
    KBPopover.show(
      <LocationSetPrinterForm space_id={space_id} loc_id={loc_id} />
    )
  },

  updatePrinter(printer) {
    const { space_id, loc_id } = this.props
    KBPopover.show(
      <LocationSetPrinterForm
        initialValues={{ ...printer }}
        state="update"
        space_id={space_id}
        loc_id={loc_id}
        printer={printer}
      />
    )
  },

  deletePrinter(printer) {
    const { id } = printer
    const { deletePrinterActions } = this.props
    KBPopover.plugins.confirm('删除打印机', '确定要删除该打印机?', {
      confirm: () => {
        api.deletePrinter(id).then(
          json => {
            deletePrinterActions.success(json, { id, key: 'printers' })
            KBPopover.close()
          },
          errors => {
            deletePrinterActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const { printers } = this.props

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>打印机管理</span>
            <button
              className="bordered-btn f-right"
              onClick={this.createPrinter}
            >
              添加打印机
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          <table className="content-table">
            <thead>
              <tr>
                <th>打印机名称</th>
                <th>状态</th>
                <th>打印设备基站ID</th>
                <th>创建者</th>
                <th className="t-right" style={{ paddingRight: 36 }}>
                  操作
                </th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={printers && printers.length > 0}
              tableHeadNum="5"
              tipMessage="暂无打印机"
            >
              {printers &&
                printers.map((printer, index) => {
                  return (
                    <tr key={index}>
                      <td>{printer.name || ''}</td>
                      <td>
                        {(printer.status &&
                          PRINTER_STATUS_OBJ[printer.status]) ||
                          ''}
                      </td>
                      <td>{printer.station_id || ''}</td>
                      <td>
                        <Link
                          className="color-link"
                          to={getUserInfoUrl(
                            printer.creator && printer.creator.id
                          )}
                        >
                          {(printer.creator && printer.creator.name) || ''}
                        </Link>
                      </td>
                      <td className="t-right">
                        <i
                          className="iconfont icon-edit m-right-sm"
                          onClick={this.updatePrinter.bind(null, printer)}
                        />
                        <i
                          className="iconfont icon-delete"
                          onClick={this.deletePrinter.bind(null, printer)}
                        />
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let space_id = location.space_id

  let printers = selectors.getPrinters(state, loc_id)
  return {
    space_id,
    loc_id,
    printers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPrintersActions: bindActionCreators(
      actions.printersAction.get,
      dispatch
    ),
    deletePrinterActions: bindActionCreators(
      actions.printersAction.delete,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSetPrinterManage)
