import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { crmSourcesActions } from 'app/actions'
import { OPPORTUNITIES_SOURCE } from 'app/constants'
import { KBFormError, KBButton, KBInput, KBSelect, KBPopover } from 'components'

var SpacesCrmSourcesForm = React.createClass({
  displayName: 'SpacesCrmSourcesForm',
  addCrmSource(model) {
    const {
      createCrmSourceAction,
      updateCrmSourceAction,
      type,
      source
    } = this.props
    model.allotment = Math.floor(model.allotment * 10000) / 1000000

    if (type) {
      return api.updateCrmSource(source.id, model).then(json => {
        updateCrmSourceAction.success(json)
        KBPopover.close()
      })
    }

    return api.createCrmSources(model).then(json => {
      createCrmSourceAction.success(json)
      KBPopover.close()
    })
  },
  render() {
    const {
      fields: { name, source_type, allotment },
      submitting,
      handleSubmit,
      type
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.addCrmSource)}>
        <header className="kb-form-header">
          <h2 className="header-title">{type ? '修改' : '新增'}渠道来源</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="渠道名称"
              field={name}
              isRequired={true}
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              arrayData={OPPORTUNITIES_SOURCE}
              title="渠道类型"
              field={source_type}
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass="input-percent"
              field={allotment}
              title="分成比例"
              hint={'如果填写为空或者为0则无分成'}
            />
          </div>
          <div className="kb-row m-top t-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              style={{ marginRight: 0 }}
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { source } = props

  return {
    initialValues: {
      name: (source && source.name) || '',
      source_type: (source && source.source_type) || OPPORTUNITIES_SOURCE[0].id,
      allotment:
        source && source.allotment
          ? Math.floor(source.allotment * 1000000) / 10000
          : 0
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createCrmSourceAction: bindActionCreators(
      crmSourcesActions.create,
      dispatch
    ),
    updateCrmSourceAction: bindActionCreators(
      crmSourcesActions.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输渠道名称'
  }

  if (valid.required(values.source_type)) {
    errors.name = '请输入渠道类型'
  }

  return errors
}

const formConfig = {
  form: 'SpacesCrmSourcesForm',
  fields: ['name', 'source_type', 'allotment'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpacesCrmSourcesForm)
