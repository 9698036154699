import React from 'react'
import intl from 'react-intl-universal'
import { bindActionCreators } from 'redux'
import { spacesActions, successState } from 'app/actions'
import { connect } from 'react-redux'
import { PaymentSetting } from 'components'
import { api } from 'app/services'
import * as selectors from 'app/selectors'

var GroupSetPayment = React.createClass({
  updateSetting(data) {
    const { updateLocation, loc_id, group } = this.props
    const locationIds = []
    group && group.locations.map(item => locationIds.push(item.id))
    const modle = {
      id: loc_id,
      name: group.name,
      location_ids: locationIds,
      payment_settings: data
    }
    updateLocation && updateLocation(modle)
  },

  render() {
    const { payment_settings } = this.props
    return (
      <section style={{ width: '100%', height: '100%' }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>支付设置</span>
          </div>
        </header>
        <div>
          <PaymentSetting
            paymentSettings={payment_settings}
            callback={this.updateSetting}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const location = props.location
  let payment_settings = (location && location.payment_settings) || {}
  payment_settings = {
    reservation: payment_settings.reservation,
    cabinet_reservation: payment_settings.cabinet_reservation
  }
  const loc_id = props.params.id
  const group = selectors.getCurrentGroup(state, loc_id)
  return {
    payment_settings,
    loc_id,
    group
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSpaceAction: bindActionCreators(spacesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSetPayment)
