import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { successState, apiCallFailure } from 'app/actions'

var LocationSetOpenFuncForm = React.createClass({
  displayName: 'LocationSetOpenFuncForm',
  getInitialState() {
    return {
      funcSetting: this.props.value[this.props.type]
    }
  },

  componentWillReceiveProps(nextprops) {
    if (nextprops.value[this.props.type] != this.state.funcSetting)
      this.setState({
        funcSetting: nextprops.value[this.props.type]
      })
  },

  updateOpenFuncSetting(val) {
    const { type, value, updateLocationSetting } = this.props
    let model = value
    model[type] = val

    return updateLocationSetting(
      {
        location_settings: {
          open_func: model
        }
      },
      {
        key: 'location_settings'
      }
    )
  },

  changeSlide() {
    const { funcSetting } = this.state
    let newSettingValue = funcSetting == 'enabled' ? 'disabled' : 'enabled'
    this.setState({
      funcSetting: newSettingValue
    })
    this.updateOpenFuncSetting(newSettingValue)
  },

  render() {
    const { type } = this.props
    const { funcSetting } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="slide-btn"
          onClick={() => {
            this.changeSlide()
          }}
        >
          <span
            className={`slide-bg ${
              funcSetting == 'enabled' ? 'slide-left' : 'slide-right'
            }`}
          >
            <span className="slide-move" />
          </span>
        </div>
        <span style={{ marginLeft: 10, marginBottom: 5 }}>{`点击开启/关闭${
          type == 'print_func' ? '打印' : '投屏'
        }功能`}</span>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSetOpenFuncForm)
