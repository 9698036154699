import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect } from 'components'
import { AREA_RENTING_TYPE } from 'app/constants'
import * as selectors from 'app/selectors'

var SpaceSettingAreaRentingTypeForm = React.createClass({
  displayName: 'SpaceSettingAreaRentingTypeForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    updateCurrentSpace(
      {
        settings: JSON.stringify({
          general_settings: model
        })
      },
      {
        key: 'general_settings'
      }
    )
  },
  render() {
    const {
      fields: { area_renting_type },
      error,
      submitting,
      handleSubmit,
      updateCurrentSpace
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="租赁方式"
              field={area_renting_type}
              arrayData={AREA_RENTING_TYPE}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.settings.area_renting_type || 'by_area'

  return {
    initialValues: {
      area_renting_type: setting_value
    }
  }
}

const formConfig = {
  form: 'SpaceSettingAreaRentingTypeForm',
  fields: ['area_renting_type'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(SpaceSettingAreaRentingTypeForm)
