import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { InitialHighCharts } from 'components/mixins'
import { percent } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticCrmSource = React.createClass({
  displayName: 'StatisticCrmSource',
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate()

    return {
      filters: {
        from,
        to
      },
      dataOut: [],
      month: ''
    }
  },
  componentDidMount() {
    const { filters, dataOut } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')

    this.mounted = true
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: null
        },
        tooltip: {
          pointFormat: '所占比率: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>',
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  'black'
              }
            }
          }
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { from, to, location_ids } = filters
    var params = { location_ids }

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.getCustomerWinOrFailedSourceStatistic(params).then(({ json }) => {
      this.parseJSON(json.failed_data)
    })
  },

  parseJSON(json) {
    var contain = {}
    let dataOut = []

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          dataOut
        })
      }
    }

    var array = Object.assign([], json)

    array.forEach(json => {
      if (!json.crm_source) {
        return
      }

      const type = json.crm_source.source_type

      if (!contain[type]) {
        contain[type] = {}
        contain[type].name = json.crm_source.source_type
        contain[type].value = json.count
        contain[type].children = [
          { name: json.crm_source.name, value: json.count }
        ]
        return
      }

      contain[type].value = contain[type].value + json.count
      contain[type].children.push({
        name: json.crm_source.name,
        value: json.count
      })
    })

    this.parseHightChartData(contain)
  },

  parseHightChartData(data) {
    let colors = this.getHighChartColor()
    let Highcharts = this.getHighcharts()
    let dataOut = []
    let count = 0

    data = Object.values(data)
    data.forEach(json => {
      count = count + json.value
    })
    let dt = {
      colorByPoint: true,
      size: '80%',
      innerSize: '60%',
      data: []
    }

    data.forEach((json, index) => {
      let y = parseFloat(percent(count, json.value))
      let name = json.name
      let color = colors[index]

      let childrenLen = json.children.length
      json.children.forEach((t, j) => {
        let brightness = 0.2 - j / childrenLen / 5
        let tname = t.name + ' ' + t.value + '个'
        let ty = parseFloat(percent(count, t.value))
        let tcolor = Highcharts.Color(color)
          .brighten(brightness)
          .get()
        dt.name = name
        dt.data.push({
          name: tname,
          y: ty,
          color: tcolor
        })
      })
    })
    dataOut.push(dt)

    if (this.mounted) {
      this.setState({
        dataOut
      })
    }

    this.fillChart(dataOut)
  },

  fillChart(dataOut) {
    this.setSerierDate(dataOut)
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render: function() {
    const { locations } = this.props
    const { filters, dataInner, dataOut, month } = this.state
    const { from, to } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>CRM流失数按渠道统计</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
          <KBDropSelect
            options={{ defaultSelectPrompt: '选择分店' }}
            defaultData={locations}
            multi={true}
            callback={this.selectCallBack}
            style={{ marginLeft: 20 }}
          />
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)

  return {
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticCrmSource)
