import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { successState, apiCallFailure } from 'app/actions'
import {
  onlyCanAdminLocationOfWeChat
} from '../../app/reducers/role'
const SETTINGS_SECTIONS = [
  {
    name: '欢迎屏幕',
    path: ''
  },
  {
    name: '访客签到',
    path: 'sign_in'
  },
  {
    name: '访客通知',
    path: 'notify'
  },
  {
    name: '访客签退',
    path: 'sign_out'
  },
  {
    name: '访客小程序',
    path: 'sign_procedure'
  },
  {
    name: '自动审核',
    path: 'automatic_check'
  }
]

const SETTING_WECHAT = [
  {
    name: '欢迎屏幕',
    path: ''
  },
  {
    name: '访客签到',
    path: 'sign_in'
  },
  {
    name: '访客通知',
    path: 'notify'
  },
  {
    name: '访客签退',
    path: 'sign_out'
  },
]


var VisitSettingsBox = React.createClass({
  getInitialState() {
    return {
      config: {},
      loading: true
    }
  },

  componentDidMount() {
    this.getVisitorSet()
  },

  getVisitorSet() {
    api
      .getVisitorSettings()
      .then(
        ({ json }) => {
          const { config } = json
          this.setState({
            config
          })
        },
        error => {
          console.log(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  updateVisitorSet(config) {
    const { successAction, apiCallFailureAction } = this.props
    return api.updateVisitorSettings({ config }).then(
      ({ json }) => {
        const { config } = json
        this.setState({
          config
        })
        successAction({
          message: '操作成功'
        })
        return true
      },
      error => {
        apiCallFailureAction({
          status: 'error',
          message: error._error || error.message || ''
        })
        return true
      }
    )
  },

  render() {
    const { setting_type, location_id, isOnlyWeChatManger } = this.props
    const { config, loading } = this.state

    let menu = SETTINGS_SECTIONS
    if(isOnlyWeChatManger){
      menu = SETTING_WECHAT
    }

    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        config,
        updateVisitorSettings: this.updateVisitorSet,
        loading
      })
    })

    return (
      <div className={`clear-fix`} style={{ height: '100%' }}>
        <section className="kb-content" style={{ paddingTop: 0 }}>
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">访客设置</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((section, index) => {
                    let active = section.path === setting_type
                    return (
                      <li
                        key={index}
                        className={classNames(
                          active
                            ? 'nav-container-list-hover select-list-hover'
                            : ''
                        )}
                      >
                        <Link
                          to={{
                            pathname: `/admin/locations/${location_id}/visit/settings/${section.path}`,
                            query: { type: section.path }
                          }}
                        >
                          {' '}
                          {section.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {childrenWithProps}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let location_id = params && params.id
  let setting_type = (location && location.query && location.query.type) || ''
  let isOnlyWeChatManger = onlyCanAdminLocationOfWeChat(state.user, location_id)
  return {
    location_id,
    setting_type,
    isOnlyWeChatManger
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSettingsBox)
