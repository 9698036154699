import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { canAccessLocationVisitor, canAccessLocation } from 'app/reducers/role'
import {
  KBPopover,
  KBLoadingContainer,
  KBAvatar,
  KBDropdown,
  KBTableWithoutData,
  KBPagination,
  KBButton,
  KBRangeDate,
  KBCheckBox
} from 'components'
import { PopoverShowPicture } from 'components/views'
import { connect } from 'react-redux'
import {
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown,
  paginate,
  formatMinuteSecondEN,
  getServiceErrorMessage
} from 'utils/kbUtil'
import moment from 'moment'
import AddVisitRequestForm from './AddVisitRequestForm'
import { apiCallFailure, successState } from 'app/actions'
import { KBPopoverConfirm } from 'components/tools'
import { KBDropSelect } from 'components/plugins'
import AddVisitBlackForm from './AddVisitBlackForm'
import _ from 'lodash'
const DROP_DOWNS = ['company']

var VisitBlackList = React.createClass({
  displayName: 'VisitBlackList',
  getInitialState() {
    const { filters } = this.props
    return {
      visitor_records: [],
      filters,
      pagination: null,
      loading: true,
      selectedUsers: '',
      phone_num: '',
      company_loading: true
    }
  },

  componentDidMount() {
    this.mounted = true
    this._loadData(1, {})
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      const { filters } = nextProps
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
      return
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(page, params, isLoading = true) {
    const { loc_id, query, routerActions } = this.props
    const { filters } = this.state
    this.setState({
      loading: isLoading
    })
    const per_page = 10
    let pageObj = { per_page, page }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      ...pageObj,
      ...filters
    })
    api
      .getVisitorBlacklists({
        location_id: loc_id,
        ...params,
        ...query,
        ...pageObj
      })
      .then(json => {
        if (!this.mounted) {
          return
        }
        const visitor_records = json.json.sort((a, b) => {
          return (
            moment(b.expect_visit_date).format('X') -
            moment(a.expect_visit_date).format('X')
          )
        })
        routerActions.replace(
          `/admin/locations/${loc_id}/visit/black_list${queryFiltersStr}`
        )
        let pagination = paginate(json.response)
        this.setState({
          visitor_records,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, loading: true })

    // force refresh
    this._refresh()
  },

  _refresh() {
    this['company'] && this['company'].hide()
    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.company.clearData()
    this.setState({
      filters: {},
      loading: true,
      phone_num: '',
      selectedUsers: ''
    })
    // force refresh
    this._refresh()
  },

  showFiltered() {
    const { filters } = this.state
    let params = Object.assign({}, filters)
    this._loadData(filters.page || 1, { ...params })
    this.setState({
      ...params
    })
  },

  visitorConfirmRefresh(cVisitor, type) {
    const { visitor_records } = this.state
    let new_visitor_records = visitor_records
    let index = visitor_records.findIndex(record => record.id === cVisitor.id)
    if (type && type === 'delete') {
      new_visitor_records.splice(index, 1)
    } else {
      new_visitor_records.splice(index, 1, cVisitor)
    }
    this.setState({
      visitor_records: new_visitor_records
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  toUserInfo(record) {
    const { routerActions } = this.props

    if (record.user_id) {
      routerActions.push(`/admin/members/${record.user_id}`)
    }
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  changeUser(user) {
    this.setState({ selectedUsers: user.target.value })
  },

  phoneNumChange(number) {
    this.setState({ phone_num: number.target.value })
  },

  orgSelectCallBack(data) {
    if (!data) {
      this._removeFilter('organization_id')
      return
    }
    this._setFilter({ organization_id: data && data.id })
  },

  _removeFilter(key) {
    let newFilters = _.omit(this.state.filters, [key])
    this.setState({ filters: newFilters })
    // force refresh
    this._refresh(key, newFilters)
  },

  addBlack() {
    const { loc_id } = this.props

    KBPopover.show(
      <AddVisitBlackForm callBack={this._refresh} loc_id={loc_id} />
    )
  },

  editBlack(record) {
    const { loc_id } = this.props

    KBPopover.show(
      <AddVisitBlackForm
        record={record}
        state="update"
        callBack={this._refresh}
        loc_id={loc_id}
      />
    )
  },

  render() {
    const {
      visitor_records,
      loading,
      pagination,
      selectedUsers,
      phone_num
    } = this.state
    const {
      isLocManager,
      loc_id,
      isLocVisitor,
      currentOrg,
      orgs,
      defaultSingleContent
    } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')
    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>访客黑名单</span>
            </div>
            <button className="bordered-btn f-right" onClick={this.addBlack}>
              添加黑名单
            </button>
          </header>
          <div
            style={{
              padding: '20px 30px 0',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <KBDropSelect
              ref={ref => {
                this.company = ref
              }}
              dropDown_name="company"
              selectedData={currentOrg}
              closeClick={this.closeDropDowns}
              options={{ defaultSingleContent, value: 'full_name' }}
              defaultData={orgs}
              multi={false}
              callback={this.orgSelectCallBack}
              active={false}
            />
            <div
              className="search-input m-left-sm"
              style={{ width: '186px', display: 'inline-block' }}
            >
              <input
                value={selectedUsers}
                type="text"
                placeholder="查询姓名"
                ref="queryName"
                onChange={this.changeUser}
              />
            </div>
            <div
              className="search-input m-left-sm"
              style={{ width: '186px', display: 'inline-block' }}
            >
              <input
                value={phone_num}
                type="text"
                placeholder="查询手机号"
                ref="queryNumber"
                onChange={this.phoneNumChange}
              />
            </div>
            <button
              className="certain-btn"
              style={{ marginLeft: 47 }}
              onClick={() => {
                this._setFilter({ phone_num, name: selectedUsers })
              }}
            >
              查询
            </button>
            <br />
          </div>
          {selectFilters.length > 0 ||
          selectedUsers != '' ||
          phone_num != '' ? (
            <div
              className="clear-criteria"
              style={{ marginTop: 5, marginLeft: 30 }}
              onClick={this._clearFilters}
            >
              <i className="iconfont icon-close" />
              <span>清除所有筛选条件</span>
            </div>
          ) : null}
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading}>
              <table className="content-table edit-table">
                <thead>
                  <tr>
                    <th>姓名</th>
                    <th>手机</th>
                    <th>添加人</th>
                    <th>所属公司</th>
                    <th>添加时间</th>
                    <th>添加原因</th>
                    <th>操作</th>
                    <th />
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={visitor_records.length > 0}
                  tableHeadNum="8"
                  tipMessage="暂无数据"
                >
                  {visitor_records &&
                    visitor_records.length > 0 &&
                    visitor_records.map((record, index) => {
                      const v_type = record.visitor_type || ''
                      const organization_name = _.get(
                        record,
                        'organization.full_name',
                        ''
                      )
                      const creator_name = _.get(record, 'creator.name', '')
                      const created_at = moment(
                        `${record.created_at}000` - 0
                      ).format('YYYY-MM-DD HH:mm')
                      return (
                        <tr
                          key={index}
                          style={{ background: '#fff', cursor: 'default' }}
                        >
                          <td>
                            <span
                              className={record.user_id ? 'a-hover' : ''}
                              onClick={() => {
                                this.toUserInfo(record)
                              }}
                            >
                              {record.name || ''}
                            </span>
                          </td>
                          <td>{record.phone_num}</td>
                          <td>{creator_name}</td>
                          <td>{organization_name}</td>
                          <td>{created_at}</td>
                          <td>{record.note}</td>
                          <td>
                            {isLocManager || isLocVisitor ? (
                              <KBDropdown>
                                <KBDropdown.KBDropdownTrigger>
                                  <div
                                    className="select-drop_down"
                                    style={{ width: 80 }}
                                  >
                                    <div
                                      className="select-dropDown_left"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        flex: 2,
                                        justifyContent: 'center'
                                      }}
                                    >
                                      更多
                                    </div>
                                    <div
                                      className="select-dropDown_right"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        flex: 1
                                      }}
                                    >
                                      <i
                                        className="iconfont icon-arrow_drop_down_circle"
                                        style={{ marginRight: 0 }}
                                      />
                                    </div>
                                  </div>
                                </KBDropdown.KBDropdownTrigger>
                                <KBDropdown.KBDropdownContent
                                  style={{
                                    left: '50%',
                                    transform: 'translateX(-50%)'
                                  }}
                                >
                                  <div className="user-dropdown">
                                    {
                                      <a
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.editBlack(record)
                                        }}
                                      >
                                        查看详情
                                      </a>
                                    }
                                  </div>
                                </KBDropdown.KBDropdownContent>
                              </KBDropdown>
                            ) : null}
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`/admin/locations/${loc_id}/visit/black_list?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location } = ownProps
  let user = selectors.getCurrentUser(state)
  let loc_id = params.id
  let space = selectors.getSpace(state) || {}
  const space_id = space.id

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  var FILTER_STATE = ['organization_id', 'name', 'phone_num']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  let section = location.pathname.split('/')
  let isLocVisitor = canAccessLocationVisitor(user, loc_id)
  let isLocManager = canAccessLocation(user, loc_id)

  let orgs = selectors.getOrgsOfLocation(state, loc_id)
  let currentOrg
  if (paramsAll.filters.organization_id) {
    currentOrg = orgs.find(orgs => paramsAll.filters.organization_id == orgs.id)
  }
  let defaultSingleContent = orgs && orgs.length > 0 ? '选择公司' : '加载中...'
  return {
    defaultSingleContent,
    orgs,
    currentOrg,
    space_id,
    loc_id,
    isLocManager,
    page,
    isLocVisitor,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitBlackList)
