import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBButton } from 'components'
import { SketchPicker } from 'react-color'

var VisitWelcomeSetColor = React.createClass({
  save(model) {
    const { saveAndUpdate } = this.props
    return saveAndUpdate(model, 'accent_color')
  },

  handleChangeComplete(color) {
    const {
      fields: { accent_color }
    } = this.props
    const { rgb, hex } = color
    let rgbaValue = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    accent_color.onChange(rgbaValue)
  },

  render() {
    const {
      fields: { accent_color },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.save)}>
        <div className="visit-setting-block">
          <div className="title-container">
            <div className="title-and-des">
              <div className="title">主题颜色</div>
              <div className="des">选择欢迎页的主题颜色</div>
            </div>
            <KBButton
              className="certain-btn"
              style={
                accent_color.value
                  ? { backgroundColor: `${accent_color.value}` }
                  : {}
              }
              type="submit"
              submitting={submitting}
            >
              保存当前颜色
            </KBButton>
          </div>
          <div className="kb-row" style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              <SketchPicker
                color={accent_color.value}
                onChangeComplete={this.handleChangeComplete}
                width={300}
              />
            </div>
            <div style={{ fontSize: 12, color: '#666', marginTop: 20 }}>
              请选择合适主题色，避免与您上传的标志图形或LOGO颜色冲突。
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { welcome } = props
  let { accent_color } = welcome

  return {
    initialValues: {
      accent_color: accent_color || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitWelcomeSetColorForm',
  fields: ['accent_color'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitWelcomeSetColor)
