import React from 'react'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import {
  KBInput,
  KBButton,
  KBUserInput,
  KBForm,
  KBPopover,
  KBTextarea,
  KBSelect,
  KBMoreFilesUpload,
  KBImageCropperInput,
  KBRadio
} from 'components'
import { notification } from 'antd'
import { toImageProxyDirect } from 'utils/kbUtil'
import _ from 'lodash'
import config from 'app/config'
import './index.css'

const typeArray = [
  {
    value: 'announcement',
    title: '广告'
  },
  {
    value: 'station_announcement',
    title: '公告'
  }
]

// const clientTypeArray = [
//   {
//     value: 'bridgego',
//     title: '奕桥'
//   },
//   {
//     value: 'office',
//     title: '办公楼'
//   },
//   {
//     value: 'mini_program',
//     title: '所有小程序'
//   }
// ]

const activityType = [
  {
    value: 'activity',
    title: '活动'
  },
  {
    value: 'valueAddedService',
    title: '增值服务'
  },
  {
    value: 'link',
    title: '微信文章'
  },
  {
    value: 'none',
    title: '无关联活动'
  }
]

let GroupSetBannerForm = React.createClass({
  getInitialState() {
    return {}
  },
  componentDidMount() {
    const {
      fields: {
        image,
        resource_id,
        announcement_type,
        title,
        link,
        activity_type,
        video_url
      },
      type,
      item
    } = this.props
    if (type === 'create') {
      announcement_type.onChange('announcement')
      activity_type.onChange('activity')
    }
    if (type === 'edit') {
      video_url.onChange(item.video_url)
      announcement_type.onChange(item.announcement_type)
      if (item.announcement_type === 'station_announcement') {
        title.onChange(item.title)
      }
      if (item.announcement_type === 'announcement') {
        resource_id.onChange(item.resource_id)
        image.onChange(item.image)
        link.onChange(item.link)
        if (item.link && !item.resource_type) {
          activity_type.onChange('link')
        } else if (item.resource_id && item.resource_type === 'Activity') {
          activity_type.onChange('activity')
        } else if (item.resource_id && item.resource_type === 'ValueAddedService') {
          activity_type.onChange('valueAddedService')
        } else {
          activity_type.onChange('none')
        }
      }
    }
  },

  createBanner(model) {
    const { type, groups_id, item, getList } = this.props
    const params = {
      location_group_id: groups_id,
      client_type: 'bridgego',
      announcement_type: model.announcement_type
    }
    if (model.announcement_type === 'station_announcement') {
      params.title = model.title
    }
    if (model.announcement_type === 'announcement') {
      if (model.activity_type === 'activity') {
        params.resource_id = model.resource_id
        params.resource_type = 'Activity'
        params.link = null
      }
      if (model.activity_type === 'valueAddedService') {
        params.resource_id = model.resource_id
        params.resource_type = 'ValueAddedService'
        params.link = null
      }
      if (model.activity_type === 'link') {
        params.link = model.link
        params.resource_id = null
        params.resource_type = null
      }
      if (model.activity_type === 'none') {
        params.link = null
        params.resource_id = null
        params.resource_type = null
      }
      delete model.activity_type
    }

    if (model.announcement_type === 'announcement') {
      params.video_url = model.video_url ? model.video_url[0].file_url : ''
    }

    params.image = model.image

    if (type === 'create') {
      api.createAnnouncements(params).then(json => {
        getList && getList()
        KBPopover.close()
      })
    }
    if (type === 'edit') {
      api.updateAnnouncements(item.id, params).then(() => {
        getList && getList()
        KBPopover.close()
      })
    }
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({ message: '上传失败', description: msg, top: 100 })
    }
  },

  changeVideo(val) {
    const {
      fields: { video_url }
    } = this.props
    video_url.onChange([val[val.length - 1]])
  },
  removeVideo() {
    const {
      fields: { video_url }
    } = this.props
    video_url.onChange([])
  },

  render() {
    const {
      fields: {
        image,
        resource_id,
        announcement_type,
        title,
        link,
        activity_type,
        video_url,
        zzfw
      },
      handleSubmit,
      groups_id,
      defaultImages,
      submitting,
      activityList,
      valueAddedServicesList,
      type: formType
    } = this.props
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createBanner)}
        fields={this.props.fields}
        style={{ position: 'relative' }}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {formType === 'edit' ? '编辑' : '添加'}广告位
          </h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBRadio
              title="广告位类型"
              className="kb-form-group kb-form-c178 kb-form-alone"
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              isRequired={true}
              arrayType={typeArray}
              name="type"
              field={announcement_type}
            />
          </div>
          {announcement_type.value === 'station_announcement' && (
            <div className="kb-row">
              {/* <KBInput
                className="kb-form-group kb-div-48"
                // isRequired={true}
                field={title}
                title="内容"
              /> */}
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={title}
                isRequired={true}
                title="内容"
              />
            </div>
          )}

          {announcement_type.value === 'announcement' && (
            <div className="kb-row kb-form-group kb-form-c18 kb-form-alone">
              <label>
                <span className="must-fill">*</span>广告封面图
              </label>
              {/* <KBMorePhotoUpload
                defaultImages={defaultImages}
                uploadedText="上传图片"
                photoShow={true}
                sort={true}
                className="kb-form-group kb-form-alone"
                field={image}
                img_width={232}
                img_height={134}
                hint="图片格式为png或jpg，尺寸：1125*1053px"
                text="上传广告位图片"
                imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
                prefix={`${config.uploadDir}groups/${groups_id}`}
              /> */}
              <KBImageCropperInput
                title={false}
                aspect={390 / 364}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={image}
                type="banner"
                width={195}
                height={182}
                imageSize={1024}
                failureCallBack={this.UploadFailureCallBack}
                accept=".png,.jpg"
                isPublic
              >
                <div className="upload-portrait">
                  {image && image.value ? (
                    <img
                      src={image.value}
                      style={{
                        width: '195px',
                        height: '182px',
                        display: 'inline-block',
                        marginLeft: 0,
                        borderRadius: 0
                      }}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                  <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                    <button className="bordered-btn m-none" type="button">
                      {image.dirty ? '图片已上传' : '上传图片'}
                    </button>
                    <p
                      className="color-nine"
                      style={{ paddingTop: 20, fontSize: 12 }}
                    >
                      图片格式为png或jpg,文件大小不大于1M
                    </p>
                  </div>
                </div>
              </KBImageCropperInput>
            </div>
          )}

          {announcement_type.value === 'announcement' && (
            <div className="kb-row kb-form-group kb-form-c18 kb-form-alone">
              <label>短视频上传</label>
              <div>
                <KBMoreFilesUpload
                  className="kb-form-group kb-form-c39 kb-form-alone"
                  text="上传视频"
                  uploadedText="上传视频"
                  uploadingText="正在上传"
                  multiple={false}
                  prefix={config.uploadDir + 'announcements/'}
                  buttonClass={'bordered-btn m-none'}
                  defaultFiles={video_url.value}
                  type="video"
                  ref="files"
                  field={video_url}
                  accept=".mp4"
                  onChange={this.changeVideo}
                  isPublic
                />
                {video_url.value && video_url.value[0] && (
                  <div
                    style={{ position: 'relative', width: 350, marginTop: 20 }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: 20,
                        height: 20,
                        textAlign: 'center',
                        borderRadius: 10,
                        backgroundColor: 'white',
                        color: 'red',
                        zIndex: 10001,
                        cursor: 'pointer'
                      }}
                      onClick={this.removeVideo}
                    >
                      x
                    </span>
                    <video
                      src={video_url.value[0].file_url}
                      controls="controls"
                      style={{ width: 350 }}
                    ></video>
                  </div>
                )}
              </div>
            </div>
          )}

          {announcement_type.value === 'announcement' && (
            <div className="kb-row">
              <KBRadio
                title="跳转类型"
                className="kb-form-group kb-form-c178 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                rightLabel="label-rt"
                isRequired={true}
                arrayType={activityType}
                name="activityType"
                field={activity_type}
              />
            </div>
          )}

          {announcement_type.value === 'announcement' &&
            activity_type.value === 'activity' && (
              <div className="kb-row" key="activityList">
                <KBSelect
                  title="选择活动"
                  label="true"
                  isRequired={true}
                  placeholder="请选择活动"
                  arrayData={activityList}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={resource_id}
                />
              </div>
            )}

          {announcement_type.value === 'announcement' &&
            activity_type.value === 'valueAddedService' && (
              <div className="kb-row" key="valueAddedServicesList">
                <KBSelect
                  title="选择增值服务"
                  label="true"
                  isRequired
                  placeholder="请选择增值服务"
                  arrayData={valueAddedServicesList}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={resource_id}
                />
              </div>
            )
          }

          {announcement_type.value === 'announcement' &&
            activity_type.value === 'link' && (
              <div className="kb-row">
                <KBInput
                  title="微信文章"
                  label="true"
                  isRequired={true}
                  placeholder="请输入微信文章链接"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={link}
                />
              </div>
            )}

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              isRequired={true}
              id="locationFormButton"
            >
              确认{formType === 'create' ? '添加' : '保存'}
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { item = {}, type } = props
  let defaultImages = []
  if (type === 'edit') defaultImages = [item.image]
  return {
    defaultImages
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if (values.announcement_type === 'announcement') {
    if (!values.image) {
      errors.image = '请上传图片'
    }
    if (!values.resource_id && values.activity_type === 'activity') {
      errors.resource_id = '请选择活动'
    }
    if (!values.link && values.activity_type === 'link') {
      errors.link = '请输入微信文章链接'
    }
    if (!values.resource_id && values.activity_type === 'valueAddedService') {
      errors.link = '请选择增值服务'
    }
  }

  if (!values.title && values.announcement_type === 'station_announcement') {
    errors.title = '请输入站内信内容'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetBase',
  fields: [
    'title',
    'image',
    'announcement_type',
    'resource_id',
    'link',
    'activity_type',
    'video_url',
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetBannerForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBannerForm)

export default GroupSetBannerForm
