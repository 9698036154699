import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { KBLoadingContainer, KBAvatar, KBPopover, KBTipsy } from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { OPPORTUNITY_STATES } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import { extendDefaultHighchartsConfig } from 'utils/kbData'

var StatisticCustomerLocation = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    return {
      data: []
    }
  },

  componentDidMount() {
    const { data } = this.state
    let Highcharts = this.getHighcharts()

    this.mounted = true
    this._loadData()
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: '项目数目'
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData() {
    api.getCustomerWithLocationStatistic().then(({ json }) => {
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    let data = []
    let xLocation = []

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    var array = Object.values(json)

    // 获取所有的人数集合
    var r = []
    // 获取所有的项目集合
    var d = []
    // 所有项目类型的集合
    var type = {}

    array.forEach(json => {
      if (!json.location.name) {
        return
      }

      const location = json.location
      r.push({ ...location })

      json.statistic &&
        json.statistic.forEach(t => {
          if (!t) {
            return
          }

          type[t.state] = OPPORTUNITY_STATES[t.state]

          d.push({
            id: location.id,
            ...t
          })
        })
    })

    // 将type 转换成数组
    type = Object.values(type)

    type.reverse()

    type.forEach(json => {
      let series = { name: json, data: [] }
      r.forEach(pop => {
        let id = pop.id
        let stateObj = d.find(
          t => id == t.id && OPPORTUNITY_STATES[t.state] == json
        )
        let number = stateObj ? stateObj.customer_count : 0
        series.data.push(number)
      })
      data.push(series)
    })

    xLocation = r.map(pop => pop.name)
    this.fillChart(data, xLocation)
  },

  fillChart(data, xLocation) {
    this.setSerierDate(data)
    this.setCategories(xLocation)
  },

  render() {
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>CRM客户分店进度分布</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCustomerLocation)
