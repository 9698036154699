import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBPopoverTop } from 'components'
import GroupSetInvoiceForm from './GroupSetInvoiceForm'
import { KBNoData, KBTable } from 'components'
import { api } from 'app/services'
import { notification } from 'antd'
import { apiCallFailure, successState } from 'app/actions'
import { bindActionCreators } from 'redux'

const types = [
  {id: 'reservation', name: '会议室'},
  {id: 'desk_reservation', name: '工位'},
  {id: 'print_reservation', name: '打印'},
]

var GroupSetInvoice = React.createClass({
  displayName: 'GroupSetInvoice',
  getInitialState() {
    return {
      list: []
    }
  },
  async componentDidMount() {
    const { groups_id } = this.props

    const res = await api.getInvoiceApplicationSetting({ location_group_id: groups_id })
    const { json } = res
    this.setState({ list: json })
  },


  changeList(value, type, index) {
    const { groups_id } = this.props
    const { list } = this.state
    let newList = [...list]
    if (type === 'add') {
      newList.push(value)
    } else if (type === 'edit') {
      newList = newList.map((item, idx) => {
        if (idx === index) {
          item = { ...value }
        }
        return item
      })
    } else {
      newList = newList.filter((_, idx) => idx !== index)
    }
    this.setState({ list: newList })
  },

  addLocationProvider() {
    const { groups_id } = this.props
    KBPopoverTop.show(
      <GroupSetInvoiceForm type="add" callBack={this.changeList} />
    )
  },

  updateLocationProvider(group, index) {
    this.setState({ currentIndex: index }, () => {
      KBPopoverTop.show(
        <GroupSetInvoiceForm
          type="edit"
          group={group}
          index={index}
          callBack={this.changeList}
        />
      )
    })
  },

  updateLocationProviders(model) {
    const { groups_id } = this.props
    const { list } = this.state
    const settings = list.map(item=>{
      delete item.modified
      return item
    })
    api.updateInvoiceApplicationSetting({ settings, location_group_id: groups_id }).then(res=>{
      const { json } = res
      if(json&&Array.isArray(json)){
        this.setState({list:json})
        this.props.successAction({ message: '保存成功' })
      }else{
        this.props.apiCallFailureActions({
          status: 'error',
          message: '保存失败'
        })
      }
    }).catch(error => {
      this.props.apiCallFailureActions(error)
    })
  },

  getInvoiceType(types = []) {
    const invoiceTypeObj = {
      ce: '电子普票',
      qs: '增值税电子普通发票',
      qc: '增值税电子专用发票'
    }

    if(types.includes('ce')) {
      return invoiceTypeObj.ce
    } else {
      return `数电发票：${types.map(i => invoiceTypeObj[i]).join('、')}`
    }
  },

  render() {
    const {
      fields: { group },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { list } = this.state
    return (
      <form onSubmit={handleSubmit(this.updateLocationProviders)}>
        <KBFormError err={error} />
        <fieldset className="fieldset">
          <div
            className="kb-title m-bottom-sm"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>开票信息(税盘)</span>
            <div className="bordered-btn" onClick={this.addLocationProvider}>
              添加开票信息
            </div>
          </div>
          {(list || []).length > 0 ? (
            <div>
              {list.map((item, index) => {
                return (
                  <div className="location-provider-container" key={index}>
                    {item.modified ? (
                      <div className="tip-msg">
                        <div className="icon">
                          <i className="iconfont icon-hint" />
                        </div>
                        <div>
                          请点击下方“保存”按钮以确认保存当前开票信息
                        </div>
                      </div>
                    ) : null}
                    <div
                      key={index}
                      className="location-provider"
                      style={item.modified?{ border: '1px dashed #DFE4E7' }:{}}
                    >
                      <span
                        className="operate-provider delete-provider"
                        onClick={() => {
                          this.changeList(item, 'del', index)
                        }}
                      >
                        <i className="iconfont icon-delete" />
                      </span>
                      <span
                        className="operate-provider update-provider"
                        onClick={this.updateLocationProvider.bind(
                          null,
                          item,
                          index
                        )}
                      >
                        <i className="iconfont icon-edit" />
                      </span>
                      <div className="provider-line">
                        <div>
                          <span>开票主体</span>
                          <span>{item.account_name || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>税盘</span>
                          <span>{item.terminal_code || '暂无'}</span>
                        </div>
                        <div>
                          <span>销方公司编号</span>
                          <span>{item.sapid || '暂无'}</span>
                        </div>
                        <div>
                          <span>发票类型</span>
                          <span>{this.getInvoiceType(item.invoice_type)}</span>
                        </div>
                      </div>
                      {/* 税收分类信息 */}
                      {
                        item.invoicing_xforceplus_settings && item.invoicing_xforceplus_settings.length > 0 && (
                          <div className="provider-line">
                            <div>
                              <h5 style={{ margin : '20px 0 0 0'}}>税收分类设置</h5>
                              <div style={{overflowX: 'auto', whiteSpace: 'nowrap', width: '786px'}}>
                                <KBTable
                                  columns={[
                                    {title: '订单类型', key: 'invoice_type', render(setting) { return types.find(i => i.id === setting.invoice_type).name }},
                                    {title: '税收分类', key: 'name', width: '120px'},
                                    {title: '税收分类编码', key: 'goods_tax_no'},
                                    {title: '不动产地址（省市区）', key: 'real_estate_place'},
                                    {title: '不动产详细地址', key: 'real_estate_address'},
                                    {title: '房屋产权证书/不动产权证号码', key: 'real_estate_no'},
                                    {title: '不动产是否跨城市', key: 'cross_city_sign', render(setting) {
                                      if(typeof setting.cross_city_sign != 'boolean') return

                                      return String(setting.cross_city_sign) === 'true' ? '是' : '否' }
                                    },
                                  ]}
                                  dataSource={item.invoicing_xforceplus_settings}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <KBNoData tipMessage="暂无账户信息" />
          )}
        </fieldset>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}

  return errors
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'GroupSetInvoice',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(GroupSetInvoice)
