import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import { SAFE_SETTING } from 'app/constants/hint'
import * as selectors from 'app/selectors'
import * as role from 'app/reducers/role'

var SpacesSettingManageForm = React.createClass({
  displayName: 'SpacesSettingManageForm',
  getInitialState() {
    return {}
  },
  updateRoles(model) {
    const { updateSpaceRole } = this.props
    return updateSpaceRole({
      role: 'admin',
      user_ids: model.admin_users.split(',')
    })
  },
  render() {
    const {
      fields: { admin_users },
      error,
      submitting,
      handleSubmit,
      users,
      selectUsers
    } = this.props
    const hint = SAFE_SETTING
    return (
      <form onSubmit={handleSubmit(this.updateRoles)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <p style={{ marginBottom: 15 }}>管理员有权限阅览和修改所有信息</p>
          <KBFormError err={error} />
          <KBUserInput
            users={users}
            hint={hint.manager}
            selectedUsers={selectUsers}
            multi={true}
            field={admin_users}
          />
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { setting } = props
  const { roles, roles_settings } = setting
  let users = selectors.getSpaceUsers(state)
  let selectUsers =
    selectors.getUsersByUserIds(state, role.getRoleUserByKey(roles, 'admin')) ||
    []
  return {
    users,
    selectUsers
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.admin_users)) {
    errors.admin_users = '请至少选择一个员工'
  }

  return errors
}

const formConfig = {
  form: 'SpacesSettingManageForm',
  fields: ['admin_users'],
  validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpacesSettingManageForm)
