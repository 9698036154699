import React from 'react'
import { KBSelect, KBUserInput } from 'components'

const GROUP_ROLES = [
  {
    id:'location_group_operator',
    name:'项目运营'
  },
  {
    id:'location_group_wechat_manager',
    name:'项目活动负责人'
  },
  {
    id:'location_group_content_manager',
    name:'项目内容管理员'
  },
]
var MemberGroupRolesSettings = React.createClass({
  componentDidMount() {},

  render() {
    const { location_group_permission_settings, location_groups, locationsObj } = this.props
    return (
      <div>
        {location_group_permission_settings.length > 0 &&
          location_group_permission_settings.map((json, index) => {

            return (json.enabled||{}).value ? (
              <div style={{ position: 'relative' }}>
                <div className="kb-row kb-form-66" key={index}>
                  <KBUserInput
                    title="项目"
                    placeholder="选择项目"
                    className="kb-form-group kb-div-c39"
                    field={json.location_group_id}
                    isRequired={true}
                    multi={false}
                    users={location_groups}
                    selectedUsers={[locationsObj[(json.location_group_id||{}).value]]}
                  />
                  <KBSelect
                    defaultValue=" "
                    isRequiredR={true}
                    className="kb-form-group kb-div-c39"
                    titleClass="label-rt"
                    title="角色"
                    field={json.role}
                    arrayData={GROUP_ROLES}
                  />
                  {/*<KBSelect defaultValue=' ' isRequiredR={true} className='kb-form-group kb-div-48' titleClass='label-rt' title='激活' field={json.enabled} arrayData={LOCATION_ROLES_ENABLED}  />*/}
                </div>
                <i
                  className="iconfont icon-delete hover-delete"
                  style={{ position: 'absolute', top: 38, right: 0 }}
                  onClick={() =>
                    location_group_permission_settings[index].enabled.onChange(false)
                  }
                />
              </div>
            ) : (
              ''
            )
          })}
      </div>
    )
  }
})

export default MemberGroupRolesSettings
