import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'

var LocationSettingSalesForm = React.createClass({
  setLocation(model) {
    const { updateLocation } = this.props
    const { sales_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'sales',
          range: 'all',
          user_ids: sales_ids ? sales_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { sales_ids },
      error,
      submitting,
      handleSubmit,
      users,
      salesManager
    } = this.props
    const hint = FIX_LOCATION

    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <div className="kb-row">
            <KBUserInput
              title="招商人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.sales}
              field={sales_ids}
              multi={true}
              users={users}
              selectedUsers={salesManager}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation
  let salesManager = selectors.getTeamRolesOfLocation(state, loc_id, 'sales')

  return {
    updateLocation,
    users,
    salesManager
  }
}

const formConfig = {
  form: 'LocationSettingSalesForm',
  fields: ['sales_ids'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(LocationSettingSalesForm)
