import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { formatMinuteSecondEN } from 'utils/kbUtil'
import { KBPopover, KBPagination } from 'components'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as util from 'utils/kbUtil'

const per_page = 50

// 操作记录
var SpaceLogs = React.createClass({
  displayName: 'SpaceLogs',
  getInitialState() {
    return {
      pagination: null
    }
  },
  componentDidMount() {
    const { page } = this.props
    this.getData(page)
  },
  componentWillReceiveProps(nextProps) {
    const { page, locks } = nextProps
    if (page != this.props.page) {
      this.getData(page)
    }
  },
  getData(page) {
    const { replaceAuditLogsAction } = this.props
    api
      .getAuditLogs({
        per_page,
        page
      })
      .then(
        json => {
          let pagination = util.paginate(json.response)
          replaceAuditLogsAction.success(json, {
            entityName: 'auditLogs'
          })
          this.setState({
            pagination
          })
        },
        error => {
          replaceAuditLogsAction.failure(error)
        }
      )
  },
  render() {
    const { auditLogs } = this.props
    const { pagination } = this.state

    return (
      <div
        className="kb-form-container"
        style={{
          padding: '30px'
        }}
      >
        <fieldset className="fieldset">
          <legend>
            <div>操作日志</div>
          </legend>
          <div>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>IP地址</th>
                  <th>模块</th>
                  <th>用户</th>
                  <th>操作</th>
                  <th>时间</th>
                </tr>
              </thead>
              <tbody>
                {auditLogs.map((log, index) => {
                  const {
                    client_ip,
                    object,
                    user,
                    action_name,
                    created_at
                  } = log
                  return (
                    <tr key={index}>
                      <td>{client_ip}</td>
                      <td>{object}</td>
                      <td>{user && user.name}</td>
                      <td>{action_name}</td>
                      <td>{formatMinuteSecondEN(created_at)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/spaces_setting/logs?page=#PAGE#`}
            />
          </div>
        </fieldset>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const auditLogs = selectors.getAuditLogs(state)
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  return {
    page,
    auditLogs
  }
}

function mapDispatchToProps(dispatch) {
  return {
    replaceAuditLogsAction: bindActionCreators(
      actions.auditLogsAction.replace,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceLogs)
