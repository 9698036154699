import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { taxesAction, apiCallFailure } from 'app/actions'
import * as selectors from 'app/selectors'
import SpaceSettingUpdateTaxesForm from './SpaceSettingUpdateTaxesForm'
import { KBPopover } from 'components'
import { connect } from 'react-redux'

var SpaceSettingTaxes = React.createClass({
  displayName: 'SpaceSettingTaxes',
  componentDidMount() {
    const { getTaxesAction } = this.props
    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  updateTax(tax) {
    KBPopover.show(
      <SpaceSettingUpdateTaxesForm
        taxId={tax.id}
        initialValues={{
          name: tax.name,
          rate: tax.rate
        }}
        state="true"
      />
    )
  },
  createTax() {
    KBPopover.show(<SpaceSettingUpdateTaxesForm />)
  },
  deleteTax(tax) {
    const { deleteTaxesAction, apiCallFailureActions } = this.props
    const { id, ref_count, name } = tax
    if (ref_count > 0) {
      apiCallFailureActions({
        status: 'error',
        message: '已使用的税不允许删除!'
      })
      return
    }
    KBPopover.plugins.confirm('删除税率', `您确定要删除税率${name || ''}吗?`, {
      confirm: () => {
        api.deleteSpaceTaxes(id).then(
          json => {
            deleteTaxesAction.success(json, { id, key: 'taxes' })
            KBPopover.close()
          },
          error => {
            deleteTaxesAction.success(error)
          }
        )
      }
    })
  },
  render() {
    const { taxes } = this.props

    return (
      <div>
        <table className="content-table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>名称</th>
              <th>税率</th>
              <th className="t-right" style={{ paddingRight: 40 }}>
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {taxes.map(tax => {
              return (
                <tr>
                  <td>{tax.name}</td>
                  <td>{`${Math.floor(tax.rate * 1000000) / 10000} %`}</td>
                  <td className="t-right">
                    <i
                      className="iconfont icon-edit"
                      onClick={this.updateTax.bind(null, tax)}
                    />
                    <i
                      className="iconfont icon-delete m-left-sm"
                      onClick={this.deleteTax.bind(null, tax)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="m-top-sm">
          <button
            className="certain-btn"
            onClick={() => {
              this.createTax()
            }}
          >
            添加税率
          </button>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let taxes = selectors.getTaxes(state)
  return {
    taxes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    deleteTaxesAction: bindActionCreators(taxesAction.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceSettingTaxes)
