import React from 'react'
import SpaceSettingContractFrom from './SpaceSettingContractFrom'
import SpaceSettingShowForm from './SpaceSettingShowForm'
import SpaceSettingPrepayCard from './SpaceSettingPrepayCard'
import SpaceSettingInvoiceReport from './SpaceSettingInvoiceReport'
import SpaceSettingInvoiceType from './SpaceSettingInvoiceType'
import SpaceSettingTaxes from './SpaceSettingTaxes'
import SpaceSettingLateFeeForm from './SpaceSettingLateFeeForm'
import SpaceSettingInvoiceConfirm from './SpaceSettingInvoiceConfirm'
import SpaceSettingInvoiceAutoConfirm from './SpaceSettingInvoiceAutoConfirm'
import SpaceSettingAreaRentingTypeForm from './SpaceSettingAreaRentingTypeForm'
import SpaceSettingMergeDepositForm from './SpaceSettingMergeDepositForm'
import SpaceSettingPhaseComputeWayForm from './SpaceSettingPhaseComputeWayForm'
import SpaceSettingPrintPriceForm from './SpaceSettingPrintPriceForm'
import SpaceSettingLowPriceContractForm from './SpaceSettingLowPriceContractForm'
import { APPROVE_CONTRACT } from 'app/constants/hint'
import _ from 'lodash'

var SpaceSettingInvoice = React.createClass({
  displayName: 'SpacesSettingCrm',
  render() {
    const {
      updateCurrentSpace,
      setting,
      updateCurrentPrintPrint,
      invoice,
      spaceRole,
      updateSpaceRole
    } = this.props
    const hint = APPROVE_CONTRACT
    if (!_.get(setting, 'settings') || !spaceRole.roles_settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>财务设置</span>
          </div>
        </header>
        <div className="kb-form-container" style={{ paddingTop: 20 }}>
          <fieldset className="fieldset">
            <legend>合同审批人设置</legend>
            <p className="color-nine" style={{ marginBottom: 20 }}>
              {hint.approve}
            </p>
            <SpaceSettingContractFrom
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>低价合同审批人设置</legend>
            <p className="color-nine" style={{ marginBottom: 20 }}>
              当合同签订的分区价格低于最低价格时，需要以下人员审批方可通过
            </p>
            <SpaceSettingLowPriceContractForm
              spaceRole={spaceRole}
              updateSpaceRole={updateSpaceRole}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>统计方式</legend>
            <SpaceSettingShowForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单类型</legend>
            <SpaceSettingInvoiceType
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单周期</legend>
            <SpaceSettingInvoiceReport
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单核销</legend>
            <SpaceSettingInvoiceConfirm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单自动核销</legend>
            <SpaceSettingInvoiceAutoConfirm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>税率设置</legend>
            <SpaceSettingTaxes />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>滞纳金设置</legend>
            <SpaceSettingLateFeeForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>工位租赁方式</legend>
            <SpaceSettingAreaRentingTypeForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>合同押金合并</legend>
            <SpaceSettingMergeDepositForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>缴租日设置</legend>
            <SpaceSettingPhaseComputeWayForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>打印价格设置</legend>
            <SpaceSettingPrintPriceForm
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>★凯星卡设置</legend>
            <SpaceSettingPrepayCard
              updateCurrentSpace={updateCurrentSpace}
              setting={setting}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpaceSettingInvoice
