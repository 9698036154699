import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { SAFE_SETTING } from 'app/constants/hint'
import * as role from 'app/reducers/role'

var SpacesSettingCRMAdminForm = React.createClass({
  displayName: 'SpacesSettingCRMForm',
  updateRoles({ crm_admin_role_setting, crm_admin_role_setting_users }) {
    const { updateSpaceRole } = this.props
    return updateSpaceRole({
      role: 'crm_admin',
      range: crm_admin_role_setting,
      user_ids:
        crm_admin_role_setting === 'admin'
          ? []
          : typeof crm_admin_role_setting_users === 'string' &&
            crm_admin_role_setting_users
          ? crm_admin_role_setting_users.split(',')
          : []
    })
  },
  render() {
    const {
      fields: { crm_admin_role_setting, crm_admin_role_setting_users },
      error,
      submitting,
      handleSubmit,
      users,
      selectUsers
    } = this.props
    const hint = SAFE_SETTING

    return (
      <form onSubmit={handleSubmit(this.updateRoles)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <label>
              <input
                type="radio"
                onChange={crm_admin_role_setting.onChange}
                value="admin"
                checked={crm_admin_role_setting.value === 'admin'}
              />{' '}
              只有管理员可以阅览和更改
            </label>
          </div>
          <div className="kb-row">
            <label>
              <input
                type="radio"
                onChange={crm_admin_role_setting.onChange}
                value="all"
                checked={crm_admin_role_setting.value === 'all'}
              />{' '}
              全部员工可以阅览和更改
            </label>
          </div>
          <div className="kb-row">
            <label>
              <input
                type="radio"
                onChange={crm_admin_role_setting.onChange}
                value="group"
                checked={crm_admin_role_setting.value === 'group'}
              />{' '}
              只有指定员工可以阅览和更改
            </label>
          </div>
          {crm_admin_role_setting.value === 'group' && (
            <div>
              <KBUserInput
                hint={hint.staff}
                users={users}
                selectedUsers={selectUsers}
                multi={true}
                field={crm_admin_role_setting_users}
              />
            </div>
          )}
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { roles_settings, roles } = props.spaceRole
  const setting_value = roles_settings.crm_admin_role_setting

  let users = selectors.getSpaceUsers(state)
  let user_group = role.getRoleUserByKey(roles, 'crm_admin')
  let selectUsers = selectors.getUsersByUserIds(state, user_group) || []
  return {
    users,
    selectUsers,
    initialValues: {
      crm_admin_role_setting: setting_value,
      crm_admin_role_setting_users: user_group || []
    }
  }
}

const formConfig = {
  form: 'SpacesSettingCRMAdminForm',
  fields: ['crm_admin_role_setting', 'crm_admin_role_setting_users'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpacesSettingCRMAdminForm)
