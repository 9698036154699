import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { DAY_TIME_HM } from 'app/constants'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBPopoverTop,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect,
  KBToReportCenter
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'

const defaultFormat = 'YYYY-MM-DD HH:mm:ss'
var TasksReportForm = React.createClass({
  getInitialState() {
    return {}
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="报修情况导出"
        message="报修情况已成功导出，可以前往报表中心下载"
      />
    )
  },

  reportTasks(model) {
    const { start_at, end_at, location_id } = model
    let params = Object.assign(
      {},
      {
        start_at: moment(start_at)
          .set({ hour: 0, minute: 0 })
          .format(defaultFormat),
        end_at: moment(end_at)
          .set({ hour: 23, minute: 59 })
          .format(defaultFormat)
      }
    )
    if (location_id) {
      params.location_id = location_id
    }
    return api.tasksReports(params).then(() => {
      this.toReportCenter && this.toReportCenter()
    })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setYMD(end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  handleScroll(e) {
    return this.formScroll.scrollHeight
  },

  setScrollTop(e) {
    let scrollHeight = this.handleScroll()
    this['taskReportScroll'].scrollTop = scrollHeight
  },

  renderLocation() {
    const {
      fields: { location_id }
    } = this.props
    const { locations, loc_id } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    let hasLocId = loc_id ? true : false
    // 获取已选择的分店
    let selectedLoc =
      locations &&
      locations.filter(data => {
        return data.id == loc_id
      })
    return (
      <div className="kb-row">
        <KBUserInput
          title="分店"
          placeholder={'请选择分店'}
          {...oneClass}
          field={location_id}
          disabled={hasLocId}
          isRequired={hasLocId}
          hint={
            hasLocId
              ? null
              : '选择要导出的分店报修信息，不选则默认导出当前空间的全部报修信息'
          }
          selectedUsers={selectedLoc}
          multi={false}
          users={locations}
        />
      </div>
    )
  },

  render() {
    const {
      fields: { startYMD, endYMD },
      error,
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM
    } = this.props
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form
        onSubmit={handleSubmit(this.reportTasks)}
        ref={ref => {
          this.formScroll = ref
        }}
        onScroll={this.handleScroll}
      >
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">报修信息导出</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div
          className="kb-form-container-m"
          ref={ref => {
            this.taskReportScroll = ref
          }}
        >
          {this.renderLocation()}
          <div className="kb-row kb-form-66" onClick={this.setScrollTop}>
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              onChange={this.changeStartYMDCallback}
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              className="kb-form-group kb-div-48"
              field={endYMD}
              onChange={this.changeEndYMDCallback}
            />
          </div>
          <div className="kb-row f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  const locations = selectors.getSerializeLocation(state)
  return {
    DEAL_DAY_TIME_HM,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.start_at &&
    values.end_at &&
    moment(values.end_at).diff(moment(values.start_at)) <= 0
  ) {
    errors.end_at = '结束日期必须大于开始日期'
  }

  return errors
}

const formConfig = {
  form: 'TasksReportForm',
  fields: ['start_at', 'end_at', 'startYMD', 'endYMD', 'location_id'],
  validate: validate,
  touchOnBlur: false
}

TasksReportForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(TasksReportForm)

export default TasksReportForm
