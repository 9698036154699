import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { KBLoadingContainer, KBAvatar, KBPopover, KBTipsy } from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { STATISTIC_CYCLE } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate
} from 'utils/kbData'

var StatisticCrmLocationExpectedCustomer = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    var from = moment()
      .subtract(1, 'days')
      .subtract(1, 'weeks')
      .startOf('week')
      .toDate()
    var to = moment()
      .subtract(1, 'days')
      .subtract(1, 'weeks')
      .startOf('week')
      .toDate()

    return {
      filters: {
        from,
        to,
        cycle: 'weekly'
      },
      data: [],
      month: 'weeks_1'
    }
  },

  componentDidMount() {
    const { filters, data } = this.state
    let Highcharts = this.getHighcharts()

    this.mounted = true
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: '客户个数'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y} 个</b>'
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters) {
    const { locations } = this.props
    const { from, to, cycle, location_ids, city_ids } = filters
    var params = { location_ids, city_ids }

    params.cycle = cycle
    if (from && to) {
      params.start_date = from && moment(from).format('YYYY-MM-DD')
      params.end_date = to && moment(to).format('YYYY-MM-DD')
    }

    if (!location_ids && !city_ids) {
      params.location_ids = locations.map(json => json.id).join(',')
    }

    api.getCustomerStatistic(params).then(({ json }) => {
      if (!json.statistic) {
        this.clearSerierDate()
        return
      }

      this.parseJSON(json.statistic)
    })
  },

  parseJSON(json) {
    var contain = {}
    let data = []
    const {
      filters: { cycle }
    } = this.state

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    var array = Object.values(json)
    let dt = {
      name: '分店感兴趣预计客户个数统计',
      dataLabels: {
        enabled: true,
        color: '#FFFFFF',
        align: 'center',
        format: '{point.y}', // one decimal
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      },
      data: []
    }

    let customerData = []
    let expected = []

    array.forEach(json => {
      if (!json.location.name) {
        return
      }

      let e = [json.location.name]

      let eCount = 0

      let statistic = (json.location && json.location.statistic) || []

      statistic.length &&
        statistic.map(sta => {
          eCount = eCount + (sta.expected_count || 0)
        })

      e.push(eCount)

      expected.push(e)
    })

    data.push(Object.assign({}, dt, { data: expected, name: '预计人数' }))

    this.fillChart(data)
  },

  fillChart(data) {
    this.setSerierDate(data)
  },

  selectLocationCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  selectCityCallBack(data) {
    this._setFilters({
      city_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  cycleChange(data) {
    this._setFilters({
      cycle: data
    })
  },

  setMonthFilters(type = 'weeks', sub_num = 1) {
    var to = null
    var from = null
    var cycle = null

    to = moment()
      .subtract(1, type)
      .toDate()
    from = moment()
      .subtract(sub_num, type)
      .toDate()

    if (type == 'days') {
      cycle = 'daily'
    } else if (type == 'weeks') {
      cycle = 'weekly'
      to = moment(to)
        .endOf('week')
        .toDate()
      from = moment(from)
        .startOf('week')
        .toDate()
    } else {
      cycle = 'monthly'
      to = moment(to)
        .endOf('month')
        .toDate()
      from = moment(from)
        .startOf('month')
        .toDate()
    }

    this.setState({
      month: type + '_' + sub_num
    })

    this._setFilters({
      from,
      cycle,
      to
    })
  },

  render() {
    const { locations, cities } = this.props
    const { filters, data, month } = this.state
    const { from, to, cycle } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>分店感兴趣预计客户个数统计</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <div className="time-filter">
            {/* <button className={month == 'days' ? 'active' : ''} onClick={ this.setMonthFilters.bind(null, 'days') } >前一天</button> */}
            <button
              className={month == 'weeks_1' ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 'weeks', 1)}
            >
              上一周
            </button>
            <button
              className={month == 'months_1' ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 'months', 1)}
            >
              上个月
            </button>
            <button
              className={month == 'months_3' ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 'months', 3)}
            >
              前3个月
            </button>
            <button
              className={month == 'months_6' ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 'months', 6)}
            >
              前6个月
            </button>
          </div>
          <KBDropSelect
            options={{ defaultSelectPrompt: '选择城市' }}
            defaultData={cities}
            multi={true}
            callback={this.selectCityCallBack}
            style={{ marginLeft: 20 }}
          />
          <KBDropSelect
            options={{ defaultSelectPrompt: '选择分店' }}
            defaultData={locations}
            multi={true}
            callback={this.selectLocationCallBack}
            style={{ marginLeft: 20 }}
          />
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  const user = selectors.getCurrentUser(state)
  const cities = user.cities
  const locations = selectors.getSerializeLocation(state)
  return {
    cities,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCrmLocationExpectedCustomer)
