import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import _ from 'lodash'
import { orgActions } from 'app/actions'
import {
  canAccessLocation,
  canAccessLocationVisitor,
  canAccessLocationVisitorOnly,
  onlyCanAdminLocationOfWeChat,
  canAccessLocationRoles
} from '../../app/reducers/role'
import { Object } from 'core-js'

let menu = [
  {
    name: '访客审核',
    value: ['', 1]
  },
  {
    name: '邀请记录',
    value: ['invite', 2]
  },
  {
    name: '访客设置',
    value: ['settings', 3]
  },
  {
    name: '设备管理',
    value: ['devices', 4]
  },
  {
    name: '访客黑名单',
    value: ['black_list', 5]
  }
]

const visitorManagerMenu = [
  {
    name: '访客审核',
    value: ['', 1]
  },
  {
    name: '邀请记录',
    value: ['invite', 2]
  },
  {
    name: '设备管理',
    value: ['devices', 3]
  },
  {
    name: '访客黑名单',
    value: ['black_list', 4]
  }
]

const weChatManger = [
  {
    name: '访客审核',
    value: ['', 1]
  },
  {
    name: '邀请记录',
    value: ['invite', 2]
  },
  {
    name: '访客设置',
    value: ['settings', 3]
  },
]

var VisitBox = React.createClass({
  componentDidMount() {
    const { loc_id, getOrganizationsAction } = this.props
    api
      .getOrganizations({
        location_id: loc_id,
        per_page: 1000,
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json, { location_id: loc_id })
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  render() {
    const {
      section,
      locationSection,
      location_id,
      isManager,
      isVisitorManager,
      isVisitorManagerOnly,
      isWeChatManger,
    } = this.props
    if (isVisitorManagerOnly) {
      menu = visitorManagerMenu
    }
    let loc_url = location_id ? `/locations/${location_id}` : ''
    let tempMenu = Object.assign([], menu)
    if (!isManager) {
      if (!isVisitorManager) {
        tempMenu.splice(
          menu.findIndex(m => m.name === '访客设置'),
          1
        )
      }
    }
    if(isWeChatManger){
      tempMenu = weChatManger
    }
    console.log(menu,'menu');
    return (
      <div
        className="kb-out-box clear-fix"
        style={location_id ? { paddingTop: 0 } : {}}
      >
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {tempMenu.map((data, index) => {
                return (
                  <Link
                    key={index}
                    className={
                      isListValue(data.value, section) ||
                      (location_id && isListValue(data.value, locationSection))
                        ? 'active'
                        : ''
                    }
                    to={`/admin${loc_url}/visit/${getListFirstValue(
                      data.value
                    )}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  let loc_id = params.id
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[3] : ''
  let locationSection = sections.length > 5 ? sections[5] : ''
  let location_id = params && params.id
  // 拥有空间权限，或者是项目外部管理人员
  const isManager = canAccessLocation(user, location_id) || canAccessLocationRoles(user, location_id, ['landlord'])
  let isVisitorManager = canAccessLocationVisitor(user, location_id)
  let isVisitorManagerOnly = canAccessLocationVisitorOnly(user, location_id)
  let isWeChatManger = onlyCanAdminLocationOfWeChat(user, location_id)

  return {
    loc_id,
    section,
    locationSection,
    user,
    location_id,
    isManager,
    isVisitorManager,
    isVisitorManagerOnly,
    isWeChatManger
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitBox)
