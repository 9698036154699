import React from 'react'
import { bindActionCreators } from 'redux'
import { spacesActions, successState } from 'app/actions'
import { connect } from 'react-redux'
import { KBInput, KBButton, KBForm, KBTextarea } from 'components'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'

var VirtualMoney = React.createClass({
  updateSetting(model) {
    const { updateLocationSetting } = this.props
    updateLocationSetting(
      {
        star_point_settings: {
          ...model,
          ex_rate: Number(model.ex_rate),
          de_rate: model.de_rate / 100,
        }
      },
      { key: 'star_point_settings' }
    )
  },

  render() {
    const {
      fields: { ex_rate, de_rate, description },
      submitting,
      handleSubmit
    } = this.props
    return (
      <section style={{ width: '100%', height: '100%' }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>虚拟货币设置</span>
          </div>
        </header>
        <div>
          <KBForm
            type="reduxForm"
            onSubmit={handleSubmit(this.updateSetting)}
            fields={this.props.fields}
          >
            <div className="kb-form-container-m">
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={{value: '星积分'}}
                  title="虚拟币名"
                  disabled
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  isRequired={true}
                  field={ex_rate}
                  title="兑换比例"
                  hint="钱与虚拟币的兑换比例(1元=x币)"
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  inputClass="input-percent"
                  isRequiredR={true}
                  titleClass="label-rt"
                  field={de_rate}
                  title="抵扣比例"
                  hint="星积分抵扣在订单中的最高使用占比，可以设置0~100，为0的则不支持星积分抵扣"
                />
              </div>
              <div className="kb-row">
                <KBTextarea
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  isRequired={true}
                  field={description}
                  title="星积分说明"
                />
              </div>
              <div className="kb-row" style={{ paddingBottom: 30 }}>
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </KBForm>
        </div>
      </section>
    )
  }
})

const validate = values => {
  const errors = {}

  if (valid.required(values.ex_rate)) {
    errors.ex_rate = '请输入抵扣比例'
  }

  if (valid.required(values.de_rate)) {
    errors.de_rate = '请输入兑换比例'
  } else {
    const deRateNum = Number(values.de_rate)
    if(isNaN(deRateNum) || deRateNum < 0 || deRateNum > 100) {
      errors.de_rate = '兑换比例只能为0-100之间的数字'
    }
  }

  if (valid.required(values.description)) {
    errors.description = '请输入描述'
  }

  return errors
}

const formConfig = {
  form: 'VirtualMoney',
  fields: ['ex_rate', 'de_rate', 'description'],
  validate: validate,
  touchOnBlur: false
}

function mapStateToProps(state, props) {
  const { groupSetting } = state
  let star_point_settings = groupSetting.star_point_settings
  return {
    initialValues: {
      ...star_point_settings,
      de_rate: star_point_settings ? star_point_settings.de_rate * 100 + '' : '0'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSpaceAction: bindActionCreators(spacesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

VirtualMoney = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VirtualMoney)

export default VirtualMoney
