import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect, KBASNumberInput } from 'components'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { meetingSettingActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { LOCKER_RESERVATION_PRIVILEGES } from 'app/constants'

var SpaceSettingLockersForm = React.createClass({
  displayName: 'SpaceSettingLockersForm',

  // componentWillReceiveProps(nextprops) {
  //   if(nextprops.values[this.props.type] != this.state.funcSetting)
  //   this.setState({
  //     funcSetting: nextprops.value[this.props.type]
  //   })
  // },

  updateSpace(model) {
    const { updateLocationSetting, setting_value } = this.props
    // model.send_message_to_operator = this.props.values.send_message_to_operator
    updateLocationSetting({
      cabinet_settings: model
    },{key:'cabinet_settings'})
  },

  render() {
    const {
      fields: { allow_timeout_minutes, remind_end_minutes, reserve_role },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <label>超时容许范围</label>
              <p className="color-nine">超时容许范围，单位：分钟</p>
            </div>
            <KBASNumberInput
              field={allow_timeout_minutes}
              className="f-right"
              type="number"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <label>即将到期提醒</label>
              <p className="color-nine">
                使用剩余至单位时间时提醒用户，单位：分钟
              </p>
            </div>
            <KBASNumberInput
              field={remind_end_minutes}
              className="f-right"
              type="number"
              counterStyle={{ marginRight: 130 }}
            />
          </div>
          {/* <div className='kb-row'>
            <div className="f-left">
              <label>使用时间超过容许范围后的使用价格</label>
              <p className='color-nine'>使用时间超过容许范围后的使用价格，单位：n 元/分钟默认0</p>
            </div>
            <KBASNumberInput field={overtime_price} className='f-right' type='number' counterStyle={{ marginRight: 130 }} />
          </div> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 25
            }}
          >
            <div>储物柜使用权限</div>
            <KBSelect
              style={{ minWidth: 250, maxWidth: 450 }}
              field={reserve_role}
              arrayData={LOCKER_RESERVATION_PRIVILEGES}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { groupSetting={} } = state
  // console.log(state,props);
  // const setting = props.setting || {}
  let setting_value = groupSetting.cabinet_settings
  return {
    initialValues: {
      ...setting_value
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'SpaceSettingLockersForm',
  fields: ['allow_timeout_minutes', 'remind_end_minutes', 'reserve_role'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingLockersForm)
