import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as selectors from 'app/selectors'
import * as valid from 'utils/validate'
import { subscriptionActions } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBPopoverTop
} from 'components'
import * as actions from 'app/actions'
import { TAXES_FORM } from 'app/constants/hint'

var SpaceAddPointPlanForm = React.createClass({
  getInitialState() {
    return {
      isLoading: false
    }
  },

  parseDiscountForm(model) {
    const {
      callback,
      updatePointPlansAction,
      createPointPlansAction,
      type,
      pointplan,
      successAction
    } = this.props

    model.plan_type = 'gift'
    model.price = 0
    model.gift_rate = Math.floor(model.gift_rate * 10000) / 1000000

    if (type == 'update') {
      return api
        .putPointPlans(pointplan.id, {
          ...model
        })
        .then(
          res => {
            updatePointPlansAction.success(res)
            successAction({ message: '更新优惠计划成功!' })
            KBPopover.close()
          },
          err => {
            updatePointPlansAction.failure(err)
          }
        )
    }

    return api
      .postPointPlans({
        ...model
      })
      .then(
        res => {
          createPointPlansAction.success(res)
          successAction({ message: '添加优惠计划成功!' })
          KBPopover.close()
        },
        err => {
          createPointPlansAction.failure(err)
        }
      )
  },

  componentWillUnmount() {
    this.setState({
      isLoading: false
    })
  },

  render() {
    const {
      fields: { number, price, gift_rate },
      error,
      submitting,
      handleSubmit,
      type,
      currencyName
    } = this.props
    const { isLoading } = this.state

    return (
      <form onSubmit={handleSubmit(this.parseDiscountForm)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">
            {type == 'update' ? '修改' : '添加'}优惠计划
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title={'需充值' + currencyName + '数'}
              isRequired={true}
              label="true"
              className="kb-form-group kb-form-c178 kb-form-alone"
              field={number}
              value={number.value}
            />
          </div>
          {/* <div className='kb-row'>
            <KBInput title='货币单价' isRequired = {true} label='true' className = 'kb-form-group kb-form-c178 kb-form-alone' field={ price } value={ price.value } />
          </div> */}
          <div className="kb-row">
            <KBInput
              title="赠送比例"
              isRequired={true}
              label="true"
              inputClass="input-percent"
              className="kb-form-group kb-form-c178 kb-form-alone"
              hint={TAXES_FORM.rate}
              field={gift_rate}
              value={gift_rate.value}
            />
          </div>
          <div className="f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              {type == 'update' ? '修改' : '添加'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { initialValues } = props
  let spaceSetting = selectors.getSpaceSetting(state)
  let {
    virtual_currency_settings: { virtual_currency_name }
  } = spaceSetting

  return {
    initialValues: {
      gift_rate:
        Math.floor(initialValues && initialValues.gift_rate * 1000000) /
          10000 || '',
      number: (initialValues && initialValues.number) || '',
      price: (initialValues && initialValues.price) || ''
    },
    currencyName: virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createPointPlansAction: bindActionCreators(
      actions.pointPlansAction.create,
      dispatch
    ),
    updatePointPlansAction: bindActionCreators(
      actions.pointPlansAction.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.isNumber(values.number)) {
    errors.number = '请输入正确的数字'
  }
  // if (valid.isNumber(values.price)) {
  //   errors.price = '请输入正确的价格数字'
  // }
  if (valid.isNumber(values.gift_rate)) {
    errors.gift_rate = '请输入正确的数字'
  }

  return errors
}

const formConfig = {
  form: 'SpaceAddPointPlanForm',
  fields: ['number', 'price', 'gift_rate'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceAddPointPlanForm)
