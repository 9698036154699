import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { paginate } from 'utils/kbUtil'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { deptActions, memberActions, apiCallFailure } from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import DepartmentNode from './DepartmentNode'
import * as selectors from 'app/selectors'
import MemberForm from './MemberForm'
import DepartmentForm from './DepartmentForm'
import MemberList from './MemberList'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBLoadingContainer,
  KBPagination,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import * as roles from 'app/reducers/role'
import _ from 'lodash'

/**
 * 空间员工列表显示
 * @return {[Object]} Members
 */
var Members = React.createClass({
  getInitialState() {
    return {
      loading: false,
      pagination: null,
      selectDepartmentNode: '',
      currentDepartment: this.props.currentDepartment,
      currentMembers: []
    }
  },
  openMemberForm() {
    const { departments, members } = this.props
    let currentDepartments =
      this.state.currentDepartment && this.state.currentDepartment.id
        ? [this.state.currentDepartment]
        : null
    let content = (
      <MemberForm
        member={{
          gender: 'male'
        }}
        onSaveMember={this.onSaveMember}
        currentDepartments={currentDepartments}
        departments={departments}
        members={members}
        callback={this._loadData}
      />
    )
    KBPopover.show(content)
  },

  selectCurrentDepartment(department) {
    this._loadData(department, 1)

    this.setState({
      selectDepartmentNode: '',
      currentDepartment: department
    })
  },

  membersFilterState(members, state) {
    let selectDepartmentNode = state || this.state.selectDepartmentNode

    if (selectDepartmentNode === 'noAssigned') {
      return members.filter(user => !user.department_ids.length)
    } else if (selectDepartmentNode === 'dimission') {
      return members.filter(user => user.state === 'dimission')
    }

    return members.filter(user => user.state !== 'dimission')
  },

  componentWillReceiveProps(nextProps) {
    const { currentDepartment, selectDepartmentNode } = this.state
    const options =
      selectDepartmentNode === 'noAssigned' ? { department_blank: true } : null

    if (nextProps.page !== this.props.page) {
      this._loadData(
        nextProps.currentDepartment || currentDepartment,
        nextProps.page,
        options
      )
    }

    if (
      nextProps.members &&
      !_.isEqual(this.props.members, nextProps.members)
    ) {
      let members = nextProps.members

      this.setState({
        currentMembers: this.membersFilterState(members)
      })
    }
  },

  onSaveMember(json) {
    this.props.createMembersAction.success(json, { entityName: 'members' })
  },

  _confirmDeleteMember(member) {
    const { apiCallFailureActions } = this.props
    // if (member.state == 'active') {
    //   return apiCallFailureActions({
    //     status: 'error',
    //     message :'该员工已经激活不能删除!'
    //   })
    // }
    var params = {}
    params = {
      user_id: member.id
    }

    if (member.state === 'dimission') {
      return KBPopover.plugins.confirm(`删除员工`, `你是否确定删除这个员工?`, {
        confirm: () => {
          this._deleteMember(member)
          KBPopover.close()
        }
      })
    }

    api.getSpaceRoles(params).then(({ json }) => {
      let isSpaceAdmin = roles.isSpaceAdmin(json)
      if (!isSpaceAdmin) {
        KBPopover.plugins.confirm(`删除员工`, `你是否确定删除这个员工?`, {
          confirm: () => {
            this._deleteMember(member)
            KBPopover.close()
          }
        })
      } else {
        apiCallFailureActions({
          status: 'error',
          message: '该员工为空间创建者不能删除!'
        })
      }
    })
  },
  // users
  _deleteMember(member) {
    const { id } = member
    api
      .deleteMember(id)
      .then(
        json => {
          this.props.deleteMembersAction.success(json, { key: 'users', id })
          this.setState({
            currentMembers: this.state.currentMembers.filter(
              user => user.id !== id
            )
          })
        },
        error => {
          this.props.deleteMembersAction.failure(error)
        }
      )
      .finally(() => {
        KBPopover.close()
      })
  },

  dismissMember(member) {
    const { deleteMembersAction } = this.props
    const { id } = member

    KBPopover.plugins.confirm(
      `员工离职`,
      `你是否确定当前员工“${member.name}”离职?`,
      {
        confirm: () => {
          api.getDismiss(id).then(
            json => {
              deleteMembersAction.success(json, { key: 'users', id })
              KBPopover.close()
              this.setState({
                currentMembers: this.state.currentMembers.filter(
                  user => user.id !== id
                )
              })
            },
            error => {
              deleteMembersAction.failure(error)
              KBPopover.close()
            }
          )
        }
      }
    )
  },

  componentDidMount() {
    const { page, currentDepartment } = this.props

    api.getDepartments().then(
      json => {
        this.props.getDepartmentsAction.success(json)
      },
      errors => {
        this.props.getDepartmentsAction.failure(errors)
      }
    )
    this._loadData(currentDepartment, page)
  },

  _loadData(currentDepartment, currentPage, options) {
    const per_page = 10
    let params = { per_page, page: currentPage || 1 }

    // load locations
    this.setState({ loading: true })

    if (options && options.state) {
      params.state = options.state
    }
    if (this.state.selName && this.state.selName != '') {
      params.email = this.state.selName
    }
    if (options && (options.email || options.email == '')) {
      params.email = options.email
    }
    params.department_blank = options && options.department_blank ? true : false

    if (currentDepartment) {
      params = Object.assign({}, params, {
        department_id: currentDepartment.id
      })
    }

    if (!params.department_id && !params.department_blank) {
      delete params.department_blank
    }

    api
      .getMembers(params)
      .then(
        ({ json, response }) => {
          let pagination = paginate(response)
          this.props.getMembersAction.success(json, { entityName: 'members' })
          options && options.callback && options.callback()
          this.setState({
            currentMembers: response.body || {},
            pagination
          })
          this.props.routerActions.replace(
            `/admin/employee?page=${params.page}`
          )
        },
        errors => {
          this.props.getMembersAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  addChildDepartment(department) {
    KBPopover.show(<DepartmentForm parent={department} />)
  },

  renameDepartment(department) {
    let parentDepartment =
      department &&
      this.props.departments.find(dept => dept.id === department.parent_id)
    KBPopover.show(
      <DepartmentForm
        initialValues={{
          name: department && department.name,
          parent_id: parentDepartment ? parentDepartment.id : ''
        }}
        department={department}
        departments={this.props.departments}
        parent={parentDepartment}
        updating={true}
      />
    )
  },

  deleteDepartment(department) {
    KBPopover.plugins.confirm(`删除部门`, `你是否确定删除这个部门?`, {
      confirm: () => {
        let deleteDepartmentsAction = this.props.deleteDepartmentsAction
        api.deleteDepartment(department.id).then(
          json => {
            deleteDepartmentsAction.success(json, {
              id: department.id,
              key: 'departments'
            })
            KBPopover.close()
          },
          errors => {
            deleteDepartmentsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  selectStatusNote(status) {
    const { currentDepartment } = this.props
    const { currentMembers } = this.state

    if (status === 'dimission') {
      let members = Object.assign([], currentMembers)

      this.setState({
        currentDepartment: {},
        selectDepartmentNode: status,
        currentMembers: members.filter(user => user.state === 'dimission')
      })

      this._loadData(currentDepartment, 1, {
        state: 'disabled'
      })
      return
    }

    if (status === 'noAssigned') {
      this._loadData(null, 1, {
        department_blank: true
      })
      this.setState({
        currentDepartment: {},
        selectDepartmentNode: status
      })
      return
    }

    this.setState({
      currentDepartment: {},
      selectDepartmentNode: status
    })
  },

  nameFilterOnchange(e) {
    let value = e.target.value
    const { currentDepartment } = this.state
    this.setState({
      selName: value
    })
    this._loadData(currentDepartment, 1, { email: value })
  },
  refreshMember() {
    const { page, currentDepartment } = this.props
    this._loadData(currentDepartment, page)
  },

  exportMembers() {
    api.exportMembers().then(res => {
      const { json: { result } = {} } = res
      if (result.code && result.code === 200) {
        KBPopoverTop.show(
          <KBToReportCenter title="会员导出" message={result.msg} />
        )
      }
    })
  },

  render() {
    var { pagination, currentDepartment, selectDepartmentNode } = this.state
    let currentMembers = this.state.currentMembers
    let { departments } = this.props
    return (
      <div className="kb-out-box clear-fix">
        {/* <KBLoadingContainer loading={!departments}>
          <section className='kb-content' style={{background:'#FFF'}}> */}
        {/* <nav className='nav-container-bg f-left'>
              <div className='nav-container-content'>
                <header className='nav-header clear-fix'>
                  <h2 className='header-title f-left'>组织架构</h2>
                  <div className='nav-header-btn f-right'>
                    <a className='add-btn' onClick={this.addChildDepartment.bind(null, departments[0])}>添加部门</a>
                  </div>
                </header>
                <div className='section'>
                  <DepartmentNode
                    key={'top'}
                    level={0}
                    isTop={true}
                    isExpandingChildren={true}
                    department={{
                      type: 'org'
                    }}
                    departments={departments}
                    currentDepartment={currentDepartment || {}}
                    onSelectCurrentDepartment={this.selectCurrentDepartment}
                    addChildDepartment={this.addChildDepartment}
                    renameDepartment={this.renameDepartment}
                    deleteDepartment={this.deleteDepartment}
                  />
                  <div className='tree-view clear-fix'>
                    {/*<div style={ {paddingLeft: 30} } className={ classNames('tree-view_item', selectDepartmentNode == 'dimission' ? 'tree-view_click' : '') } onClick={ this.selectStatusNote.bind(null, 'dimission') } >
                      <div className='tree-view_content'>
                        <nobr className='node'>
                          已离职
                        </nobr>
                      </div>
                    </div>*/}
        {/* <div style={{ paddingLeft: 30 }} className={classNames('tree-view_item', selectDepartmentNode === 'noAssigned' ? 'tree-view_click' : '')} onClick={this.selectStatusNote.bind(null, 'noAssigned')} >
                      <div className='tree-view_content'>
                        <nobr className='node'>
                          未分配部门
                        </nobr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav> */}
        <div
          className="kb-content-container"
          style={{ background: '#FFF', width: 1136, margin: '0 auto' }}
        >
          <header className="nav-header border-bottom">
            <div className="clear-fix">
              <h2 className="nav-section-header-title">
                <span>
                  {(currentDepartment && currentDepartment.name) || `全公司`}
                </span>
                <div className="f-right">
                  <button
                    className="c-btn-secondary f-right"
                    style={{ marginLeft: 10 }}
                    onClick={this.exportMembers}
                  >
                    导出会员信息
                  </button>
                  <a className="bordered-btn" onClick={this.openMemberForm}>
                    <i className="iconfont icon-add" />
                    添加新员工
                  </a>
                </div>
              </h2>
              <div className="color-nine" style={{ marginTop: `10px` }}>
                <i className="iconfont icon-account_circle" />
                {(pagination && pagination.total_count) || 0}人
              </div>
            </div>
          </header>
          <div
            className="nav-section-content-container"
            style={{ background: '#FFF' }}
          >
            <div className="nav-section-action-bar">
              <div
                className="kb-form-group kb-form-100 kb-form-alone"
                style={{ width: '100%', marginBottom: 18 }}
              >
                <div />
                <div style={{ width: '100%' }}>
                  <input
                    type="text"
                    className="kb-input"
                    style={{
                      paddingLeft: 42,
                      background:
                        '#ffffff url(https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/header-search.jpg) no-repeat 15px center',
                      backgroundSize: '14px 14px'
                    }}
                    placeholder="输入邮箱筛选员工"
                    onChange={this.nameFilterOnchange}
                  />
                </div>
              </div>
            </div>
            <KBLoadingContainer loading={!currentMembers}>
              <MemberList
                members={currentMembers}
                departments={departments}
                department={currentDepartment}
                deleteMember={this._confirmDeleteMember}
                dismissMember={this.dismissMember}
                refreshMember={this.refreshMember}
              />
              {currentMembers && currentMembers.length > 0 ? (
                <KBPagination
                  pagination={pagination}
                  template={
                    `/admin/employee?page=#PAGE#` +
                    (currentDepartment
                      ? `&department_id=${currentDepartment.id}`
                      : ``)
                  }
                />
              ) : null}
            </KBLoadingContainer>
          </div>
        </div>
        {/* </section>
        </KBLoadingContainer> */}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let page = parseInt(location.query && location.query.page, 10) || 1
  let department_id = parseInt(location.query.department_id, 10)
  let departments = selectors.getDepartments(state) || []
  let currentDepartment = selectors.getDepartment(state, department_id)
  const settingRoles = selectors.getRoleSetting(state)
  return {
    settingRoles,
    departments,
    page,
    currentDepartment
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDepartmentsAction: bindActionCreators(deptActions.all, dispatch),
    getMembersAction: bindActionCreators(memberActions.replace, dispatch),
    createMembersAction: bindActionCreators(memberActions.create, dispatch),
    deleteMembersAction: bindActionCreators(memberActions.delete, dispatch),
    deleteDepartmentsAction: bindActionCreators(deptActions.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members)
