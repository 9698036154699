import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect } from 'components'
import * as selectors from 'app/selectors'
import { FINANCE_NUMBER_FORMAT } from 'app/constants'

var SpaceSettingShowForm = React.createClass({
  displayName: 'SpaceSettingShowForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          general_settings: model
        })
      },
      {
        key: 'general_settings'
      }
    )
  },
  render() {
    const {
      fields: { finance_number_format },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c39 kb-form-alone"
              field={finance_number_format}
              title="当计算金额出现小数时"
              arrayData={FINANCE_NUMBER_FORMAT}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.settings.finance_number_format || 'origin'
  return {
    initialValues: { finance_number_format: setting_value }
  }
}

const formConfig = {
  form: 'SpaceSettingShowForm',
  fields: ['finance_number_format'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpaceSettingShowForm)
