import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBRadio,
  KBSelect,
  KBFormError,
  KBButton,
  KBUserInput,
  KBDepartmentInput,
  KBPopover,
  KBPopoverTop
} from 'components'
import * as selectors from 'app/selectors'
import MemberLocationRolesForm from './MemberLocationRolesForm'
import MemberGroupRolesSettingsForm from './MemberGroupRolesSettingsForm'
import {
  memberActions,
  successState,
  usersActions,
  apiCallFailure
} from 'app/actions'
import { MEBMER_GENDERS_FORM } from 'app/constants'
import { ADD_STAFF } from 'app/constants/hint'
import * as roles from 'app/reducers/role'
import MemberLocationRolesSettings from './MemberLocationRolesSettings'
import MemberGroupRolesSettings from './MemberGroupRolesSettings'
import _ from 'lodash'
import { normalize } from 'normalizr'
import { userSchema } from 'app/constants/Schemas'

const getLocationRoles = (space_roles)=>{
  return space_roles.filter(item=>!item.location_group_id&&item.location_id)
}

const getSpaceRoles = (space_roles)=>{
  return space_roles.filter(item=>!item.location_group_id&&!item.location_id)
}

const getGroupRoles = (space_roles)=>{
  return space_roles.filter(item=>item.location_group_id&&!item.location_id)
}

var MemberForm = React.createClass({
  getInitialState() {
    return {
      dc_name: '',
      isVerifyPassed: false,
      dcNameBtn: '验证',
      timer: -1,
      dcNameDisable: false,
      showAdminInput:false
    }
  },

  /**
   * 获取用户当前修改的 permission_setting
   */
  parsePermissionSettings(model) {
    const { currentMember } = this.props
    const { permission_settings, location_permission_settings } = model
    let oldUserSettings = roles
      .getCurrentUserSettingsOfCurrentSpace(
        currentMember.space_roles,
        currentMember
      )
      .map(role_o => role_o.id)
    let roles_n = []
    location_permission_settings.forEach(l => {
      if (!l.location_id) {
        return
      }
      roles_n.push({
        role: l.role,
        enabled: l.enabled,
        location_id: l.location_id
      })
    })
    let currentSettings = permission_settings
      ? permission_settings.indexOf(',') > -1
        ? permission_settings.split(',')
        : [permission_settings]
      : []

    let differenceRoles = currentSettings.length
      ? _.difference(currentSettings, oldUserSettings)
      : oldUserSettings
    if (currentSettings && currentSettings.length) {
      oldUserSettings.forEach(c => {
        const isExist = currentSettings.find(role_c => role_c === c)
        roles_n.push({
          role: c,
          enabled: isExist ? true : false
        })
      })
      differenceRoles.forEach(c => {
        roles_n.push({
          role: c,
          enabled: true
        })
      })
    } else {
      oldUserSettings.forEach(c => {
        roles_n.push({
          role: c,
          enabled: false
        })
      })
    }

    // 这一段逻辑会导致：如果该账号是空间管理员，那么权限就只能增加不能减少
    // 为什么要这段逻辑？原因未知
    if (roles.isSpaceAdmin(currentMember)) {
      oldUserSettings.forEach(c => {
        roles_n = roles_n.map(item=>{
          if(c===item.role){
            item.enabled = true
          }
          return item
        })
      })
    }

    return roles_n
  },

  createMember(model) {
    const {
      type,
      successAction,
      updateUsersAction,
      createUsersAction,
      callback,
      refreshMember
    } = this.props
    const { isVerifyPassed } = this.state
    const permission_settings = this.parsePermissionSettings(model)
    if (
      model.location_group_permission_settings &&
      model.location_group_permission_settings.length > 0
    ) {
      model.location_group_permission_settings.forEach(item => {
        permission_settings.push(item)
      })
    }
    delete model.location_group_permission_settings
    const params = { ...model, permission_settings }
    delete params.location_permission_settings

    if (!isVerifyPassed) {
      return
    }
    if (type === 'update') {
      if (params.phone_num && params.phone_num.includes('*')) {
        delete params.phone_num
      }
      const { currentMember } = this.props
      return api.updateMember(currentMember.id, params).then(
        json => {
          const memberData = selectors.getSpaceMemberInfo(
            json.json,
            currentMember.id
          )
          const memberDataSchema = normalize(memberData, userSchema)
          this.props.updateMemberAction.success(json)
          updateUsersAction.success(memberDataSchema)
          successAction({ message: '编辑员工成功' })
          refreshMember && refreshMember()
          KBPopover.close()
          return true
        },
        error => {
          this.props.updateMemberAction.failure(error)
        }
      )
    }
    return api.createMember(params).then(
      json => {
        this.props.createMembersAction.success(json, { entityName: 'members' })
        createUsersAction.success(json)
        successAction({ message: '添加员工成功' })
        refreshMember && refreshMember()
        callback && callback()
        KBPopover.close()
        return true
      },
      error => {
        this.props.createUsersAction.failure(error)
      }
    )
  },

  componentDidMount() {
    const {
      fields: {
        location_permission_settings,
        location_group_permission_settings,
        dc_name
      },
      type,
      currentMember
    } = this.props
    var params = {}
    if (dc_name.value && type === 'update') {
      this.setState({
        isVerifyPassed: true
      })
    }
    if (type) {
      params = {
        user_id: currentMember.id
      }
    }
    const currentRoles = currentMember.space_roles || []
    currentRoles.forEach(item => {
      if (item.location_group_id) {
        location_group_permission_settings.addField({
          location_group_id: item.location_group_id,
          role: item.role,
          enabled: true
        })
      }
    })

    currentRoles.forEach(j => {
      if (!j.location_id) {
        return
      }
      location_permission_settings.addField(addFormField(j))
    })

    function addFormField(Item) {
      return Object.assign({}, Item, {
        location_id: Item.location_id,
        role: Item.role,
        enabled: true
      })
    }
  },

  getLocationSettingList(locationSettings) {
    const { locations } = this.props
    const locationSettingsList = []
    locations.map(location => {
      let {
        location_manager,
        location_operator,
        observer,
        finance,
        repair,
        sales
      } = locationSettings[location.id]
      if (location_manager) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'location_manager',
          enabled: true
        })
      }
      if (location_operator) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'location_operator',
          enabled: true
        })
      }
      if (observer) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'observer',
          enabled: true
        })
      }
      if (finance) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'finance',
          enabled: true
        })
      }
      if (repair) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'repair',
          enabled: true
        })
      }
      if (sales) {
        locationSettingsList.push({
          location_id: location.id,
          role: 'sales',
          enabled: true
        })
      }
    })
    return locationSettingsList
  },

  addLocationRolesSetting() {
    KBPopoverTop.show(
      <MemberLocationRolesForm callBack={this.LocationRolesField} />
    )
  },

  addGroupRolesSetting() {
    KBPopoverTop.show(
      <MemberGroupRolesSettingsForm callBack={this.groupRolesField} />
    )
  },

  groupRolesField(field) {
    const {
      fields: { location_group_permission_settings }
    } = this.props
    let settingIndex =
      location_group_permission_settings &&
      location_group_permission_settings.length > 0
        ? location_group_permission_settings.findIndex(
            setting =>
              field.location_group_id == setting.location_group_id.value &&
              field.role == setting.role.value
          )
        : -1
    if (settingIndex != -1) {
      location_group_permission_settings[settingIndex].enabled.onChange(true)
    } else {
      location_group_permission_settings.addField(getPhase(field))
    }
    function getPhase(Item) {
      return Object.assign({}, Item, {
        location_group_id: Item.location_group_id,
        role: Item.role,
        enabled: true
      })
    }
  },

  LocationRolesField(field) {
    const {
      fields: { location_permission_settings }
    } = this.props
    let settingIndex =
      location_permission_settings && location_permission_settings.length > 0
        ? location_permission_settings.findIndex(
            setting =>
              field.location_id == setting.location_id.value &&
              field.role == setting.role.value
          )
        : -1
    if (settingIndex != -1) {
      location_permission_settings[settingIndex].enabled.onChange(true)
    } else {
      location_permission_settings.addField(getPhase(field))
    }
    function getPhase(Item) {
      return Object.assign({}, Item, {
        location_id: Item.location_id,
        role: Item.role,
        enabled: true
      })
    }
  },
  getSettings(settings) {
    const { currentMember, type } = this.props
    return roles.getSettingssOfCurrentSpace(settings, currentMember, type)
  },
  saveDcName(e) {
    const {
      fields: { dc_name }
    } = this.props
    let value = e.target.value
    dc_name.onChange(value)
    this.setState({
      dc_name: value,
      isVerifyPassed: false
    })
  },

  fillValue(value){
    const {
      fields: { location_permission_settings, location_group_permission_settings }
    } = this.props
    const { space_roles } = value
    const locationRoles = getLocationRoles(space_roles)
    const groupRoles = getGroupRoles(space_roles)
    locationRoles.forEach((item,index)=>{
      const len = location_permission_settings.length
      if(index>len-1){
        location_permission_settings.addField(addFormField(item))
      }else{
        const {enabled, location_id, role } = location_permission_settings[index]
        enabled.onChange(true)
        location_id.onChange(item.location_id)
        role.onChange(item.role)
      }
    })
    groupRoles.forEach((item,index)=>{
      const len = location_group_permission_settings.length
      if(index>len-1){
        location_group_permission_settings.addField(addFormField(item))
      }else{
        const {enabled, location_group_id, role } = location_group_permission_settings[index]
        enabled.onChange(true)
        location_group_id.onChange(item.location_group_id)
        role.onChange(item.role)
      }
    })

    function addFormField(Item) {
      return Object.assign({}, Item, {
        location_id: Item.location_id,
        role: Item.role,
        enabled: true,
        location_group_id:Item.location_group_id
      })
    }

    this.setState({ addCurrentMember:value, showAdminInput:true })
  },

  toVerifyDcName() {
    const { dc_name } = this.state
    const {
      fields: { name, phone_num, email },
      type
    } = this.props
    if (!dc_name) {
      if (type === 'update') {
        return this.props.apiCallFailureActions({
          status: 'error',
          message: '公司域账号已验证，无需重复验证'
        })
      }
      return this.props.apiCallFailureActions({
        status: 'error',
        message: '公司域账号不能为空'
      })
    }
    this.setState({ dcNameDisable: true })
    let timer = 10
    api.VerifyDcName({ ad_name: dc_name }).then(
      json => {
        const ad_info = json.response.body

        this.props.successAction({ message: '公司域账号验证已通过' })
        this.setState({
          isVerifyPassed: true
        })
        if(ad_info.user_id){
          api.currentUserAllInfo(ad_info.user_id).then(res=>{
            const { response:{ body } = {} } = res
            if(body){
              this.fillValue(body)
            }
          })
        }else{
          this.setState({addCurrentMember:null})
        }
        if (ad_info) {
          name.onChange(ad_info.ad_name)
          phone_num.onChange(ad_info.mobile)
          email.onChange(ad_info.email)
        }
        let timeChange = setInterval(() => {
          timer--
          this.setState({
            timer: timer,
            dcNameBtn: `${timer}s`,
            dcNameDisable: true
          })
          if (timer < 1) {
            this.setState({
              // timer:-1,
              dcNameBtn: '验证',
              dcNameDisable: false
            })
            clearInterval(timeChange)
          }
        }, 1000)
      },
      error => {
        this.setState({ dcNameDisable: false })
        this.props.updateMemberAction.failure(error)
      }
    )
  },
  render() {
    const {
      fields: {
        name,
        gender,
        phone_num,
        wechat,
        email,
        dc_name,
        department_ids,
        location_permission_settings,
        permission_settings,
        space_title,
        parent_id,
        location_group_permission_settings
      },
      error,
      submitting,
      handleSubmit,
      locations,
      isHelpDesk,
      locationsObj
    } = this.props
    const {
      departments,
      currentDepartments,
      members,
      type,
      settingRole,
      currentMember,
      location_groups,
      groupsObj
    } = this.props
    const { dcNameDisable, dcNameBtn, isVerifyPassed, addCurrentMember, showAdminInput } = this.state

    const current_member = addCurrentMember || currentMember
    let settingList = this.getSettings(settingRole.roles_settings)
    let currentUserSettings = roles.getCurrentUserSettingsOfCurrentSpace(
      current_member.space_roles,
      current_member
    )
    let isSpaceAdmin = roles.isSpaceAdmin(current_member)
    let currentDepartment =
      (current_member &&
        departments &&
        departments.filter(dep => {
          return (
            current_member.department_ids &&
            current_member.department_ids.includes(dep.id)
          )
        })) ||
      null
    const hint = ADD_STAFF
    return (
      <form onSubmit={handleSubmit(this.createMember)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">
            {type === 'update' ? '编辑员工信息' : '添加新员工'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <div className="kb-row" style={{ marginBottom: 5 }}>
              <KBInput
                className="kb-form-group kb-form-c18 "
                title="公司域账号"
                isRequired={true}
                field={dc_name}
                onChange={this.saveDcName}
              />
              <div className="kb-form-group" style={{ marginTop: 30 }}>
                <KBButton
                  className={dcNameDisable ? 'cancel-btn' : 'certain-btn'}
                  type="button"
                  onClick={!dcNameDisable && this.toVerifyDcName}
                >
                  {dcNameBtn}
                </KBButton>
              </div>
            </div>
            {!isVerifyPassed && (
              <p className="lr-error">
                公司域账号未验证或验证未通过,请先验证公司域账号
              </p>
            )}
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              disabled={true}
              title="姓名"
              field={name}
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBRadio
              className="kb-form-group kb-form-c18 kb-form-alone"
              inClassName="kb-from-initial"
              isRequired={true}
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="性别"
              arrayType={MEBMER_GENDERS_FORM}
              name="sex"
              field={gender}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              disabled={false}
              title="手机"
              isRequired={true}
              field={phone_num}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              disabled={true}
              title="邮箱"
              field={email}
              isRequired={true}
              hint={
                type === 'update' &&
                current_member && !current_member.unconfirmed_email
                  ? '注：员工邮箱修改后需要验证新邮箱，验证通过后修改才会生效'
                  : ''
              }
            />
            {current_member && current_member.unconfirmed_email ? (
              <div className="kb-notice-block" style={{ marginTop: 10 }}>
                邮箱已修改为:{' '}
                <span
                  style={{ color: '#39B54A', fontSize: 16, fontWeight: 500 }}
                >
                  {current_member.unconfirmed_email}
                </span>
                , 我们已向此邮箱发送了一封验证邮件,
                请登录邮箱验证通过后修改才会生效.
              </div>
            ) : null}
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="微信"
              field={wechat}
            />
          </div>
          <div className="kb-row">
            <KBDepartmentInput
              title="部门"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={department_ids}
              multi={true}
              departments={departments}
              selectedDepartments={currentDepartment}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="职务"
              field={space_title}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="上级"
              placeholder="选择上级"
              hint={hint.superior}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={parent_id}
              multi={false}
              users={members}
              selectedUsers={this.props.parent}
            />
          </div>
          {!isSpaceAdmin || isHelpDesk|| showAdminInput ? (
            <div className="kb-row">
              <KBUserInput
                title="权限"
                placeholder="选择权限"
                hint={hint.permission_settings}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={permission_settings}
                multi={true}
                users={settingList}
                selectedUsers={currentUserSettings}
              />
            </div>
          ) : (
            ''
          )}
          <MemberLocationRolesSettings
            location_permission_settings={location_permission_settings}
            locations={locations}
            locationsObj={locationsObj}
          />
          <div className="kb-row" style={{ paddingBottom: 20 }}>
            <span
              className="certain-btn kb-from-cmr"
              onClick={this.addLocationRolesSetting}
            >
              添加分店权限
            </span>
          </div>
          <MemberGroupRolesSettings
            location_group_permission_settings={
              location_group_permission_settings
            }
            location_groups={location_groups}
            locationsObj={groupsObj}
          />
          <div className="kb-row" style={{ paddingBottom: 20 }}>
            <span
              className="certain-btn kb-from-cmr"
              onClick={this.addGroupRolesSetting}
            >
              添加项目权限
            </span>
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

MemberForm.PropTypes = {
  currentDepartments: PropTypes.array.isRequired,
  // departments: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  onSaveMember: PropTypes.func.isRequired
}

function getParentDept(dept, departmentObj) {
  if (!dept || !dept.parent_id) {
    return null
  }
  let parent = departmentObj[dept.parent_id]

  return parent
}

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)
  let locationsObj = selectors.getSerializeLocationObj(state)
  const location_groups = selectors.getLocationGroups(state)
  const groupsObj = _.get(state, 'entities.locationGroups')
  let departmentObj = selectors.getDepartmentsObj(state)
  let departments = selectors.getDepartments(state)
  departments = _.cloneDeep(departments).map(dep => {
    let parent = getParentDept(dep, departmentObj)
    let parentName = ''
    while (parent) {
      parentName = parent.name + (parentName ? ' -> ' + parentName : parentName)
      let newParent = departmentObj[parent.id]
      parent = getParentDept(newParent, departmentObj)
    }

    dep.name = parentName ? parentName + ' -> ' + dep.name : dep.name
    return dep
  })
  const { type } = props
  let settingRole = selectors.getRoleSetting(state)

  let currentMember = props.member
  if (type === 'update') {
    currentMember = selectors.getSpaceMemberInfo(state, props.member.id)
  }
  const isHelpDesk = roles.canAdminAccount(state.user)
  return {
    currentMember,
    locations,
    settingRole,
    locationsObj,
    departments,
    initialValues: props.member,
    isHelpDesk,
    location_groups,
    groupsObj
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateMemberAction: bindActionCreators(memberActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    updateUsersAction: bindActionCreators(usersActions.update, dispatch),
    createUsersAction: bindActionCreators(usersActions.create, dispatch),
    createMembersAction: bindActionCreators(memberActions.create, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入员工姓名'
  }

  if (valid.required(values.gender)) {
    errors.gender = '请选择性别'
  }

  if (
    (valid.required(values.phone_num) || valid.mobile(values.phone_num)) &&
    values.phone_num &&
    !values.phone_num.includes('*')
  ) {
    errors.phone_num = '请输入正确的手机号码'
  }

  if ((values.email && valid.email(values.email)) || !values.email) {
    errors.email = '请输入正确的邮件地址'
  }

  // if (valid.required(values.dc_name)) {
  //   errors.dc_name = '请填写并验证公司域账号'
  // }
  return errors
}

const formConfig = {
  form: 'createMember',
  fields: [
    'name',
    'gender',
    'phone_num',
    'email',
    'dc_name',
    'wechat',
    'department_ids',
    'space_title',
    'parent_id',
    'permission_settings',
    'location_permission_settings[].role',
    'location_permission_settings[].enabled',
    'location_permission_settings[].location_id',
    'location_permission_settings[].location_group_id',
    'location_group_permission_settings[].role',
    'location_group_permission_settings[].enabled',
    'location_group_permission_settings[].location_group_id'
  ],
  touchOnBlur: false,
  validate: validate
}

MemberForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MemberForm)

export default MemberForm
