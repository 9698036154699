import React, { Component, PropTypes } from 'react'
// CRM客户来源分布
import StatisticCrmSource from './crm/StatisticCrmSource'
// CRM客户预计人数来源统计
import StatisticCrmSourceExpected from './crm/StatisticCrmSourceExpected'
// Crm客户个数统计
import StatisticCrmCustomer from './crm/StatisticCrmCustomer'
// CRM客户预计人数趋势线
import StatisticCrmExpected from './crm/StatisticCrmExpected'
// 分店感兴趣客户数统计
import StatisticCrmLocationCustomer from './crm/StatisticCrmLocationCustomer'
// 分店感兴趣预计客户数统计
import StatisticCrmLocationExpectedCustomer from './crm/StatisticCrmLocationExpectedCustomer'
// CRM客户负责人进度分布
import StatisticCustomerLeader from './crm/StatisticCustomerLeader'
// CRM客户分店进度分布
import StatisticCustomerLocation from './crm/StatisticCustomerLocation'
// Crm 跟进统计
import StatisticFollowUp from './crm/StatisticFollowUp'
// Crm 各状态客户分布
import StatisticCustomerState from './crm/StatisticCustomerState'
// CRM分店总赢单数比较
import StatisticCrmWinLocationCountRate from './crm/StatisticCrmWinLocationCountRate'
// CRM分店赢单总预计数比较
import StatisticCrmWinLocationExpectedRate from './crm/StatisticCrmWinLocationExpectedRate'
// CRM用户赢单总数比较
import StatisticCrmWinUserCountRate from './crm/StatisticCrmWinUserCountRate'
// CRM用户赢单总预计数比较
import StatisticCrmWinUserExpectedRate from './crm/StatisticCrmWinUserExpectedRate'
// Crm 客户漏斗图
import StatisticCustomerStateHistory from './crm/StatisticCustomerStateHistory'
// 分店赢单数比较
import StatisticCrmLocationWinRateCompare from './crm/StatisticCrmLocationWinRateCompare'
// 用户赢单数比较
import StatisticCrmUserWinRateCompare from './crm/StatisticCrmUserWinRateCompare'
// 分店流失数比较
import StatisticCrmLocationLostRateCompare from './crm/StatisticCrmLocationLostRateCompare'
// 用户流失数比较
import StatisticCrmUserLostRateCompare from './crm/StatisticCrmUserLostRateCompare'
// CRM赢单数按渠道统计
import StatisticCrmWinSource from './crm/StatisticCrmWinSource'
// CRM流失数按渠道统计
import StatisticCrmFailSource from './crm/StatisticCrmFailSource'
// CRM客户流失原因分布
import StatisticCustomerFailedReason from './crm/StatisticCustomerFailedReason'

var StatisticCrmBox = React.createClass({
  displayName: 'StatisticCrmBox',
  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticCustomerState />
          <StatisticCustomerStateHistory />
          <StatisticCrmCustomer />
          <StatisticCrmExpected />
          <StatisticCrmWinLocationCountRate />
          <StatisticCrmWinLocationExpectedRate />
          <StatisticCrmWinUserCountRate />
          <StatisticCrmWinUserExpectedRate />
          <StatisticCrmLocationWinRateCompare />
          <StatisticCrmUserWinRateCompare />
          <StatisticCrmLocationLostRateCompare />
          <StatisticCrmUserLostRateCompare />
          <StatisticCrmLocationCustomer />
          <StatisticCrmLocationExpectedCustomer />
          <StatisticCustomerLeader />
          <StatisticCustomerLocation />
          <StatisticFollowUp />
          <StatisticCrmSource />
          <StatisticCrmSourceExpected />
          <StatisticCrmWinSource />
          <StatisticCrmFailSource />
          <StatisticCustomerFailedReason />
        </div>
      </section>
    )
  }
})

export default StatisticCrmBox
