import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { KBPopover, KBTableWithoutData } from 'components'
import MemberRow from './MemberRow'

var MemberList = React.createClass({
  render() {
    const { members, departments, department, dismissMember, refreshMember } = this.props

    var memberList = Object.assign([], members)

    return (
      <table className="content-table" style={{ whiteSpace: 'nowrap' }}>
        <thead>
          <tr>
            <th>姓名</th>
            <th>联系方式</th>
            <th>部门</th>
            <th>状态</th>
            <th />
          </tr>
        </thead>
        <KBTableWithoutData
          hasData={memberList.length > 0}
          tableHeadNum="8"
          tipMessage="暂无员工信息"
        >
          {memberList &&
            memberList.map((member, index) => (
              <MemberRow
                departments={departments}
                members={members}
                member={member}
                deleteMember={this.props.deleteMember}
                dismissMember={dismissMember}
                key={index}
                refreshMember={refreshMember}
              />
            ))}
        </KBTableWithoutData>
      </table>
    )
  }
})

MemberList.PropTypes = {
  members: PropTypes.array.isRequired,
  departments: PropTypes.object.isRequired,
  deleteMember: PropTypes.func.isRequired
}

export default MemberList
