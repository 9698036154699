import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { apiCallFailure, successState } from 'app/actions'
import { KBInput, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { toImageProxyDirect } from 'utils/kbUtil'

var VisitDeviceAdd = React.createClass({
  getInitialState() {
    return {
      disableSubmit: true,
      isCreate: true,
      validateSuccess: false,
      newDevice: {}
    }
  },

  createPad(model) {
    const {
      fields: { name }
    } = this.props
    const {
      location_id,
      apiCallFailureAction,
      successAction,
      routerActions,
      type,
      area,
      updateOfficePadList
    } = this.props
    const { isCreate } = this.state
    model.location_id = location_id
    if (isCreate) {
      return api.createPadWithCode(model).then(
        json => {
          let newDevice = json.json || {}
          name.onChange(newDevice.name || '')
          this.setState({
            isCreate: false,
            validateSuccess: true,
            newDevice
          })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message || '验证失败，请稍后重试'
          })
        }
      )
    }

    if (!model.name) {
      return apiCallFailureAction({
        status: 'error',
        message: '请输入平板名称'
      })
    }
    const { newDevice } = this.state

    if (type === 'office') {
      model.area_id = area.id
      return api
        .createMeetingDevice({
          pad_id: newDevice.id,
          name: model.name,
          location_id,
          area_id: model.area_id
        })
        .then(
          json => {
            updateOfficePadList && updateOfficePadList()
            successAction({
              message: '办公区平板添加成功'
            })
          },
          error => {
            apiCallFailureAction({
              status: 'error',
              message: error.message || '添加失败，请确认信息后重试'
            })
          }
        )
    }

    if (type === 'meeting') {
      if (!model.area_id) {
        return apiCallFailureAction({
          status: 'error',
          message: '请选择要绑定的会议室'
        })
      }
      return api
        .createMeetingDevice({
          pad_id: newDevice.id,
          name: model.name,
          location_id,
          area_id: model.area_id
        })
        .then(
          json => {
            routerActions.push(`/admin/locations/${location_id}/meeting/theme`)
          },
          error => {
            apiCallFailureAction({
              status: 'error',
              message: error.message || '添加失败，请确认信息后重试'
            })
          }
        )
    }
    return api.updatePad(newDevice.id, { name: model.name }).then(
      json => {
        routerActions.push(`/admin/locations/${location_id}/visit/devices`)
      },
      error => {
        apiCallFailureAction({
          status: 'error',
          message: error.message || '添加失败，请确认信息后重试'
        })
      }
    )
  },

  codeInputChange(e) {
    let value = e.target.value || ''
    const {
      fields: { code }
    } = this.props
    if (value.length > 6) {
      value = value.slice(0, 6)
    }
    let disableSubmit = value.length === 6 ? false : true
    code.onChange(value)
    this.setState({
      disableSubmit
    })
  },

  renderJsx() {
    const {
      fields: { code, name, area_id },
      submitting,
      handleSubmit,
      type,
      typeText,
      meetingRooms,
      updateOfficePadList
    } = this.props
    const { disableSubmit, isCreate, validateSuccess } = this.state

    return (
      <div
        className={
          type === 'meeting' || type === 'office'
            ? ''
            : 'kb-content-container clear-fix'
        }
      >
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            {type === 'office' ? (
              <i
                className="iconfont iconfont icon-arrow_drop_down_circle a-hover"
                style={{
                  transform: 'translateY(2px) rotate(90deg)',
                  color: '#bbb'
                }}
                onClick={() => {
                  updateOfficePadList()
                }}
              />
            ) : null}
            <span>添加平板</span>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30 }}
        >
          <form onSubmit={handleSubmit(this.createPad)}>
            <div className="kb-form-container visit-setting-block">
              <div className="visit-pad-add-tip">
                <div className="icon">
                  <i className="iconfont icon-hint" />
                </div>
                <div className="msg">在输入框中输入平板上显示的6位数配对码</div>
                <div className="img">
                  <img
                    src={toImageProxyDirect(
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/pad_hint.png',
                      145,
                      138
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="title-container">
                <div className="title-and-des">
                  <div className="title">与{typeText}平板配对</div>
                  <div className="des">
                    {typeText}系统需要一个平板，请按照以下操作进行配对
                  </div>
                </div>
              </div>
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="des">
                    1.根据你的平板操作系统下载-酷办{typeText}系统(
                    <a style={{ color: '#55CE6B', cursor: 'pointer' }}>
                      下载地址
                    </a>
                    )
                  </div>
                  <div className="des">
                    2.安装完成后，打开{typeText}系统，选择进行配对
                  </div>
                  <div className="des">3.在下面输入6位配对设备码</div>
                </div>
              </div>
              <div
                className="title-container m-top-sm"
                style={{ alignItems: 'center', justifyContent: 'flex-start' }}
              >
                <KBInput
                  field={code}
                  className="kb-form-group kb-form-100 kb-form-alone"
                  style={{ width: '252px' }}
                  inputClass="six-digit-input"
                  onChange={this.codeInputChange}
                />
                {validateSuccess ? (
                  <div style={{ color: '#55CE6B', marginLeft: 15 }}>
                    <i
                      className="iconfont icon-Uploadsuccess"
                      style={{
                        marginRight: 5,
                        fontSize: '15px',
                        fontWeight: 900
                      }}
                    />
                    <span>验证成功</span>
                  </div>
                ) : null}
              </div>
              {isCreate ? null : (
                <div className="title-container m-top-sm">
                  <KBInput
                    title="设置平板名称"
                    field={name}
                    className="kb-form-group kb-form-66 kb-form-alone"
                    inputContainerStyle={{ width: '100%' }}
                    isRequired={true}
                  />
                </div>
              )}
              {isCreate ? null : type === 'meeting' ? (
                <div
                  className="title-container m-top-sm"
                  style={{ width: '33%', display: 'block' }}
                >
                  <KBUserInput
                    title="绑定会议室"
                    isRequired={true}
                    className="kb-form-group kb-form-100 kb-form-alone"
                    field={area_id}
                    placeholder="请选择会议室"
                    multi={false}
                    users={meetingRooms}
                  />
                </div>
              ) : null}
              <div className="title-container">
                {isCreate ? (
                  <KBButton
                    className="certain-btn"
                    disabled={disableSubmit}
                    type="submit"
                    submitting={submitting}
                  >
                    验证
                  </KBButton>
                ) : (
                  <KBButton
                    className="certain-btn"
                    type="submit"
                    submitting={submitting}
                  >
                    确认添加
                  </KBButton>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  },

  render() {
    const { type } = this.props

    return type === 'meeting' || type === 'office' ? (
      <section className="nav-section-container  f-right">
        {this.renderJsx()}
      </section>
    ) : (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        {this.renderJsx()}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let location_id = params && params.id
  let {
    query: { type }
  } = location
  const deviceTypes = {
    meeting: '会议室',
    office: '办公区'
  }
  let typeText = (type && deviceTypes[type]) || '访客'
  let meetings = selectors.getMeetingRoomsOfLocation(state, location_id)
  let meetingRooms = meetings.filter(room => {
    return room.area_type === 'meeting_room'
  })

  return {
    location_id,
    type,
    typeText,
    meetingRooms
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitDeviceAddForm',
  fields: ['code', 'name', 'area_id'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitDeviceAdd)
