import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import {
  KBButton,
  KBTextarea,
  KBFormError,
  KBForm,
  KBMorePhotoUpload,
  KBMoreImageCropperInput
} from 'components'
import {notification} from 'antd'
import config from 'app/config'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { KBSelect } from 'components/views'
import _ from 'lodash'
import { DAY_TIME_HOUR } from 'app/constants'

let GroupSetStationImageForm = React.createClass({
  getInitialState() {
    const {
      fields: { desk_image }
    } = this.props
    return {
      fileList: desk_image.initialValue || []
    }
  },

  componentDidMount() {
    const {
      fields: { desk_image },
      loc_id
    } = this.props
    api.getGroupsInfo(loc_id).then(json => {
      const groups = _.get(json, 'json.entities.groups', {})[loc_id]
      const fileList = _.get(groups, 'desk_image', [])
      this.setState({ fileList })
      desk_image.onChange(fileList)
    })
  },

  updateDeskImage(model) {
    const { updateLocation } = this.props
    updateLocation && updateLocation(model)
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({ message: '上传失败', description: msg, top: 100 })
    }
  },

  removeFile(index) {
    const {
      fields: { desk_image }
    } = this.props
    const { fileList } = this.state
    let newFileList = [...fileList]
    newFileList.splice(index, 1)
    desk_image.onChange(newFileList)
    this.setState({ fileList: newFileList })
  },

  render() {
    const {
      fields: { desk_image },
      handleSubmit,
      submitting,
      loc_id
    } = this.props
    const { fileList } = this.state
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.updateDeskImage)}
        fields={this.props.fields}
      >
        <fieldset className="fieldset" style={{ marginTop: '30px' }}>
          <legend>工位图片</legend>
          <div>
            <KBMoreImageCropperInput
              title={false}
              aspect={1125 / 750}
              accept=".png,.jpg,.jpeg"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={desk_image}
              type="groups"
              prefix={`groupsStation/${loc_id}/`}
              width={225}
              height={150}
              imageSize={1024}
              failureCallBack={this.UploadFailureCallBack}
              callback={v => this.setState({ fileList: v })}
              isPublic
            >
              <div className="upload-portrait">
                <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                  <button className="bordered-btn m-none" type="button">
                    {fileList && fileList.length ? '继续上传图片' : '上传图片'}
                  </button>
                  <p
                    className="color-nine"
                    style={{ paddingTop: 20, fontSize: 12 }}
                  >
                    图片格式为png, jpeg或jpg,文件大小不大于1M
                  </p>
                </div>
              </div>
            </KBMoreImageCropperInput>
            <div>
              {fileList ? (
                <ul className="kb-more-file" style={{ margin: 0 }}>
                  {fileList.map((file, index) => {
                    return (
                      <li key={index} style={{ cursor: 'pointer' }}>
                        <img
                          src={file}
                          alt="loading"
                          style={{
                            width: '225px',
                            height: '150px',
                            marginBottom: 10
                          }}
                        />

                        <div className="delete">
                          <i
                            className="iconfont icon-close"
                            onClick={this.removeFile.bind(null, index)}
                          />
                        </div>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                ''
              )}
            </div>
            {/* <KBMorePhotoUpload
              defaultImages={defaultImages}
              uploadedText="上传图片"
              photoShow={true}
              sort={true}
              className="kb-form-group kb-form-alone"
              field={desk_image}
              img_width={232}
              img_height={134}
              hint="图片格式为png或jpg,尺寸大小不小于200x150,文件大小不大于1M"
              text="上传图片"
              imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
              prefix={`${config.uploadDir}groups/${loc_id}`}
            /> */}
          </div>
        </fieldset>
        <div className="kb-row border-bottom" style={{ paddingBottom: 30 }}>
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            确认保存
          </KBButton>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { group, loc_id } = props
  const defaultImages = group.desk_image
  return {
    loc_id,
    initialValues: {
      desk_image: defaultImages
    }
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'GroupSetStationImageForm',
  fields: ['desk_image'],
  validate: validate,
  touchOnBlur: false
}

GroupSetStationImageForm = reduxForm(
  formConfig,
  mapStateToProps
)(GroupSetStationImageForm)

export default GroupSetStationImageForm
