import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { apiCallFailure } from 'app/actions'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBDateInput,
  KBPopover,
  KBForm
} from 'components'
import { CRM_TIME_NUMBER } from 'app/constants'
import { ADD_LOCATION } from 'app/constants/hint'
import * as selectors from 'app/selectors'
import { arrayToObject, getServiceErrorMessage } from 'utils/kbUtil'
import moment from 'moment'

var AddVisitRequestForm = React.createClass({
  getInitialState() {
    return {
      submitting: false
    }
  },

  componentDidMount() {},

  addVisitRequest(model) {
    let info = {}
    info.visitor_type = '_tour'
    info.request_type = 'request'
    info.expect_arrival_date = moment(model.date).format('YYYY-MM-DD')
    info.expect_arrival_time =
      parseInt(model.visit_time_hour) * 60 + parseInt(model.visit_time_minute)
    info.name = model.name
    if (model.company) {
      info.company = model.company
    }
    if (model.phone) {
      info.phone_num = model.phone
    }
    if (model.email) {
      info.email = model.email
    }
    this.setState({
      submitting: true
    })
    api
      .createVisitor({ location_id: this.props.loc_id, info })
      .then(
        () => {
          this.props.loadData(1, this.props.recordParams, false)
          KBPopover.close()
        },
        error => {
          const errorMessage = getServiceErrorMessage(error)
          errorMessage &&
            this.props.apiCallFailureActions({
              status: 'error',
              message: errorMessage
            })
        }
      )
      .finally(() => {
        this.setState({
          submitting: false
        })
      })
  },

  render() {
    const {
      fields: {
        date,
        name,
        phone,
        email,
        company,
        visit_time_hour,
        visit_time_minute
      },
      error,
      handleSubmit,
      minuteNumberArray,
      hourNumberArray
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.addVisitRequest)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">添加访客</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-84">
            <KBDateInput
              field={date}
              title="到访时间"
              className="kb-form-group kb-div-210"
            />
            <div className="f-left" style={{ display: 'flex', marginTop: 27 }}>
              <KBSelect
                className="kb-form-group kb-form-alone f-left"
                selectStyle={{ backgroundColor: '#fff', width: 60 }}
                field={visit_time_hour}
                arrayData={hourNumberArray}
              />
              <span style={{ padding: 9, float: 'left' }}>:</span>
              <KBSelect
                className="kb-form-group kb-form-alone f-left"
                selectStyle={{ backgroundColor: '#fff', width: 60 }}
                field={visit_time_minute}
                arrayData={minuteNumberArray}
              />
            </div>
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="访客姓名"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={phone}
              title="访客电话"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={company}
              title="访客公司"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={email}
              title="访客邮箱"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={this.state.submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  let hourNumberArray = CRM_TIME_NUMBER(8, 21, 1)
  let minuteNumberArray = CRM_TIME_NUMBER(0, 59, 15)

  return {
    minuteNumberArray,
    hourNumberArray,
    initialValues: {
      date: moment().format('YYYY-MM-DD'),
      visit_time_hour: 8,
      visit_time_minute: 0
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入访客名称'
  }

  return errors
}

const formConfig = {
  form: 'AddVisitRequestForm',
  fields: [
    'date',
    'name',
    'phone',
    'email',
    'company',
    'visit_time_hour',
    'visit_time_minute'
  ],
  validate: validate,
  touchOnBlur: false
}

AddVisitRequestForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AddVisitRequestForm)

export default AddVisitRequestForm
