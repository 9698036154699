import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { STATISTIC_CYCLE } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticMeetingReserveCountTrend = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate(new Date(), 'daily')
    return {
      filters: {
        from,
        to,
        cycle: 'daily'
      },
      data: [],
      month: ''
    }
  },

  componentDidMount() {
    const { filters, data } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')

    this.mounted = true
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'spline'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: '时间'
          },
          labels: {
            format: '{value:%Y-%m-%d}'
          }
        },
        yAxis: {
          title: {
            text: '时间数'
          }
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '日期：{point.x:%Y-%m-%d}, 时间数：{point.y}'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        series: data
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { locations } = this.props
    const { from, to, location_ids, cycle } = filters
    var params = { location_ids, cycle }
    if (!location_ids && locations) {
      params.location_ids = locations.map(json => json.id).join(',')
    }

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.getReserveCountTrendStatistic(params).then(({ json }) => {
      if (!json.length) {
        return
      }
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    var contain = {}
    let data = []

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    var array = json
    var dt = { name: '预定数量', data: [] }
    var dr = { name: '预定时间长度', data: [] }
    array.forEach(json => {
      let { year, month, day } = getYMD(json.date)
      dt.data.push([Date.UTC(year, month, day), json.total_count])
      dr.data.push([Date.UTC(year, month, day), json.renting_minute])
    })

    dt.data.sort(this.compare)
    dr.data.sort(this.compare)

    data.push(dt)
    data.push(dr)
    this.fillChart(data)
  },

  compare(a, b) {
    if (!a || !b || !a[0] || !b[0]) {
      return 0
    }

    if (a[0] - b[0] > 0) {
      return 1
    } else if (a[0] - b[0] < 0) {
      return -1
    }

    return 0
  },

  fillChart(data) {
    this.setSerierDate(data)
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  cycleChange(data) {
    this._setFilters({
      cycle: data
    })
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render() {
    const { locations } = this.props
    const { filters, data, month } = this.state
    const { from, to, cycle } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>总预定数和预订时间长度按周期变化</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
          <KBDropSelect
            options={{ defaultSelectPrompt: '选择分店' }}
            defaultData={locations}
            multi={true}
            callback={this.selectCallBack}
            style={{ marginLeft: 20 }}
          />
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  // const locations = selectors.getSerializeLocation(state)
  const { group_id } = props
  const locations = selectors.getSerializeGroupsLocation(state, group_id)
  return {
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticMeetingReserveCountTrend)
