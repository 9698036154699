import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import {
  usersActions,
  apiCallFailure,
  orgUserActions,
  successState
} from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBRadio,
  KBForm,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBTextarea,
  KBMorePhotoUpload,
  KBImageCropperInput,
  KBAvatar
} from 'components'
import { notification } from 'antd'
import config from 'app/config'


var GroupSetBaseAddFacilities = React.createClass({
  getInitialState() {
    return {
      loading: false
    }
  },

  componentDidMount() {
    const {
      fields: { id, name, description, image },
      type,
      product
    } = this.props
    if (type === 'edit') {
      id.onChange(product.id)
      description.onChange(product.description)
      image.onChange(product.image)
      name.onChange(product.name)
    }
  },

  addProduct(model) {
    const { callBack } = this.props
    callBack && callBack(model)
  },

  loadingCallBack(loading) {
    this.setState({ loading })
  },

  UploadFailureCallBack(msg){
    if(msg){
      notification.error({message: '上传失败', description:msg,top:100 })
    }
  },


  render() {
    const {
      fields: { id, name, description, image },
      handleSubmit,
      type,
      submitting,
      groupId
    } = this.props
    const { loading } = this.state
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.addProduct)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'create' ? '添加' : '编辑'}社区介绍
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className='kb-row'>
            <KBInput
              title="社区名称"
              field={name}
              className="kb-form-group kb-div-48 kb-form-alone"
              isRequiredR={true}
            />
          </div>
          <div className="kb-row">
            <fieldset className="fieldset">
              <legend>社区图片</legend>
              <div>
                <KBImageCropperInput
                  // callback={this.callback}
                  // failureCallBack={this.failureCallBack}
                  field={image}
                  style={{ display: 'inline-block' }}
                  type={`groups/${groupId}`}
                  loadingCallBack={this.loadingCallBack}
                  imageSize={1024}
                  failureCallBack={this.UploadFailureCallBack}
                  width={225}
                  height={150}
                  aspect={1125 / 750}
                  previewBorderRadius={0}
                  accept=".png,.jpg,.jpeg"
                  isPublic
                >
                  <div className="upload-portrait">
                    <button
                      className="bordered-btn"
                      type="button"
                      style={{ marginLeft: 0, marginBottom: 20, width: 100 }}
                    >
                      {loading
                        ? '上传中...'
                        : image.value
                        ? '上传新图片'
                        : '上传图片'}
                    </button>
                    <br />
                    {image.value && (
                      <KBAvatar
                        imgSize={{ width: 225, height: 150 }}
                        user={{ avatar: image.value }}
                        imgStyle={{ marginLeft: 0, verticalAlign: 'middle', borderRadius: 150 }}
                        style={{ display: 'inline-block', marginLeft: 0 }}
                      />
                    )}
                  </div>
                </KBImageCropperInput>
              </div>
            </fieldset>
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={description}
              title="描述"
            />
          </div>
        </div>
        <div className="kb-row fixed-form-btn">
          <span className="cancel-btn" onClick={KBPopover.close}>
            取消
          </span>
          <KBButton
            className="certain-btn"
            type="submit"
            style={{ marginLeft: 20 }}
            submitting={submitting}
          >
            {type === 'create' ? '添加' : '保存'}
          </KBButton>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = '请输入社区名称'
  }
  if (!values.description) {
    errors.description = '请填写描述'
  }
  if (!values.image) {
    errors.image = '请上传图片'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetBaseAddProductForm',
  fields: ['id', 'name', 'description', 'image'],
  validate: validate,
  touchOnBlur: false
}

GroupSetBaseAddFacilities = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBaseAddFacilities)

export default GroupSetBaseAddFacilities
