import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import {
  KBFormError,
  KBPopover,
  KBButton,
  KBInput,
  KBUserInput
} from 'components'
import { apiCallFailure } from 'app/actions'
import * as valid from 'utils/validate'
import _ from 'lodash'

var VisitAddNewTypeForm = React.createClass({
  addNewVisitorType(model) {
    const {
      config,
      visitor_types,
      updateVisitorSettings,
      apiCallFailureAction
    } = this.props
    let index = model.copy_current
    let newType = Object.assign({}, index === 0 ? {} : visitor_types[index - 1])
    if (index && model.name === visitor_types[index - 1].type_name) {
      return apiCallFailureAction({
        status: 'error',
        message: '当前访客类型已存在，请勿重复添加'
      })
    }
    newType.name = model.name
    newType.type_name = model.name
    visitor_types.push(newType)
    config.visitor_types = visitor_types
    KBPopover.close()
    return updateVisitorSettings(config)
  },

  render() {
    const {
      fields: { name, copy_current },
      error,
      submitting,
      handleSubmit,
      newTypes
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.addNewVisitorType)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加访客类型</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div
          className="kb-form-container"
          style={{ overflowY: '-webkit-paged-y' }}
        >
          <div className="kb-row">
            <KBInput
              title="访客类型名称"
              className="kb-form-group kb-form-210 kb-form-alone"
              isRequired={true}
              field={name}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="复制现有访客"
              placeholder="选择现有访客类型"
              hint="若选择，则会将选中访客类型里除名称外的所有设置复制到新增的类型中"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={copy_current}
              multi={false}
              users={newTypes}
            />
          </div>

          <div className="f-right" onClick={this.setScrollTop}>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              确认添加
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { config } = props
  let signin = (config && config.signin) || {}
  let visitor_types = signin.visitor_types || []
  let newTypes = _.cloneDeep(visitor_types)
  newTypes.map((type, index) => {
    type.id = index + 1
    return type
  })
  newTypes.unshift({ id: 0, name: '不复制' })

  return {
    signin,
    config,
    newTypes,
    visitor_types
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入访客类型名称'
  }

  if (values.name && values.name.startsWith('_')) {
    errors.name = '类型名称不能以下划线(_)开始'
  }

  return errors
}

const formConfig = {
  form: 'VisitAddNewTypeForm',
  fields: ['name', 'copy_current'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitAddNewTypeForm)
