export default {
  TITLE: '登录',
  SEND_UNLOCK_EMAIL: '点击发送解锁邮件',
  INPUT_EMAIL: '输入电子邮件地址',
  INPUT_PASSWORD: '输入密码',
  SAVE_PASSWORD: '保持登录',
  LOGIN: '登录',
  LOGGING_IN: '登录中',
  FORGET_PASSWORD: '忘记密码'
}
