import React, { Component, PropTypes } from 'react'
import { KBTipsy, KBDropdown, KBPopover } from 'components'
import classNames from 'classnames'
import { api, apiUtils } from 'app/services'
import { getChildDepartments, getTopDepartments } from 'app/utils/CommonUtils'

var DepartmentNode = React.createClass({
  getInitialState() {
    return {
      isExpandingChildren: this.props.isExpandingChildren // whether children is shown
    }
  },

  toggleChildren() {
    this.setState({
      isExpandingChildren: !this.state.isExpandingChildren
    })
  },

  _renderDropdown() {
    const isTop = this.props.isTop
    const {
      department,
      addChildDepartment,
      renameDepartment,
      deleteDepartment
    } = this.props
    return isTop ? (
      <ul className="section-option" style={{ left: '160px' }}>
        <li onClick={() => addChildDepartment(department)}>
          <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/new-next-section.png" />
          新建下级部门
        </li>
      </ul>
    ) : (
      <ul className="section-option" style={{ left: '160px' }}>
        <li onClick={() => addChildDepartment(department)}>
          <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/new-next-section.png" />
          新建下级部门
        </li>
        <li onClick={() => renameDepartment(department)}>
          <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/rename.png" />
          修改部门
        </li>
        <li className="option-underline" />
        <li onClick={() => deleteDepartment(department)}>
          <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/delete.png" />
          删除
        </li>
      </ul>
    )
  },

  _renderChildren(childrenDepartments) {
    const { level } = this.props
    return (
      childrenDepartments &&
      childrenDepartments.map(dept => (
        <DepartmentNode
          {...this.props}
          key={dept.id}
          level={level + 1}
          isTop={false}
          department={dept}
        />
      ))
    )
  },

  _renderArrow(childrenDepartments) {
    const { isExpandingChildren } = this.state

    return childrenDepartments && childrenDepartments.length > 0 ? (
      <i
        className={`iconfont ${classNames(
          'icon-arrow_drop_down_circle',
          isExpandingChildren ? 'tree-view_arrow-down' : 'tree-view-right-hover'
        )}`}
        onClick={this.toggleChildren}
      />
    ) : null
  },

  render() {
    const {
      department,
      departments,
      currentDepartment,
      level,
      isTop
    } = this.props

    let isCurrent = false
    let childrenDepartments = []
    if (!department.id) {
      childrenDepartments = getTopDepartments(departments)
      isCurrent = !currentDepartment
    } else {
      childrenDepartments = getChildDepartments(department, departments)
      isCurrent = currentDepartment && currentDepartment.id === department.id
    }

    let paddingLeft = 30 + level * 18

    return (
      <div className="tree-view clear-fix">
        <div
          style={{ paddingLeft: paddingLeft }}
          className={classNames(
            'tree-view_item',
            isCurrent ? 'tree-view_click' : ''
          )}
          onClick={() => this.props.onSelectCurrentDepartment(department)}
        >
          {this._renderArrow(childrenDepartments)}

          <div className="tree-view_content">
            <nobr className="node">{isTop ? '全公司' : department.name}</nobr>
            <KBDropdown>
              <KBDropdown.KBDropdownTrigger>
                <i className="iconfont icon-settings" />
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent style={{ overflow: 'initial' }}>
                {this._renderDropdown()}
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          </div>
        </div>
        {this.state.isExpandingChildren
          ? this._renderChildren(childrenDepartments)
          : null}
      </div>
    )
  }
})

DepartmentNode.propTypes = {
  department: PropTypes.object.isRequired,
  departments: PropTypes.array,
  level: PropTypes.number.isRequired,
  isTop: PropTypes.bool.isRequired, // current is top
  currentDepartment: PropTypes.object,
  onSelectCurrentDepartment: PropTypes.func.isRequired,
  isExpandingChildren: PropTypes.bool,
  addChildDepartment: PropTypes.func.isRequired,
  renameDepartment: PropTypes.func.isRequired,
  deleteDepartment: PropTypes.func.isRequired
}
export default DepartmentNode
