import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import SpaceEmailTempleteForm from './SpaceEmailTempleteForm'
import { api } from 'app/services'
import { successState } from 'app/actions'
import { KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'

var SpaceEmailTemplete = React.createClass({
  displayName: 'SpaceEmailTemplete',
  getInitialState() {
    return {
      templates: [],
      templateKeys: [],
      templateKeywordsOfKey: []
    }
  },

  componentWillMount() {
    this.loadTemplates()
    this.loadTemplateKeys()
  },

  loadTemplates() {
    api.getTemplates().then(
      json => {
        let templates = json.response.body || []
        // this.setState({ templates: [{name: '这是模板名称', body: '这是模板内容...'}] })
        this.setState({ templates })
      },
      errors => {}
    )
  },

  loadTemplateKeys() {
    api.getTemplateKeys().then(
      json => {
        let templateKeys = json.response.body || []
        templateKeys.map(key => {
          this.getTemplateKeywordsOfKey(key.id)
        })
        this.setState({ templateKeys })
      },
      errors => {}
    )
  },

  getTemplateKeywordsOfKey(key_id) {
    let { templateKeywordsOfKey } = this.state
    if (templateKeywordsOfKey[key_id]) {
      return
    }

    let params = {
      template_key_id: key_id
    }
    api.getTemplateKeywordsOfKey(params).then(
      json => {
        let keywordsOfKey = json.response.body || []
        templateKeywordsOfKey[key_id] = keywordsOfKey
        this.setState({ templateKeywordsOfKey })
      },
      errors => {}
    )
  },

  createTemplateForm() {
    KBPopover.show(
      <SpaceEmailTempleteForm
        updateTemplates={this.loadTemplates}
        {...this.props}
      />
    )
  },

  updateTemplateForm(temp) {
    KBPopover.show(
      <SpaceEmailTempleteForm
        recoveredBody={this.recoverTemplateKeywords(temp)}
        temp={temp}
        updateTemplates={this.loadTemplates}
        {...this.props}
      />
    )
  },

  deleteTemplate(temp) {
    const { successAction } = this.props
    let id = temp.id

    KBPopoverConfirm({
      name: '删除模板',
      context: `是否确认删除该模板`,
      callback: () => {
        return api.deleteTemplate(id).then(json => {
          successAction({ message: '删除模板成功' })
          this.loadTemplates()
          KBPopover.close()
          return true
        })
      }
    })
  },

  escape(str) {
    return str.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--')
  },

  recoverTemplateKeywords(template) {
    const { templateKeys, templateKeywordsOfKey } = this.state
    let templateStr = template.body
    let currentKey =
      templateKeys.find(k => {
        return k.key == template.template_key
      }) || {}
    let keywordsOfKey = templateKeywordsOfKey[currentKey.id]

    keywordsOfKey &&
      templateStr &&
      keywordsOfKey.map(keyword => {
        let newSubstr = `&lt;&lt;${keyword.description}&gt;&gt;`
        let substr = `%{${keyword.name}}`
        templateStr = templateStr.replace(substr, newSubstr)
      })
    return templateStr || ''
  },

  render() {
    const { templates } = this.state

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>邮件模板设置</span>

            <span
              className="bordered-btn f-right"
              onClick={this.createTemplateForm}
            >
              新建模板
            </span>
          </div>
        </header>
        <div className="kb-form-container">
          {(templates.length &&
            templates.map((temp, index) => {
              let canShowContent = this.state['show_content_' + index]
              return (
                <fieldset key={index} className="fieldset">
                  <legend>
                    <span className="a-hover">
                      <i
                        className={`iconfont icon-${
                          canShowContent
                            ? 'arrow_drop_down_circle'
                            : 'navigate_next'
                        }`}
                        onClick={() => {
                          this.setState({
                            ['show_content_' + index]: !canShowContent
                          })
                        }}
                      />
                    </span>
                    {temp.name || '暂无'}
                    <span className="f-right" style={{ fontSize: 14 }}>
                      <i
                        className="iconfont icon-edit a-hover"
                        onClick={this.updateTemplateForm.bind(null, temp)}
                      />
                      <i
                        className="iconfont icon-delete a-hover m-left-sm"
                        onClick={this.deleteTemplate.bind(null, temp)}
                      />
                    </span>
                  </legend>
                  {canShowContent ? (
                    <div
                      style={{ padding: 30, backgroundColor: '#F3F7F9' }}
                      dangerouslySetInnerHTML={{
                        __html: temp.body
                          ? this.escape(this.recoverTemplateKeywords(temp))
                          : '此模板暂无内容'
                      }}
                    />
                  ) : null}
                </fieldset>
              )
            })) ||
            null}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceEmailTemplete)
