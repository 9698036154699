import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  tasksActions,
  memberActions,
  taskCommentsActions,
  apiCallFailure
} from 'app/actions'
import classNames from 'classnames'
import { toImageProxyDirect, preventDefaultPropagation } from 'utils/kbUtil'
import {
  KBLoadingContainer,
  KBPopover,
  KBAvatar,
  KBTextarea,
  KBButton,
  KBMorePhotoUpload,
  KBDropDownListInput,
  KBDropdown,
  KBTipsy
} from 'components'
import { UserLink } from 'components'
import moment from 'moment'
import {
  TASK_SOURCE_OBJ,
  TASK_PRIORITIES_FORM,
  TASK_STATES,
  TASK_PRIORITIES,
  TASK_STATES_CLASS,
  TASK_SUBTYPES,
  TASK_SUBTYPE
} from 'app/constants'
import * as selectors from 'app/selectors'
import TaskNote from './TaskNote'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { PopoverShowPicture } from 'components/views'
import DayPicker, { DateUtils } from 'react-day-picker'
import { canAccessLocationRoles } from 'app/reducers/role'
import MomentLocaleUtils from 'react-day-picker/moment'
import { getUserInfoUrl } from 'utils/kbUrl'
import { Link } from 'react-router'

var TaskInfo = React.createClass({
  displayName: 'TaskInfo',
  getInitialState() {
    return {
      loading: false,
      task: this.props.task,
      slideState: false
    }
  },

  componentDidMount() {
    const { getTasksAction, task_id } = this.props

    this.setState({ loading: true })

    api
      .getTask(task_id)
      .then(
        json => {
          getTasksAction.success(json)
          let task = getEntitiesJsonArray(json, 'tasks')
          this.setState({ task: task })
        },
        errors => {
          getTasksAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  changeSlide() {
    this.setState({ slideState: !this.state.slideState })
  },

  _updateTaskProperty(key, value) {
    const { task } = this.state
    this.setState({ task: Object.assign({}, task, { [key]: value }) })

    this._updateTaskRemotely({ [key]: value })
  },

  _updateTaskRemotely(params) {
    const { task_id, updateTasksAction } = this.props

    api.updateTask(task_id, params).then(
      json => {
        updateTasksAction.success(json)
        let task = getEntitiesJsonArray(json, 'tasks')
        this.setState({ task: task })
      },
      errors => {
        updateTasksAction.failure(errors)
      }
    )
  },

  _updateStatus(value) {
    this._updateTaskProperty('state', value)
  },

  _updateTaskPriority(value) {
    this._updateTaskProperty('priority', value)
    this.refs.taskPriorityDropdown.hide()
  },

  _updateTaskType(value) {
    this._updateTaskProperty('subtype', value)
    this.refs.taskTypeDropdown.hide()
  },

  _updateDueDate(date) {
    this._updateTaskProperty('due_date', date)
    this.refs.taskDuedateDropdown.hide()
  },

  createTaskComment(model) {
    const {
      taskCommentsActions,
      task_id,
      resetForm,
      apiCallFailureActions
    } = this.props
    const { slideState } = this.state

    if (!model.body) {
      return apiCallFailureActions({
        status: 'error',
        message: '请输入更新状态!'
      })
    }

    return api
      .addTaskComment(task_id, { ...model, is_show: slideState })
      .then(json => {
        let task = getEntitiesJsonArray(json, 'tasks')
        this.setState({ task: task })
        taskCommentsActions.success(json)
        resetForm()
      })
  },

  addFollower(user) {
    const { task } = this.state
    const { task_id, updateTasksAction, isManager } = this.props
    let isExisted = task.followers.find(follower => follower.id === user.id)
    if (isExisted && isManager) {
      return
    }
    let newFollowers = [user, ...task.followers]
    this.setState({
      task: Object.assign({}, task, { followers: newFollowers })
    })
    api.addFollower(task_id, { follower_id: user.id }).then(
      json => {
        updateTasksAction.success(json)
        let task = getEntitiesJsonArray(json, 'tasks')
        this.setState({ task: task })
      },
      errors => {
        updateTasksAction.failure(errors)
      }
    )
  },

  removeFollower(user) {
    const { task } = this.state
    const { task_id, updateTasksAction } = this.props
    let isExisted = task.followers.find(follower => follower.id === user.id)
    if (!isExisted) {
      return
    }
    let newFollowers = task.followers.filter(
      follower => follower.id !== user.id
    )
    this.setState({
      task: Object.assign({}, task, { followers: newFollowers })
    })
    api.deleteFollower(task_id, { follower_id: user.id }).then(
      json => {
        updateTasksAction.success(json)
        let task = getEntitiesJsonArray(json, 'tasks')
        this.setState({ task: task })
      },
      errors => {
        updateTasksAction.failure(errors)
      }
    )
  },

  _resetDueDate() {
    this._updateDueDate('')
  },

  handleChange(e) {
    this.setState({
      task: Object.assign({}, this.state.task, {
        subject: e.target.value
      })
    })
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  _renderHeader() {
    const { task } = this.state
    const { type } = this.props
    const { task_type, custom_services } = task

    return (
      <header className="nav-header clear-fix m-bottom">
        <div className="nav-section-header-title">
          <span
            className={classNames(
              'receipt-status',
              `${TASK_STATES_CLASS[task.state]}`
            )}
          >
            {TASK_STATES[task.state]}
          </span>
          {task.subtype && (
            <span style={{ verticalAlign: 'middle', minWidth: 64 }}>
              {TASK_SUBTYPES[task.subtype]}-
            </span>
          )}
          <span
            className="guarantee-content"
            type="text"
            defaultValue={task.subject}
          >
            {task.subject}
          </span>
          {task_type === 'custom_service' &&
          custom_services &&
          custom_services[0] ? (
            <span
              className="receipt-status invoice-application-state-pending m-left-sm"
              style={{
                padding: '3px 6px',
                backgroundColor: '#fff',
                border: '1px solid #60a6ec'
              }}
            >
              {custom_services[0]}
            </span>
          ) : null}
        </div>
        <div className="header-photo-box">
          <div className="photo-box">
            {task.attachments &&
              task.attachments.map(photo => {
                return (
                  <div
                    className="upload-photo"
                    key={photo.id}
                    onClick={this.clickPhoto.bind(null, photo.url)}
                  >
                    <img
                      src={toImageProxyDirect(photo.url, 118, 78)}
                      alt="photo"
                    />
                  </div>
                )
              })}
          </div>
        </div>
        <div className="nav-section-action-bar">
          <span className="task-creator">
            {TASK_SOURCE_OBJ[task.source]}&nbsp;
            <Link
              to={getUserInfoUrl((task.posted_by && task.posted_by.id) || '')}
              className="color-link"
            >
              {(task.posted_by && task.posted_by.name) || ''}
            </Link>
            &nbsp;
          </span>
          <span style={{ marginRight: '20px' }}>
            创建于 {moment.unix(task.created_at).fromNow()}
          </span>
          {task.location ? (
            <div className="guarantee-position">
              <i className="iconfont icon-position" />
              <span>{task.location.name}</span>
            </div>
          ) : null}
          <div className="guarantee-task-status">
            <KBDropdown ref="taskPriorityDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div>
                  <i
                    className="iconfont icon-radio_button_unchecked"
                    style={
                      TASK_PRIORITIES[task.priority] == '紧急'
                        ? { color: '#c8174b' }
                        : {}
                    }
                  />
                  <span>{TASK_PRIORITIES[task.priority]}</span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent
                style={{ left: '50%', transform: 'translateX(-50%)' }}
              >
                <ul className="task-type">
                  <li onClick={() => this._updateTaskPriority('normal')}>
                    <i className="iconfont icon-radio_button_unchecked" />
                    <span>{TASK_PRIORITIES['normal']}</span>
                  </li>
                  <li onClick={() => this._updateTaskPriority('high')}>
                    <i className="iconfont icon-radio_button_unchecked" />
                    <span>{TASK_PRIORITIES['high']}</span>
                  </li>
                </ul>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          </div>
          {type === 'repair' ? (
            <div className="guarantee-task-status">
              <KBDropdown ref="taskTypeDropdown" style={{ marginLeft: 20 }}>
                <KBDropdown.KBDropdownTrigger>
                  <div>
                    <span>{TASK_SUBTYPES[task.subtype] || '选择分类'}</span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent
                  style={{ left: '50%', transform: 'translateX(-50%)' }}
                >
                  <ul className="task-type">
                    {TASK_SUBTYPE.map((type, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => this._updateTaskType(type.id)}
                        >
                          <span>{type.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
            </div>
          ) : null}
          <div className="date-setting">
            <KBDropdown ref="taskDuedateDropdown">
              <KBDropdown.KBDropdownTrigger>
                <i className="iconfont icon-timer" />
                <div className="date-title">
                  {task.due_date
                    ? `到期时间: ${moment.unix(task.due_date).format('MMMDo')}`
                    : '设置到期时间'}
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <DayPicker
                    dir="zh-cn"
                    locale="zh-cn"
                    ref="daypicker"
                    selectedDays={day =>
                      DateUtils.isSameDay(
                        day,
                        moment.unix(task.due_date).toDate()
                      )
                    }
                    onDayClick={(e, day) => {
                      this._updateDueDate(day)
                    }}
                    localeUtils={MomentLocaleUtils}
                  />
                  <div
                    className="date-btn"
                    style={{
                      padding: 20,
                      borderTop: '1px solid #e6e6e6',
                      background: '#fff'
                    }}
                  >
                    <div className="f-right">
                      <a
                        className="add-btn"
                        style={{ marginRight: 0 }}
                        href="#"
                        onClick={this._resetDueDate}
                      >
                        重置到期时间
                      </a>
                    </div>
                  </div>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          </div>
        </div>
      </header>
    )
  },

  _updateAssignedTo(follower) {
    const { task } = this.state
    this.setState({
      task: Object.assign({}, task, { assigned_to: follower })
    })
    // need to deal with assigned_to differently because we also need to pass
    // in follower ids
    let follower_ids = task.followers
      .map(f => f.id)
      .filter(id => id !== follower.id)
    let params = {
      assigned_to: follower.id,
      followers: follower_ids.join(',')
    }

    this._updateTaskRemotely(params)
  },

  _renderTaskTeam() {
    const { isManager, users } = this.props
    const { task } = this.state
    // sort team to put assigned_to first
    let sortedTeam = task.followers
    if (task.assigned_to) {
      const team = task.followers.filter(
        user => user.id !== task.assigned_to.id
      )
      sortedTeam = [task.assigned_to, ...team]
    }
    return (
      <div className="nav-container-content border-none">
        <header className="nav-inner-header clear-fix">
          <h2 className="header-title f-left">负责人</h2>
        </header>
        <div className="m-top-sm m-bottom-sm">
          <KBDropDownListInput
            data={users}
            styleWidth={'100%'}
            placeholder={'添加参与人'}
            selectCallBack={this.addFollower}
            filterIdArray={task.followers.map(json => json.id)}
            query={['name', 'name_pinyin']}
          />
        </div>
        <ul className="desk-1-ul">
          {sortedTeam.map(follower => {
            let isAssignedTo =
              task.assigned_to && task.assigned_to.id === follower.id
            return (
              <li key={follower.id}>
                {isAssignedTo ? (
                  <div className="select-principal principal-select">
                    <a href="#" />
                  </div>
                ) : (
                  <div className="select-principal">
                    {isManager ? (
                      <KBTipsy content="设为负责人">
                        <a
                          href="#"
                          onClick={() => this._updateAssignedTo(follower)}
                        />
                      </KBTipsy>
                    ) : (
                      <a href="#" />
                    )}
                  </div>
                )}
                <div className="desk-user-img">
                  <KBAvatar user={follower} size={30} />
                </div>
                <div className="desk-user-message">
                  <div className="desk-user-name">
                    <span>{follower.name}</span>
                  </div>
                </div>
                {isAssignedTo ? (
                  <div className="repairs-principal">
                    <span>负责人</span>
                  </div>
                ) : null}
                {!isAssignedTo && isManager ? (
                  <div className="desk-user-close">
                    <KBTipsy content="删除">
                      <i
                        className="iconfont icon-close"
                        onClick={() => this.removeFollower(follower)}
                      />
                    </KBTipsy>
                  </div>
                ) : null}
              </li>
            )
          })}
        </ul>
      </div>
    )
  },

  _handleCompleteTask(newState) {
    let body = this.refs.taskNoteTextarea.value()
    // if (body.length > 0) {
    //   this.createTaskComment({body}).then(() => this._updateStatus(newState))
    // } else {
    this._updateStatus(newState)
    // }
  },

  _renderTaskNoteForm() {
    const {
      handleSubmit,
      submitting,
      fields: { body, attachments, state },
      isManager
    } = this.props
    const { task, slideState } = this.state

    return (
      <form onSubmit={handleSubmit(this.createTaskComment)}>
        <div className="add-status-record">
          <KBTextarea
            field={body}
            placeholder="输入状态更新"
            ref="taskNoteTextarea"
          />
          {isManager ? (
            <div className="t-right clear-fix" style={{ marginTop: '25px' }}>
              <div className="slide-btn f-left" onClick={this.changeSlide}>
                <span
                  className={`slide-bg ${
                    slideState ? 'slide-left' : 'slide-right'
                  }`}
                  style={{ marginTop: 3 }}
                >
                  <span className="slide-move" />
                </span>
              </div>
              <span className="f-left" style={{ marginTop: 5, marginLeft: 10 }}>
                {slideState ? '空间入驻用户可见' : '仅空间管理内部可见'}
              </span>
              {task.state === 'completed' ? (
                <KBTipsy content="点击重新打开此任务">
                  <KBButton
                    className="bordered-btn"
                    type="button"
                    onClick={() => this._handleCompleteTask('reopen')}
                  >
                    重新打开任务
                  </KBButton>
                </KBTipsy>
              ) : (
                <KBTipsy content="点击关闭此任务">
                  <KBButton
                    className="bordered-btn"
                    type="button"
                    onClick={() => this._handleCompleteTask('completed')}
                  >
                    关闭任务
                  </KBButton>
                </KBTipsy>
              )}
              {task.state == 'submit_task' ? (
                <KBButton
                  className="bordered-btn m-left-sm"
                  type="button"
                  onClick={() => this._handleCompleteTask('in_progress')}
                >
                  接单
                </KBButton>
              ) : task.state == 'in_progress' || task.state == 'reopen' ? (
                <KBButton
                  id="dasdasdaas"
                  className="bordered-btn m-left-sm"
                  type="button"
                  onClick={e => {
                    preventDefaultPropagation(e)
                    this._handleCompleteTask('completed')
                  }}
                >
                  完成
                </KBButton>
              ) : (
                ''
              )}
              <KBButton
                className="bordered-btn m-left-sm"
                type="submit"
                submitting={submitting}
              >
                更新状态
              </KBButton>
            </div>
          ) : (
            ''
          )}
        </div>
      </form>
    )
  },

  _renderTaskInfoForm() {
    const { user, users } = this.props
    const { task } = this.state

    return (
      <div>
        {this._renderHeader()}

        <div className="nav-section-content-container">
          <div
            className="nav-content-1-md f-left"
            style={{ width: 'calc(100% - 280px)' }}
          >
            <div className="add-market-record clear-fix">
              {this._renderTaskNoteForm()}
            </div>
            <div className="kb-section">
              <header>
                <h2>更新记录</h2>
              </header>
              <ul>
                {task &&
                  task.notes &&
                  task.notes.map(note => (
                    <TaskNote note={note} task={task} key={note.id} />
                  ))}
              </ul>
            </div>
          </div>
          <nav className="nav-content-2 f-right">{this._renderTaskTeam()}</nav>
        </div>
      </div>
    )
  },

  render() {
    const { type } = this.props
    const { loading, task } = this.state

    return (
      <div
        className={
          type === 'repair'
            ? 'nav-section-container f-right'
            : 'kb-content-container'
        }
      >
        <KBLoadingContainer
          loading={!task}
          innerStyle={{ marginTop: 0, paddingTop: 100 }}
        >
          {task && this._renderTaskInfoForm()}
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { task_id, id } = props.params

  const tasks = selectors.getTasks(state)
  const task = tasks && tasks[task_id]

  const user = selectors.getCurrentUser(state)
  const users = selectors.getSpaceUsers(state) || []

  let isManager = canAccessLocationRoles(user, id, [
    'location_manager',
    'location_operator',
    'repair'
  ])

  let sections = props.location.pathname.split('/')
  let location_id = id
  let type = location_id ? sections[5] : sections[3]

  return {
    task,
    task_id,
    user,
    users,
    type,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateTasksAction: bindActionCreators(tasksActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getTasksAction: bindActionCreators(tasksActions.get, dispatch),
    taskCommentsActions: bindActionCreators(
      taskCommentsActions.create,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.body)) {
    errors.body = '请输入更新记录'
  }

  return errors
}

const formConfig = {
  form: 'TaskInfoForm',
  fields: ['body', 'attachments', 'state'],
  touchOnBlur: false
  //validate: validate
}

TaskInfo = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(TaskInfo)

export default TaskInfo
