import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { successState, apiCallFailure } from 'app/actions'
import { routerActions } from 'react-router-redux'
import {
  KBForm,
  KBInput,
  KBSelect,
  KBImageCropperInput,
  KBButton
} from 'components'
import { api } from 'app/services'
import { notification, Select } from 'antd'

var EditValueAddedServices = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {
    const { editId } = this.props.location.query

    if (editId) {
      // 获取详情数据
      const {
        fields: { name, category, icon, desc_image, tag_list }
      } = this.props
      api.getValueAddedServiceDetail(editId).then(({ json }) => {
        name.onChange(json.name)
        category.onChange(json.category)
        icon.onChange(json.icon)
        desc_image.onChange(json.desc_image)
        tag_list.onChange(json.tag_list)
      })
    }
  },

  goBack() {
    this.props.routerActions.goBack()
  },

  submitData(model) {
    const {
      params: { id },
      apiCallFailureAction,
      successAction,
      routerActions
    } = this.props
    const { editId } = this.props.location.query
    if (!editId) {
      // 新建
      api
        .addValueAddedService({
          ...model,
          location_group_id: id
        })
        .then(res => {
          successAction({ message: '创建成功' })
          routerActions.push(`/admin/groups/${id}/valueAddedServices`)
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    } else {
      // 编辑
      api
        .editValueAddedService(editId, {
          ...model,
          location_group_id: id
        })
        .then(res => {
          successAction({ message: '修改成功' })
          routerActions.push(`/admin/groups/${id}/valueAddedServices`)
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    }
  },

  failureCallBack(err) {
    if (!err) return
    return notification.error({ message: '上传失败', description: err })
  },

  changeServicesList(val) {
    this.props.fields.tag_list.onChange(val)
  },

  render() {
    const {
      fields: { name, icon, category, desc_image, tag_list },
      submitting,
      handleSubmit
    } = this.props

    return (
      <div className="kb-content-container">
        <KBForm
          type="reduxForm"
          fields={this.props.fields}
          onSubmit={handleSubmit(this.submitData)}
        >
          <header className="kb-form-header">
            <h2 className="header-title">创建增值服务</h2>
          </header>
          <div className="kb-form-container">
            <div className="kb-row ">
              <KBInput
                className="kb-form-group kb-form-alone"
                title="服务名称"
                isRequired
                field={name}
                maxLength="8"
              />
            </div>
            <div className="kb-row">
              <KBSelect
                defaultValue="-1"
                field={category}
                title="服务类别"
                isRequired
                arrayData={[
                  { id: 'property', name: '物业服务' },
                  { id: 'business', name: '商务服务' },
                  { id: 'sustainable_love', name: '可持续喜爱 SustainLOVEbility' },
                ]}
                className="kb-form-group kb-form-alone"
              />
            </div>

            <div className="kb-row kb-form-66">
              <KBImageCropperInput
                title="Icon"
                aspect={1 / 1}
                isRequired
                className="kb-form-group kb-form-210 kb-form-alone"
                field={icon}
                accept="image/jpeg, image/png"
                imageSize={1024 * 1}
                failureCallBack={this.failureCallBack}
                isPublic
              >
                <div className="upload-portrait">
                  <img
                    src={
                      icon.value ||
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_pic@3x.png'
                    }
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'inline-block',
                      marginLeft: 0
                    }}
                    alt=""
                  />
                  <button className="bordered-btn" type="button">
                    上传图片
                  </button>
                </div>
                <p
                  className="color-nine"
                  style={{
                    fontSize: '12px'
                  }}
                >
                  图片格式为png,
                  jpeg或jpg,文件大小不大于1M，尺寸要求40*40，显示在增值服务列表页面
                </p>
                {icon.error && icon.touched && (
                  <p className="lr-error">{icon.error}</p>
                )}
              </KBImageCropperInput>
            </div>
            <div className="kb-row kb-form-66">
              <KBImageCropperInput
                title="服务介绍长图"
                aspect={false}
                isRequired
                className="kb-form-group kb-form-210 kb-form-alone"
                field={desc_image}
                imageSize={1024 * 50}
                accept="image/jpeg, image/png"
                failureCallBack={this.failureCallBack}
                isPublic
              >
                <div className="upload-portrait">
                  <img
                    src={
                      desc_image.value ||
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_pic@3x.png'
                    }
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'inline-block',
                      marginLeft: 0
                    }}
                    alt=""
                  />
                  <button className="bordered-btn" type="button">
                    上传图片
                  </button>
                </div>
                <p
                  className="color-nine"
                  style={{
                    fontSize: '12px'
                  }}
                >
                  图片格式为png，jpeg或jpg，显示在增值服务详情页面
                </p>
                {desc_image.error && desc_image.touched && (
                  <p className="lr-error">{desc_image.error}</p>
                )}
              </KBImageCropperInput>
            </div>
            <div className="kb-row">
            <div className='kb-form-group kb-form-c18 kb-form-alone kb-input'>
              <label>服务项目<span className="must-fill">*</span></label>
              <Select
                mode="tags"
                showSearch
                style={{ width: '100%' }}
                onChange={this.changeServicesList}
                value={tag_list.value}
              ></Select>
              <p
                className="color-nine"
                style={{
                  fontSize: '12px'
                }}
              >
                创建该服务下的具体服务内容（可输入多个）
              </p>
              {tag_list.error && tag_list.touched && (
                <p className="lr-error">{tag_list.error}</p>
              )}
            </div>
            </div>
            <div className="kb-row" style={{ marginTop: '80px' }}>
              <span className="cancel-btn" onClick={this.goBack}>取消</span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                保存
              </KBButton>
            </div>
          </div>
        </KBForm>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {
    initialValues: {
      tag_list: []
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入服务名称'
  }

  if (valid.required(values.category)) {
    errors.category = '请选择服务类别'
  }

  if (valid.required(values.icon)) {
    errors.icon = '请上传Icon'
  }

  if (valid.required(values.desc_image)) {
    errors.desc_image = '请上传服务介绍长图'
  }

  if (!values.tag_list || !values.tag_list.length) {
    errors.tag_list = '请输入服务项目'
  }

  return errors
}

const formConfig = {
  form: 'EditValueAddedServices',
  fields: ['name', 'category', 'icon', 'desc_image', 'tag_list'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(EditValueAddedServices)
