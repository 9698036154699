export default {
  TITLE: 'Login',
  SEND_UNLOCK_EMAIL: 'Click send unlocked mail',
  INPUT_EMAIL: 'Enter e - mail address',
  INPUT_PASSWORD: 'Enter Password',
  SAVE_PASSWORD: 'Remember Me',
  LOGIN: 'Login',
  LOGGING_IN: 'Loading',
  FORGET_PASSWORD: 'Forget Password'
}
