import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError } from 'components'
import { createSpaceAction } from 'app/actions'
import classNames from 'classnames'

var CreateSpaceSuccess = React.createClass({
  render() {
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box create-success">
            <i></i>
          </div>
          <div className="kb-box t-center">
            <span className="green-font">空间创建成功</span>
          </div>
          <div className="kb-box t-center">
            <span className="common-font-1">
              我们将尽快审核,请注意查收您的邮件.
            </span>
          </div>
        </div>
      </div>
    )
  }
})

export default CreateSpaceSuccess
