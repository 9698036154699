import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBTextarea,
  KBImageCropperInput,
  KBAvatar
} from 'components'
import { createSpaceAction } from 'app/actions'
import classNames from 'classnames'
import { notification } from 'antd'

var CreateSpacePage = React.createClass({
  getInitialState() {
    return {
      errorMsg: {
        message: ''
      }
    }
  },
  createSpace(values) {
    return api.createSpace(values).then(
      json => {
        this.props.createSpaceAction.success(json)
        this.setState({
          errorMsg: {
            message: ''
          }
        })
        this.props.routerActions.replace('/spaces/create_success')
      },
      error => {
        this.props.createSpaceAction.failure(error)
        if (error.status == '403') {
          this.setState({
            errorMsg: {
              message: '请检查邮箱验证信息'
            }
          })
        }
      }
    )
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: {
        name,
        full_name,
        subdomain,
        logo,
        description,
        telephone,
        one_liner
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { errorMsg } = this.state
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">创建您的空间</span>
          </div>
          <form onSubmit={handleSubmit(this.createSpace)}>
            <KBFormError
              error={errorMsg.message ? errorMsg : this.props.error}
            />
            <KBInput field={name} placeholder="空间名称" />
            <div
              className={classNames(
                'kb-box',
                'input-6',
                subdomain.touched && subdomain.error
                  ? 'kb-input-error'
                  : 'kb-input'
              )}
            >
              <input type="text" placeholder="域名" {...subdomain} />
              <span class="cf-lf suitable-font">.kuban.io</span>
              {subdomain.touched && subdomain.error && (
                <p className="lr-error">{subdomain.error}</p>
              )}
            </div>
            <KBInput field={full_name} placeholder="您的空间全称" />
            <KBInput field={telephone} placeholder="负责人电话" />
            <KBInput field={one_liner} placeholder="一句话描述" />
            <KBTextarea
              field={description}
              placeholder="空间介绍"
              className="kb-form-group"
              divStyle={{ float: 'none', height: 'initial', width: '100%' }}
              txtStyle={{ width: '100%' }}
              childDivStyle={{ width: '100%' }}
            />
            <div style={{ margin: '10px 0' }}>
              <KBImageCropperInput
                field={logo}
                style={{ display: 'inline-block' }}
                type={`spaces`}
                accept="image/*"
                failureCallBack={this.failureCallBack}
              >
                <div className={`upload-portrait`}>
                  <KBAvatar
                    user={{ avatar: logo.value }}
                    size={100}
                    imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
                    style={{
                      backgroundColor: '#000',
                      display: 'inline-block',
                      marginLeft: 0
                    }}
                  />
                  <button className="bordered-btn" type="button">
                    {logo.dirty ? '上传Logo' : '上传新Logo'}
                  </button>
                </div>
              </KBImageCropperInput>
            </div>
            <div className="kb-box">
              <KBButton type="submit" disabled={submitting}>
                {submitting ? '创建中' : '创建'}
              </KBButton>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state) {
  return {
    space: state.space,
    initialValues: {
      logo: 'https://media-ssl.kuban.io/static/web/v1/images/logo-b.svg'
    }
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    createSpaceAction: bindActionCreators(createSpaceAction, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入空间名称'
  } else if (valid.required(values.subdomain)) {
    errors.subdomain = '请选择正确的域名'
  } else if (valid.required(values.full_name)) {
    errors.full_name = '请输入您的公司全称'
  } else if (valid.required(values.description)) {
    errors.description = '请输入空间介绍'
  }
  return errors
}

const formConfig = {
  form: 'createSpace',
  fields: [
    'name',
    'full_name',
    'subdomain',
    'logo',
    'description',
    'telephone',
    'one_liner'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(CreateSpacePage)
