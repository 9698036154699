import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import { KBAvatar, KBTipsy } from 'components'
import moment from 'moment'
import { TASK_STATES, TASK_STATES_CLASS } from 'app/constants'
import { getUserInfoUrl, getUrlOfLocationHomePage } from 'utils/kbUrl'

var TaskRow = React.createClass({
  render() {
    const { task, tasks_type, location_id, type } = this.props
    const taskState = TASK_STATES
    const taskStateClass = TASK_STATES_CLASS
    return (
      <tr key={task.id}>
        <td style={{ width: 480 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className={classNames(
                'receipt-status',
                `${taskStateClass[task.state]}`
              )}
              style={{ minWidth: 64, padding: '5px 6px' }}
            >
              {taskState[task.state]}
            </span>
            <div style={{ paddingLeft: 15, display: 'inline-block' }}>
              <div className="accident-content">
                <Link
                  className="color-link"
                  to={
                    location_id
                      ? `/admin/locations/${location_id}/tasks/${type}/${
                          task.id
                        }${type === 'repair' ? '?type=' + tasks_type : ''}`
                      : `/admin/tasks/${type}/${task.id}${
                          type === 'repair' ? '?type=' + tasks_type : ''
                        }`
                  }
                >
                  {task.subject}
                </Link>
                {task.priority === 'high' ? (
                  <div className="accident-degree">
                    <i className="iconfont icon-radio_button_unchecked" />
                    <span>紧急</span>
                  </div>
                ) : null}
              </div>
              <div className="color-nine">
                <i className="iconfont icon-account_circle" />
                <span style={{ marginLeft: 8 }}>
                  {task.source == 'space_member' ? '内部提交' : '用户提交'}
                </span>
                <span className="responsible-person">
                  <Link
                    to={getUserInfoUrl(
                      (task.posted_by && task.posted_by.id) || ''
                    )}
                    className="color-link"
                  >
                    {(task.posted_by && task.posted_by.name) || ''}
                  </Link>
                </span>
                <span className="update-time">
                  {moment.unix(task.updated_at).fromNow()}
                </span>
                {task.attachments.length ? (
                  <i
                    style={{ marginLeft: 10, fontSize: 16 }}
                    className="iconfont icon-photo"
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </td>

        <td style={{ verticalAlign: 'middle' }}>
          {task.assigned_to ? (
            task.assigned_to.space_title ? (
              <KBTipsy content={task.assigned_to.space_title || ''}>
                <div className="td-position">
                  <KBAvatar user={task.assigned_to} size={20} />
                  <Link
                    to={getUserInfoUrl(task.assigned_to.id)}
                    className="color-link"
                  >
                    {task.assigned_to.name}
                  </Link>
                </div>
              </KBTipsy>
            ) : (
              <div className="td-position">
                <KBAvatar user={task.assigned_to} size={20} />
                <Link
                  to={getUserInfoUrl(task.assigned_to.id)}
                  className="color-link"
                >
                  {task.assigned_to.name}
                </Link>
              </div>
            )
          ) : null}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <Link
            className="color-link"
            to={getUrlOfLocationHomePage(task.location && task.location.id)}
          >
            {task.location ? task.location.name : ''}
          </Link>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {task.created_at ? (
            <div className={classNames('degree-important')}>
              <KBTipsy
                content={moment.unix(task.created_at).format('YYYY-MM-DD')}
              >
                <span>
                  {task.due_date
                    ? task.state === 'completed'
                      ? ''
                      : moment.unix(task.due_date).fromNow()
                    : ''}
                </span>
              </KBTipsy>
              <br />
              <span>
                {task.due_date
                  ? moment.unix(task.due_date).diff(moment()) < 0
                    ? task.state === 'completed'
                      ? ''
                      : '已过期'
                    : ''
                  : ''}
              </span>
            </div>
          ) : null}
        </td>
      </tr>
    )
  }
})
TaskRow.propTypes = {
  task: PropTypes.object.isRequired,
  tasks_type: PropTypes.string
}

export default TaskRow
