import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBInput, KBFormError, KBButton, KBRadio } from 'components'
import { MEBMER_GENDERS_FORM } from 'app/constants'

var UserBaseForm = React.createClass({
  displayName: 'UserBaseForm',
  render() {
    const {
      fields: { name, phone_num, space_title, gender },
      error,
      submitting,
      handleSubmit,
      updateUserForm
    } = this.props
    return (
      <form onSubmit={handleSubmit(updateUserForm)}>
        <KBFormError err={error} />
        <div className="kb-row">
          <KBInput
            title="姓名"
            field={name}
            className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
          />
        </div>
        <div className="kb-row">
          <KBRadio
            className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
            inClassName="kb-div-11"
            divClassName="kb-form-group kb-div-33"
            rightLabel="label-rt"
            title="性别"
            arrayType={MEBMER_GENDERS_FORM}
            name="sex"
            field={gender}
          />
        </div>
        <div className="kb-row">
          <KBInput
            title="职位"
            field={space_title}
            className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
          />
        </div>
        <div className="kb-row">
          <KBInput
            title="联系电话"
            field={phone_num}
            className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
          />
        </div>
        <div className="kb-row m-top">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            确定
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = props

  return {
    initialValues: user
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '姓名不能为空'
  }

  if (
    (valid.required(values.phone_num) || valid.mobile(values.phone_num)) &&
    values.phone_num &&
    !values.phone_num.includes('*')
  ) {
    errors.phone_num = '请输入正确的手机号码'
  }

  return errors
}

const formConfig = {
  form: 'UserBaseForm',
  fields: ['name', 'phone_num', 'space_title', 'gender'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(formConfig, mapStateToProps)(UserBaseForm)
