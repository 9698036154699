import validator from 'validator';
const isEmpty = value => value === undefined || value === null || value === ''
const join = rules => (value, data) =>
  rules
    .map(rule => rule(value, data))
    .filter(error => !!error)[0 /* first error */]

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (
    !isEmpty(value) && !validator.isEmail(value)
    // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    return 'Invalid email address'
  }
}

export function commonPhoneNumber(value) {
  if (!isEmpty(value) && !/^[0-9\-\+]{9,15}$/.test(value)) {
    return 'Invalid phone number'
  }
}

export function numberAndLetter(value) {
  if (!isEmpty(value) && !/^[A-Za-z0-9]+$/i.test(value)) {
    return 'Invalid number and letter'
  }
}

export function mobile(value) {
  if (!isEmpty(value) && !/^[0-9\-\+]{9,15}$/.test(value)) {
    return 'Invalid mobile phone number'
  }
}

export function isMobile(value) {
  if (!isEmpty(value) && !/^[0-9\-\+]{11,11}$/.test(value)) {
    return 'Invalid mobile phone number'
  }
}

export function IDCard(value) {
  if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X)$)/i.test(value)) {
    return 'Invalid ID Card'
  }
}

export function passport(value) {
  if (!/^[0-9A-Za-z]+$/i.test(value)) {
    return 'Invalid passport'
  }
}

export function Float(value) {
  if (!isEmpty(value) && !/^[+|-]?\d*\.?\d*$/.test(value)) {
    return 'Must be an float'
  }
}
export function Int(value) {
  if (!isEmpty(value) && !/^\d+$/.test(value)) {
    return 'Must be an Int'
  }
}

export function Link(value) {
  if (!/^http(s)?:\/\/(www\.)?\S+$/.test(value)) {
    return 'Invalid Link'
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required'
  }
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }
  }
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }
  }
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer'
  }
}

export function isNull(value) {
  if (value == '') {
    return true
  }
  var regu = '^[ ]+$'
  var re = new RegExp(regu)
  return re.test(value)
}

export function isPrice(value) {
  if (!/^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
    return '请输入正确的格式, 小数点保留2位'
  }
}

export function isNumber(value) {
  if (!/^(\-)?\d+(\.\d{1,2})?$/.test(value)) {
    return '请输入正确的数字'
  }
}

export function oneOf(enumeration) {
  return value => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`
    }
  }
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match'
      }
    }
  }
}
