import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { KBPopoverConfirm } from 'components/tools'
import { KBPopover, KBLoadingContainer } from 'components'
import VisitTypeEditNameForm from './VisitTypeEditNameForm'
import VisitTypeEditBadgeForm from './VisitTypeEditBadgeForm'
import VisitTypeEditNdaForm from './VisitTypeEditNdaForm'
import VisitTypeEditFieldsForm from './VisitTypeEditFieldsForm'

var VisitTypeEditForm = React.createClass({
  updateCurrentType(newType) {
    const { updateVisitorSettings, config, index } = this.props
    let newConfig = Object.assign({}, config)
    newConfig.signin.visitor_types[index] = newType
    return updateVisitorSettings(newConfig)
  },

  toggleSlider(first_field, second_field) {
    const { currentVisitType } = this.props
    let newVisitType = Object.assign({}, currentVisitType)
    let value = currentVisitType[first_field][second_field]
    let newValue = !value
    newVisitType[first_field][second_field] = newValue
    this.updateCurrentType(newVisitType)
  },

  deleteCurrentType() {
    const {
      updateVisitorSettings,
      config,
      signin,
      index,
      location_id,
      routerActions
    } = this.props
    const { visitor_types } = signin
    let newConfig = Object.assign({}, config)
    let newVisitTypes = visitor_types || []
    newVisitTypes.splice(index, 1)
    newConfig.signin.visitor_types = newVisitTypes
    updateVisitorSettings(newConfig)
    routerActions.push(
      `/admin/locations/${location_id}/visit/settings/sign_in?type=sign_in`
    )
    KBPopover.close()
  },

  deleteCurrentTypeConfirm() {
    const { currentVisitType } = this.props

    KBPopoverConfirm({
      name: '删除访客类型',
      context: `您确定要删除当前访客类型 ${currentVisitType.name || ''} 吗?`,
      callback: () => {
        return this.deleteCurrentType()
      }
    })
  },

  render() {
    const { currentVisitType } = this.props
    const { name, arrival, plus_one, badge, nda, photo } = currentVisitType
    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>编辑访客类型: {name}</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">常规</span>
            </div>
            <VisitTypeEditNameForm
              updateCurrentType={this.updateCurrentType}
              currentVisitType={currentVisitType}
            />

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">签到信息</span>
            </div>
            <VisitTypeEditFieldsForm
              updateCurrentType={this.updateCurrentType}
              currentVisitType={currentVisitType}
            />

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">保密协议</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">启用保密协议</div>
                  <div className="des">
                    启用后访客签到时会出现保密协议文件,访客可以选择接受保密协议或不接受保密协议
                  </div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'nda', 'enabled')}
                >
                  <span
                    className={`slide-bg ${
                      nda && nda.enabled ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">允许拒绝保密协议</div>
                  <div className="des">
                    访客依然可以看到保密协议文件,但允许访客不接受保密协议
                  </div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'nda', 'allow_decline')}
                >
                  <span
                    className={`slide-bg ${
                      nda && nda.allow_decline ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <VisitTypeEditNdaForm
              updateCurrentType={this.updateCurrentType}
              currentVisitType={currentVisitType}
            />

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">纸质标签</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">启用纸质标签</div>
                  <div className="des">启用后将可打印纸质访客标签</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'badge', 'enabled')}
                >
                  <span
                    className={`slide-bg ${
                      badge && badge.enabled ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">自动打印</div>
                  <div className="des">启用后将自动打印访客凭证</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'badge', 'auto_print')}
                >
                  <span
                    className={`slide-bg ${
                      badge && badge.auto_print ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <VisitTypeEditBadgeForm
              updateCurrentType={this.updateCurrentType}
              currentVisitType={currentVisitType}
            />

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">访客照片</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">访客拍照</div>
                  <div className="des">访客签到时需要拍照</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'photo', 'enabled')}
                >
                  <span
                    className={`slide-bg ${
                      photo && photo.enabled ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">身份验证</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">身份证</div>
                  <div className="des">访客登录时,要求访客输入身份证信息</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'plus_one', 'enabled')}
                >
                  <span
                    className={`slide-bg ${
                      plus_one && plus_one.enabled
                        ? 'slide-left'
                        : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>

            <div
              className="kb-title m-bottom-sm f-left"
              style={{ width: '100%' }}
            >
              <span className="color-six">登录后展示信息</span>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">登录后展示信息</div>
                  <div className="des">登录后是否展示信息</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.toggleSlider.bind(null, 'arrival', 'enabled')}
                >
                  <span
                    className={`slide-bg ${
                      arrival && arrival.enabled ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            {currentVisitType.type_name &&
            currentVisitType.type_name.startsWith('_') ? null : (
              <button
                className="certain-delete-btn f-right"
                onClick={this.deleteCurrentTypeConfirm}
              >
                删除当前类型
              </button>
            )}
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config, params, location } = props
  const location_id = params && params.id
  let signin = (config && config.signin) || {}
  let visitor_types = signin.visitor_types || []
  let index = (location && location.query && location.query.index) || 0
  let currentVisitType = visitor_types[index] || {}

  return {
    location_id,
    signin,
    config,
    visitor_types,
    currentVisitType,
    index
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitTypeEditForm)
