import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBTextarea,
  KBUserInput
} from 'components'
import { arrayToObject, objectToArray } from 'utils/kbUtil'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { LOCATION_TYPES } from 'app/constants'

/**
 * 修改分店 基本设置
 */
var LocationBaseForm = React.createClass({
  displayName: 'LocationBaseForm',
  getInitialState() {
    return {
      addressHint: '',
      location_stores: []
    }
  },
  componentWillMount() {
    const {
      fields: { physical_address },
      location
    } = this.props
    physical_address.onChange(location.physical_address)
  },

  componentDidMount() {
    const {
      fields: {
        name,
        location_type,
        priority,
        serial_number,
        prefix,
        city_id,
        longitude,
        traffic,
        neighborhood,
        latitude,
        district_id,
        physical_address,
        size,
        telephone,
        description,
        price_range,
        location_store_id,
        trading_area
      },
      location
    } = this.props

    name.onChange(location.name)
    priority.onChange(location.priority)
    serial_number.onChange(location.serial_number)
    prefix.onChange(location.prefix)
    city_id.onChange(location.city_id || location.city)
    district_id.onChange(location.district && location.district.id)
    // physical_address.onChange(location.physical_address)
    size.onChange(location.size)
    telephone.onChange(location.telephone)
    description.onChange(location.description)
    longitude.onChange(location.longitude)
    latitude.onChange(location.latitude)
    neighborhood.onChange(location.neighborhood)
    traffic.onChange(location.traffic)
    price_range.onChange(location.price_range)
    location_store_id.onChange(location.location_store_id)
    location_type.onChange(location.location_type)
    trading_area.onChange(location.trading_area)
    if (
      !location.longitude &&
      !location.latitude &&
      location.physical_address &&
      location.city_id
    ) {
      this.changeAddress(location.physical_address, location.city_id)
    }
    api.getLocationStores().then(json => {
      let location_stores_list = json.json
      let location_stores = []
      location_stores_list &&
        location_stores_list.map(stores => {
          location_stores.push({
            id: stores.id,
            name: stores.location_name
          })
        })
      this.setState({
        location_stores
      })
    })
    this.searchProvince()
  },

  searchProvince() {
    const {
      fields: { province_id },
      cities,
      location
    } = this.props
    const provinceId =
      cities[location.city_id] && cities[location.city_id].province_id
    province_id.onChange(provinceId)
  },
  changecity(cities) {
    const {
      fields: { province_id },
      location
    } = this.props
    const provinceId =
      cities[location.city_id] && cities[location.city_id].province_id
    province_id.onChange(provinceId)
  },

  cityChange(data) {
    const {
      fields: { province_id, district_id }
    } = this.props
    const citiesArray = objectToArray(this.props.cities).filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)

    const districts =
      (cities && data && cities[data] && cities[data].districts) || []
    if (districts.length <= 0) {
      district_id.onChange('')
    }
  },

  beforeUpdateLocation(model) {
    const { updateLocation } = this.props
    if (!model.district_id) {
      delete model.district_id
    }
    model.longitude = (model.longitude + '').trim()
    model.latitude = (model.latitude + '').trim()
    return updateLocation && updateLocation(model)
  },

  changeAddress(value, cityId) {
    if (typeof value !== 'string') {
      value = value.target.value
    }
    const {
      fields: { city_id, physical_address, longitude, latitude },
      cities
    } = this.props
    const cityIdValue = city_id.value || cityId
    const cityName = cities[cityIdValue] && cities[cityIdValue].name
    physical_address.onChange(value)
    if (!value) {
      longitude.onChange('')
      latitude.onChange('')
      return
    }
    let params = {
      address: value
    }
    if (cityName) {
      params.city = cityName
    }
    api.getReverseGeocode(params).then(({ json }) => {
      const geocodes = json.geocodes
      if (geocodes && geocodes.length) {
        const location = geocodes[0].location.split(',')
        longitude.onChange(location[0])
        latitude.onChange(location[1])
      } else {
        longitude.onChange(' ')
        latitude.onChange(' ')

        this.setState({
          addressHint: (
            <span style={{ color: 'red' }}>{`您输入的地址不正确`}</span>
          )
        })
        return
      }
      this.setState({
        addressHint: ''
      })
    })
  },

  render() {
    const { provinces } = this.props
    const { addressHint, location_stores } = this.state
    const {
      fields: {
        name,
        location_type,
        serial_number,
        prefix,
        traffic,
        neighborhood,
        city_id,
        longitude,
        priority,
        latitude,
        district_id,
        province_id,
        physical_address,
        size,
        telephone,
        description,
        device_ids,
        price_range,
        location_store_id,
        location_group_id,
        trading_area
      },
      error,
      submitting,
      handleSubmit,
      devices,
      location,
      locationGroups
    } = this.props
    const citiesArray = objectToArray(this.props.cities).filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)
    const districts =
      (cities &&
        city_id.value &&
        cities[city_id.value] &&
        cities[city_id.value].districts) ||
      []
    const selectDevices = (location && location.devices) || []
    const currentLocGroups =
      (location &&
        location.location_groups &&
        location.location_groups.map(lg => lg.id)) ||
      []

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <form onSubmit={handleSubmit(this.beforeUpdateLocation)}>
        <KBFormError err={error} />
        <fieldset className="fieldset">
          <div className="kb-row kb-form-84">
            <KBInput
              className="kb-form-group kb-div-210"
              isRequired={true}
              title="分店名称"
              field={name}
              fistFoucs={true}
            />
            <KBInput
              className="kb-form-group kb-div-48"
              title="编号"
              field={serial_number}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-84">
            <KBUserInput
              title="所属项目"
              placeholder="选择项目"
              className="kb-form-group kb-div-210"
              field={location_group_id}
              multi={false}
              users={locationGroups}
              selectedUsers={locationGroups.filter(lg =>
                currentLocGroups.includes(lg.id)
              )}
            />
            <KBInput
              className="kb-form-group kb-div-48"
              isRequired={true}
              title="项目前缀"
              hint="用于合同和订单编号的生成"
              field={prefix}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-84">
            <KBSelect
              title="分店类型"
              defaultValue={'-1'}
              className="kb-form-group kb-div-210"
              field={location_type}
              arrayData={LOCATION_TYPES}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={trading_area}
              title="所属区域"
            />
          </div>
          <div className="kb-row kb-form-84">
            <div className="kb-form-66">
              <KBSelect
                defaultValue={'-1'}
                className="kb-form-group kb-div-48"
                isRequired={true}
                title="省份"
                field={province_id}
                arrayData={provinces}
              />
              <KBSelect
                defaultValue={'-1'}
                onChange={this.cityChange}
                className="kb-form-group kb-div-48"
                isRequiredR={true}
                title="城市"
                titleClass="label-rt"
                field={city_id}
                arrayData={cities}
              />
            </div>
            {districts.length ? (
              <KBSelect
                defaultValue={'-1'}
                className="kb-form-group kb-div-48"
                isRequiredR={true}
                titleClass="label-rt"
                title="区"
                field={district_id}
                arrayData={districts}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className="kb-row">
            <KBInput
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              onChange={this.changeAddress}
              field={physical_address}
              title="地址"
              hint={addressHint}
            />
          </div>
          <div className="kb-row kb-div-66">
            <KBInput {...halfClass} title="经度" field={longitude} />
            <KBInput
              {...halfClass}
              title="纬度"
              field={latitude}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-84">
            <div className="kb-form-66">
              <KBInput
                className="kb-form-group kb-div-48"
                field={telephone}
                title="电话"
              />
              <KBInput
                className="kb-form-group kb-div-48"
                inputClass="input-area"
                isRequiredR={true}
                titleClass="label-rt"
                field={size}
                title="面积"
              />
            </div>
            <KBInput
              className="kb-form-group kb-div-48"
              hint={'请输入数字，修改客户端的社区位置，数字越大排上面'}
              titleClass="label-rt"
              field={priority}
              title="排序位"
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="价格范围"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={'请输入价格范围的描述'}
              field={price_range}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue=" "
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="Location store"
              field={location_store_id}
              arrayData={location_stores}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="设备"
              selectedUsers={selectDevices}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_ids}
              placeholder="选择设备"
              multi={true}
              users={devices}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              hint="输入附近地铁站，主要公路等交通信息，如步行5分钟至10号线三元桥站"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={traffic}
              title="交通"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              hint="输入对周边环境的表述"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={neighborhood}
              title="周边"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
              placeholder="输入简介"
              title="简介"
            />
          </div>
        </fieldset>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入分店名称'
  }
  if (valid.required(values.prefix)) {
    errors.prefix = '请输入项目前缀'
  }
  if (values.telephone && valid.mobile(values.telephone)) {
    errors.telephone = '请输入正确的手机号码'
  }

  if (valid.required(values.size)) {
    errors.size = '请输入分店面积'
  }

  if (valid.required(values.physical_address)) {
    errors.physical_address = '请输入分店地址'
  }

  if (valid.required(values.province_id)) {
    errors.province_id = '请选择省份'
  }

  if (valid.required(values.city_id)) {
    errors.city_id = '请选择城市'
  }

  // if(valid.required(values.district_id)){
  //   errors.district_id = '请选择城市区域'
  // }

  if (values.priority && valid.Int(values.priority)) {
    errors.priority = '排序只能输入数字'
  }

  return errors
}

function mapStateToProps(state, props) {
  const devices = selectors.getDevices(state)
  const locationGroups = selectors.getLocationGroups(state)

  return {
    devices,
    locationGroups
  }
}

const formConfig = {
  form: 'LocationBaseForm',
  fields: [
    'name',
    'location_type',
    'serial_number',
    'prefix',
    'city_id',
    'traffic',
    'neighborhood',
    'longitude',
    'latitude',
    'district_id',
    'province_id',
    'trading_area_id',
    'physical_address',
    'size',
    'telephone',
    'description',
    'priority',
    'device_ids',
    'price_range',
    'location_store_id',
    'location_group_id',
    'trading_area'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(LocationBaseForm)
