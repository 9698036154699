import React from 'react'
import { connect } from 'react-redux'
import LocationSettingManageForm from './LocationSettingManageForm'
import LocationSettingFinanceForm from './LocationSettingFinanceForm'
import LocationSettingObserverForm from './LocationSettingObserverForm'
import LocationSettingRepairForm from './LocationSettingRepairForm'
import LocationSettingInvoiceVerifyForm from './LocationSettingInvoiceVerifyForm'
import LocationSettingContractForm from './LocationSettingContractForm'
import LocationSettingSalesForm from './LocationSettingSalesForm'
import LocationSettingVisitorForm from './LocationSettingVisitorForm'
import LocationSettingLandlordForm from './LocationSettingLandlordForm';
import LocationSettingLowPriceContractForm from './LocationSettingLowPriceContractForm'
import * as selectors from 'app/selectors'

var LocationSettingRole = React.createClass({
  render() {
    const { updateLocation, loc_id, locationSetting } = this.props
    if (!locationSetting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>修改权限</span>
            </div>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>管理公司</legend>
            <LocationSettingManageForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>财务负责人</legend>
            <LocationSettingFinanceForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>核销人员</legend>
            <LocationSettingInvoiceVerifyForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>观察人员</legend>
            <LocationSettingObserverForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>招商人员</legend>
            <LocationSettingSalesForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>物业负责人</legend>
            <LocationSettingRepairForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>分店合同审批人</legend>
            <LocationSettingContractForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>分店低价合同审批人</legend>
            <LocationSettingLowPriceContractForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>访客管理人员</legend>
            <LocationSettingVisitorForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>项目外部管理人员</legend>
            <LocationSettingLandlordForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  return {
    loc_id,
    locationSetting
  }
}

export default connect(mapStateToProps)(LocationSettingRole)
