// 账单url
export const getLocationInvoicesURL = (loc_id, invoice_id) => {
  if (invoice_id) {
    return `/admin/locations/${loc_id}/invoices/invoices_list/${invoice_id}`
  }

  return `/admin/locations/${loc_id}/invoices/invoices_list`
}

// 分店员工详情
export const getUserInfoUrl = (user_id, isSpace) => {
  return isSpace
    ? `/admin/memberships/memberManage/members/${user_id}`
    : `/admin/members/${user_id}`
}

// 会员详情
export const getMembershipMember = user_id => {
  return `/admin/memberships/memberManage/members/${user_id}`
}

// 公司详情
export const getUrlOfOrgDetailPage = (loc_id, org_id) => {
  return `/admin/locations/${loc_id}/organizations/${org_id}`
}

// 分店首页
export const getUrlOfLocationHomePage = loc_id => {
  return `/admin/locations/${loc_id}`
}

// 会议室详情
export const getMeetingRoomDetailPage = (loc_id, meeting_room_id) => {
  return `/admin/locations/${loc_id}/meeting/info/${meeting_room_id}`
}
