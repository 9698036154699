import React from 'react'
import { bindActionCreators } from 'redux'
import {
  KBFormError,
  KBButton,
} from 'components'
import { successState } from 'app/actions'
import { reduxForm } from 'redux-form'
import { Select } from 'antd'
import * as valid from 'utils/validate'

var GroupSetPreview = React.createClass({
  getInitialState() {
    return {
      emailList: [],
    }
  },

  updateSpace(model) {
    const { updateLocationSetting, setting_value } = this.props
    // model.send_message_to_operator = this.props.values.send_message_to_operator
    updateLocationSetting(
      {
        sales_lead_settings: model
      },
      { key: 'sales_lead_settings' }
    )
  },

  changeEmail(val) {
    this.props.fields.emails.onChange(val)
  },

  render() {
    const {
      fields: { emails },
      updateLocationSetting,
      setting,
      handleSubmit,
      submitting
    } = this.props
    const { emailList, emailValue } = this.state
    if (!setting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>预约参观设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateSpace)}>
            <KBFormError error={this.props.error} />
            <div className="kb-form-container" style={{ padding: 0 }}>
              <fieldset className="fieldset">
                <legend style={{position: 'relative'}}><span className="must-fill">*</span>邮件发送列表</legend>
                <div>
                  <Select
                    mode="tags"
                    showSearch
                    style={{ width: '100%' }}
                    onChange={this.changeEmail}
                    value={emails.value || []}
                  >
                  </Select>
                </div>
                {
                  emails.error && <p className="lr-error">{emails.error}</p>
                }
              </fieldset>

              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { groupSetting } = state
  let setting_value = groupSetting.sales_lead_settings
  return {
    initialValues: {
      ...setting_value
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (!values.emails || values.emails.length === 0) {
    errors.emails = '请输入邮件发送列表'
  }

  const allAreEmail = (values.emails || []).every(i => !valid.email(i))
  if (!allAreEmail) {
    errors.emails = '请验证输入的邮箱'
  }

  return errors
}

const formConfig = {
  form: 'GroupSetPreview',
  fields: ['emails'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetPreview)
