import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBButton, KBMorePhotoUpload } from 'components'

var VisitWelcomeSetSlides = React.createClass({
  save(model) {
    const { saveAndUpdate } = this.props
    model.slide_urls = (model.slide_urls && model.slide_urls.split(',')) || []
    return saveAndUpdate(model, 'slide_urls')
  },

  changeSlidesVisiblity(value) {
    const {
      fields: { hide_logo },
      saveAndUpdate
    } = this.props
    hide_logo.onChange(!value)
    saveAndUpdate({ hide_logo: !value }, 'hide_logo')
  },

  render() {
    const {
      fields: { slide_urls, hide_logo },
      submitting,
      handleSubmit,
      default_slide_urls
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.save)}>
        <div className="visit-setting-block">
          <div className="title-container">
            <div className="title-and-des">
              <div className="title">幻灯片展示</div>
              <div className="des">
                最多可以选择10个全屏幕图像来循环播放您的品牌或者其他图片。
              </div>
            </div>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
          <div className="kb-row f-left">
            <KBMorePhotoUpload
              defaultImages={default_slide_urls}
              uploadedText="上传图片"
              photoShow={true}
              sort={true}
              className="kb-form-group kb-form-alone"
              field={slide_urls}
              img_width={232}
              img_height={134}
              hint="我们建议上传1000px*1000px的正方形图片，如果您想自己设计图片请下载：PSD Sketch"
              text="上传图片"
              imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
              prefix={`images/locations/visit`}
              accept="image/*,.psd,.sketch"
              isPublic
            />
          </div>
          <div
            className="title-container border-top f-left"
            style={{ paddingTop: 20, width: '100%', marginBottom: 0 }}
          >
            <div className="title-and-des">
              <div className="title">隐藏标志图形</div>
              <div className="des">隐藏上传的图形或LOGO，仅显示幻灯片图片</div>
            </div>
            <div
              className="slide-btn"
              onClick={this.changeSlidesVisiblity.bind(null, hide_logo.value)}
            >
              <span
                className={`slide-bg ${
                  hide_logo.value ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { welcome } = props
  let { slide_urls, hide_logo } = welcome
  let default_slide_urls = slide_urls || []

  return {
    default_slide_urls,
    initialValues: {
      slide_urls: (slide_urls && slide_urls.join(',')) || '',
      hide_logo: hide_logo || false
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitWelcomeSetSlidesForm',
  fields: ['slide_urls', 'hide_logo'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitWelcomeSetSlides)
