import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBSelect } from 'components'
import { successState } from 'app/actions'
import { bindActionCreators } from 'redux'
import { INVOICE_CONFIRM } from 'app/constants'

var SpaceSettingInvoiceConfirm = React.createClass({
  displayName: 'SpaceSettingInvoiceConfirm',

  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    model.invoice_need_confirm =
      model.invoice_need_confirm == 'true' ? true : false
    updateCurrentSpace(
      {
        settings: JSON.stringify({
          invoice_settings: model
        })
      },
      {
        key: 'invoice_settings'
      }
    )
  },

  render() {
    const {
      fields: { invoice_need_confirm },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c39 kb-form-alone"
              field={invoice_need_confirm}
              title="账单确认核销"
              arrayData={INVOICE_CONFIRM}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.invoice_settings.invoice_need_confirm || 'false'
  return {
    initialValues: { invoice_need_confirm: setting_value }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingInvoiceConfirm',
  fields: ['invoice_need_confirm'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingInvoiceConfirm)
