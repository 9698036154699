import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { KBLoadingContainer, KBAvatar, KBPopover, KBTipsy } from 'components'
import { connect } from 'react-redux'
import { KBDropSelect } from 'components/plugins'
import { OPPORTUNITY_STATES } from 'app/constants'
import { InitialHighCharts } from 'components/mixins'
import { percent } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate
} from 'utils/kbData'

var StatisticCustomerState = React.createClass({
  displayName: 'StatisticCustomerState',
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate()
    return {
      filters: {
        from,
        to
      },
      dataOut: [],
      customerStateArray: []
    }
  },
  componentDidMount() {
    const { filters, dataOut } = this.state
    let Highcharts = this.getHighcharts()

    this.mounted = true
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: null
        },
        tooltip: {
          pointFormat: '所占比率: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  'black'
              }
            }
          }
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters) {
    api.getCustomerStateStatistic().then(({ json }) => {
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    let dataOut = []

    var array = json

    array.forEach(json => {
      let t = {}
      t.name = OPPORTUNITY_STATES[json.state]
      t.value = json.customer_count
      dataOut.push(t)
    })
    if (this.mounted) {
      this.setState({
        customerStateArray: dataOut
      })
    }
    this.parseHightChartData(dataOut)
  },

  parseHightChartData(data) {
    let colors = this.getHighChartColor()
    let Highcharts = this.getHighcharts()
    let dataOut = []
    let count = 0

    data.forEach(json => {
      count = count + json.value
    })
    let dt = {
      colorByPoint: true,
      size: '80%',
      innerSize: '60%',
      data: []
    }
    data.forEach((json, index) => {
      let y = parseFloat(percent(count, json.value))
      let name = json.name
      let color = colors[index]

      dt.name = name
      dt.data.push({
        name: name,
        y: y,
        color: color
      })
    })
    dataOut.push(dt)

    if (this.mounted) {
      this.setState({
        dataOut
      })
    }

    this.fillChart(dataOut)
  },

  fillChart(dataOut) {
    this.setSerierDate(dataOut)
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },
  render: function() {
    const { customerStateArray } = this.state
    return (
      <article className="clear-fix">
        <div className="chart-title">
          <span>CRM各状态客户分布</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <ul className="chart-data-list f-left">
          {customerStateArray &&
            customerStateArray.map((obj, index) => {
              return (
                <li key={index}>
                  <span className="f-left">{obj.name}</span>
                  <span className="f-right">{obj.value}</span>
                </li>
              )
            })}
        </ul>
        <div
          ref="highcharts"
          className="f-right"
          style={{ width: 560, height: 350 }}
        />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticCustomerState)
