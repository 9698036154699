import React from 'react'
import SpacesCrmForm from './SpacesCrmForm'
import SpacesCrmSources from './SpacesCrmSources'
import SpacesCrmDynamicAttributes from './SpacesCrmDynamicAttributes'

var SpacesSettingCrm = React.createClass({
  displayName: 'SpacesSettingCrm',
  render() {
    if (!this.props.setting.crm_settings) {
      return null
    }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>CRM基本设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>渠道来源</legend>
            <SpacesCrmSources />
          </fieldset>
        </div>
        {/* <div className='kb-form-container'>
          <fieldset className='fieldset'>
            <legend>自定义字段</legend>
            <SpacesCrmDynamicAttributes />
          </fieldset>
        </div> */}
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>通知提醒</legend>
            <SpacesCrmForm {...this.props} />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpacesSettingCrm
