import React from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPopover,
  KBTipsy,
  KBStatisticNumber,
  KBTableWithoutData,
  KBDropdown,
  KBPagination,
  KBRangeDate
} from 'components'
import { KBDayPicker } from 'components/views'
import { api } from 'app/services'
import { locationActions, apiCallFailure } from 'app/actions'
import { INVOICES_TYPE } from 'app/constants'
import { connect } from 'react-redux'
import * as selector from 'app/selectors'
import {
  canAccessLocationRoles,
  canAccessLocation,
  canAccessLocationFinance,
  canAccessLocationRepairOnly,
  canAdminSpace,
  canAccessLocationVisitor
} from 'app/reducers/role'
import moment from 'moment'
import SelectOrganizationForm from './SelectOrganizationForm'
import LocationSendMessageForm from './LocationSendMessageForm'
import _ from 'lodash'
import {
  isWooSpace,
  formatOnlyHmStageEN,
  paginate,
  getServiceErrorMessage,
  formatYearDayEN,
  fmoney,
  formatYearDaySOL,
  formatOnlyMinuteEN,
  formatTimeNumber,
  fillNoDataDate,
  kbCloseDropDown
} from 'utils/kbUtil'
import * as urls from 'utils/kbUrl'
import { getUserInfoUrl } from 'utils/kbUrl'
import { Chart, Geom, Axis, Tooltip, Coord, Legend, Guide } from 'bizcharts'
import DataSet from '@antv/data-set'
import { KBNoData } from 'components'

const INVOICE_STATIS_TYPE = [
  {
    id: 'count',
    name: '账单数'
  },
  {
    id: 'amount',
    name: '账单金额'
  }
]

const DROP_DOWNS = ['visitDateDropDown', 'meetingDateDropDown']

var LocationHomePage = React.createClass({
  displayName: 'LocationHomePage',

  getInitialState() {
    return {
      allNotifications: [],
      timeArrayedNotifications: [],
      currentNotiPage: 1,
      pagination: {},
      notisLoading: true,
      statisticEnterAndArchive: [],
      statisticVisitor: [],
      statisticMeetingRoom: [],
      statisticInvoiceCount: [],
      statisticInvoiceAmount: [],
      currentInvoiceStaType: 'amount',
      statisticContract: [],
      invoices: [],
      invoicesLoading: false,
      invoicePanigation: null,
      reservations: [],
      meetingReservationLoading: false,
      reservationPanigation: null,
      visitRecords: [],
      visitRecordsLoading: false,
      visitRecordPanigation: null,
      location: {}
    }
  },

  componentDidMount() {
    this.getLocationInfo()
    this.getLocationInvoices()
    this.changeDayPickerMeeting({
      from: new Date(),
      to: moment()
        .add(1, 'weeks')
        .toDate()
    })
    this.changeDayPickerVisit({
      from: new Date(),
      to: moment()
        .add(1, 'months')
        .toDate()
    })
    this.getOrgEnterAndArchiveStatistic()
    this.getVisitorStatistic()
    this.getMeetingRoomUsageStatistic()
    this.getInvoiceOverdueCountStatistic()
    this.getSubExpiringCountStatistic()
  },

  getOrgEnterAndArchiveStatistic() {
    const { loc_id } = this.props

    let params = {
      location_id: loc_id,
      cycle: 'monthly',
      start_date: moment()
        .subtract(11, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: moment()
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    api.getHomeOrgEnterAndArchiveStatistic(params).then(
      json => {
        const resData = json.response.body
        resData.entered = fillNoDataDate(
          resData.entered,
          params.start_date,
          params.end_date,
          params.cycle
        )
        resData.archived = fillNoDataDate(
          resData.archived,
          params.start_date,
          params.end_date,
          params.cycle
        )
        const statisticOrgEnter = this.formatStatisticToArray(
          resData.entered || {},
          'month'
        )
        const statisticOrgArchive = this.formatStatisticToArray(
          resData.archived || {},
          'month'
        )
        const statisticEnterAndArchive = statisticOrgEnter
          .map(s => {
            s.type = '入驻'
            return s
          })
          .concat(
            statisticOrgArchive.map(s => {
              s.type = '毕业'
              return s
            })
          )

        this.setState({
          statisticEnterAndArchive
        })
      },
      error => {
        return this.handleServerError(error)
      }
    )
  },

  getVisitorStatistic() {
    const { loc_id } = this.props

    let params = {
      location_id: loc_id,
      cycle: 'daily',
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD')
    }
    api.getHomeVisitorStatistic(params).then(
      json => {
        let resData = fillNoDataDate(
          json.response.body,
          params.start_date,
          params.end_date,
          params.cycle
        )
        const statisticVisitor = this.formatStatisticToArray(
          resData || {},
          'day'
        )
        this.setState({
          statisticVisitor
        })
      },
      error => {
        return this.handleServerError(error)
      }
    )
  },

  getMeetingRoomUsageStatistic() {
    const { loc_id } = this.props

    api
      .getHomeMeetingRoomUsageStatistic({
        location_id: loc_id,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment()
          .add(7, 'days')
          .format('YYYY-MM-DD')
      })
      .then(
        json => {
          const statisticMeetingRoom =
            Math.round(
              (Object.values(json.response.body || {})[0] || 0) * 10000
            ) / 100
          this.setState({
            statisticMeetingRoom
          })
        },
        error => {
          return this.handleServerError(error)
        }
      )
  },

  getInvoiceOverdueCountStatistic() {
    const { loc_id } = this.props

    let params = {
      location_ids: String(loc_id),
      cycle: 'monthly',
      start_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: moment()
        .add(4, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    api.getHomeInvoiceOverdueCountStatistic(params).then(
      json => {
        let resData = json.response.body
        resData.invoice_count = fillNoDataDate(
          resData.invoice_count,
          params.start_date,
          params.end_date,
          params.cycle
        )
        resData.total_amount = fillNoDataDate(
          resData.total_amount,
          params.start_date,
          params.end_date,
          params.cycle
        )
        const statisticInvoiceCount = this.formatStatisticToArray(
          resData.invoice_count || {},
          'month'
        )
        const statisticInvoiceAmount = this.formatStatisticToArray(
          resData.total_amount || {},
          'month'
        )
        this.setState({
          statisticInvoiceCount,
          statisticInvoiceAmount
        })
      },
      error => {
        return this.handleServerError(error)
      }
    )
  },

  getSubExpiringCountStatistic() {
    const { loc_id } = this.props

    let params = {
      location_id: loc_id,
      cycle: 'monthly',
      start_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: moment()
        .add(11, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    api.getHomeSubExpiringCountStatistic(params).then(
      json => {
        let resData = fillNoDataDate(
          json.response.body,
          params.start_date,
          params.end_date,
          params.cycle
        )
        const statisticContract = this.formatStatisticToArray(
          resData || {},
          'month'
        )
        this.setState({
          statisticContract
        })
      },
      error => {
        return this.handleServerError(error)
      }
    )
  },

  getLocationInfo() {
    const { getLocationInfo, loc_id } = this.props

    api.getLocationInfo(loc_id).then(
      json => {
        getLocationInfo.success(json)
        this.setState({
          location: json.response.body
        })
        //   this.getLocationNotifications()
      },
      errors => {
        getLocationInfo.failure(errors)
      }
    )
  },

  getLocationInvoices(filters) {
    const { loc_id } = this.props

    this.setState({
      invoicesLoading: true
    })

    api
      .getInvoices({
        location_id: loc_id,
        per_page: 3,
        page: 1,
        ...filters,
        status: 'unpaid,partially_paid'
      })
      .then(
        json => {
          const { invoices } = json.response.body
          let invoicePanigation = paginate(json.response)
          this.setState({
            invoices,
            invoicePanigation
          })
        },
        error => {
          return this.handleServerError(error)
        }
      )
      .finally(() => {
        this.setState({
          invoicesLoading: false
        })
      })
  },

  getLocationReservations(filters) {
    const { loc_id } = this.props

    this.setState({
      meetingReservationLoading: true
    })

    api
      .getReservations({
        location_id: loc_id,
        per_page: 5,
        page: 1,
        ...filters
      })
      .then(
        json => {
          const reservations = json.response.body
          let reservationPanigation = paginate(json.response)
          this.setState({
            reservations,
            reservationPanigation
          })
        },
        error => {
          return this.handleServerError(error)
        }
      )
      .finally(() => {
        this.setState({
          meetingReservationLoading: false
        })
      })
  },

  getLocationVisitRecords(filters) {
    const { loc_id } = this.props

    this.setState({
      visitRecordsLoading: true
    })

    api
      .getVisitors({
        location_id: loc_id,
        request_type: 'request',
        per_page: 4,
        page: 1,
        ...filters
      })
      .then(
        json => {
          const visitRecords = json.response.body
          let visitRecordPanigation = paginate(json.response)
          this.setState({
            visitRecords,
            visitRecordPanigation
          })
        },
        error => {
          return this.handleServerError(error)
        }
      )
      .finally(() => {
        this.setState({
          visitRecordsLoading: false
        })
      })
  },

  getLocationNotifications(page = 1) {
    const { loc_id } = this.props
    this.setState({ notisLoading: true })
    api
      .getSystemNotifications({ location_id: loc_id, page, per_page: 30 })
      .then(
        ({ json, response }) => {
          const { allNotifications } = this.state
          let pagination = paginate(response)
          let notificaitons = json || []
          let newAllNotifications = allNotifications.concat(notificaitons)
          let timeArrayedNotifications = this.getTimeArrayedNotis(
            newAllNotifications
          )
          this.setState({
            timeArrayedNotifications,
            allNotifications: newAllNotifications,
            currentNotiPage: page,
            pagination
          })
        },
        error => {}
      )
      .finally(() => {
        this.setState({
          notisLoading: false
        })
      })
  },

  getTimeArrayedNotis(notificaitons) {
    if (!notificaitons || !notificaitons.length) {
      return []
    }
    notificaitons.map(n => {
      n.created_day = moment
        .unix(n.created_at)
        .calendar(null, {
          sameDay: '[今天]',
          lastDay: '[昨天]',
          lastWeek: 'dddd',
          sameElse: 'YYYY-MM-DD'
        })
      return n
    })
    return Object.values(_.groupBy(notificaitons, n => n.created_day))
  },

  formatStatisticToArray(obj, date_type) {
    if (!obj) {
      return []
    }
    let keys = Object.keys(obj)
    let values = Object.values(obj)
    let newArr = []
    keys.map((key, index) => {
      let singleValue = {}
      let date = ''
      let formatedDateStr = key
      if (date_type === 'month') {
        date = moment(formatedDateStr).month() + 1 + '月'
      } else if (date_type === 'day') {
        date =
          moment(formatedDateStr).month() +
          1 +
          '-' +
          moment(formatedDateStr).date()
      } else {
        date = formatedDateStr
      }
      singleValue.item = date
      singleValue.count = values[index]
      singleValue.key = formatedDateStr
      newArr.push(singleValue)
      return key
    })
    return newArr
  },

  handleServerError(error) {
    const { apiCallFailureAction } = this.props

    return apiCallFailureAction({
      status: 'error',
      message: getServiceErrorMessage(error)
    })
  },

  locationNotificationsJsx() {
    const {
      timeArrayedNotifications,
      currentNotiPage,
      pagination,
      notisLoading
    } = this.state
    const { current_page, total_pages } = pagination
    const styleNoData = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      paddingTop: 40,
      cursor: 'default'
    }

    return (
      <div style={{ padding: '0 20px', position: 'relative' }}>
        {timeArrayedNotifications.length > 0 ? (
          timeArrayedNotifications.map(notis => {
            let day = notis[0].created_day
            return (
              <div className="notification-day-block">
                <div className="day-time">{day}</div>
                {notis.map(noti => {
                  const { owner, summary, created_at } = noti
                  const time = formatOnlyHmStageEN(created_at)
                  return (
                    <div className="noti-block">
                      <div className="noti-header vf-center">
                        <KBTipsy content={owner && owner.name}>
                          <Link
                            to={owner && getUserInfoUrl(owner.id)}
                            className="color-link"
                            style={{ display: 'initial', marginRight: '5px' }}
                          >
                            <KBAvatar user={owner} size={26} />
                          </Link>
                        </KBTipsy>
                        <div className="noti-centent">{summary}</div>
                      </div>
                      <div className="noti-time">{time}</div>
                    </div>
                  )
                })}
              </div>
            )
          })
        ) : notisLoading ? null : (
          <KBNoData tipMessage="暂时没有动态哦" outterStyle={styleNoData} />
        )}

        {notisLoading ? (
          <KBLoadingContainer
            loading={notisLoading}
            innerStyle={{ margin: '40px auto 40px' }}
          />
        ) : null}

        {!notisLoading &&
        current_page &&
        total_pages &&
        current_page < total_pages ? (
          <div className="add-more">
            <div
              className="add-more-btn"
              onClick={e => {
                e.stopPropagation()
                this.getLocationNotifications(currentNotiPage + 1)
              }}
            >
              加载更多
            </div>
          </div>
        ) : null}
      </div>
    )
  },

  _toOppInfo(invoice) {
    const { routerActions, location } = this.props
    routerActions.push(urls.getLocationInvoicesURL(location.id, invoice.id))
  },

  _toInvoiceList() {
    const { routerActions, location } = this.props
    routerActions.push(urls.getLocationInvoicesURL(location.id))
  },

  addTeam(type) {
    const { loc_id, routerActions } = this.props
    return routerActions.push(
      `/admin/locations/${loc_id}/new_organization?add_type=${type}`
    )
  },

  addSubscriptions() {
    const { loc_id } = this.props
    KBPopover.show(
      <SelectOrganizationForm loc_id={loc_id} callback={this.toCreateSub} />
    )
  },

  addMember() {
    const { loc_id } = this.props
    KBPopover.show(
      <SelectOrganizationForm loc_id={loc_id} callback={this.toAddMember} />
    )
  },

  toTasks() {
    const { loc_id } = this.props
    const { routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/tasks/repair/type/my?task=new`
    )
  },

  toAppointment() {
    const { loc_id } = this.props
    const { routerActions } = this.props
    routerActions.push(`/admin/locations/${loc_id}/meeting?appointment=new`)
  },

  toAddMember(org_id) {
    const { loc_id } = this.props
    const { routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/organizations/${org_id}?member=new`
    )
  },

  toCreateSub(org_id) {
    const { loc_id } = this.props
    const { routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/new`
    )
  },

  sendMessage() {
    const { loc_id, location } = this.props
    KBPopover.show(
      <LocationSendMessageForm loc_id={loc_id} location={location} />
    )
  },

  financialEnterRateChart() {
    const { location } = this.props
    const {
      statistic: { financial_entering_rate }
    } = location
    const { DataView } = DataSet
    const { Html } = Guide
    const data = [
      { item: '已入驻', count: financial_entering_rate || 0 },
      {
        item: '未入驻',
        count: Math.round((100 - (financial_entering_rate || 0)) * 100) / 100
      }
    ]
    const dv = new DataView()
    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    })

    return (
      <div style={{ width: '40%', height: '100%' }}>
        <Chart height={251} data={dv} padding={20} forceFit>
          <Coord type={'theta'} radius={0.85} innerRadius={0.75} />
          <Axis name="percent" />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Guide>
            <Html
              position={['50%', '50%']}
              html={`<div style="color:#354052;font-size:32px;font-weight:500;text-align: center;width: 10em;">${financial_entering_rate ||
                0}<span style="color:#999;font-size:14px">%</span></div>`}
              alignX="middle"
              alignY="middle"
            />
          </Guide>
          <Geom
            type="intervalStack"
            position="percent"
            color={['item', ['#42a2ff', '#EFF3F6']]}
            tooltip={[
              'item*percent',
              (item, percent) => {
                percent = percent * 100 + '%'
                return {
                  name: item,
                  value: percent
                }
              }
            ]}
            style={{ lineWidth: 1, stroke: '#fff' }}
          ></Geom>
        </Chart>
        <div className="flex-center" style={{ paddingBottom: 45 }}>
          <span
            style={{
              display: 'inline-block',
              width: 14,
              height: 14,
              marginRight: 10,
              border: '3px solid #279BF3',
              borderRadius: '50%',
              backgroundColor: '#fff'
            }}
          />
          <span>财务入驻率</span>
        </div>
      </div>
    )
  },

  locationInfo() {
    const { location, loc_id, isManager, entering_rate_calc_type } = this.props
    const {
      statistic: {
        desks_count,
        occupied_desks_count,
        assigned_private_office_count,
        private_office_count
      }
    } = location
    return (
      <div style={{ position: 'relative', display: 'flex' }}>
        <div style={{ width: '60%', padding: 45 }}>
          <div className="chart-out_box">
            <div className="chart-box">
              <KBStatisticNumber
                number={private_office_count || 0}
                name="总办公区数"
                unit="个"
              />
            </div>
            <div className="chart-box">
              <KBStatisticNumber
                number={assigned_private_office_count || 0}
                name="已出租办公区数"
                unit="个"
              />
            </div>
          </div>
          <div className="chart-out_box">
            <div className="chart-box">
              {isManager ? (
                <Link to={`/admin/locations/${loc_id}/areas/desks/list`}>
                  <KBStatisticNumber
                    number={
                      entering_rate_calc_type == 'by_desk'
                        ? desks_count || 0
                        : parseFloat(desks_count || 0).toFixed(2)
                    }
                    name={
                      entering_rate_calc_type == 'by_desk'
                        ? '总工位数'
                        : '总面积数'
                    }
                    unit={entering_rate_calc_type == 'by_desk' ? '个' : '平米'}
                  />
                </Link>
              ) : (
                <KBStatisticNumber
                  number={
                    entering_rate_calc_type == 'by_desk'
                      ? desks_count || 0
                      : parseFloat(desks_count || 0).toFixed(2)
                  }
                  name={
                    entering_rate_calc_type == 'by_desk'
                      ? '总工位数'
                      : '总面积数'
                  }
                  unit={entering_rate_calc_type == 'by_desk' ? '个' : '平米'}
                />
              )}
            </div>
            <div className="chart-box">
              <KBStatisticNumber
                number={
                  entering_rate_calc_type == 'by_desk'
                    ? occupied_desks_count || 0
                    : parseFloat(occupied_desks_count || 0).toFixed(2)
                }
                unit={entering_rate_calc_type == 'by_desk' ? '个' : '平米'}
                name={
                  entering_rate_calc_type == 'by_desk'
                    ? '出租工位数'
                    : '出租面积数'
                }
              />
            </div>
          </div>
        </div>
        {this.financialEnterRateChart()}
      </div>
    )
  },

  quickButtons() {
    const { isManager, isFinance, isSales, space_id, isUnionLoc } = this.props

    return (
      <div style={{ padding: 20 }}>
        {(isManager && !isWooSpace(space_id)) || (!isManager && isSales) ? (
          <a
            className="short-btn"
            onClick={this.addTeam.bind(null, 'shortterm')}
          >
            <div>
              <i className="iconfont icon-team_big" />
              <span>添加联办公司</span>
            </div>
          </a>
        ) : (
          ''
        )}
        {isUnionLoc && (isManager || isFinance || isSales) ? (
          <a
            className="short-btn"
            onClick={this.addTeam.bind(null, 'longterm')}
          >
            <div>
              <i className="iconfont icon-team_big" />
              <span>添加写字楼公司</span>
            </div>
          </a>
        ) : (
          ''
        )}
        {isManager ? (
          <a className="short-btn" onClick={this.addMember}>
            <div>
              <i className="iconfont icon-people_big" />
              <span>添加新员工</span>
            </div>
          </a>
        ) : (
          ''
        )}
        {isManager ? (
          <a className="short-btn" onClick={this.toAppointment}>
            <div>
              <i className="iconfont icon-Room_big" />
              <span>预约场地</span>
            </div>
          </a>
        ) : (
          ''
        )}
        {isManager ? (
          <a className="short-btn" onClick={this.toTasks}>
            <div>
              <i className="iconfont icon-repair_big" />
              <span>新增报修</span>
            </div>
          </a>
        ) : (
          ''
        )}
        {isManager ? (
          <a className="short-btn" onClick={this.sendMessage}>
            <div>
              <i className="iconfont icon-imagges" />
              <span>发送消息</span>
            </div>
          </a>
        ) : (
          ''
        )}
      </div>
    )
  },

  orgEnterAndLeaveChart() {
    const { statisticEnterAndArchive } = this.state
    const cols = {
      count: {
        type: 'linear',
        tickInterval: 30
      }
    }

    if (statisticEnterAndArchive.every(s => !s.count)) {
      return <KBNoData outterStyle={{ margin: '90px 0 80px' }} />
    }

    return (
      <div style={{ padding: 20 }}>
        <Chart
          height={330}
          data={statisticEnterAndArchive}
          scale={cols}
          padding="auto"
          forceFit
        >
          <Axis name="item" />
          <Axis name="count" visible={false} />
          <Legend />
          <Tooltip crosshairs={{ type: 'line' }} />
          <Geom type="area" position="item*count" color="type" />
          <Geom type="line" position="item*count" size={2} color="type" />
        </Chart>
      </div>
    )
  },

  contractExpireChart() {
    const { statisticContract } = this.state
    const cols = {
      count: { tickInterval: 20 }
    }

    if (statisticContract.every(s => !s.count)) {
      return <KBNoData outterStyle={{ margin: '90px 0 80px' }} />
    }

    return (
      <div style={{ padding: 20 }}>
        <Chart
          height={300}
          data={statisticContract}
          scale={cols}
          padding="auto"
          forceFit
        >
          <Axis name="item" />
          <Axis name="count" visible={false} />
          <Tooltip crosshairs={{ type: 'y' }} />
          <Geom
            type="interval"
            position="item*count"
            tooltip={[
              'item*count*key',
              (item, count, key) => {
                return {
                  name: '数量',
                  value: count,
                  title: moment(key).format('YYYY年MM月')
                }
              }
            ]}
          />
        </Chart>
      </div>
    )
  },

  moreOps() {},

  toInvoiceInfo(invoice) {
    const { loc_id, routerActions } = this.props

    routerActions.push(
      `/admin/locations/${loc_id}/invoices/invoices_list/${invoice.id}`
    )
  },

  invoicesTable() {
    const { invoices, invoicePanigation, invoicesLoading } = this.state

    return (
      <KBLoadingContainer
        loading={invoicesLoading}
        style={{ height: 348 }}
        innerStyle={{ margin: '0 auto', paddingTop: 100 }}
      >
        <table className="content-table edit-table" style={{ marginTop: 0 }}>
          <thead style={{ whiteSpace: 'nowrap' }}>
            <tr>
              <th>账单类型</th>
              <th>公司名称</th>
              <th>应付金额</th>
              <th>待付金额</th>
              <th>到期日期</th>
              <th className="t-right">实际付款日</th>
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={invoices && invoices.length > 0}
            tableHeadNum="7"
            tipMessage="暂无缴费账单"
          >
            {invoices.map(data => {
              let overDueDays = moment().diff(moment(data.due_date), 'days')
              let obligation =
                Math.round(data.total_amount * 100) / 100 -
                Math.round(data.paid_amount * 100) / 100
              return (
                <tr onClick={this.toInvoiceInfo.bind(null, data)}>
                  <td>{INVOICES_TYPE[data.invoice_type]}</td>
                  <td style={{ position: 'static', verticalAlign: 'middle' }}>
                    <span>
                      {data.sales_customer && data.sales_customer.name}
                      {data.status === 'overdue' && overDueDays > 0 ? (
                        <span style={{ color: '#dd5a55', marginLeft: 5 }}>
                          {'(已逾期' + overDueDays + '天)'}
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                    <div style={{ marginTop: 10 }} className="color-nine">
                      <span>
                        账单:&nbsp;
                        <KBTipsy content={data.serial_number}>
                          <span>{data.serial_number.slice(0, 6) + '...'}</span>
                        </KBTipsy>
                      </span>
                    </div>
                  </td>
                  <td>¥{fmoney(data.total_amount, 2)}</td>
                  <td className={obligation < 0 ? 'color-red' : ''}>
                    {fmoney(obligation, 2)}
                  </td>
                  <td>
                    {data.due_date ? formatYearDaySOL(data.due_date) : '-'}
                  </td>
                  <td className="t-right">
                    {data.last_paid_at
                      ? formatYearDaySOL(data.last_paid_at)
                      : '-'}
                  </td>
                </tr>
              )
            })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={invoicePanigation}
          isSimple={true}
          posRight={true}
          withoutUrl={true}
          pageClickCallback={page => {
            this.getLocationInvoices({ page })
          }}
          outterStyle={{ marginTop: 23, paddingRight: 15 }}
        />
      </KBLoadingContainer>
    )
  },

  invoicesExpireChart() {
    const {
      statisticInvoiceCount,
      statisticInvoiceAmount,
      currentInvoiceStaType
    } = this.state
    let isCount = currentInvoiceStaType === 'count'
    let statisticInvoice = isCount
      ? statisticInvoiceCount
      : statisticInvoiceAmount
    let name = isCount ? '数量' : '金额'

    if (statisticInvoice.every(s => !s.count)) {
      return <KBNoData outterStyle={{ margin: '90px 0 80px' }} />
    }

    return (
      <div style={{ padding: 20 }}>
        <Chart height={330} data={statisticInvoice} padding="auto" forceFit>
          <Axis name="item" />
          <Axis name="count" />
          <Tooltip crosshairs={{ type: 'y' }} />
          <Geom
            type="interval"
            position="item*count"
            tooltip={[
              'item*count*key',
              (item, count, key) => {
                return {
                  name,
                  value: isCount ? count : `¥ ${fmoney(count, 2)}`,
                  title: moment(key).format('YYYY年MM月')
                }
              }
            ]}
          />
        </Chart>
      </div>
    )
  },

  meetingRoomTable() {
    const {
      reservations,
      reservationPanigation,
      meetingReservationLoading
    } = this.state

    return (
      <KBLoadingContainer
        loading={meetingReservationLoading}
        style={{ height: 348 }}
        innerStyle={{ margin: '0 auto', paddingTop: 100 }}
      >
        <table
          className="content-table"
          style={{ whiteSpace: 'nowrap', marginTop: 0 }}
        >
          <thead>
            <tr>
              <th>预订人</th>
              <th>联系电话</th>
              <th>使用日期</th>
              <th>开始时间</th>
              <th>结束时间</th>
              <th className="t-right">会议室名称</th>
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={reservations && reservations.length > 0}
            tableHeadNum="6"
            tipMessage="暂无会议室预订记录"
          >
            {reservations.map(reservation => {
              return (
                <tr>
                  <td>
                    <div className="vf-center">
                      <KBAvatar
                        style={{ display: 'inline-block', marginRight: '5px' }}
                        imgStyle={{ marginRight: 5 }}
                        user={reservation.host}
                        size={20}
                      />
                      <Link
                        className="color-link"
                        to={getUserInfoUrl(
                          reservation.host && reservation.host.id
                        )}
                      >
                        {(reservation.host && reservation.host.name) || ''}
                      </Link>
                    </div>
                  </td>
                  <td>{reservation.contacts_phone}</td>
                  <td>
                    {reservation.start_at
                      ? formatYearDayEN(reservation.start_at)
                      : '-'}
                  </td>
                  <td>
                    {reservation.start_at
                      ? formatOnlyMinuteEN(reservation.start_at)
                      : '-'}
                  </td>
                  <td>
                    {reservation.end_at
                      ? formatOnlyMinuteEN(reservation.end_at)
                      : '-'}
                  </td>
                  <td className="t-right">{reservation.area_name}</td>
                </tr>
              )
            })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={reservationPanigation}
          isSimple={true}
          posRight={true}
          withoutUrl={true}
          pageClickCallback={page => {
            this.getLocationReservations({ page })
          }}
          outterStyle={{ marginTop: 23, paddingRight: 15 }}
        />
      </KBLoadingContainer>
    )
  },

  meetingRoomUsageChart() {
    const { statisticMeetingRoom } = this.state
    const { DataView } = DataSet
    const { Html } = Guide
    const data = [
      { item: '已使用', count: statisticMeetingRoom || 0 },
      {
        item: '未使用',
        count: Math.round((100 - (statisticMeetingRoom || 0)) * 100) / 100
      }
    ]
    const dv = new DataView()
    dv.source(data).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    })

    return (
      <div style={{ padding: 20 }}>
        <Chart height={251} data={dv} padding={10} forceFit>
          <Coord type={'theta'} radius={0.85} innerRadius={0.75} />
          <Axis name="percent" />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Guide>
            <Html
              position={['50%', '50%']}
              html={`<div style="color:#354052;font-size:32px;font-weight:500;text-align: center;width: 10em;">${statisticMeetingRoom ||
                0}<span style="color:#999;font-size:14px">%</span></div>`}
              alignX="middle"
              alignY="middle"
            />
          </Guide>
          <Geom
            type="intervalStack"
            position="percent"
            color={['item', ['#42a2ff', '#EFF3F6']]}
            tooltip={[
              'item*percent',
              (item, percent) => {
                percent = percent * 100 + '%'
                return {
                  name: item,
                  value: percent
                }
              }
            ]}
            style={{ lineWidth: 1, stroke: '#fff' }}
          ></Geom>
        </Chart>
        <div className="flex-center" style={{ paddingBottom: 13 }}>
          <span
            style={{
              display: 'inline-block',
              width: 14,
              height: 14,
              marginRight: 10,
              border: '3px solid #279BF3',
              borderRadius: '50%',
              backgroundColor: '#fff'
            }}
          />
          <span>会议室使用率</span>
        </div>
      </div>
    )
  },

  visitorConfirmRefresh(cVisitor, type) {
    const { visitRecords } = this.state
    let new_visitor_records = visitRecords

    let index = visitRecords.findIndex(record => {
      return record.id === cVisitor.id
    })
    if (type && type === 'delete') {
      new_visitor_records.splice(index, 1)
    } else {
      new_visitor_records.splice(index, 1, cVisitor)
    }
    this.setState({
      visitRecords: new_visitor_records
    })
  },

  signInVisitor(visitor) {
    KBPopover.plugins.confirm('访客签到', `访客 ${visitor.name} 是否已签到?`, {
      confirm: () => {
        api.visitorSignIn(visitor.id).then(
          json => {
            let aVisitor = json.json
            this.visitorConfirmRefresh(aVisitor)
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  signOutVisitor(visitor) {
    KBPopover.plugins.confirm('访客签退', `是否确认签退访客 ${visitor.name}?`, {
      confirm: () => {
        api.visitorSignOut(visitor.id).then(
          json => {
            let aVisitor = json.json
            this.visitorConfirmRefresh(aVisitor)
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  deleteVisitor(visitor) {
    KBPopover.plugins.confirm('删除访客', `是否确认删除访客 ${visitor.name}?`, {
      confirm: () => {
        api.deleteVisitor(visitor.id).then(
          json => {
            let aVisitor = json.json
            this.visitorConfirmRefresh(aVisitor, 'delete')
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  visitorTable() {
    const {
      visitRecords,
      visitRecordPanigation,
      visitRecordsLoading
    } = this.state
    const { isManager, isvisitorManager } = this.props

    return (
      <KBLoadingContainer
        loading={visitRecordsLoading}
        style={{ height: 348 }}
        innerStyle={{ margin: '0 auto', paddingTop: 100 }}
      >
        <table
          className="content-table"
          style={{ whiteSpace: 'nowrap', marginTop: 0 }}
        >
          <thead>
            <tr>
              <th>访客姓名</th>
              <th>预约到访时间</th>
              <th className="t-right">操作</th>
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={visitRecords && visitRecords.length > 0}
            tableHeadNum="3"
            tipMessage="暂无访客"
          >
            {visitRecords.map(record => {
              return (
                <tr>
                  <td>
                    <div className="vf-center">
                      <KBAvatar
                        user={record}
                        imgStyle={{ marginRight: 5 }}
                        style={{ display: 'inline-block', marginRight: '5px' }}
                        size={30}
                      />
                      <span>{record.name || ''}</span>
                    </div>
                  </td>
                  <td>
                    <span>{`${formatYearDayEN(
                      record.expect_arrival_date
                    )} ${formatTimeNumber(record.expect_arrival_time)}`}</span>
                    {record.signed_out_at || record.signed_in_at ? (
                      <span className="span-type-smail-i m-left-sm">
                        {record.signed_out_at
                          ? '已签退'
                          : record.signed_in_at
                          ? '已签到'
                          : ''}
                      </span>
                    ) : null}
                  </td>
                  <td className="t-right">
                    {isManager || isvisitorManager ? (
                      <KBDropdown>
                        <KBDropdown.KBDropdownTrigger>
                          <div className="select-drop_down">
                            <div className="select-dropDown_left">更多操作</div>
                            <div className="select-dropDown_right">
                              <i className="iconfont icon-arrow_drop_down_circle" />
                            </div>
                          </div>
                        </KBDropdown.KBDropdownTrigger>
                        <KBDropdown.KBDropdownContent
                          style={{ left: '50%', transform: 'translateX(-50%)' }}
                        >
                          <div className="user-dropdown">
                            {!record.signed_in_at ? (
                              <a
                                onClick={e => {
                                  e.stopPropagation()
                                  this.signInVisitor(record)
                                }}
                              >
                                签到
                              </a>
                            ) : null}
                            {record.signed_in_at && !record.signed_out_at ? (
                              <a
                                onClick={e => {
                                  e.stopPropagation()
                                  this.signOutVisitor(record)
                                }}
                              >
                                签退
                              </a>
                            ) : null}
                            {
                              <a
                                onClick={e => {
                                  e.stopPropagation()
                                  this.deleteVisitor(record)
                                }}
                              >
                                删除
                              </a>
                            }
                          </div>
                        </KBDropdown.KBDropdownContent>
                      </KBDropdown>
                    ) : null}
                  </td>
                </tr>
              )
            })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={visitRecordPanigation}
          isSimple={true}
          posRight={true}
          withoutUrl={true}
          pageClickCallback={page => {
            this.getLocationVisitRecords({ page })
          }}
          outterStyle={{ marginTop: 23, paddingRight: 15 }}
        />
      </KBLoadingContainer>
    )
  },

  visitorChart() {
    const { statisticVisitor } = this.state
    const cols = {
      count: { tickInterval: 20 }
    }

    if (statisticVisitor.every(s => !s.count)) {
      return <KBNoData outterStyle={{ margin: '90px 0 80px' }} />
    }

    return (
      <div style={{ padding: 20 }}>
        <Chart
          height={330}
          data={statisticVisitor}
          scale={cols}
          padding="auto"
          forceFit
        >
          <Axis name="item" />
          <Axis name="count" visible={false} />
          <Tooltip crosshairs={{ type: 'y' }} />
          <Geom
            type="interval"
            position="item*count"
            tooltip={[
              'item*count*key',
              (item, count, key) => {
                return {
                  name: '数量',
                  value: count,
                  title: moment(key).format('YYYY年MM月DD日')
                }
              }
            ]}
          />
        </Chart>
      </div>
    )
  },

  changeDayPickerMeeting(range) {
    this.meetingDateDropDown && this.meetingDateDropDown.hide()
    let params = {
      start_at: formatYearDayEN(range.from),
      end_at: formatYearDayEN(range.to)
    }
    this.getLocationReservations(params)
    this.setState({
      meetingDateFilter: range,
      meetingReservationLoading: true
    })
  },

  changeDayPickerVisit(range) {
    this.visitDateDropDown && this.visitDateDropDown.hide()
    this.getLocationVisitRecords({
      expect_visit_date_start: formatYearDayEN(range.from),
      expect_visit_date_end: formatYearDayEN(range.to)
    })
    this.setState({
      visitDateFilter: range,
      visitRecordsLoading: true
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const {
      loc_id,
      isLocationManager,
      isvisitorManager,
      isManager,
      isFinance
    } = this.props
    const {
      meetingDateFilter,
      visitDateFilter,
      currentInvoiceStaType,
      location
    } = this.state
    const isLocationPublic = location.public
    return (
      <KBLoadingContainer loading={!location}>
        <section className="kb-content">
          <div
            className="nav-section-container-multi f-left"
            style={{ width: '100%' }}
          >
            {isLocationPublic ? null : location.name ? (
              <div className="kb-tip-warning">
                <div className="icon">
                  <i className="iconfont icon-hint" />
                </div>
                <div className="msg">
                  该分店已隐藏，外部用户不可见
                  {isLocationManager ? (
                    <span>
                      ，点击这里&nbsp;—>&nbsp;
                      <Link
                        to={`/admin/locations/${loc_id}/settings`}
                        style={{ color: '#fff', textDecoration: 'underline' }}
                      >
                        查看设置
                      </Link>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : null}
            {/* 第1行 =================================================================== */}
            {(isManager || isLocationManager) && (
              <div className="flex-row-right-270 m-bottom-sm">
                <div className="location-box multi-section-each m-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    分店信息
                  </header>
                  {this.locationInfo()}
                </div>
                <div className="location-box multi-section-each m-bottom-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    快捷方式
                  </header>
                  {this.quickButtons()}
                </div>
              </div>
            )}

            {/* 第2行 =============================================================================== */}
            {(isManager || isLocationManager) && (
              <div className="flex-row-evenly m-bottom-sm">
                <div className="location-box multi-section-each m-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    公司入驻毕业统计图
                  </header>
                  {this.orgEnterAndLeaveChart()}
                </div>
                <div className="location-box multi-section-each m-bottom-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    合同到期预测
                  </header>
                  {this.contractExpireChart()}
                </div>
              </div>
            )}

            {/* 第3行 =============================================================================== */}
            {(isManager || isLocationManager || isFinance) && (
              <div className="flex-row-evenly m-bottom-sm">
                <div className="location-box multi-section-each m-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    待缴费账单
                  </header>
                  {this.invoicesTable()}
                </div>
                <div className="location-box multi-section-each m-bottom-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    到期账单预测
                    <div
                      className="time-filter m-none f-right"
                      style={{
                        fontSize: 14,
                        fontWeight: 'normal',
                        transform: 'translateY(3px)'
                      }}
                    >
                      {INVOICE_STATIS_TYPE.map(type => {
                        return (
                          <span
                            className={
                              currentInvoiceStaType === type.id ? 'active' : ''
                            }
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({ currentInvoiceStaType: type.id })
                            }}
                          >
                            {type.name}
                          </span>
                        )
                      })}
                    </div>
                  </header>
                  {this.invoicesExpireChart()}
                </div>
              </div>
            )}

            {/* 第4行 =============================================================================== */}
            {(isManager || isLocationManager) && (
              <div className="flex-row-right-270 m-bottom-sm">
                <div className="location-box multi-section-each m-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    会议室
                    <span className="f-right" style={{ fontSize: 14 }}>
                      <KBRangeDate
                        from={
                          (meetingDateFilter && meetingDateFilter.from) || ''
                        }
                        to={(meetingDateFilter && meetingDateFilter.to) || ''}
                        title="使用日期"
                        callback={this.changeDayPickerMeeting}
                        closeClick={this.closeDropDowns}
                        dropDown_name="meetingDateDropDown"
                        ref={ref => {
                          this.meetingDateDropDown = ref
                        }}
                        leftNone={true}
                      />
                    </span>
                  </header>
                  {this.meetingRoomTable()}
                </div>
                <div className="location-box multi-section-each m-bottom-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    当前会议室使用率
                  </header>
                  {this.meetingRoomUsageChart()}
                </div>
              </div>
            )}

            {/* 第5行 =============================================================================== */}
            {(isManager || isvisitorManager || isLocationManager) && (
              <div className="flex-row-evenly m-bottom-sm">
                <div className="location-box multi-section-each m-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    访客
                    <span className="f-right" style={{ fontSize: 14 }}>
                      <KBRangeDate
                        from={(visitDateFilter && visitDateFilter.from) || ''}
                        to={(visitDateFilter && visitDateFilter.to) || ''}
                        title="到访日期"
                        callback={this.changeDayPickerVisit}
                        closeClick={this.closeDropDowns}
                        dropDown_name="visitDateDropDown"
                        ref={ref => {
                          this.visitDateDropDown = ref
                        }}
                        leftNone={true}
                      />
                    </span>
                  </header>
                  {this.visitorTable()}
                </div>
                <div className="location-box multi-section-each m-bottom-none">
                  <header className="border-bottom" style={{ padding: 20 }}>
                    访客到访统计
                  </header>
                  {this.visitorChart()}
                </div>
              </div>
            )}
          </div>
        </section>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  const { id } = params
  let locations = selector.getLocations(state)
  //   var location = selector.getCurrentLocation(state, id)
  let location = locations.filter(loc => loc.id && loc.id == id)[0]

  let space_id = Object.keys(state.entities.spaces || {})[0]
  const isUnionLoc =
    location.location_type === 'self_office' ||
    location.location_type === 'sales_office'

  let team = (location && location.team) || []
  let isAdmin = canAdminSpace(user)
  let isManager = canAccessLocation(user, id)
  const isLocationManager =
    canAccessLocationRoles(user, id, ['admin', 'location_manager']) || isAdmin
  //
  let isFinance = canAccessLocationFinance(user, id)
  //let isObserver = canAccessLocationObserver(user, id)
  let isRepair = canAccessLocationRepairOnly(user, id)
  let isSales = canAccessLocationRoles(user, id, ['sales'])
  let isvisitorManager = canAccessLocationVisitor(user, id)
  if (team) {
    team.sort((a, b) => {
      if (b.is_manager) {
        return 1
      } else {
        return -1
      }
    })
  }

  location = Object.assign(
    {},
    {
      statistic: {
        space_desks_count: 0,
        organizations_count: 0,
        space_organizations_count: 0,
        org_members_count: 0,
        archived_count: 0,
        desks_count: 0,
        today_reservations: 0
      }
    },
    location
  )

  const locationSettings = selector.getLocationSetting(state, id) || {}
  const location_settings = locationSettings.location_settings || {}
  const entering_rate_calc_type =
    location_settings.entering_rate_calc_type || 'by_desk'

  return {
    team,
    loc_id: id,
    location,
    user,
    isManager,
    isFinance,
    isRepair,
    isSales,
    space_id,
    entering_rate_calc_type,
    isLocationManager,
    isUnionLoc,
    isvisitorManager
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationInfo: bindActionCreators(locationActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationHomePage)
