import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import {
  DEFAULT_VISITOR_TYPE,
  VISIT_AUDIT_STATES,
  VISIT_AUDIT_STATES_OBJ,
  VISIT_STATUS_ARRAY,
  VISIT_STATUS_OBJ,
  VISIT_STATUS_COLOR
} from 'app/constants'
import * as selectors from 'app/selectors'
import { canAccessLocationVisitor, canAccessLocation, canAdminLocationWeChat } from 'app/reducers/role'
import {
  KBPopover,
  KBLoadingContainer,
  KBAvatar,
  KBDropdown,
  KBTableWithoutData,
  KBPagination,
  KBButton,
  KBRangeDate,
  KBCheckBox
} from 'components'
import { PopoverShowPicture } from 'components/views'
import { connect } from 'react-redux'
import {
  formatHoursMinutesEN,
  formatTimeNumber,
  formatYearDayEN,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown,
  paginate,
  formatMinuteSecondEN,
  getServiceErrorMessage
} from 'utils/kbUtil'
import moment from 'moment'
import AddVisitRequestForm from './AddVisitRequestForm'
import { apiCallFailure, successState } from 'app/actions'
import { KBPopoverConfirm } from 'components/tools'
import { get } from 'lodash'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'
const DROP_DOWNS = ['filterDate', 'statusDropdown', 'inviteStatusDropdown']

var Visits = React.createClass({
  displayName: 'Visits',
  getInitialState() {
    const { filters, isInvite } = this.props
    return {
      visitor_records: [],
      searchTime: filters.expect_visit_date || moment().toDate(),
      request_type: isInvite ? 'invite' : 'request',
      filters,
      pagination: null,
      loading: true,
      recordParams: {},
      allCheckedBtn: false,
      selectedUsers: [],
      phone_num: ''
    }
  },

  componentDidMount() {
    const { searchTime } = this.state
    this.mounted = true
    this.changeDayPicker(searchTime)
    // this.state.allCheckedBtn = false
  },

  componentWillReceiveProps(nextProps) {
    this.state.allCheckedBtn = false
    this.state.selectedUsers = []
    if (this.props.isInvite !== nextProps.isInvite) {
      let request_type = nextProps.isInvite ? 'invite' : 'request'
      // this._loadData({ request_type })
      this.setState({
        request_type
      })
      setTimeout(this.showFiltered)
      return
    }
    if (this.props.page !== nextProps.page) {
      const { filters } = nextProps
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
      return
    }
  },

  componentWillUnmount() {
    this.mounted = false
    // this.state.allCheckedBtn = false
  },

  _loadData(page, params, isLoading = true) {
    const { loc_id, query, routerActions, isInvite } = this.props
    const { filters } = this.state
    let is_invite = params.request_type === 'invite'
    // delete params.request_type
    // delete query.request_type
    delete query.audit_status
    this.setState({
      loading: isLoading,
      recordParams: params
    })

    const per_page = 10
    let pageObj = { per_page, page }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      ...pageObj,
      ...filters
    })
    api
      .getVisitors({ location_id: loc_id, ...params, ...query, ...pageObj })
      .then(json => {
        if (!this.mounted) {
          return
        }
        const visitor_records = json.json.sort((a, b) => {
          return (
            moment(b.expect_visit_date).format('X') -
            moment(a.expect_visit_date).format('X')
          )
        })
        routerActions.replace(
          `/admin/locations/${loc_id}/visit${is_invite || isInvite ? '/invite' : ''
          }${queryFiltersStr}`
        )
        let pagination = paginate(json.response)
        this.setState({
          visitor_records,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, loading: true })

    // force refresh
    this._refresh()
  },

  _refresh() {
    this['filterDate'] && this['filterDate'].hide()
    this['statusDropdown'] && this['statusDropdown'].hide()
    this['inviteStatusDropdown'] && this['inviteStatusDropdown'].hide()
    this.setState({ phone_num: '' })
    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.setState({ filters: {}, searchTime: '', loading: true })
    // force refresh
    this._refresh()
  },

  showFiltered() {
    const { filters, request_type } = this.state
    const { isInvite } = this.props
    if (isInvite) {
      delete filters.audit_status
    }

    let params = Object.assign({}, filters)
    this._loadData(filters.page || 1, { ...params, request_type })
    this.setState({
      ...params
    })
  },

  changeDayPicker(date) {
    if (!date) {
      return null
    }
    this.changeVisitDateRange({
      from: moment(date)
        .startOf('day')
        .toDate(),
      to: moment(date)
        .endOf('day')
        .toDate()
    })
  },

  changeVisitDateRange(range) {
    if (!(range.from && range.to)) {
      return null
    }
    this.filterDate.hide()
    this._setFilter({
      expect_visit_date_start: formatMinuteSecondEN(range.from),
      expect_visit_date_end: formatMinuteSecondEN(range.to)
    })
    if (this.mounted) {
      this.setState({
        searchTime: formatYearDayEN(range.from),
        loading: true
      })
    }
  },

  visitorConfirmRefresh(cVisitor, type) {
    const { visitor_records } = this.state
    let new_visitor_records = visitor_records
    let index = visitor_records.findIndex(record => record.id === cVisitor.id)
    if (type && type === 'delete') {
      new_visitor_records.splice(index, 1)
    } else {
      new_visitor_records.splice(index, 1, cVisitor)
    }
    this.setState({
      visitor_records: new_visitor_records
    })
  },

  signInVisitor(visitor) {
    const { apiCallFailureActions } = this.props
    let isclick = true
    KBPopover.plugins.confirm('访客签到', `访客 ${visitor.name} 是否已签到?`, {
      confirm: () => {
        if (isclick) {
          isclick = false
          api.visitorSignIn(visitor.id).then(
            json => {
              if (!this.mounted) {
                return
              }
              let aVisitor = json.json
              this.visitorConfirmRefresh(aVisitor)
              KBPopover.close()
            },
            errors => {
              apiCallFailureActions({
                status: 'error',
                message: getServiceErrorMessage(errors)
              })
              KBPopover.close()
            }
          )
        } else {
          apiCallFailureActions({
            status: 'error',
            message: '请勿重复点击，请耐心等待'
          })
        }
      }
    })
  },

  signOutVisitor(visitor) {
    const { apiCallFailureActions } = this.props
    let isclick = true
    KBPopover.plugins.confirm('访客签退', `是否确认签退访客 ${visitor.name}?`, {
      confirm: () => {
        if (isclick) {
          isclick = false
          api.visitorSignOut(visitor.id).then(
            json => {
              if (!this.mounted) {
                return
              }
              let aVisitor = json.json
              this.visitorConfirmRefresh(aVisitor)
              KBPopover.close()
            },
            errors => {
              apiCallFailureActions({
                status: 'error',
                message: getServiceErrorMessage(errors)
              })
              KBPopover.close()
            }
          )
        } else {
          apiCallFailureActions({
            status: 'error',
            message: '请勿重复点击，请耐心等待'
          })
        }
      }
    })
  },

  approveVisitor(visitor) {
    const { space_id, loc_id, apiCallFailureActions } = this.props
    let isclick = true
    KBPopover.plugins.confirm(
      '访客审核通过',
      `是否确认访客 ${visitor.name} 通过审核?`,
      {
        confirm: () => {
          if (isclick) {
            isclick = false
            api
              .visitorApprove(visitor.id, {
                'X-space-id': space_id,
                location_id: loc_id,
                id: visitor.id
              })
              .then(
                json => {
                  let aVisitor = json.json
                  this.visitorConfirmRefresh(aVisitor)
                  KBPopover.close()
                },
                errors => {
                  apiCallFailureActions({
                    status: 'error',
                    message: getServiceErrorMessage(errors)
                  })
                  KBPopover.close()
                }
              )
          } else {
            apiCallFailureActions({
              status: 'error',
              message: '请勿重复点击，请耐心等待'
            })
          }
        }
      }
    )
  },

  disapproveVisitor(visitor) {
    const { space_id, loc_id, apiCallFailureActions } = this.props
    let isclick = true
    KBPopover.plugins.confirm(
      '访客审核拒绝',
      `是否确认拒绝访客 ${visitor.name} 的访问?`,
      {
        confirm: () => {
          if (isclick) {
            isclick = false
            api
              .visitorDisapprove(visitor.id, {
                'X-space-id': space_id,
                location_id: loc_id,
                id: visitor.id
              })
              .then(
                json => {
                  if (!this.mounted) {
                    return
                  }
                  let aVisitor = json.json
                  this.visitorConfirmRefresh(aVisitor)
                  KBPopover.close()
                },
                errors => {
                  apiCallFailureActions({
                    status: 'error',
                    message: getServiceErrorMessage(errors)
                  })
                  KBPopover.close()
                }
              )
          } else {
            apiCallFailureActions({
              status: 'error',
              message: '请勿重复点击，请耐心等待'
            })
          }
        }
      }
    )
  },
  addBlacklist(visitor) {
    const { loc_id, successActions, apiCallFailureActions } = this.props
    api
      .postVisitorBlacklists({
        location_id: loc_id,
        phone_num: visitor.phone_num
      })
      .then(
        json => {
          this._loadData(this.props.page, { ...this.state.filters })
          successActions({ message: '用户已加入黑名单！' })
        },
        errors => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(errors)
          })
        }
      )
  },
  delBlacklist(visitor) {
    const { successActions, apiCallFailureActions } = this.props
    api.delVisitorBlacklists(visitor.visitor_blacklist_id).then(
      json => {
        this._loadData(this.props.page, { ...this.state.filters })
        successActions({ message: '用户已移出黑名单！' })
      },
      errors => {
        apiCallFailureActions({
          status: 'error',
          message: getServiceErrorMessage(errors)
        })
      }
    )
  },
  deleteVisitor(visitor) {
    const { apiCallFailureActions } = this.props
    let isclick = true
    KBPopover.plugins.confirm('删除访客', `是否确认删除访客 ${visitor.name}?`, {
      confirm: () => {
        if (isclick) {
          isclick = false
          api.deleteVisitor(visitor.id).then(
            json => {
              if (!this.mounted) {
                return
              }
              let aVisitor = json.json
              this.visitorConfirmRefresh(aVisitor, 'delete')
              KBPopover.close()
            },
            errors => {
              apiCallFailureActions({
                status: 'error',
                message: getServiceErrorMessage(errors)
              })
              KBPopover.close()
            }
          )
        } else {
          apiCallFailureActions({
            status: 'error',
            message: '请勿重复点击，请耐心等待'
          })
        }
      }
    })
  },
  successRequest(users) {
    const {
      loc_id,
      apiCallFailureActions,
      successActions,
      space_id
    } = this.props
    const ids = users.map(u => u.id)
    let isclick = true
    KBPopoverConfirm({
      name: '访客审核通过',
      context: '是否确认通过 ' + users.length + ' 个访客',
      callback: () => {
        if (isclick) {
          isclick = false
          return api
            .visitorBatchApprove({
              'X-space-id': space_id,
              location_id: loc_id,
              visitor_ids: ids
            })
            .then(
              json => {
                if (!this.mounted) {
                  return
                }
                this.state.selectedUsers = []
                let aVisitor = json.json
                // this.bathVisitorConfirmRefresh(aVisitor)
                this._loadData(this.props.page, { ...this.state.filters })
                KBPopover.close()
              },
              error => {
                apiCallFailureActions({
                  status: 'error',
                  message: getServiceErrorMessage(error)
                })
                KBPopover.close()
              }
            )
        } else {
          apiCallFailureActions({
            status: 'error',
            message: '请勿重复点击，请耐心等待'
          })
        }
      }
    })
  },
  rejectRequest(users) {
    const {
      loc_id,
      apiCallFailureActions,
      successActions,
      space_id
    } = this.props
    const ids = users.map(u => u.id)
    let isclick = true
    KBPopoverConfirm({
      name: '访客审核拒绝',
      context: '是否确认拒绝 ' + users.length + ' 个访客',
      callback: () => {
        if (isclick) {
          isclick = false
          return api
            .visitorBatchDispprove({
              'X-space-id': space_id,
              location_id: loc_id,
              visitor_ids: ids
            })
            .then(
              json => {
                if (!this.mounted) {
                  return
                }
                this.state.selectedUsers = []
                let aVisitor = json.json
                this._loadData(this.props.page, { ...this.state.filters })
                KBPopover.close()
              },
              error => {
                apiCallFailureActions({
                  status: 'error',
                  message: getServiceErrorMessage(error)
                })
                KBPopover.close()
              }
            )
        } else {
          apiCallFailureActions({
            status: 'error',
            message: '请勿重复点击，请耐心等待'
          })
        }
      }
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  toUserInfo(record) {
    const { routerActions } = this.props

    if (record.user_id) {
      routerActions.push(`/admin/members/${record.user_id}`)
    }
  },

  addVisitRequestForm() {
    KBPopover.show(
      <AddVisitRequestForm
        loadData={this._loadData}
        recordParams={this.state.recordParams}
        loc_id={this.props.loc_id}
      />
    )
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },
  checkAllUsers() {
    const { allCheckedBtn, visitor_records } = this.state
    let isAllUsersChecked_now = !allCheckedBtn
    let unselected =
      visitor_records &&
      visitor_records.length &&
      visitor_records.filter(data => !(data.approved_at || data.disapproved_at))

    let users_now = unselected
    if (!isAllUsersChecked_now) {
      users_now = []
    }
    this.setState({
      selectedUsers: users_now,
      allCheckedBtn: isAllUsersChecked_now
    })
  },
  changeUser(checked, data) {
    var users = Object.assign([], this.state.selectedUsers)
    const { visitor_records } = this.state
    let unselected =
      visitor_records &&
      visitor_records.length &&
      visitor_records.filter(data => !(data.approved_at || data.disapproved_at))
    if (checked) {
      users.push(data)
      // 当选中的 user 逐个增加时的判断
      if (users.length == unselected.length) {
        this.setState({
          selectedUsers: users,
          allCheckedBtn: true
        })
      } else {
        this.setState({
          selectedUsers: users,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = users.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    users.splice(removeIndex, 1)
    // 当选中的 user 逐个减少时的判断
    if (users.length == unselected.length) {
      this.setState({
        selectedUsers: users,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectedUsers: users,
        allCheckedBtn: false
      })
    }
  },
  isBatchAudit() {
    const { isLocManager, isLocVisitor } = this.props
    const { selectedUsers } = this.state
    if (selectedUsers.length === 0 || (!isLocManager && !isLocVisitor)) {
      return null
    }
    return (
      <div className="invoice-table-upon" style={{ marginTop: 10 }}>
        <button
          className="c-btn-success m-none"
          onClick={() => {
            this.successRequest(selectedUsers)
          }}
        >
          通过审核
        </button>
        <button
          className="c-btn-red m-none"
          onClick={() => {
            this.rejectRequest(selectedUsers)
          }}
        >
          审核拒绝
        </button>
      </div>
    )
  },
  phoneNumChange(e) {
    this.setState({ phone_num: e.target.value })
  },

  selPhoneNum() {
    this._setFilter({ phone_num: this.state.phone_num })
  },

  exportVisitorRecord() {
    KBPopoverConfirm({
      name: '导出访客记录',
      context: '您即将导出当前列表筛选条件下的访客记录，是否确认导出？',
      callback: () => {
        //   const { location_id, apiCallFailureActions, filters } = this.props
        //   const params = Object.assign({}, filters, location_id ? { location_id } : {})
        //   params.as_json = false
        //   return api.postMonthlyInvoicesStatistics(params).then(() => {
        //     this.toReportCenter && this.toReportCenter()
        //     KBPopover.close()
        //   }, error => {
        //     apiCallFailureActions({
        //       status: 'error',
        //       message: error.message || '服务器出问题了，请稍后重试哦~'
        //     })
        //     KBPopover.close()
        //   })
      }
    })
  },

  render() {
    const {
      visitor_records,
      loading,
      searchTime,
      pagination,
      filters,
      selectedUsers,
      phone_num
    } = this.state
    const {
      isInvite,
      isLocManager,
      loc_id,
      isLocVisitor,
      visitor_require_approval,
      isWeChatManger
    } = this.props
    const { expect_visit_date_start, expect_visit_date_end } = filters
    const today = moment().toDate()
    const tomorrow = moment()
      .add(1, 'days')
      .toDate()
    const yesterday = moment()
      .subtract(1, 'days')
      .toDate()
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')
    let unselected =
      visitor_records &&
      visitor_records.length &&
      visitor_records.filter(data => !(data.approved_at || data.disapproved_at))
    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>{isInvite ? '邀请记录' : '访问列表'}</span>
            </div>
            <KBButton
              className="certain-btn f-right"
              onClick={() => {
                this._setFilter({ audit_status: '', phone_num: '' })
                // this._loadData(this.props.page, { ...filters })
              }}
            >
              刷新访客列表
            </KBButton>
            {!visitor_require_approval && (
              <div
                className="f-right"
                style={{ marginTop: 8, marginRight: 10 }}
              >
                自动通过开关已打开，打开后提交的所有申请都会自动通过（黑名单除外）
              </div>
            )}
            {/* <div className='f-right' style={{ paddingRight: 20 }}>
              <button className='c-btn-secondary' onClick={this.exportVisitorRecord}>导出访客记录</button>
            </div> */}
            {/* {
              !isInvite && (isLocManager|| isLocVisitor) ? <KBButton className='bordered-btn f-right' onClick={ () => { this.addVisitRequestForm() } }>添加访客</KBButton> : null
            } */}
          </header>

          <div style={{ padding: '20px 30px 0' }}>
            <div className="time-filter" style={{ margin: '0 20px 0 0' }}>
              <button
                className={
                  searchTime && moment(searchTime).isSame(yesterday, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, yesterday)}
              >
                昨天
              </button>
              <button
                className={
                  searchTime && moment(searchTime).isSame(today, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, today)}
              >
                今天
              </button>
              <button
                className={
                  searchTime && moment(searchTime).isSame(tomorrow, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, tomorrow)}
              >
                明天
              </button>
            </div>
            <KBRangeDate
              from={expect_visit_date_start || ''}
              to={expect_visit_date_end || ''}
              title="选择到访日期"
              callback={this.changeVisitDateRange}
              closeClick={this.closeDropDowns}
              dropDown_name="filterDate"
              ref={ref => {
                this.filterDate = ref
              }}
              leftNone={true}
            />
            {isInvite ? (
              <div className="select-progress">
                <KBDropdown
                  ref={ref => {
                    this.inviteStatusDropdown = ref
                  }}
                >
                  <KBDropdown.KBDropdownTrigger>
                    <div
                      className="task-status-title m-left-sm"
                      onClick={this.closeDropDowns.bind(
                        null,
                        'inviteStatusDropdown'
                      )}
                    >
                      <span>
                        {this.state.filters.status
                          ? VISIT_STATUS_OBJ[this.state.filters.status]
                          : '选择状态'}
                      </span>
                      <span className="task-deg" />
                    </div>
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent>
                    <div>
                      <ul className="task-type">
                        <li onClick={() => this._setFilter({ status: 'all' })}>
                          全部
                        </li>
                        {VISIT_STATUS_ARRAY.map((state, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() =>
                                this._setFilter({ status: state.id })
                              }
                            >
                              <span>{state.name}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
              </div>
            ) : (
                <div className="select-progress">
                  <KBDropdown
                    ref={ref => {
                      this.statusDropdown = ref
                    }}
                  >
                    <KBDropdown.KBDropdownTrigger>
                      <div
                        className="task-status-title m-left-sm"
                        onClick={this.closeDropDowns.bind(null, 'statusDropdown')}
                      >
                        <span>
                          {this.state.filters.audit_status
                            ? VISIT_AUDIT_STATES_OBJ[
                            this.state.filters.audit_status
                            ]
                            : '选择审核状态'}
                        </span>
                        <span className="task-deg" />
                      </div>
                    </KBDropdown.KBDropdownTrigger>
                    <KBDropdown.KBDropdownContent>
                      <div>
                        <ul className="task-type">
                          <li
                            onClick={() => this._setFilter({ audit_status: '' })}
                          >
                            全部
                        </li>
                          {VISIT_AUDIT_STATES.map((state, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() =>
                                  this._setFilter({ audit_status: state.id })
                                }
                              >
                                <span>{state.name}</span>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </KBDropdown.KBDropdownContent>
                  </KBDropdown>
                </div>
              )}
            <div
              className="add-staff-component"
              style={Object.assign(
                {},
                { width: '300px', display: 'inline-block', marginRight: 40 }
              )}
            >
              <input
                type="text"
                maxLength={19}
                placeholder="请输入访客手机号"
                value={phone_num || ''}
                onChange={this.phoneNumChange}
                onFocus={null}
                onBlur={null}
              />
              <div className="currency-symbol" onClick={this.selPhoneNum}>
                搜索
              </div>
            </div>
            <br />
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>

          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading}>
              {isInvite ? null : this.isBatchAudit()}
              <table className="content-table edit-table">
                <thead>
                  <tr>
                    {isInvite ? null : unselected.length === 0 ||
                      visitor_records.length === 0 ? (
                        <div />
                      ) : (
                        <th>
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={visitor_records}
                            checked={this.state.allCheckedBtn}
                            callback={this.checkAllUsers}
                          />
                        </th>
                      )}
                    <th>姓名</th>
                    <th>face++ 照片</th>
                    {/* <th>类型</th> */}
                    <th>公司</th>
                    <th>自测体温</th>
                    <th>是否不适</th>
                    <th>是否去过疫区</th>
                    <th>是否离开本市</th>
                    <th>是否在黑名单中</th>
                    <th>预约到达时间</th>
                    {/* <th>签到时间</th> */}
                    {/* <th>签退时间</th> */}
                    <th>访客电话</th>
                    {/* {isInvite ? null : <th>审核状态</th>} */}
                    <th>审核状态</th>
                    {isInvite ? <th>状态</th> : null}
                    <th />
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={visitor_records.length > 0}
                  tableHeadNum="12"
                  tipMessage="暂无访客"
                >
                  {visitor_records &&
                    visitor_records.length > 0 &&
                    visitor_records.map((record, index) => {
                      const v_type = record.visitor_type || ''
                      const body_temperature = get(
                        record,
                        'survey.body_temperature',
                        'N/A'
                      )
                      const cough_or_high_temperature = get(
                        record,
                        'survey.cough_or_high_temperature',
                        false
                      )
                      const been_to_hubei = get(
                        record,
                        'survey.been_to_hubei',
                        false
                      )
                      const leave_homebase = get(
                        record,
                        'survey.leave_homebase',
                        false
                      )
                      const is_blacklisted = get(
                        record,
                        'is_blacklisted',
                        false
                      )
                      const blacklisted_organizations = get(
                        record,
                        'blacklisted_organizations',
                        []
                      )
                      const blacklisted_org_str = []
                      if (
                        blacklisted_organizations &&
                        blacklisted_organizations.length > 0
                      ) {
                        blacklisted_organizations.map(org => {
                          blacklisted_org_str.push(org.full_name)
                        })
                      }
                      return (
                        <tr
                          key={index}
                          style={{ background: '#fff', cursor: 'default' }}
                        >
                          {isInvite ? null : record.approved_at ? (
                            <div />
                          ) : record.disapproved_at ? (
                            <div />
                          ) : (
                                <td>
                                  <KBCheckBox
                                    style={{ marginTop: 2 }}
                                    data={record}
                                    checked={
                                      (selectedUsers &&
                                        selectedUsers.length &&
                                        selectedUsers.find(data => {
                                          return data.id === record.id
                                        })) ||
                                      false
                                    }
                                    callback={this.changeUser}
                                  />
                                </td>
                              )}
                          <td>
                            {/* <KBAvatar user={record} imgStyle={{ marginRight: 5 }} style={{ display: 'inline-block', marginRight: '5px' }} size={30} /> */}
                            <span
                              className={record.user_id ? 'a-hover' : ''}
                              onClick={() => {
                                this.toUserInfo(record)
                              }}
                            >
                              {record.name || ''}
                            </span>
                          </td>
                          <td>
                            {record.face_photo_uploaded ?
                            (
                              record.face_photo_url?
                              <KBDownloadPrivacy preview={url => this.clickPhoto(url)} url={record.face_photo_url}>
                                {/* <img
                                  className="face-img-in-table"
                                  onClick={this.clickPhoto.bind(
                                    null,
                                    record.face_photo_url
                                  )}
                                  src={record.face_photo_url}
                                  alt="Face++ Img"
                                /> */}
                                <span className='color-link' style={{textDecoration: 'underline', cursor: 'pointer'}}>点击预览</span>
                              </KBDownloadPrivacy>
                              :'已上传'
                            )
                             : (
                                '未上传'
                              )}
                          </td>
                          {/* <td>{record.is_blacklisted ?'黑名单' : DEFAULT_VISITOR_TYPE[v_type] || v_type}</td> */}
                          <td style={{ width: 150, whiteSpace: 'pre-wrap' }}>
                            {record.company || ''}
                          </td>
                          <td>{body_temperature}</td>
                          <td>
                            {cough_or_high_temperature == 'true' ? '是' : '否'}
                          </td>
                          <td>{been_to_hubei == 'true' ? '是' : '否'}</td>
                          <td>{leave_homebase == 'true' ? '是' : '否'}</td>
                          {/* <td>{!is_blacklisted ? `是(${blacklisted_org_str.toString()})` : '否'}</td> */}
                          <td>
                            <div>
                              <div>{is_blacklisted ? '是' : '否'}</div>
                              <div
                                style={{
                                  width: 100,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {is_blacklisted &&
                                  `(${blacklisted_org_str.toString()})`}
                              </div>
                            </div>
                          </td>
                          <td>
                            {`${formatYearDayEN(record.expect_arrival_date)}`}
                          </td>
                          {/* <td>{formatHoursMinutesEN(record.signed_in_at)}</td> */}
                          {/* <td>{formatHoursMinutesEN(record.signed_out_at)}</td> */}
                          <td>{record.phone_num || ''}</td>
                            <td>
                              {record.approved_at ? (
                                <span>已通过</span>
                              ) : record.disapproved_at ? (
                                <span>已拒绝</span>
                              ) : isLocManager || isLocVisitor || isWeChatManger ? (
                                <KBDropdown>
                                  <KBDropdown.KBDropdownTrigger>
                                    <div className="color-link">
                                      <span style={{ marginRight: 10 }}>
                                        未审核
                                      </span>
                                      <i className="iconfont icon-arrow_drop_down_circle" />
                                    </div>
                                  </KBDropdown.KBDropdownTrigger>
                                  <KBDropdown.KBDropdownContent
                                    style={{
                                      left: '50%',
                                      transform: 'translateX(-50%)'
                                    }}
                                  >
                                    <div className="user-dropdown">
                                      <a
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.approveVisitor(record)
                                        }}
                                      >
                                        通过审核
                                      </a>
                                      <a
                                        style={{ padding: '0 20px' }}
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.disapproveVisitor(record)
                                        }}
                                      >
                                        拒绝审核
                                      </a>
                                    </div>
                                  </KBDropdown.KBDropdownContent>
                                </KBDropdown>
                              ) : (
                                      <span>未审核</span>
                                    )}
                            </td>
                          {isInvite ? (
                            <td>
                              <span
                                style={{
                                  color: VISIT_STATUS_COLOR[record.state]
                                }}
                              >
                                {VISIT_STATUS_OBJ[record.state]}
                              </span>
                            </td>
                          ) : null}
                          <td>
                            {isLocManager || isLocVisitor ? (
                              <KBDropdown>
                                <KBDropdown.KBDropdownTrigger>
                                  <div
                                    className="select-drop_down"
                                    style={{ width: 80 }}
                                  >
                                    <div
                                      className="select-dropDown_left"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        flex: 2,
                                        justifyContent: 'center'
                                      }}
                                    >
                                      更多
                                    </div>
                                    <div
                                      className="select-dropDown_right"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        flex: 1
                                      }}
                                    >
                                      <i
                                        className="iconfont icon-arrow_drop_down_circle"
                                        style={{ marginRight: 0 }}
                                      />
                                    </div>
                                  </div>
                                </KBDropdown.KBDropdownTrigger>
                                <KBDropdown.KBDropdownContent
                                  style={{
                                    left: '50%',
                                    transform: 'translateX(-50%)'
                                  }}
                                >
                                  <div className="user-dropdown">
                                    {/* {
                                        !record.signed_in_at ? <a onClick={(e) => { e.stopPropagation(); this.signInVisitor(record) }}>签到</a> : null
                                      }
                                      {
                                        record.signed_in_at && !record.signed_out_at ? <a onClick={(e) => { e.stopPropagation(); this.signOutVisitor(record) }}>签退</a> : null
                                      } */}
                                    {
                                      record.is_blacklisted ? <a onClick={(e) => { e.stopPropagation(); this.delBlacklist(record) }}>移出黑名单</a>
                                        :
                                        <a onClick={(e) => { e.stopPropagation(); this.addBlacklist(record) }}>加入黑名单</a>
                                    }
                                    {
                                      <a
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.deleteVisitor(record)
                                        }}
                                      >
                                        删除
                                      </a>
                                    }
                                  </div>
                                </KBDropdown.KBDropdownContent>
                              </KBDropdown>
                            ) : null}
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={
                  isInvite
                    ? `/admin/locations/${loc_id}/visit/invite?page=#PAGE#`
                    : `/admin/locations/${loc_id}/visit?page=#PAGE#`
                }
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location } = ownProps
  const { locationSetting } = state
  let user = selectors.getCurrentUser(state)
  let loc_id = params.id
  let space = selectors.getSpace(state) || {}
  const space_id = space.id

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  var FILTER_STATE = [
    'expect_visit_date_start',
    'expect_visit_date_end',
    'audit_status',
    'status',
    'phone_num'
  ]
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  let section = location.pathname.split('/')
  let isInvite = section[section.length - 1] === 'invite' ? true : false
  let isLocVisitor = canAccessLocationVisitor(user, loc_id)
  let isLocManager = canAccessLocation(user, loc_id)
  // const location_settings = spaceSetting.location_settings;
  const visitor_require_approval = get(
    locationSetting,
    `${loc_id}.location_settings.visitor_require_approval`,
    true
  )
  const isWeChatManger = canAdminLocationWeChat(user, loc_id)
  return {
    visitor_require_approval,
    space_id,
    loc_id,
    isLocManager,
    isInvite,
    page,
    isLocVisitor,
    isWeChatManger,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Visits)
