import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBRangeDate,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import { STATISTIC_CYCLE } from 'app/constants'
import { KBSelect } from 'components/views'
import { InitialHighCharts } from 'components/mixins'
import { percent, getYMD } from 'utils/kbUtil'
import {
  extendDefaultHighchartsConfig,
  getStatisticInitialDate,
  getInitialDate
} from 'utils/kbData'

var StatisticMeetingReserCountByHour = React.createClass({
  mixins: [InitialHighCharts],
  getInitialState() {
    const { from, to } = getStatisticInitialDate(new Date(), 'daily')
    return {
      filters: {
        from,
        to
      },
      data: [],
      month: ''
    }
  },

  componentDidMount() {
    const { filters, data } = this.state
    let Highcharts = this.getHighcharts()
    let filtersMonths = moment(filters.to).diff(moment(filters.from), 'months')

    this.mounted = true
    this.setState({
      month: filtersMonths
    })
    this._loadData(filters)
    this.renderChart(
      'Chart',
      extendDefaultHighchartsConfig({
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: '客户数'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y} 个</b>'
        }
      })
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  _loadData(filters) {
    const { locations } = this.props
    const { from, to, location_ids } = filters
    var params = { location_ids }
    if (!location_ids && locations) {
      params.location_ids = locations.map(json => json.id).join(',')
    }

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    api.geReserveCountByHourStatistic(params).then(({ json }) => {
      this.parseJSON(json)
    })
  },

  parseJSON(json) {
    var contain = {}
    let data = []

    if (!json || !Object.keys(json).length) {
      if (this.mounted) {
        this.setState({
          data
        })
      }
    }

    var array = Object.keys(json)
    let dt = {
      name: '小时预订的时间段分布',
      dataLabels: {
        enabled: false,
        color: '#FFFFFF',
        align: 'center',
        format: '{point.y}', // one decimal
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      },
      data: []
    }

    let customerData = []

    array.forEach(ar => {
      let name = ar.substr(ar.indexOf('_') + 1, ar.length) + ':00'

      let r = [name]

      r.push(json[ar] || 0)

      customerData.push(r)
    })

    data.push(Object.assign({}, dt, { data: customerData, name: '预订数' }))

    this.fillChart(data)
  },

  fillChart(data) {
    this.setSerierDate(data)
  },

  selectCallBack(data) {
    this._setFilters({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(newFilters)
  },

  changeDate(range) {
    this._setFilters(range)
    this.setState({
      month: ''
    })
  },

  setMonthFilters(num = 1) {
    var to = moment().toDate()
    var from = moment()
      .subtract(num, 'months')
      .toDate()

    this.setState({
      month: num
    })

    this._setFilters({
      from,
      to
    })
  },

  render() {
    const { locations } = this.props
    const { filters, data, month } = this.state
    const { from, to } = filters
    return (
      <article>
        <div className="chart-title m-top-lg">
          <span>预定时间段分布图</span>
          <button className="bordered-btn-small" onClick={this.print}>
            <i className="iconfont icon-contract" />
            打印
          </button>
          <button className="bordered-btn-small" onClick={this.exportingExcel}>
            <i className="iconfont icon-EXCEL" />
            导出Excel
          </button>
        </div>
        <div className="m-bottom-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            leftNone={true}
          />
          <div className="time-filter">
            <button
              className={month == 1 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 1)}
            >
              1个月
            </button>
            <button
              className={month == 3 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 3)}
            >
              3个月
            </button>
            <button
              className={month == 6 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 6)}
            >
              6个月
            </button>
            <button
              className={month == 12 ? 'active' : ''}
              onClick={this.setMonthFilters.bind(null, 12)}
            >
              1年
            </button>
          </div>
          <KBDropSelect
            options={{ defaultSelectPrompt: '选择分店' }}
            defaultData={locations}
            multi={true}
            callback={this.selectCallBack}
            style={{ marginLeft: 20 }}
          />
        </div>
        <div ref="highcharts" />
      </article>
    )
  }
})

function mapStateToProps(state, props) {
  // const locations = selectors.getSerializeLocation(state)
  const { group_id } = props
  const locations = selectors.getSerializeGroupsLocation(state, group_id)
  return {
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticMeetingReserCountByHour)
