import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  locationGroupsAction,
  getCitiesAction,
  getProvincesAction
} from 'app/actions'
import {
  KBInput,
  KBButton,
  KBUserInput,
  KBForm,
  KBPopover,
  KBTextarea,
  KBSelect
} from 'components'
import * as selectors from 'app/selectors'
import { arrayToObject, groupLocationsByLocationGroup } from 'utils/kbUtil'

var SpacesSettingLocationGroupForm = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {
    api.getCities().then(
      json => {
        this.props.getCitiesAction.success(json)
      },
      errors => {
        this.props.getCitiesAction.failure(errors)
      }
    )
    // 省份列表
    api.getProvinces().then(
      json => {
        this.props.getProvincesAction.success(json)
      },
      errors => {
        this.props.getProvincesAction.failure(errors)
      }
    )
  },

  submitLocationGroup(model) {
    const {
      createLocationGroupsAction,
      updateLocationGroupsAction,
      type,
      group,
      createCallback
    } = this.props
    if (type !== 'update') {
      return api.postLocationGroups(model).then(
        json => {
          createLocationGroupsAction.success(json)
          createCallback && createCallback()
          KBPopover.close()
        },
        error => {
          createLocationGroupsAction.failure(error)
        }
      )
    }
    return api.putLocationGroup(group.id, model).then(
      json => {
        updateLocationGroupsAction.success(json)
        KBPopover.close()
      },
      error => {
        updateLocationGroupsAction.failure(error)
      }
    )
  },

  render() {
    const {
      fields: { name, location_ids, description, province_id, city_id },
      submitting,
      handleSubmit,
      locations,
      type,
      selectLocations,
      titleText
    } = this.props
    const citiesArray = this.props.cities.filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)
    const provinces = this.props.provinces
    let groups = groupLocationsByLocationGroup(locations)
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.submitLocationGroup)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'update' ? '编辑' : '添加'}
            {titleText}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={name}
              title={`${titleText}名称`}
            />
          </div>
          <div className="kb-form-66">
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-48"
              isRequired={true}
              title="省份"
              field={province_id}
              arrayData={provinces}
            />
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-48"
              isRequired={true}
              title="城市"
              titleClass="label-rt"
              field={city_id}
              arrayData={cities}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="分店"
              multi={true}
              placeholder="选择分店"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={location_ids}
              selectedUsers={selectLocations}
              users={groups.no_group}
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint=""
              field={description}
              title="项目介绍"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  let locations = selectors.getLocations(state)
  // let locations = arrayToObject(selectors.getLocationGroups(state))
  const isProject = props.fromLocList
  const titleText = isProject ? '项目' : '分组'
  const cities = selectors.getCitiesToArray(state)
  const provinces = selectors.getProvinces(state)
  return {
    locations,
    isProject,
    titleText,
    provinces,
    cities
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLocationGroupsAction: bindActionCreators(
      locationGroupsAction.create,
      dispatch
    ),
    updateLocationGroupsAction: bindActionCreators(
      locationGroupsAction.update,
      dispatch
    ),
    getCitiesAction: bindActionCreators(getCitiesAction, dispatch),
    getProvincesAction: bindActionCreators(getProvincesAction, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入项目名称'
  }

  if (valid.required(values.location_ids)) {
    errors.location_ids = '请选择分店'
  }

  if (valid.required(values.province_id)) {
    errors.province_id = '请选择省份'
  }

  if (valid.required(values.city_id)) {
    errors.city_id = '请选择城市'
  }

  return errors
}

const formConfig = {
  form: 'SpacesSettingLocationGroupForm',
  fields: ['name', 'location_ids', 'description', 'province_id', 'city_id'],
  validate: validate,
  touchOnBlur: false
}

SpacesSettingLocationGroupForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpacesSettingLocationGroupForm)

export default SpacesSettingLocationGroupForm
