import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { arrayToObject, formatYearDayEN } from 'utils/kbUtil'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { KBMorePhotoUpload, KBPopover, KBButton } from 'components'
import { connect } from 'react-redux'
import ValidPlaceholderList from './ValidPlaceholderList'
import SpaceTempletePlaceholderForm from './SpaceTempletePlaceholderForm'
import * as selectors from 'app/selectors'
import _ from 'lodash'
import config from 'app/config'

var SpaceTempleteEdit = React.createClass({
  displayName: 'SpaceTempleteEdit',
  getInitialState() {
    const { template_type, location } = this.props
    return {
      templeteTypeState:
        template_type ||
        (location.id ? 'contract_invoice_attachment' : 'new_contract'),
      errorMessage: true
    }
  },
  componentDidMount() {
    const {
      id,
      updateSubscriptionTempletesAction,
      getSubscriptionPlaceholderAction,
      subscriptionTempletesAction,
      contract
    } = this.props
    if (!contract.length) {
      api.getValidPlaceholder().then(
        ({ json }) => {
          const { contract, customer, special } = json
          contract.map(holder => {
            holder.type = 'contract'
          })
          customer.map(holder => {
            holder.type = 'customer'
          })
          special.map(holder => {
            holder.type = 'special'
          })
          let placeholders = _.concat(contract, customer, special)
          getSubscriptionPlaceholderAction.success({
            entities: { subscriptionPlaceholder: arrayToObject(placeholders) }
          })
        },
        e => subscriptionTempletesAction.failure(e)
      )
    }

    if (!id) {
      return
    }

    api.getSubscriptionTempleteInfo(id).then(
      json => {
        updateSubscriptionTempletesAction.success(json)
      },
      error => {
        updateSubscriptionTempletesAction.failure(error)
      }
    )
  },
  templeteForm(model) {
    const {
      subscriptionTempletesAction,
      id,
      routerActions,
      updateSubscriptionTempletesAction,
      successAction,
      isCreate,
      apiCallFailureActions,
      location
    } = this.props
    const { errorMessage } = this.state
    let urls = model.template_url && model.template_url.split(',')
    model.template_url = urls && urls.length && urls[urls.length - 1]

    if (!model.template_url) {
      return apiCallFailureActions({
        status: 'error',
        message: '请添加文件'
      })
    }

    if (isCreate) {
      if (!errorMessage) {
        apiCallFailureActions({
          status: 'error',
          message: '无效的文件格式'
        })
        return
      }

      if (location.id) {
        //添加附件
        model.template_type = 'contract_invoice_attachment'
      }

      return api.postSubscriptionTempletes(model).then(json => {
        subscriptionTempletesAction.success(json)
        successAction({ message: '创建模板成功!' })
        // routerActions.push('/admin/spaces_setting/templete')
        routerActions.goBack()
      })
    }

    if (!errorMessage) {
      apiCallFailureActions({
        status: 'error',
        message: '无效的文件格式'
      })
      return
    }

    return api.putSubscriptionTemplete(id, model).then(json => {
      updateSubscriptionTempletesAction.success(json)
      successAction({ message: '修改模板成功!' })
    })
  },

  changeTempleteType(type) {
    const {
      fields: { template_type }
    } = this.props
    this.setState({
      templeteTypeState: type
    })
    template_type.onChange(type)
  },

  getURLCallback(url) {
    const { apiCallFailureActions, location } = this.props

    if (url == 'suffixError' && !location.id) {
      this.setState({
        errorMessage: false
      })
      return apiCallFailureActions({
        status: 'error',
        message: '请上传docx格式的文件'
      })
    }
    if (!location.id) {
      api.postValidateDocxMailMerge(url).then(
        json => {
          this.setState({
            errorMessage: true
          })
        },
        error => {
          let message = (error && error._error && error._error.message) || ''
          apiCallFailureActions({
            status: 'error',
            message: message
          })
          this.setState({
            errorMessage: false
          })
        }
      )
    }
  },

  createSubscriptionPlaceholder() {
    KBPopover.show(<SpaceTempletePlaceholderForm />)
  },

  render() {
    const { contract, customer, special } = this.props
    const {
      fields: { template_url, name, template_type },
      handleSubmit,
      submitting,
      isCreate,
      isEditWay,
      location
    } = this.props
    const { templeteTypeState } = this.state

    return (
      <div className="kb-form-container" style={{ padding: '30px' }}>
        <form
          onSubmit={handleSubmit(this.templeteForm)}
          style={{ height: '100%' }}
        >
          <header className="item-header">
            <span>名称</span>
            <input placeholder={`模板名称`} {...name} />
          </header>
          {name.touched && name.error ? (
            <p className="lr-error">{name.error}</p>
          ) : (
            ''
          )}
          <div
            className="clear-fix m-top-sm m-bottom-sm border-bottom"
            style={{ paddingBottom: 10 }}
          >
            <div className="f-left" style={{ marginRight: 28 }}>
              模板
            </div>
            {location.id ? null : (
              <div
                className="f-left"
                onClick={this.changeTempleteType.bind(null, 'new_contract')}
              >
                <input
                  type="radio"
                  id="input-new_contract"
                  checked={templeteTypeState == 'new_contract' ? 'checked' : ''}
                />
                <label
                  htmlFor="input-new_contract"
                  style={{ marginLeft: 5, color: '#999' }}
                >
                  新合同模板
                </label>
              </div>
            )}
            {location.id ? null : (
              <div
                className="f-left m-left"
                onClick={this.changeTempleteType.bind(
                  null,
                  'continue_contract'
                )}
              >
                <input
                  type="radio"
                  id="input-continue_contract"
                  checked={
                    templeteTypeState == 'continue_contract' ? 'checked' : ''
                  }
                />
                <label
                  htmlFor="input-continue_contract"
                  style={{ marginLeft: 5, color: '#999' }}
                >
                  续约合同模板
                </label>
              </div>
            )}
            {location.id ? null : (
              <div
                className="f-left m-left"
                onClick={this.changeTempleteType.bind(
                  null,
                  'no_sales_area_contract'
                )}
              >
                <input
                  type="radio"
                  id="input-no_sales_area_contract"
                  checked={
                    templeteTypeState == 'no_sales_area_contract' ? 'checked' : ''
                  }
                />
                <label
                  htmlFor="input-no_sales_area_contract"
                  style={{ marginLeft: 5, color: '#999' }}
                >
                  无销售面积合同模板
                </label>
              </div>
            )}
            {location.id ? (
              <div
                className="f-left m-left"
                onClick={this.changeTempleteType.bind(
                  null,
                  'contract_invoice_attachment'
                )}
              >
                <input
                  type="radio"
                  id="input-contract_invoice_attachment"
                  checked={
                    templeteTypeState == 'contract_invoice_attachment'
                      ? 'checked'
                      : ''
                  }
                />
                <label
                  htmlFor="input-contract_invoice_attachment"
                  style={{ marginLeft: 5, color: '#999' }}
                >
                  账单附件模版
                </label>
              </div>
            ) : null}
          </div>
          <div className="clear-fix">
            <KBMorePhotoUpload
              outerStyle={{ width: 300, float: 'left' }}
              text={template_url.value ? '文件已上传' : '上传模板文件'}
              uploadingText="正在上传"
              prefix={config.uploadDir + 'subscriptions/templete/'}
              type="source"
              suffix={location.id ? '' : 'docx'}
              hint={location.id ? '' : '请上传后缀为docx格式的文件'}
              multiple={false}
              photoShow={false}
              getURLCallback={this.getURLCallback}
              defaultFiles={template_url.value}
              ref="file"
              buttonClass={'bordered-btn m-none'}
              field={template_url}
              accept=".docx,.doc"
            />
            <div className="f-right">
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                {isCreate ? '创建' : '保存'}
              </KBButton>
            </div>
          </div>
          <div className="clear-fix" style={{ padding: '10px 0' }}>
            <div className="m-bottom-sm">
              <span style={{ fontSize: 16 }}>①&nbsp;如何制作模板文件</span>
              <div
                className="flex-vertical-center"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                <img
                  src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/pdf.png"
                  alt="pdf"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                <a
                  href="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/dc980bf2-ae00-4a86-8ef9-b2f0524822cb.docx"
                  className="color-link c-pointer"
                >
                  点击下载模板制作教程
                </a>
              </div>
            </div>
            <div
              className="border-bottom m-bottom-sm"
              style={{
                paddingBottom: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <span style={{ fontSize: 16 }}>②&nbsp;有效模板命令字符</span>
              {/*<button className="bordered-btn" onClick={ () => { this.createSubscriptionPlaceholder() } }>创建模板命令符号</button>*/}
            </div>
            <div className="clear-fix">
              <div
                className="m-bottom"
                style={{ fontSize: 16, color: '#444444' }}
              >
                合同信息
              </div>
              {contract.map(json => {
                return <ValidPlaceholderList placeholder={json} />
              })}
            </div>
            <div className="clear-fix">
              <div
                className="m-bottom"
                style={{ fontSize: 16, color: '#444444' }}
              >
                租客信息
              </div>
              {customer.map(json => {
                return <ValidPlaceholderList placeholder={json} />
              })}
            </div>
            <div className="clear-fix">
              <div
                className="m-bottom"
                style={{ fontSize: 16, color: '#444444' }}
              >
                自定义信息
              </div>
              {special.map(json => {
                return (
                  <ValidPlaceholderList placeholder={json} type={'special'} />
                )
              })}
            </div>
          </div>
        </form>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let subscriptionPlaceholder = selectors.getSubscriptionPlaceholder(state)
  let placeholderOfcontract =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'contract')
  let placeholderOfcustomer =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'customer')
  let placeholderOfspecial =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'special')
  const type = _.get(location, 'query.type', 'create')
  const isCreate = type == 'create' || type == 'copy' ? true : false
  const id = _.get(location, 'query.token', '')
  const currentSubscriptionTemplete =
    (id && selectors.getSubscriptionTempletes(state, id)) || {}
  const validPlaceholders = props.validPlaceholders || {}
  const contract = _.get(validPlaceholders, 'contract.length', false)
    ? validPlaceholders.contract
    : placeholderOfcontract
  const customer = _.get(validPlaceholders, 'customer.length', false)
    ? validPlaceholders.customer
    : placeholderOfcustomer
  const special = _.get(validPlaceholders, 'special.length', false)
    ? validPlaceholders.special
    : placeholderOfspecial
  const isEditWay =
    type == 'copy'
      ? true
      : isCreate
      ? false
      : currentSubscriptionTemplete.id
      ? true
      : false

  const { name, template_type, template_url } = currentSubscriptionTemplete

  const data = {
    template_type: template_type || 'new_contract',
    template_url,
    name
  }
  return {
    isEditWay,
    id,
    contract,
    customer,
    special,
    initialValues: {
      ...data
    },
    isCreate,
    template_type
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    subscriptionTempletesAction: bindActionCreators(
      actions.subscriptionTempletesAction.create,
      dispatch
    ),
    updateSubscriptionTempletesAction: bindActionCreators(
      actions.subscriptionTempletesAction.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch),
    getSubscriptionPlaceholderAction: bindActionCreators(
      actions.subscriptionPlaceholder.get,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(actions.apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入模板名称'
  }

  return errors
}

const formConfig = {
  form: 'SpaceTempleteEdit',
  fields: ['name', 'template_url', 'template_type'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceTempleteEdit)
