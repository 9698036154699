import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { leaveEnterOptionActions, successState } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import { IS_FINISH } from 'app/constants'
var SpaceSettingOptionsForm = React.createClass({
  createSpaceSettingOptionsForm(model) {
    const { successStateActions } = this.props
    const {
      state,
      type,
      option,
      createLeaveEnterOptionAction,
      updateLeaveEnterOptionAction
    } = this.props
    const params = Object.assign({}, model, {
      cate: type
    })

    if (state === 'update') {
      return api.updateLeveOrEnterOptions(option.id, params).then(json => {
        this.props.updateLeaveEnterOptionAction.success(json)
        successStateActions({ message: '修改成功!' })
        KBPopover.close()
        return true
      })
    }

    return api.createLeveOrEnterOptions(params).then(json => {
      this.props.createLeaveEnterOptionAction.success(json)
      successStateActions({ message: '创建成功!' })
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { item, is_required, notes },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { opportunities, state } = this.props
    const classNmaeCom = {
      className: 'kb-form-group kb-div-c39'
    }

    const isFinish = IS_FINISH

    return (
      <form onSubmit={handleSubmit(this.createSpaceSettingOptionsForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '修改待办事项' : '创建待办事项'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="待办事项"
                isRequired={true}
                fistFoucs={true}
                field={item}
              />
            </div>
            <div className="kb-row kb-form-66">
              <KBRadio
                {...classNmaeCom}
                inClassName="kb-form-group kb-div-33"
                isRequired={true}
                groupStyle={{ width: '100%' }}
                divClassName="kb-form-group kb-div-66"
                rightLabel="label-rt"
                title="必须完成"
                arrayType={isFinish}
                name="lock"
                field={is_required}
              />
            </div>
            <div className="kb-row">
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={notes}
                title="备注"
              />
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

SpaceSettingOptionsForm.PropTypes = {
  type: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createLeaveEnterOptionAction: bindActionCreators(
      leaveEnterOptionActions.create,
      dispatch
    ),
    updateLeaveEnterOptionAction: bindActionCreators(
      leaveEnterOptionActions.update,
      dispatch
    ),
    successStateActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.item)) {
    errors.item = '请输入待办事项'
  }

  if (valid.required(values.is_required)) {
    errors.is_required = '请选择是否必须'
  }

  return errors
}

const formConfig = {
  form: 'SpaceSettingOptionsForm',
  fields: ['item', 'is_required', 'notes'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingOptionsForm)
