import React from 'react'
import { bindActionCreators } from 'redux'
import {
  KBFormError,
  KBButton,
  KBImageCropperInput,
} from 'components'
import { successState } from 'app/actions'
import { reduxForm } from 'redux-form'
import { Select, notification } from 'antd'
import * as valid from 'utils/validate'
const demoImg = require('styles/images/qrcode-demo.jpg')

var GroupSetValueAddedServices = React.createClass({
  getInitialState() {
    return {
      emailList: [],
    }
  },

  updateSpace(model) {
    const { updateLocationSetting, setting_value } = this.props
    updateLocationSetting(
      {
        value_added_service_settings: model
      },
      { key: 'value_added_service_settings' }
    )
  },

  changeEmail(val) {
    this.props.fields.emails.onChange(val)
  },

  failureCallBack(err) {
    if (!err) return
    return notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: { emails, qiyechat_image },
      updateLocationSetting,
      setting,
      handleSubmit,
      submitting
    } = this.props
    if (!setting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>增值服务设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.updateSpace)}>
            <KBFormError error={this.props.error} />
            <div className="kb-form-container" style={{ padding: 0 }}>
              <fieldset className="fieldset">
                <legend>邮件发送列表</legend>
                <div>
                  <Select
                    mode="tags"
                    showSearch
                    style={{ width: '100%' }}
                    onChange={this.changeEmail}
                    value={emails.value || []}
                  >
                    {/* {emailList.map(item => {
                      return (
                        <Option key={item.id} value={item.email}>
                          {item.email}
                        </Option>
                      )
                    })} */}
                  </Select>
                </div>
                {
                  emails.error && <p className="lr-error">{emails.error}</p>
                }
              </fieldset>

              <fieldset
                className="fieldset"
                style={{ display: 'inline-block' }}
              >
                <legend>企业微信二维码</legend>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <KBImageCropperInput
                    aspect={1}
                    isRequired
                    className="kb-form-group kb-form-210 kb-form-alone"
                    field={qiyechat_image}
                    type="origanizations"
                    accept="image/jpeg, image/png"
                    imageSize={1024 * 2}
                    failureCallBack={this.failureCallBack}
                    isPublic
                  >
                    <div className="upload-portrait">
                      {qiyechat_image.value && (
                        <img
                          alt="failed"
                          src={qiyechat_image.value}
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'inline-block',
                            marginLeft: 0
                          }}
                        />
                      )}
                      <button className="bordered-btn" type="button">
                        上传图片
                      </button>
                    </div>
                  </KBImageCropperInput>
                  <div
                    style={{
                      paddingRight: '60px',
                      color: '#999999',
                      textAlign: 'center'
                    }}
                  >
                    <p>样例二维码</p>
                    <img src={demoImg} alt="demo" style={{ width: '100px' }} />
                  </div>
                </div>
                <p
                  className="color-nine"
                  style={{
                    paddingTop: '20px',
                    fontSize: '12px'
                  }}
                >
                  图片格式为png,
                  jpeg或jpg,文件大小不大于2M，请不要上传不带边框修饰的二维码，以上样例供参考
                </p>
              </fieldset>

              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { groupSetting } = state
  let setting_value = groupSetting.value_added_service_settings
  return {
    initialValues: {
      ...setting_value,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (!values.emails || values.emails.length === 0) {
    errors.emails = '请输入邮件发送列表'
  }

  const allAreEmail = (values.emails || []).every(i => !valid.email(i))
  if (!allAreEmail) {
    errors.emails = '请验证输入的邮箱'
  }

  return errors
}

const formConfig = {
  form: 'GroupSetValueAddedServices',
  fields: ['emails', 'qiyechat_image'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetValueAddedServices)
