import React from 'react'
import UserBaseForm from './UserBaseForm'
import { LogoCropperForm } from 'components/views'

var UserInfo = React.createClass({
  displayName: 'UserInfo',
  render() {
    const { updateUserForm, user } = this.props

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title">
            <span>个人信息</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>基本设置</legend>
            <UserBaseForm updateUserForm={updateUserForm} user={user} />
          </fieldset>
          <fieldset className="fieldset">
            <legend>上传头像</legend>
            <LogoCropperForm
              imgSubmit={updateUserForm}
              type={`users/${user.id}`}
              user={user}
              accept=".jpg,.jpeg,.png"
            />
          </fieldset>
        </div>
      </section>
    )
  }
})

export default UserInfo
