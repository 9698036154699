import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBInput, KBButton } from 'components'

var VisitTypeEditNameForm = React.createClass({
  saveName(model) {
    const { updateCurrentType, currentVisitType } = this.props
    let newVisitType = Object.assign({}, currentVisitType)
    newVisitType.name = model.name
    return updateCurrentType(newVisitType)
  },

  render() {
    const {
      fields: { name },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.saveName)}>
        <div className="kb-form-container visit-setting-block">
          <KBFormError error={error} />
          <div className="kb-row">
            <KBInput
              title="访客类型名称"
              className="kb-form-group kb-div-210 kb-form-alone"
              isRequired={true}
              field={name}
            />
          </div>
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { currentVisitType } = props

  return {
    initialValues: {
      name: currentVisitType.name || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入访客类型名称'
  }

  return errors
}

const formConfig = {
  form: 'VisitTypeEditNameForm',
  fields: ['name'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitTypeEditNameForm)
