import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'

var VisitSignOutSet = React.createClass({
  changeSlidesVisiblity(field) {
    const { updateVisitorSettings, config } = this.props
    let value = !this.props.signout[field]
    let { signout } = config
    signout[field] = value
    config.signout = signout
    updateVisitorSettings(config)
  },

  render() {
    const { allow_sign_out, auto_sign_out } = this.props.signout

    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>访客签退</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">访客自助签退</div>
                  <div className="des">允许访客自己签退</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(
                    null,
                    'allow_sign_out'
                  )}
                >
                  <span
                    className={`slide-bg ${
                      allow_sign_out ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">自动登出</div>
                  <div className="des">在24:00后自动签退所有签到访客</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(
                    null,
                    'auto_sign_out'
                  )}
                >
                  <span
                    className={`slide-bg ${
                      auto_sign_out ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config } = props
  let signout = (config && config.signout) || {}

  return {
    signout,
    config
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSignOutSet)
