import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { buildingInfoAction } from 'app/actions'
import { KBInput, KBPopover, KBFormError, KBButton } from 'components'
import * as selectors from 'app/selectors'

var BuildingForm = React.createClass({
  displayName: 'BuildingForm',
  createBuildingForm(model) {
    const {
      createBuildingAction,
      updateBuildingAction,
      state,
      loc_id,
      building
    } = this.props
    if (state == 'update') {
      return api.putBuildingInfos(building.id, loc_id, model).then(json => {
        updateBuildingAction.success(json)
        KBPopover.close()
        return
      })
    }
    return api.postBuildingInfos(loc_id, model).then(json => {
      createBuildingAction.success(json)
      KBPopover.close()
      return
    })
  },
  render() {
    const {
      fields: { name },
      error,
      submitting,
      handleSubmit,
      state
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    return (
      <form onSubmit={handleSubmit(this.createBuildingForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改大楼' : '添加大楼'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="大楼名"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

BuildingForm.PropTypes = {
  loc_id: PropTypes.number
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createBuildingAction: bindActionCreators(
      buildingInfoAction.create,
      dispatch
    ),
    updateBuildingAction: bindActionCreators(
      buildingInfoAction.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入名称'
  }

  return errors
}

const formConfig = {
  form: 'BuildingForm',
  fields: ['name'],
  validate: validate,
  touchOnBlur: false
}

BuildingForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(BuildingForm)

export default BuildingForm
