import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { subscriptionPlaceholder } from 'app/actions'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'

var SpaceTempletePlaceholderForm = React.createClass({
  displayName: 'SpaceTempletePlaceholderForm',
  createPlaceholderForm(model) {
    const { updateSubscriptionPlaceholderAction } = this.props
    delete model.placeholderId

    return api.createSubscriptionSpecialPlaceholder(model).then(
      ({ json }) => {
        let obj = {}
        obj[json.id] = json
        updateSubscriptionPlaceholderAction.success({
          entities: { subscriptionPlaceholder: obj }
        })
        KBPopover.close()
      },
      error => {
        updateSubscriptionPlaceholderAction.failure(error)
      }
    )
  },

  componentDidMount() {
    const {
      fields: { name, description }
    } = this.props
    name.onChange('office_area_name')
    description.onChange('办公地点名称')
  },

  render() {
    const {
      fields: { placeholderId, value },
      error,
      submitting,
      handleSubmit
    } = this.props

    const placeholderSpecial = [
      {
        id: 1,
        name: '办公地点名称'
      }
    ]
    return (
      <form onSubmit={handleSubmit(this.createPlaceholderForm)}>
        <div>
          <header className="kb-form-header">
            <h2 className="header-title">创建模板命令字符</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
          <KBFormError err={this.props.error} />
          <div className="kb-form-container">
            <div className="kb-row">
              <KBUserInput
                title="模板命令"
                placeholder="选择模板命令"
                className="kb-form-group kb-form-210 kb-form-alone"
                field={placeholderId}
                isRequired={true}
                multi={false}
                users={placeholderSpecial}
                selectedUsers={[]}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-210 kb-form-alone"
                isRequired={true}
                field={value}
                title="命令字符值"
              />
            </div>
            <div className="kb-row">
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionPlaceholderAction: bindActionCreators(
      subscriptionPlaceholder.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.value)) {
    errors.value = '请输入命令字符值'
  }

  if (valid.required(values.placeholderId)) {
    errors.placeholderId = '请选择模板命令'
  }

  return errors
}

const formConfig = {
  form: 'ChanceForm',
  fields: ['placeholderId', 'value', 'name', 'description'],
  touchOnBlur: false,
  validate: validate
}

SpaceTempletePlaceholderForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceTempletePlaceholderForm)

export default SpaceTempletePlaceholderForm
