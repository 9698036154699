import React, { Component, PropTypes } from 'react'
import StatisticTaskPersonRepair from './task/StatisticTaskPersonRepair'
import StatisticTaskLocationRepair from './task/StatisticTaskLocationRepair'
import StatisticIncrementActive from './task/StatisticIncrementActive'
import StatisticSubtypeTask from './task/StatisticSubtypeTask'
import StatisticPriorityTask from './task/StatisticPriorityTask'

var StatisticCrmBox = React.createClass({
  displayName: 'StatisticCrmBox',
  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticIncrementActive group_id={this.props.group_id} />
          <StatisticTaskPersonRepair group_id={this.props.group_id} />
          <StatisticTaskLocationRepair group_id={this.props.group_id} />
          <StatisticSubtypeTask group_id={this.props.group_id} />
          <StatisticPriorityTask group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticCrmBox
