import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { floorInfosAction } from 'app/actions'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBTextarea
} from 'components'
import * as selectors from 'app/selectors'

var FloorInfoFrom = React.createClass({
  displayName: 'FloorInfoFrom',
  createFloorInfoForm(model) {
    const {
      createFloorInfoAction,
      updateFloorInfoAction,
      callback,
      state,
      loc_id,
      floorInfo,
      building_info_id
    } = this.props
    model.building_info_id = building_info_id

    if (state == 'update') {
      return api.putFloorInfos(floorInfo.id, loc_id, model).then(json => {
        updateFloorInfoAction.success(json)
        KBPopover.close()
        return
      })
    }
    return api.postFloorInfos(loc_id, model).then(json => {
      createFloorInfoAction.success(json)
      KBPopover.close()
      return
    })
  },
  render() {
    const {
      fields: { layer_num, layer_description, notes },
      error,
      submitting,
      handleSubmit,
      state
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    return (
      <form onSubmit={handleSubmit(this.createFloorInfoForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改楼层' : '添加楼层'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              label={true}
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              placeholder={'第几层'}
              field={layer_num}
              title="楼层"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              label={true}
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              placeholder={'例：M1-N1 一层'}
              field={layer_description}
              title="描述"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
              title="备注"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

FloorInfoFrom.PropTypes = {
  loc_id: PropTypes.number,
  building_info_id: PropTypes.number
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createFloorInfoAction: bindActionCreators(
      floorInfosAction.create,
      dispatch
    ),
    updateFloorInfoAction: bindActionCreators(floorInfosAction.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.layer_num)) {
    errors.layer_num = '请输入楼层'
  }
  if (valid.required(values.layer_description)) {
    errors.layer_description = '请输入大楼描述简短的话M1-N1 一层'
  }

  return errors
}

const formConfig = {
  form: 'FloorInfoFrom',
  fields: ['layer_num', 'layer_description', 'notes'],
  validate: validate,
  touchOnBlur: false
}

FloorInfoFrom = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FloorInfoFrom)

export default FloorInfoFrom
