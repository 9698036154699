import React from 'react'
import LocationSetOpenFuncForm from './LocationSetOpenFuncForm'
import { api } from 'app/services'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'

var LocationSetOpenFunc = React.createClass({
  displayName: 'LocationSetOpenFunc',

  getInitialState() {
    return {
      openfuncs: {}
    }
  },

  componentWillMount() {
    const { locationSetting } = this.props
    this.updateFileds(locationSetting)
  },

  updateFileds(setting = {}) {
    let openFuncs =
      (setting.location_settings && setting.location_settings.open_func) || {}
    if (typeof openFuncs === 'string') {
      openFuncs = JSON.parse(openFuncs)
    }
    this.setState({
      openfuncs: openFuncs
    })
  },

  componentWillReceiveProps(nextProps) {
    const { locationSetting } = nextProps
    if (
      locationSetting &&
      locationSetting.settings &&
      locationSetting != this.props.locationSetting
    ) {
      this.updateFileds(locationSetting)
    }
  },

  render() {
    const { updateLocationSetting } = this.props
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>开放功能设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>打印功能</legend>
            <LocationSetOpenFuncForm
              type="print_func"
              value={this.state.openfuncs}
              location={this.props.location}
              updateLocationSetting={updateLocationSetting}
            />
          </fieldset>
          <fieldset className="fieldset">
            <legend>投屏功能</legend>
            <LocationSetOpenFuncForm
              type="screen_func"
              value={this.state.openfuncs}
              location={this.props.location}
              updateLocationSetting={updateLocationSetting}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id)
  return {
    locationSetting
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSetOpenFunc)
