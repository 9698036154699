import React from 'react'
import { connect } from 'react-redux'

var SpacesSettingAccountBindingForm = React.createClass({
  displayName: 'SpacesSettingManageForm',
  getInitialState() {
    const { accountBindingValue } = this.props
    return {
      accountOnly: accountBindingValue
    }
  },
  changeSlide() {
    let accountOnly = !this.state.accountOnly
    this.setState({
      accountOnly
    })
    this.updateSpace({ device_account_binding_setting: accountOnly })
  },

  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          settings: model
        })
      },
      {
        key: 'settings'
      }
    )
  },
  render() {
    const { accountOnly } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="slide-btn"
          onClick={() => {
            this.changeSlide()
          }}
        >
          <span
            className={`slide-bg ${accountOnly ? 'slide-left' : 'slide-right'}`}
          >
            <span className="slide-move" />
          </span>
        </div>
        <span style={{ marginLeft: 10, marginBottom: 5 }}>
          限定账号只可以绑定一个手机号码
        </span>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting.settings || {}
  const { device_account_binding_setting } = setting
  let accountBindingValue = device_account_binding_setting
  return {
    accountBindingValue
  }
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpacesSettingAccountBindingForm)
