import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { orgEnterSettingActions, successState } from 'app/actions'

var SpaceSettingCompanyForm = React.createClass({
  displayName: 'SpaceSettingCompanyForm',
  getInitialState() {
    return {
      enterThroughOrgNotes: this.props.setting_value
    }
  },

  componentWillMount() {
    const { getOrgEnterSettingActions } = this.props
    let enterThroughOrgNotes

    // api.getOrganizationEnterSetting().then(json => {
    //   enterThroughOrgNotes = json.response.body.enter_through_org_notes=='true' || json.response.body.enter_through_org_notes ? true : false
    //   getOrgEnterSettingActions.success(json)
    //   this.setState({ enterThroughOrgNotes })
    // }, errors => {
    //   getOrgEnterSettingActions.failure(errors)
    // })
  },

  updateOrgEnterSetting(model) {
    const { updateCurrentSpace } = this.props
    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          organization_settings: {
            org_enter_setting: model
          }
        })
      },
      {
        key: 'organization_settings'
      }
    )
  },

  changeSlide() {
    let enterThroughOrgNotes = !this.state.enterThroughOrgNotes
    this.setState({
      enterThroughOrgNotes
    })
    this.updateOrgEnterSetting &&
      this.updateOrgEnterSetting({
        enter_through_org_notes: enterThroughOrgNotes
      })
  },
  render() {
    const { enterThroughOrgNotes } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="slide-btn"
          onClick={() => {
            this.changeSlide()
          }}
        >
          <span
            className={`slide-bg ${
              enterThroughOrgNotes ? 'slide-left' : 'slide-right'
            }`}
          >
            <span className="slide-move" />
          </span>
        </div>
        <span style={{ marginLeft: 10, marginBottom: 5 }}>
          通过勾选待办事项来办理公司入驻
        </span>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value =
    setting.organization_settings.org_enter_setting.enter_through_org_notes

  return {
    setting_value
  }
}
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    getOrgEnterSettingActions: bindActionCreators(
      orgEnterSettingActions.get,
      dispatch
    ),
    updateOrgEnterSettingActions: bindActionCreators(
      orgEnterSettingActions.update,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingCompanyForm)
