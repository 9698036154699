import React from 'react'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { locationActions, apiCallFailure } from 'app/actions'
import { KBButton, KBPopover } from 'components'

/**
 * 修改分店 基本设置
 */
var LocationPublicForm = React.createClass({
  displayName: 'LocationBaseForm',

  getInitialState() {
    return {
      publicStatus: this.props.loc_public
    }
  },

  changeSlide(publicState) {
    const { updateLocationAction, loc_id, apiCallFailureActions } = this.props
    if (publicState) {
      apiCallFailureActions({
        status: 'error',
        message:
          '分店启用后不能转成非启用状态，如果确实需要请联系系统管理员操作!'
      })
      return
    }

    return KBPopover.plugins.confirm(
      `启用分店`,
      `你是否确定用户可以在客户端App或微信上看见此分店的基本信息和图片?`,
      {
        confirm: () => {
          const loc_public = !publicState
          api.updateLocation(loc_id, { public: loc_public }).then(
            json => {
              KBPopover.close()
              updateLocationAction.success(json)
            },
            errors => {
              updateLocationAction.failure(errors)
              KBPopover.close()
            }
          )
        }
      }
    )
  },

  render() {
    const { loc_public } = this.props

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="slide-btn"
          onClick={this.changeSlide.bind(null, loc_public)}
        >
          <span
            className={`slide-bg ${loc_public ? 'slide-left' : 'slide-right'}`}
          >
            <span className="slide-move" />
          </span>
        </div>
        <span style={{ marginLeft: 15 }}>
          用户可以在客户端App或微信上看见此分店的基本信息和图片。
          <br />
          如果关闭，此分店信息仅对运营管理人员可见
        </span>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let loc_id = location.id
  let loc_public = location.public
  return {
    location,
    loc_id,
    loc_public
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationPublicForm)
