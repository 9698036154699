import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { KBPopover, KBTipsy } from 'components'
import * as util from 'utils/kbUtil'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as constants from 'app/constants'
import SpaceAddPointPlanForm from './SpaceAddPointPlanForm'
import { formatYearDayEN } from 'utils/kbUtil'

var SpacePointPlans = React.createClass({
  displayName: 'SpacePointPlans',
  getInitialState() {
    return {}
  },
  componentDidMount() {
    const { pointPlansAction } = this.props

    api.getPointPlans().then(
      json => {
        pointPlansAction.success(json)
      },
      error => {
        pointPlansAction.failure(error)
      }
    )
  },
  toDetail() {
    // console.log('detail')
  },
  updatePointPlans(pointplan, e) {
    util.preventDefaultPropagation(e)
    KBPopover.show(
      <SpaceAddPointPlanForm
        type="update"
        pointplan={pointplan}
        initialValues={pointplan}
      />
    )
  },
  deletePointPlans(id, e) {
    util.preventDefaultPropagation(e)
    const { deletePointPlansAction, successAction } = this.props
    KBPopover.plugins.confirm('删除优惠计划', '您是否删除该优惠计划?', {
      confirm: () => {
        api.deletePointPlans(id).then(
          json => {
            deletePointPlansAction.success(json, { id, key: 'pointPlans' })
            successAction({ message: '删除优惠计划成功!' })
            KBPopover.close()
          },
          e => {
            deletePointPlansAction.failure(e)
          }
        )
      }
    })
  },
  addPointPlan() {
    KBPopover.show(<SpaceAddPointPlanForm />)
  },
  render() {
    const { pointPlans, virtual_currency_name } = this.props

    return (
      <div>
        <div>
          <button
            className="bordered-btn"
            style={{ marginLeft: 0 }}
            onClick={this.addPointPlan}
          >
            <i className="iconfont icon-add" />
            新增优惠计划
          </button>
        </div>
        <div>
          <table className="content-table" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th>充值{virtual_currency_name}</th>
                <th>赠送比例</th>
                <th>价格</th>
                <th>创建时间</th>
                <th style={{ float: 'right', marginRight: '10px' }}>操作</th>
              </tr>
            </thead>
            <tbody>
              {pointPlans.map((pointplan, index) => {
                const { id } = pointplan
                return (
                  <tr onClick={this.toDetail.bind(null, id)} key={index}>
                    <td>{pointplan.number}</td>
                    <td>{`${Math.floor(pointplan.gift_rate * 1000000) /
                      10000} %`}</td>
                    <td>{`${pointplan.number * pointplan.price} 元`}</td>
                    <td>{formatYearDayEN(pointplan.created_at)}</td>
                    <td style={{ float: 'right' }}>
                      <i
                        className="iconfont icon-edit"
                        style={{ marginRight: '20px' }}
                        onClick={this.updatePointPlans.bind(null, pointplan)}
                      />
                      <i
                        className="iconfont icon-delete"
                        onClick={this.deletePointPlans.bind(null, id)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  // const state
  const pointPlans = selectors.getPointPlans(state)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  return {
    pointPlans,
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    pointPlansAction: bindActionCreators(
      actions.pointPlansAction.get,
      dispatch
    ),
    deletePointPlansAction: bindActionCreators(
      actions.pointPlansAction.delete,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpacePointPlans)
