import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBSelect, KBASNumberInput } from 'components'
import { api, apiUtils } from 'app/services'
import { successState } from 'app/actions'
import { bindActionCreators } from 'redux'
import { INVOICE_AUTO_CONFIRM } from 'app/constants'

var SpaceSettingInvoiceAutoConfirm = React.createClass({
  displayName: 'SpaceSettingInvoiceAutoConfirm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    model.invoice_auto_confirm_when_pay_amount_equal_total_amount =
      model.invoice_auto_confirm_when_pay_amount_equal_total_amount == 'true'
        ? true
        : false

    return updateCurrentSpace(
      {
        settings: JSON.stringify({
          invoice_settings: model
        })
      },
      {
        key: 'invoice_settings'
      }
    )
  },

  render() {
    const {
      fields: {
        invoice_auto_confirm_when_pay_amount_equal_total_amount,
        check_paid_overdue_days
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <div className="f-left">
              <div>
                (&nbsp;
                <span className="color-link">
                  {check_paid_overdue_days.value}
                </span>
                &nbsp;)&nbsp;&nbsp;天不核销，默认核销不通过
              </div>
              <p className="color-nine d-iblock">
                示例：如果设置为15，那么账单若在付款完成的15天后还没有进行核销操作，则自动进行核销不通过操作
              </p>
            </div>
            <KBASNumberInput
              field={check_paid_overdue_days}
              className="f-right"
              type="number"
              maxValue="100"
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c39 kb-form-alone"
              field={invoice_auto_confirm_when_pay_amount_equal_total_amount}
              title="账单自动核销和确认核销"
              hint="财务人员上传凭证时，当付款金额等于账单金额，账单是否自动核销和确认核销"
              arrayData={INVOICE_AUTO_CONFIRM}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value =
    setting.invoice_settings
      .invoice_auto_confirm_when_pay_amount_equal_total_amount || 'false'
  return {
    initialValues: {
      invoice_auto_confirm_when_pay_amount_equal_total_amount: setting_value,
      check_paid_overdue_days:
        setting.invoice_settings.check_paid_overdue_days || 0
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingInvoiceAutoConfirm',
  fields: [
    'invoice_auto_confirm_when_pay_amount_equal_total_amount',
    'check_paid_overdue_days'
  ],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingInvoiceAutoConfirm)
