import React from 'react'
import { connect } from 'react-redux'
import LocationBaseForm from './LocationBaseForm'
import LocationPublicForm from './LocationPublicForm'
import LocationAutoAuthForm from './LocationAutoAuthForm'
import { bindActionCreators } from 'redux'

import { KBMorePhotoUpload } from 'components'
import { LogoCropperForm } from 'components/views'
import * as selectors from 'app/selectors'
import LocationImagesUploadForm from './LocationImagesUploadForm'
import FloorManage from './FloorManage'
import LocationImportAreasMap from './LocationImportAreasMap'
import { api, apiUtils } from 'app/services'
import {
  membersActions,
  devicesActions,
  serializeLocationsActions,
  logout,
  spacesSalesSettingAction,
  spacesSettingAction,
  locationActions,
  getCitiesAction,
  spaceRoleAction,
  getProvincesAction,
  locationGroupsAction
} from 'app/actions'
import { get, forEach } from 'lodash'
/**
 * 分店基础设置
 */
var LocationSettingBase = React.createClass({
  displayName: 'LocationSettingBase',
  getInitialState() {
    return {
      cityData: null
    }
  },
  componentDidMount() {
    // 城市列表
    api.getCities().then(
      json => {
        // this.getCitiesAction.success(json)
        let citiy = get(json, 'response.body')
        let cityData = {}
        forEach(citiy, item => {
          cityData[item.id] = item
        })
        this.setState({
          cityData
        })
        // this.refs.LocationBaseForm.changecity(cityData);
      },
      error => {
        this.getCitiesAction.failure(error)
      }
    )

    // 省份列表
    api.getProvinces().then(
      json => {
        this.props.getProvincesAction.success(json)
      },
      errors => {
        this.props.getProvincesAction.failure(errors)
      }
    )
  },
  render() {
    const {
      cities,
      provinces,
      location,
      updateLocation,
      defaultImages
    } = this.props
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>基本设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-title m-bottom-sm">
              <span className="color-six">客户端APP可见</span>
            </div>
            <LocationPublicForm location={location} />
          </fieldset>
          <fieldset className="fieldset">
            <div className="kb-title m-bottom-sm">
              <span className="color-six">是否允许小程序用户自助认证</span>
            </div>
            <LocationAutoAuthForm location={location} />
          </fieldset>
          <fieldset className="fieldset">
            <div className="kb-title m-bottom-sm">
              <span className="color-six">基本信息</span>
            </div>
            {this.state.cityData ? (
              <LocationBaseForm
                ref="LocationBaseForm"
                updateLocation={updateLocation}
                location={location}
                cities={this.state.cityData}
                provinces={provinces}
              />
            ) : null}
          </fieldset>
          <fieldset className="fieldset">
            <div className="kb-title m-bottom-sm">
              <span className="color-six">上传分店图片</span>
            </div>
            <LocationImagesUploadForm
              defaultImages={defaultImages}
              imgSubmit={updateLocation}
              loc_id={location.id}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const location = props.location
  const cities = selectors.getCities(state)
  const provinces = selectors.getProvinces(state)
  const updateLocation = props.updateLocation
  const defaultImages = location.images
  return {
    cities,
    location,
    updateLocation,
    defaultImages,
    provinces
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getProvincesAction: bindActionCreators(getProvincesAction, dispatch),
    getCitiesAction: bindActionCreators(getCitiesAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSettingBase)
