import React, { Component, PropTypes } from 'react'
import { KBExcelUpload } from 'components'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { api, apiUtils } from 'app/services'
import { KBButton, KBPopover } from 'components'

const QINGNIU_STATIC_FILE = 'https://media-ssl.kuban.io/static'

var GuideLocation = React.createClass({
  displayName: 'GuideLocation',
  getInitialState() {
    return {
      //Location 页的 index
      locationIndex: 3,
      endStep: false
    }
  },
  selectLocationMenu(index) {
    this.setState({
      locationIndex: index
    })
  },

  preLocationStep() {
    var locationIndex = this.state.locationIndex - 1

    if (locationIndex < 1) {
      locationIndex = 1
    }

    this.setState({
      locationIndex
    })
  },

  nextLocationStep() {
    var locationIndex = this.state.locationIndex + 1

    if (locationIndex > 3) {
      locationIndex = 3
    }

    this.setState({
      locationIndex
    })
  },

  locationEnd() {
    this.setState({ endStep: true })

    this.props.routerActions.push(`/admin/locations/${this.props.location.id}`)
  },

  desksJsx() {
    const { location } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.areasImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          {/* <a href={`${QINGNIU_STATIC_FILE}/templates/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%88%86%E5%BA%97%E5%88%86%E5%8C%BA%E6%A8%A1%E6%9D%BF.xlsx`} download="批量添加分店分区">批量添加分店分区模板</a> */}
          <a
            href={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/f9c21c2b-7184-4b84-84ec-a4a537a4f81e.xlsx`}
            download="批量添加分店分区"
          >
            批量添加分店分区模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>提示:下载标准文档,填写完毕后,点击中间位置进行文档上传</span>
        </div>
      </div>
    )
  },

  parkingsJsx() {
    const { location } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.parkingImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/v2_parking_import.xlsx`}
            download="添加分店车位信息"
          >
            添加分店车位信息模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>提示:下载标准文档,填写完毕后,点击中间位置进行文档上传</span>
        </div>
      </div>
    )
  },

  orgJsx() {
    const { location } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.orgImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/添加分店公司信息.xlsx`}
            download="添加分店公司信息"
          >
            添加分店公司信息模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档,填写完毕后,点击中间位置进行文档上传,点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  subscriptionsJsx() {
    const { location } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.subscriptionsImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/导入分店合同信息.xlsx`}
            download="导入分店合同信息"
          >
            导入分店合同信息模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档,填写完毕后,点击中间位置进行文档上传,点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  orgMemberJsx() {
    const { location } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.orgMembersAsyncImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          <a
            href={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/file/员工信息登记表_.xlsx`}
            download="员工信息登记表"
          >
            员工信息登记表模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>提示：下载标准文档,填写完毕后，点击中间位置进行文档上传</span>
        </div>
        <div className="m-top-sm" style={{ color: '#E03641' }}>
          注意：导入负责人时请确认添加的邮箱为有效邮箱，否则您将无法收到验证邮件及管理端登录密码。
        </div>
      </div>
    )
  },

  continueLocation() {
    this.setState({
      locationIndex: 1,
      endStep: false
    })
  },

  render() {
    const {
      menuIndex,
      errorMsg,
      locationIndex,
      page,
      locationSuccess,
      endStep
    } = this.state
    let { location, locationMenu } = this.props

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>导入数据 - {location.name}</span>
          </div>
        </header>
        <div className="kb-form-container">
          {location ? (
            <ul className="kb-form-nav">
              {locationMenu.map((json, index) => (
                <li
                  key={index}
                  className={classNames(
                    locationIndex == json.index ? 'kb-nav-hover' : ''
                  )}
                >
                  <span
                    onClick={this.selectLocationMenu.bind(null, json.index)}
                  >
                    {json.name}
                  </span>
                  <i className="iconfont icon-navigate_next" />
                </li>
              ))}
            </ul>
          ) : (
            ''
          )}
          {
            <div
              className="space-setting"
              style={{ opacity: 1, width: '620px' }}
            >
              {locationIndex == 1 ? this.desksJsx() : ''}
              {locationIndex == 2 ? this.parkingsJsx() : ''}
              {locationIndex == 3 ? this.orgMemberJsx() : ''}
            </div>
          }
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props

  let locationObj = Object.assign(
    {},
    {
      id: '-',
      name: '-'
    },
    location
  )

  const locationMenu = [
    { index: 1, name: '空间信息' },
    // { index : 2, name : '车位信息' },
    { index: 3, name: '员工信息' }
  ]

  return {
    location: locationObj,
    locationMenu
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideLocation)
