import React from 'react'
import { Router, Route, IndexRoute, Link, browserHistory } from 'react-router'
import { routerActions } from 'react-router-redux'
import { UserAuthWrapper } from 'redux-auth-wrapper'
import {
  getRole,
  canAccessLocation,
  canAccessLocationOfRoles,
  canViewLocationFinance,
  canAccessSpaceAPP,
  canAccessSpaceTaskFeedback,
  canViewSpaceFinance,
  canAccessSpaceStatistic,
  canAccessSpaceEnterprise,
  canAccessSpaceHr,
  canAccessSpaceFaq,
  canAdminLocation,
  canAdminSpace,
  canAccessSpaceFinance,
  canAccessLocationRoles,
  canAccessSpaceCRM,
  canAccessSpaceRepair,
  canAdminLocationActivity,
  canAdminLocationGroups,
  onlyCanAdminLocationOfWeChat,
  canAdminAccount,
  isGroupContentManager
} from 'app/reducers/role'

import HomePage from './containers/HomePage'
import Register from './containers/assist/Register'
import Login from './containers/assist/Login'
import LoginOld from './containers/assist/LoginOld'
import Unauthorized from './containers/assist/Unauthorized'
import Authorized from './containers/assist/Authorized'
import VerifyState from './containers/assist/VerifyState'
import ValidateEmail from './containers/assist/ValidateEmail'
import SendResetPassword from './containers/assist/SendResetPassword'
import ResetPassword from './containers/assist/ResetPassword'
import ResetPasswordSuccess from './containers/assist/ResetPasswordSuccess'
import SendUnlockEmail from './containers/assist/SendUnlockEmail'
import UnlockUser from './containers/assist/UnlockUser'

import CreateSpacePage from './containers/spaces/Create'
import CreateSpaceSuccess from './containers/spaces/CreateSpaceSuccess'
import AdminBox from './containers/shuck/AdminBox'
import AssistBox from './containers/shuck/AssistBox'
import LocationBox from './containers/shuck/LocationBox'
import PermissionBox from './containers/shuck/PermissionBox'

import CrmBox from 'containers/shuck/CrmBox'
import CrmIndex from 'containers/crm/CrmIndex'
import CrmOpportunity from 'containers/crm/CrmOpportunity'
import CrmWinSubscriptions from 'containers/crm/CrmWinSubscriptions'
import CrmOpportunityList from 'containers/crm/CrmOpportunityList'
import CrmNewOpportunityForm from 'containers/crm/CrmNewOpportunityForm'
import CrmLeads from 'containers/crm/CrmLeads'
import CrmInventory from 'containers/crm/CrmInventory'

import Members from 'containers/spaces/Members'

import TasksList from 'containers/tasks/TasksList'
import TasksListMe from 'containers/tasks/TasksListMe'
import TasksListAll from 'containers/tasks/TasksListAll'
import TasksListFollowing from 'containers/tasks/TasksListFollowing'
import TasksListReports from 'containers/tasks/TasksListReports'
import TaskInfo from 'containers/tasks/TaskInfo'
import TasksBox from 'containers/shuck/TasksBox'
import TaskBox from 'containers/shuck/TaskBox'

import FaqBox from 'containers/shuck/FaqBox'
import FaqDownload from 'containers/faq/FaqDownload'
import FaqMessage from 'containers/faq/FaqMessage'
import FaqTopicsForm from 'containers/faq/FaqTopicsForm'
import MessagesManage from 'containers/faq/MessagesManage'
import WechatSettings from 'containers/faq/WechatSettings'
import LocationsShow from 'containers/faq/LocationsShow'
import LocationSetting from 'containers/faq/LocationSetting'

import Announcements from 'containers/announcements/Announcements'

import ServiceBox from 'containers/shuck/ServiceBox'
import ServiceIndex from 'containers/service/ServiceIndex'
import ServiceProvider from 'containers/service/ServiceProvider'
import ProviderRequest from 'containers/service/ProviderRequest'
import ProviderDetail from 'containers/service/ProviderDetail'
import ServiceRequestDetail from 'containers/service/ServiceRequestDetail'
import ServiceRequest from 'containers/service/ServiceRequest'

import LockBox from 'containers/shuck/LockBox'
import LockManageBox from 'containers/shuck/LockManageBox'
import LockIndex from 'containers/locks/LockIndex'
import LockEvents from 'containers/locks/LockEvents'
import LockInfo from 'containers/locks/LockInfo'
import LockPermissions from 'containers/locks/LockPermissions'
import LockDashboard from 'containers/locks/LockDashboard'
import ItemLockDashboard from 'containers/locks/ItemLockDashboard'
import LockEntryRecord from 'containers/locks/LockEntryRecord'
import LockInactiveUsers from 'containers/locks/LockInactiveUsers'
import LockGroups from 'containers/locks/LockGroups'
import LockGroupDetail from 'containers/locks/LockGroupDetail'

import MeetingIndex from 'containers/meeting/MeetingIndex'
import MeetingBox from 'containers/shuck/MeetingBox'
import MeetingRecord from 'containers/meeting/MeetingRecord'
import MeetingManage from 'containers/meeting/MeetingManage'
import MeetingAddBooking from 'containers/meeting/MeetingAddBooking'
import MeetingInfo from 'containers/meeting/MeetingInfo'
import MeetingTheme from 'containers/meeting/MeetingTheme'
import MeetingDeviceDetail from 'containers/meeting/MeetingDeviceDetail'
import MeetingCoupon from 'containers/meeting/MeetingCoupon'
import CouponDetail from 'containers/meeting/CouponDetail'

import ApplicationBox from 'containers/shuck/ApplicationBox'
import Applications from 'containers/application/Applications'
import ItemApplications from 'containers/application/ItemApplications'

import ValueAddedServicesList from 'containers/valueAddedServices/ValueAddedServicesList'
import ValueAddedIntention from 'containers/valueAddedServices/ValueAddedIntention'
import EditValueAddedServices from 'containers/valueAddedServices/EditValueAddedServices'

import MemberWelfareList from 'containers/memberWelfare/MemberWelfareList'
import MemberWelfareInfo from 'containers/memberWelfare/MemberWelfareInfo'

import SpaceStatisticBox from 'containers/shuck/SpaceStatisticBox'
import StatisticCrmBox from 'containers/statistic/StatisticCrmBox'
import StatisticTaskBox from 'containers/statistic/StatisticTaskBox'
import StatisticMeetingBox from 'containers/statistic/StatisticMeetingBox'
import StatisticDeskBox from 'containers/statistic/StatisticDeskBox'
import StatisticInvoiceBox from 'containers/statistic/StatisticInvoiceBox'
import StatisticOrgBox from 'containers/statistic/StatisticOrgBox'
import StatisticLockBox from 'containers/statistic/StatisticLockBox'

import SpaceLocationStatisticBox from 'containers/shuck/SpaceLocationStatisticBox'

import InvoicesBox from 'containers/invoices/InvoicesBox'
import InvoiceBox from 'containers/shuck/InvoiceBox'
import InvoicesContract from 'containers/invoices/InvoicesContract'
import Invoices from 'containers/invoices/Invoices'
import InvoicesApplicationList from 'containers/invoices/InvoicesApplicationList'
import InvoicingPoolList from 'containers/invoices/InvoicingPoolList'
import InvoicePayments from 'containers/invoices/InvoicePayments'
import InvoicesInfo from 'containers/invoices/InvoicesInfo'
import InvoiceSendEmail from 'containers/invoices/InvoiceSendEmail'
import StatisticInvoiceMonthly from 'containers/invoices/StatisticInvoiceMonthly'
import StatisticsInvoiceConfirmed from 'containers/invoices/StatisticsInvoiceConfirmed'
import DepositList from 'containers/invoices/DepositList'

import OrdersBox from 'containers/orders/OrdersBox'
import OrdersInfo from 'containers/orders/OrdersInfo'
import OrdersPersonal from 'containers/orders/OrdersPersonal'
import OrdersOrg from 'containers/orders/OrdersOrg'

import Locations from 'containers/spaces/Locations'
import Organization from 'containers/organization/Organization'
import EditOrganization from 'containers/organization/EditOrganization'
import OrganizationMembers from 'containers/organization/OrganizationMembers'
import OrgSummarize from 'containers/organization/OrgSummarize'
import OrgSubscriptions from 'containers/organization/OrgSubscriptions'
import OrgAccounting from 'containers/organization/OrgAccounting'
import OrganizationPoint from 'containers/organization/OrganizationPoint'
import OrganizationMeeting from 'containers/organization/OrganizationMeeting'
import OrganizationAnnex from 'containers/organization/OrganizationAnnex'

import Organizations from 'containers/organization/Organizations'
import CreateOrganization from 'containers/organization/createOrganization/CreateOrganization'
import NewOrgSubscription from 'containers/organization/NewOrgSubscription'
import SubscriptionDetailBox from 'containers/organization/SubscriptionDetailBox'
import OrgSubscriptionDetailHistories from 'containers/organization/OrgSubscriptionDetailHistories'
import OrgSubscriptionDetail from 'containers/organization/OrgSubscriptionDetail'
import OrgSubscriptionDetailHistory from 'containers/organization/OrgSubscriptionDetailHistory'
import OrgSubscriptionDetailInvoices from 'containers/organization/OrgSubscriptionDetailInvoices'
import LocationMemberInfo from 'containers/members/LocationMemberInfo'
import MembersMessage from 'containers/members/MembersMessage'
import MembersLocksRecord from 'containers/members/MembersLocksRecord'
import MembersLockPermission from 'containers/members/MembersLockPermission'
import MembersMeetRecord from 'containers/members/MembersMeetRecord'
import MembersPoint from 'containers/members/MembersPoint'
import MembersElevator from 'containers/members/MembersElevator'
import OrgDetailBox from 'containers/members/OrgDetailBox'
import OrgDetail from 'containers/members/OrgDetail'
import OrgMembers from 'containers/members/OrgMembers'
import LocationSettingBox from 'containers/shuck/LocationSettingBox'
import LocationSetBase from 'containers/spaces/LocationSetBase'
import LocationSetRole from 'containers/spaces/LocationSetRole'
import LocationSetInvoice from 'containers/spaces/LocationSetInvoice'
import LocationSetLock from 'containers/spaces/LocationSetLock'
import LocationSetArea from 'containers/spaces/LocationSetArea'
import LocationSetSubscription from 'containers/spaces/LocationSetSubscription'
import LocationSetMeeting from 'containers/spaces/LocationSetMeeting'
import GuideLocation from 'containers/spaces/GuideLocation'
import FloorManage from 'containers/spaces/FloorManage'
import LocationSetOpenFunc from 'containers/spaces/LocationSetOpenFunc'
import LocationSetPrinterManage from 'containers/spaces/LocationSetPrinterManage'
import LocationSetLockPermission from 'containers/spaces/LocationSetLockPermission'

import Visit from 'containers/visit/Visit'
import VisitBox from 'containers/visit/VisitBox'
import VisitDeviceManage from 'containers/visit/VisitDeviceManage'
import VisitBlackList from 'containers/visit/VisitBlackList'
import VisitDeviceAdd from 'containers/visit/VisitDeviceAdd'
import VisitSettingsBox from 'containers/visit/VisitSettingsBox'
import VisitWelcomeSet from 'containers/visit/VisitWelcomeSet'
import VisitSignInSet from 'containers/visit/VisitSignInSet'
import VisitTypeEditForm from 'containers/visit/VisitTypeEditForm'
import VisitNotifySet from 'containers/visit/VisitNotifySet'
import VisitSignOutSet from 'containers/visit/VisitSignOutSet'
import VisitSignProcedure from 'containers/visit/VisitSignProcedure'
import VisitAutomaticCheck from 'containers/visit/VisitAutomaticCheck'

import AreasList from 'containers/desks/Areas'
import AreaFloorPlan from 'containers/desks/AreaFloorPlan'
import AreasBox from 'containers/shuck/AreasBox'
import AreaBox from 'containers/shuck/AreaBox'
import AreasContainer from 'containers/desks/AreasContainer'
import AreasTimeLine from 'containers/desks/AreasTimeLine'
import Desks from 'containers/desks/Desks'
import DeskReservationList from 'containers/desks/DeskReservationList'
import LocationMembers from 'containers/members/LocationMembers'

import SpaceReportBox from 'containers/shuck/SpaceReportBox'
import ReportIndex from 'containers/report/ReportIndex'

import MembershipsBox from 'containers/memberships/MembershipsBox'
import MembershipsHome from 'containers/memberships/MembershipsHome'
import MembershipsManage from 'containers/memberships/MembershipsManage'
import MembershipsMembers from 'containers/memberships/MembershipsMembers'
import MembershipsNonMembers from 'containers/memberships/MembershipsNonMembers'
import MembershipsCards from 'containers/memberships/MembershipsCards'
import MembershipsResources from 'containers/memberships/MembershipsResources'
import MembershipsPointsRecord from 'containers/memberships/MembershipsPointsRecord'
import SpaceAddMemberShip from 'containers/memberships/SpaceAddMemberShip'
import SpaceAddMemberShipSource from 'containers/memberships/SpaceAddMemberShipSource'

import SpacesSettingBox from 'containers/shuck/SpacesSettingBox'
import SpacesSettingBase from 'containers/spaces/setting/SpacesSettingBase'
import SpacesSettingCrm from 'containers/spaces/setting/SpacesSettingCrm'
import SpacesSettingSafe from 'containers/spaces/setting/SpacesSettingSafe'
import SpaceSettingInvoice from 'containers/spaces/setting/SpaceSettingInvoice'
import SpaceEmailTemplete from 'containers/spaces/setting/SpaceEmailTemplete'
import SpaceSettingMeeting from 'containers/spaces/setting/SpaceSettingMeeting'
import SpaceSettingLockers from 'containers/spaces/setting/SpaceSettingLockers'
import SpaceSettingOptions from 'containers/spaces/setting/SpaceSettingOptions'
import SpaceSettingService from 'containers/spaces/setting/SpaceSettingService'
import SpacesSettingLocationGroups from 'containers/spaces/setting/SpacesSettingLocationGroups'
import SpaceSettingLock from 'containers/spaces/setting/SpaceSettingLock'
import SpaceSettingCurrency from 'containers/spaces/setting/SpaceSettingCurrency'
import SpacePayFlow from 'containers/spaces/setting/SpacePayFlow'
import SpaceSettingPay from 'containers/spaces/setting/SpaceSettingPay'

import SpaceMemberInfo from 'containers/members/SpaceMemberInfo'

import UserSettingBox from 'containers/shuck/UserSettingBox'
import UserInfo from 'containers/user/UserInfo'
import PassWord from 'containers/user/PassWord'

// 显示分店的首页
import LocationHomePage from 'containers/spaces/LocationHomePage'
// 显示分区的信息列表
import LocationAreas from 'containers/spaces/LocationAreas'
import LocationAreasUsage from 'containers/spaces/LocationAreasUsage'

// excel导入引导页面
import GuidePage from 'containers/spaces/GuidePage'

// 合同模板
import SpaceTempleteBox from 'containers/spaces/setting/templete/SpaceTempleteBox'
import SpaceTempleteEdit from 'containers/spaces/setting/templete/SpaceTempleteEdit'
import SpaceTempleteList from 'containers/spaces/setting/templete/SpaceTempleteList'

// 操作记录
import SpaceLogs from 'containers/spaces/setting/SpaceLogs'

// 商城
import ShoppingBox from 'containers/shopping/ShoppingBox'
import SalesProductsBox from 'containers/shopping/SalesProductsBox'
import Shops from 'containers/shopping/Shops'
import ProductCategories from 'containers/shopping/ProductCategories'
import ProductOrders from 'containers/shopping/ProductOrders'
import ProductOrderInfo from 'containers/shopping/ProductOrderInfo'
import SalesProducts from 'containers/shopping/SalesProducts'

// 活动
import ActivityBox from 'containers/activities/ActivityBox'
import ActivitiesList from 'containers/activities/ActivitiesList'
import ActivitiesInfo from 'containers/activities/ActivitiesInfo'
import ActivitiesContainer from 'containers/activities/ActivitiesContainer'
import ActivitiesOrders from 'containers/activities/ActivitiesOrders'
import ActivitiesCreateForm from 'containers/activities/ActivitiesCreateForm'
import ActivitiesComments from 'containers/activities/ActivitiesComments'

// 加购管理
import ReplacementBox from 'containers/replacement/ReplacementBox'
import ReplacementList from 'containers/replacement/ReplacementList'
import ReplacementInfo from 'containers/replacement/ReplacementInfo'
import ReplacementContainer from 'containers/replacement/ReplacementContainer'
import ReplacementOrders from 'containers/replacement/ReplacementOrders'
import ReplacementCreateForm from 'containers/replacement/ReplacementCreateForm'

// 储物柜
import LockersBox from 'containers/lockers/LockersBox'
import Lockers from 'containers/lockers/Lockers'
import LockerRecord from 'containers/lockers/LockerRecord'
import LockerCleanUpRecord from 'containers/lockers/LockerCleanUpRecord'
import LockerCategories from 'containers/lockers/LockerCategories'

// 灯控
import LightBox from 'containers/light/LightBox'
import Light from 'containers/light/Light'

// 资讯
import NewsBox from 'containers/news/NewsBox'
import NewsList from 'containers/news/NewsList'
import NewsDetail from 'containers/news/NewsDetail'
import NewsEditForm from 'containers/news/NewsEditForm'

// 社群帖子
import PostList from 'containers/posts/PostList'
import PostDetail from 'containers/posts/PostDetail'

// 车位
import ParkingBox from 'containers/parking/ParkingBox'
import ParkingManageBox from 'containers/parking/ParkingManageBox'
import ParkingList from 'containers/parking/ParkingList'
import ParkingTimeLine from 'containers/parking/ParkingTimeLine'
import ParkingContracts from 'containers/parking/ParkingContracts'
import ParkingContractCreate from 'containers/parking/ParkingContractCreate'
import ParkingReservation from 'containers/parking/ParkingReservation'
import ParkingContractPlatesRecord from 'containers/parking/ParkingContractPlatesRecord'

import AppBox from 'containers/shuck/AppBox'
import ValueAddedServicesBox from 'containers/shuck/ValueAddedServicesBox'

//项目
import Groups from 'containers/spaces/Groups'
import GroupBox from 'containers/shuck/GroupBox'
import GroupSettingBox from 'containers/shuck/GroupSettingBox'
import GroupSetBase from 'containers/spaces/GroupSetBase'
import GroupSetPayment from 'containers/spaces/GroupSetPayment'
import GroupSetVirtualMoney from 'containers/spaces/GroupSetVirtualMoney'
import GroupLockBox from 'containers/shuck/GroupLockBox'
import GroupSetRole from 'containers/spaces/GroupSetRole'
import GroupSetStation from 'containers/spaces/GroupSetStation'
import GroupSetBanner from 'containers/spaces/GroupSetBanner'
import GroupSetActivity from 'containers/spaces/GroupSetActivity'
import GroupSetPreview from 'containers/spaces/GroupSetPreview'
import GroupSetValueAddedServices from 'containers/spaces/GroupSetValueAddedServices'
// 自助认证
import SpaceAutoAuth from 'containers/shuck/SpaceAutoAuth'
import AuthList from 'containers/autoAuth/AuthList'
// 优惠券
import CouponBox from 'containers/shuck/CouponBox'
import CouponList from 'containers/coupon/CouponList'
import CouponContainer from 'containers/coupon/CouponContainer'
import CouponInfo from 'containers/coupon/CouponInfo'
import CouponForm from 'containers/coupon/CouponForm'
import CouponOrders from 'containers/coupon/CouponOrders'

// init scss
require('./styles/init.scss')

class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>Page Not Found.</h1>
        <p>
          Go to <Link to="/">Home Page</Link>
        </p>
      </div>
    )
  }
}

// https://github.com/mjrussell/redux-auth-wrapper
// Redirects to /login by default
const UserIsAuthenticated = UserAuthWrapper({
  authSelector: state => state.user, // how to get the user state
  redirectAction: routerActions.replace, // the redux action to dispatch for redirect
  wrapperDisplayName: 'UserIsAuthenticated' // a nice name for this auth check
})

// Admin Authorization, redirects non-admins to /app and don't send a redirect param
const UserIsAdmin = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserIsAdmin',
  predicate: user => canAdminSpace(user)||canAdminLocationActivity(user),
  allowRedirectBack: false
})

const UserCanAccessFinance = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessFinance',
  predicate: user => canViewSpaceFinance(user),
  allowRedirectBack: false
})

const UserCanAccessLocationFinance = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessLocationFinance',
  predicate: user => canViewLocationFinance(user),
  allowRedirectBack: false
})

const UserCanAccessRepair = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessRepair',
  predicate: user =>
    canAccessSpaceRepair(user) || canAccessSpaceTaskFeedback(user),
  allowRedirectBack: false
})

const UserCanAccessFaq = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessFaq',
  predicate: user => canAccessSpaceFaq(user),
  allowRedirectBack: false
})

const UserCanAccessAPP = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessAPP',
  predicate: user => canAccessSpaceAPP(user),
  allowRedirectBack: false
})

const UserCanAccessStatistic = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessStatistic',
  predicate: user => canAccessSpaceStatistic(user),
  allowRedirectBack: false
})

const UserCanAccessEnterprise = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessEnterprise',
  predicate: user => canAccessSpaceEnterprise(user),
  allowRedirectBack: false
})

const UserCanAccessHr = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessHr',
  predicate: user => canAccessSpaceHr(user) || canAdminAccount(user),
  allowRedirectBack: false
})

const UserCanAccessCRM = UserAuthWrapper({
  authSelector: state => state.user,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessCRM',
  predicate: user => canAccessSpaceCRM(user),
  allowRedirectBack: false
})

// UserCanAccessLocation assumes :id in URL is location id
const UserCanAccessLocation = UserAuthWrapper({
  authSelector: (state, ownProps) => {
    return ({
    user: state.user,
    location_id: ownProps.params.id,
    isGroup: ownProps.location.pathname.indexOf('/admin/groups') !== -1,
    pathname: ownProps.location.pathname
  })},
  // 分店id和项目id混淆了
  predicate: ({ user, location_id, isGroup, pathname }) =>
    canAccessLocationOfRoles(user, location_id) || isGroupContentManager(user, location_id, isGroup, pathname) || canAdminLocationGroups(user,location_id) || onlyCanAdminLocationOfWeChat(user, location_id) || (canAccessSpaceFinance(user) || canAccessLocationRoles(user, location_id, ['invoice_check', 'invoice_confirm', 'invoice_check_notify'])) || canAccessLocationRoles(user, location_id, ['landlord']),
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserCanAccessLocation',
  allowRedirectBack: false
})

// UserCanAdminLocation assumes :id in URL is location id
const UserCanAdminLocation = UserAuthWrapper({
  authSelector: (state, ownProps) => ({
    user: state.user,
    location_id: ownProps.params.id
  }),
  predicate: ({ user, location_id }) => canAdminLocation(user, location_id),
  redirectAction: routerActions.replace,
  failureRedirectPath: `/`,
  wrapperDisplayName: 'UserCanAdminLocation',
  allowRedirectBack: false
})

export default (
  <Route path="/">
    <IndexRoute component={HomePage} />
    <Route component={AssistBox}>
      <Route path="login" component={Login} />
      {
        (process.env.NODE_ENV === 'development' || process.env.REACT_APP_URLCONFIG === 'dev' || process.env.REACT_APP_URLCONFIG === 'uat') && (
          <Route path="LoginOld" component={LoginOld} />
        )
      }
      <Route path="login" component={Login} />
      <Route path="unauthorized" component={Unauthorized} />
      <Route path="authorized" component={Authorized} />
      <Route path="register" component={Register} />
      <Route path="send_unlock_email" component={SendUnlockEmail} />
      <Route path="unlock_user" component={UnlockUser} />
      <Route path="send_reset_password" component={SendResetPassword} />
      <Route path="reset_password" component={ResetPassword} />
      <Route path="reset_password_success" component={ResetPasswordSuccess} />
      <Route path="verify_state" component={VerifyState} />
      <Route path="validate_email" component={ValidateEmail} />
    </Route>
    <Route path="spaces" component={UserIsAuthenticated(AssistBox)}>
      <Route path="create" component={CreateSpacePage} />
      <Route path="create_success" component={CreateSpaceSuccess} />
    </Route>
    <Route path="admin" component={UserIsAuthenticated(AdminBox)}>
      <Route path="groups/:id" component={UserCanAccessLocation(GroupBox)}>
        <IndexRoute component={Groups} />
        <Route path="app" component={UserCanAccessLocation(AppBox)}>
          <IndexRoute component={ItemApplications} />
        </Route>
        <Route path="valueAddedServices" component={UserCanAccessLocation(ValueAddedServicesBox)}>
          <IndexRoute component={ValueAddedServicesList} />
          <Route path="intention" component={ValueAddedIntention} />
          <Route path="edit" component={EditValueAddedServices} />
        </Route>
        <Route path="memberWelfare">
          <IndexRoute component={MemberWelfareList} />
          <Route path="edit" component={MemberWelfareInfo} />
        </Route>
        <Route path="locks" component={UserCanAccessLocation(GroupLockBox)}>
          <IndexRoute component={ItemLockDashboard} />
        </Route>
        <Route
          path="statistic"
          component={UserCanAccessLocation(SpaceStatisticBox)}
        >
          <IndexRoute component={StatisticDeskBox} />
          <Route path="crm" component={StatisticCrmBox} />
          <Route path="task" component={StatisticTaskBox} />
          <Route path="meeting" component={StatisticMeetingBox} />
          <Route path="organization" component={StatisticOrgBox} />
          <Route path="invoice" component={StatisticInvoiceBox} />
          <Route path="lock" component={StatisticLockBox} />
        </Route>
        <Route path="settings" component={GroupSettingBox}>
          <IndexRoute component={GroupSetBase} />
          <Route path="meeting" component={SpaceSettingMeeting} />
          <Route path="payment" component={GroupSetPayment} />
          <Route path="virtualMoney" component={GroupSetVirtualMoney} />
          <Route path="lockers" component={SpaceSettingLockers} />
          <Route path="options" component={SpaceSettingOptions} />
          <Route path="role" component={GroupSetRole} />
          <Route path="station" component={GroupSetStation} />
          <Route path="banner" component={GroupSetBanner} />
          <Route path='finance' component={LocationSetInvoice}/>
          <Route path='activity' component={GroupSetActivity}/>
          <Route path='preview' component={GroupSetPreview}/>
          <Route path='valueAddedServices' component={GroupSetValueAddedServices}/>
          <Route path="invoice/editTemplate" component={SpaceTempleteEdit} />
        </Route>
      </Route>

      <Route path="locations" component={UserCanAccessLocation(PermissionBox)}>
        <IndexRoute component={Locations} />
      </Route>
      <Route path="employee" component={UserCanAccessHr(PermissionBox)}>
        <IndexRoute component={Members} />
      </Route>

      <Route path="invoices" component={UserCanAccessFinance(InvoiceBox)}>
        <IndexRoute component={InvoicesContract} />
        <Route path="invoices_list" component={Invoices} />
        <Route
          path="invoices_application"
          component={InvoicesApplicationList}
        />
        <Route path="invoicing_pool" component={InvoicingPoolList} />
        <Route path="invoice_payments" component={InvoicePayments} />
        <Route path="invoices_list/:invoice_id" component={InvoicesInfo} />
        <Route
          path=":invoice_id/organizations/:organization_id/subscriptions/:subscription_id"
          component={SubscriptionDetailBox}
        >
          <IndexRoute component={OrgSubscriptionDetail} />
          <Route path="history" component={OrgSubscriptionDetailHistory} />
          <Route path="histories" component={OrgSubscriptionDetailHistories} />
          <Route path="invoices" component={OrgSubscriptionDetailInvoices} />
          <Route path="plates_record" component={ParkingContractPlatesRecord} />
        </Route>
        <Route path="membership_orders" component={OrdersPersonal} />
        <Route path="membership_orders/:invoice_id" component={OrdersInfo} />
        <Route path=":invoice_id/email" component={InvoiceSendEmail} />
        <Route path="monthly_invoices" component={StatisticInvoiceMonthly} />
        <Route
          path="confirmed_invoices"
          component={StatisticsInvoiceConfirmed}
        />
        <Route path="deposit" component={DepositList} />
      </Route>
      <Route path="tasks" component={UserCanAccessRepair(TasksBox)}>
        <Route path="repair" component={TaskBox}>
          <IndexRoute component={TasksList} />
          <Route path="type/my" component={TasksListMe} />
          <Route path="type/all" component={TasksListAll} />
          <Route path="type/following" component={TasksListFollowing} />
          <Route path="type/reports" component={TasksListReports} />
          <Route path=":task_id" component={TaskInfo} />
        </Route>
        <Route path="custom_service" component={TasksList} />
        <Route path="custom_service/:task_id" component={TaskInfo} />
        <Route path="feedback" component={TasksList} />
        <Route path="feedback/:task_id" component={TaskInfo} />
      </Route>
      <Route path="shopping" component={ShoppingBox}>
        <IndexRoute component={Shops} />
        <Route path=":shopping_id" component={SalesProductsBox}>
          <IndexRoute component={SalesProducts} />
          <Route path="product_categories" component={ProductCategories} />
          <Route path="product_orders" component={ProductOrders} />
        </Route>
        <Route
          path=":shopping_id/product_orders/:invoice_id"
          component={ProductOrderInfo}
        />
      </Route>
      <Route path="faq" component={UserCanAccessAPP(FaqBox)}>
        <IndexRoute component={LocationsShow} />
        <Route path="sections" component={FaqMessage} />
        <Route path="announcements" component={Announcements} />
        <Route
          path="locations/:location_id/setting"
          component={LocationSetting}
        />
        <Route path="sections/:section_id/edit" component={FaqTopicsForm} />
        <Route
          path="sections/:section_id/edit/:topics_id"
          component={FaqTopicsForm}
        />
        <Route path="application" component={Applications} />
      </Route>
      <Route path="service" component={UserCanAccessEnterprise(ServiceBox)}>
        <IndexRoute component={ServiceIndex} />
        <Route path="provider/:provider_id" component={ServiceProvider}>
          <Route path="info" component={ProviderRequest} />
          <Route path="detail" component={ProviderDetail} />
        </Route>
        <Route path="request" component={ServiceRequest} />
        <Route
          path="request_detail/:request_id"
          component={ServiceRequestDetail}
        />
      </Route>
      <Route path="members/:member_id" component={LocationMemberInfo}>
        <IndexRoute component={MembersMessage} />
        <Route path="lock_permissions" component={MembersLockPermission} />
        <Route path="locks" component={MembersLocksRecord} />
        <Route path="meeting" component={MembersMeetRecord} />
        <Route path="member_orders" component={OrdersPersonal} />
        <Route path="member_orders/:invoice_id" component={OrdersInfo} />
        <Route path="point" component={MembersPoint} />
        <Route path="elevator" component={MembersElevator} />
      </Route>
      <Route path="organizations/:org_id" component={OrgDetailBox}>
        <IndexRoute component={OrgDetail} />
        <Route path="members" component={OrgMembers} />
      </Route>
      <Route path="memberships" component={UserIsAdmin(MembershipsBox)}>
        <IndexRoute component={MembershipsHome} />
        <Route path="memberManage" component={MembershipsManage}>
          <Route path="members" component={MembershipsMembers} />
          <Route path="members/:member_id" component={LocationMemberInfo}>
            <IndexRoute component={MembersMessage} />
            <Route path="lock_permissions" component={MembersLockPermission} />
            <Route path="locks" component={MembersLocksRecord} />
            <Route path="meeting" component={MembersMeetRecord} />
            <Route path="member_orders" component={OrdersPersonal} />
            <Route path="member_orders/:invoice_id" component={OrdersInfo} />
            <Route path="point" component={MembersPoint} />
            <Route path="elevator" component={MembersElevator} />
          </Route>
          <Route path="non_members" component={MembershipsNonMembers} />
          <Route path="cards" component={MembershipsCards} />
          <Route path="cards/setting" component={SpaceAddMemberShip} />
          <Route path="resources" component={MembershipsResources} />
          <Route
            path="resources/setting"
            component={SpaceAddMemberShipSource}
          />
          <Route path="announcements" component={Announcements} />
          <Route path="messages" component={MessagesManage} />
          <Route path="pointsRecord" component={MembershipsPointsRecord} />
          <Route path="activities" component={ActivitiesList} />
          <Route path="activities/edit" component={ActivitiesCreateForm} />
          <Route path="activities/:activity_id" component={ActivitiesContainer}>
            <Route path="info" component={ActivitiesInfo} />
            <Route path="orders" component={ActivitiesOrders} />
            <Route path="comments" component={ActivitiesComments} />
          </Route>
          <Route path="memberWelfare">
            <IndexRoute component={MemberWelfareList} />
            <Route path="edit" component={MemberWelfareInfo} />
          </Route>
          <Route path="news" component={NewsList} />
          <Route path="news/edit" component={NewsEditForm} />
          <Route path="news/:news_id/detail" component={NewsDetail} />
          <Route path="posts" component={PostList} />
          <Route path="posts/:post_id" component={PostDetail} />
        </Route>
      </Route>
      <Route path="spaces_setting" component={UserIsAdmin(SpacesSettingBox)}>
        <IndexRoute component={SpacesSettingBase} />
        <Route path="crm" component={SpacesSettingCrm} />
        <Route path="security" component={SpacesSettingSafe} />
        <Route path="invoice" component={SpaceSettingInvoice} />
        <Route path="pay" component={SpaceSettingPay}>
          <IndexRoute component={SpacePayFlow} />
        </Route>
        <Route path="templete" component={SpaceTempleteBox}>
          <IndexRoute component={SpaceTempleteList} />
          <Route path="edit" component={SpaceTempleteEdit} />
        </Route>
        <Route path="templete_email" component={SpaceEmailTemplete} />
        <Route path="meeting" component={SpaceSettingMeeting} />
        <Route path="lock" component={SpaceSettingLock} />
        <Route path="currency" component={SpaceSettingCurrency} />
        <Route path="logs" component={SpaceLogs} />
        <Route path="options" component={SpaceSettingOptions} />
        <Route path="service" component={SpaceSettingService} />
        <Route path="location_groups" component={SpacesSettingLocationGroups} />
        <Route path="wechat" component={WechatSettings} />
      </Route>
      <Route path="autoAuth" component={SpaceAutoAuth}>
        <IndexRoute component={AuthList} />
      </Route>
      <Route path="coupon" component={CouponBox}>
        <IndexRoute component={CouponList} />
        <Route path="couponForm" component={CouponForm} />
        <Route path=":couponId" component={CouponContainer}>
          <Route path="info" component={CouponInfo} />
          <Route path="orders" component={CouponOrders} />
        </Route>
      </Route>
      <Route path="crm" component={UserCanAccessCRM(CrmBox)}>
        <IndexRoute component={CrmIndex} />
        <Route path="opportunities" component={CrmOpportunityList} />
        <Route path="opportunities/new" component={CrmNewOpportunityForm} />
        <Route path="opportunities/:opp_id" component={CrmOpportunity} />
        <Route path="opportunities/sub">
          <Route
            path="organizations/:organization_id/subscriptions/new"
            component={NewOrgSubscription}
          />
          <Route
            path="organizations/:organization_id/subscriptions/:subscription_id"
            component={SubscriptionDetailBox}
          >
            <IndexRoute component={OrgSubscriptionDetail} />
            <Route path="history" component={OrgSubscriptionDetailHistory} />
            <Route
              path="histories"
              component={OrgSubscriptionDetailHistories}
            />
            <Route path="invoices" component={OrgSubscriptionDetailInvoices} />
            <Route
              path="plates_record"
              component={ParkingContractPlatesRecord}
            />
          </Route>
        </Route>
        <Route
          path="winsubscription/:invoice_id/organizations/:organization_id/subscriptions/:subscription_id"
          component={SubscriptionDetailBox}
        >
          <IndexRoute component={OrgSubscriptionDetail} />
          <Route path="history" component={OrgSubscriptionDetailHistory} />
          <Route path="histories" component={OrgSubscriptionDetailHistories} />
          <Route path="invoices" component={OrgSubscriptionDetailInvoices} />
          <Route path="plates_record" component={ParkingContractPlatesRecord} />
        </Route>
        <Route path="winsubscription" component={CrmWinSubscriptions} />
        <Route path="leads" component={CrmLeads} />
        <Route path="inventory" component={CrmInventory} />
        <Route path="inventory_timeline" component={AreasTimeLine} />
      </Route>
      <Route path="guide" component={UserIsAdmin(GuidePage)} />
      <Route
        path="locations/:id"
        component={UserCanAccessLocation(LocationBox)}
      >
        <IndexRoute component={LocationHomePage} />
        <Route path="coupon" component={CouponBox}>
          <IndexRoute component={CouponList} />
          <Route path="couponForm" component={CouponForm} />
          <Route path=":couponId" component={CouponContainer}>
            <Route path="info" component={CouponInfo} />
            <Route path="orders" component={CouponOrders} />
          </Route>
        </Route>
        <Route path="visit" component={VisitBox}>
          <IndexRoute component={Visit} />
          <Route path="invite" component={Visit} />
          <Route path="devices" component={VisitDeviceManage} />
          <Route path="devices/add" component={VisitDeviceAdd} />
          <Route path="settings" component={VisitSettingsBox}>
            <IndexRoute component={VisitWelcomeSet} />
            <Route path="sign_in" component={VisitSignInSet} />
            <Route path="visitor_type_edit" component={VisitTypeEditForm} />
            <Route path="notify" component={VisitNotifySet} />
            <Route path="sign_out" component={VisitSignOutSet} />
            <Route path="sign_procedure" component={VisitSignProcedure} />
            <Route path="automatic_check" component={VisitAutomaticCheck} />
          </Route>
          <Route path="black_list" component={VisitBlackList} />
        </Route>
        <Route path="location_areas_usage" component={LocationAreasUsage} />
        <Route path="location_areas" component={LocationAreas} />
        <Route path="organizations" component={Organizations} />
        <Route path="new_organization" component={CreateOrganization} />
        <Route
          path="organizations/:organization_id/subscriptions/new"
          component={NewOrgSubscription}
        />
        <Route
          path="organizations/:organization_id/subscriptions/:subscription_id"
          component={SubscriptionDetailBox}
        >
          <IndexRoute component={OrgSubscriptionDetail} />
          <Route path="history" component={OrgSubscriptionDetailHistory} />
          <Route path="histories" component={OrgSubscriptionDetailHistories} />
          <Route path="invoices" component={OrgSubscriptionDetailInvoices} />
          <Route path="plates_record" component={ParkingContractPlatesRecord} />
        </Route>
        <Route path="edit_organization" component={EditOrganization} />
        <Route path="organizations/:organization_id" component={Organization}>
          <IndexRoute component={OrgSummarize} />
          <Route path="members" component={OrganizationMembers} />
          <Route path="contracts" component={OrgSubscriptions} />
          <Route path="payments" component={OrgAccounting} />
          <Route path="orders" component={OrdersOrg} />
          <Route path="point" component={OrganizationPoint} />
          <Route path="meeting" component={OrganizationMeeting} />
          <Route path="annex" component={OrganizationAnnex} />
        </Route>

        <Route path="members" component={LocationMembers} />

        <Route path="locks" component={UserCanAccessLocation(LockBox)}>
          <IndexRoute component={LockEvents} />
          <Route
            path="lock_info"
            component={UserCanAccessLocation(LockManageBox)}
          >
            <Route path="lock_list" component={LockIndex} />
            <Route path="lock_list/:lock_id" component={LockInfo} />
            <Route path="lock_group" component={LockGroups} />
            <Route
              path="lAreasContainerock_group/:group_id"
              component={LockGroupDetail}
            />
          </Route>
          <Route path="lock_permission" component={LockPermissions} />
          <Route path="lock_dashboard" component={LockDashboard} />
          <Route path="entry_record" component={LockEntryRecord} />
          <Route path="inactive_users" component={LockInactiveUsers} />
        </Route>

        <Route path="areas" component={AreaBox}>
          <Route path="desks" component={AreasBox}>
            <IndexRoute component={AreaFloorPlan} />
            <Route path="area" component={AreasContainer} />
            <Route path="list" component={AreasList} />
            <Route path="time_line" component={AreasTimeLine} />
          </Route>
          <Route path="desk_reservation" component={DeskReservationList} />
          <Route path='areacoupon' component={MeetingCoupon}/>
        </Route>
        <Route path="parking" component={ParkingBox}>
          <Route path="manage" component={ParkingManageBox}>
            <IndexRoute component={ParkingList} />
            <Route path="time_line" component={ParkingTimeLine} />
          </Route>
          <Route path="contract" component={ParkingContracts} />
          <Route
            path="contract/:subscription_id"
            component={SubscriptionDetailBox}
          >
            <IndexRoute component={OrgSubscriptionDetail} />
            <Route path="history" component={OrgSubscriptionDetailHistory} />
            <Route
              path="histories"
              component={OrgSubscriptionDetailHistories}
            />
            <Route path="invoices" component={OrgSubscriptionDetailInvoices} />
            <Route
              path="plates_record"
              component={ParkingContractPlatesRecord}
            />
          </Route>
          <Route path="contract_new" component={ParkingContractCreate} />
          <Route path="reserve" component={ParkingReservation} />
        </Route>
        <Route path='meetingCoupon/detail' component={CouponDetail}/>
        <Route path="meeting" component={MeetingBox}>
          <IndexRoute component={MeetingIndex} />
          <Route path="record" component={MeetingRecord} />
          <Route path="manage" component={MeetingManage} />
          <Route path="booking" component={MeetingAddBooking} />
          <Route path="info/:meeting_id" component={MeetingInfo} />
          <Route path="theme" component={MeetingTheme} />
          <Route path="theme/add" component={VisitDeviceAdd} />
          <Route path="theme/:device_id" component={MeetingDeviceDetail} />
          <Route path='coupon' component={MeetingCoupon}/>
        </Route>
        <Route path="tasks" component={TasksBox}>
          <Route path="repair" component={TaskBox}>
            <IndexRoute component={TasksList} />
            <Route path="type/my" component={TasksListMe} />
            <Route path="type/all" component={TasksListAll} />
            <Route path="type/following" component={TasksListFollowing} />
            <Route path="type/reports" component={TasksListReports} />
            <Route path=":task_id" component={TaskInfo} />
          </Route>
          <Route path="custom_service" component={TasksList} />
          <Route path="custom_service/:task_id" component={TaskInfo} />
          <Route path="feedback" component={TasksList} />
          <Route path="feedback/:task_id" component={TaskInfo} />
        </Route>
        <Route
          path="invoices"
          component={UserCanAccessLocationFinance(InvoiceBox)}
        >
          <IndexRoute component={InvoicesContract} />
          <Route path="invoices_list" component={Invoices} />
          <Route path="invoices_list/:invoice_id" component={InvoicesInfo} />
          <Route
            path="invoices_list/:invoice_id/email"
            component={InvoiceSendEmail}
          />
          <Route
            path="invoices_application"
            component={InvoicesApplicationList}
          />
          <Route path="invoicing_pool" component={InvoicingPoolList} />
          <Route path="invoice_payments" component={InvoicePayments} />
          <Route path="location_orders" component={OrdersPersonal} />
          <Route path="location_orders/:invoice_id" component={OrdersInfo} />
          <Route path="monthly_invoices" component={StatisticInvoiceMonthly} />
          <Route
            path="confirmed_invoices"
            component={StatisticsInvoiceConfirmed}
          />
          <Route path="deposit" component={DepositList} />
        </Route>
        <Route path="activities" component={UserCanAccessLocation(ActivityBox)}>
          <IndexRoute component={ActivitiesList} />
          <Route path="edit" component={ActivitiesCreateForm} />
          <Route path=":activity_id" component={ActivitiesContainer}>
            <Route path="info" component={ActivitiesInfo} />
            <Route path="orders" component={ActivitiesOrders} />
            <Route path="comments" component={ActivitiesComments} />
          </Route>
        </Route>
        {/* 加购管理开始 */}
        <Route path="replacement" component={UserCanAccessLocation(ReplacementBox)}>
          <IndexRoute component={ReplacementList} />
          <Route path="edit" component={ReplacementCreateForm} />
          <Route path=":activity_id" component={ReplacementContainer}>
            <Route path="info" component={ReplacementInfo} />
            <Route path="orders" component={ReplacementOrders} />
          </Route>
        </Route>
        {/* 加购管理结束 */}
        <Route path="autoAuth" component={UserCanAccessLocation(SpaceAutoAuth)}>
          <IndexRoute component={AuthList} />
        </Route>
        <Route path="news" component={UserCanAccessLocation(NewsBox)}>
          <IndexRoute component={NewsList} />
          <Route path="edit" component={NewsEditForm} />
          <Route path=":news_id/detail" component={NewsDetail} />
        </Route>
        <Route path="lockers" component={LockersBox}>
          <IndexRoute component={Lockers} />
          <Route path="record" component={LockerRecord} />
          <Route path="categories" component={LockerCategories} />
          <Route path="cleanup" component={LockerCleanUpRecord} />
        </Route>
        <Route path="light" component={LightBox}>
          <IndexRoute component={Light} />
        </Route>
        <Route path="settings" component={LocationSettingBox}>
          <IndexRoute component={LocationSetBase} />
          <Route path="role" component={LocationSetRole} />
          <Route path="invoice" component={LocationSetInvoice} />
          <Route path="invoice/edit" component={SpaceTempleteEdit} />
          <Route path="area" component={LocationSetArea} />
          <Route path="subscription" component={LocationSetSubscription} />
          <Route path="lock" component={LocationSetLock} />
          <Route path="meeting" component={LocationSetMeeting} />
          <Route path="guide" component={GuideLocation} />
          <Route path="floorinfo" component={FloorManage} />
          <Route path="open_func" component={LocationSetOpenFunc} />
          <Route path="printer" component={LocationSetPrinterManage} />
          <Route path="lock_permission" component={LocationSetLockPermission} />
          <Route path="third_equity" component={LocationSetLockPermission} />
        </Route>
        <Route path="app" component={UserCanAccessLocation(AppBox)}>
          <IndexRoute component={Applications} />
        </Route>
      </Route>

      <Route path="report" component={SpaceReportBox}>
        <IndexRoute component={ReportIndex} />
      </Route>

      <Route path="user_setting" component={UserSettingBox}>
        <IndexRoute component={UserInfo} />
        <Route path="password" component={PassWord} />
      </Route>

      {/* <Route path='employee/:member_id' component = { UserCanAccessHr(SpaceMemberInfo) }>
          <IndexRoute component={ SpaceMemberInfo }/>
        </Route> */}
    </Route>
    <Route path="*" component={PageNotFound} />
  </Route>
)
