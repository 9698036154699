import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import * as selectors from 'app/selectors'
import { api } from 'app/services'
import { locationActions, successState } from 'app/actions'
import { bindActionCreators } from 'redux'
var VisitSignProcedure = React.createClass({
  // changeSlidesVisiblity (field) {
  //   const { updateVisitorSettings, config } = this.props
  //   let value = !this.props.signout[field]
  //   let { signout } = config
  //   signout[field] = value
  //   config.signout = signout
  //   updateVisitorSettings(config)
  // },
  changeSlidesVisiblity(visitorStatus) {
    const { location_id, updateLocationAction, successAction } = this.props
    const newVisitorStatus = !visitorStatus
    api.updateLocation(location_id, { visitor_visible: newVisitorStatus }).then(
      json => {
        updateLocationAction.success(json)
        // successAction({ message: '开启成功!' })
        newVisitorStatus
          ? successAction({ message: '已开启!' })
          : successAction({ message: '已关闭!' })
      },
      errors => {
        updateLocationAction.failure(errors)
      }
    )
  },

  render() {
    const { allow_sign_out, auto_sign_out } = this.props.signout
    const { visitorVisible } = this.props
    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>访客小程序</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div className="visit-setting-block">
              <div className="title-container" style={{ marginBottom: 0 }}>
                <div className="title-and-des">
                  <div className="title">分店是否在小程序中可见</div>
                </div>
                <div
                  className="slide-btn"
                  onClick={this.changeSlidesVisiblity.bind(
                    null,
                    visitorVisible
                  )}
                >
                  <span
                    className={`slide-bg ${
                      visitorVisible ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config, params } = props
  let signout = (config && config.signout) || {}
  const location_id = params.id
  const currentLocation = selectors.getCurrentLocation(state, location_id)
  let visitorVisible = currentLocation && currentLocation.visitor_visible
  return {
    signout,
    config,
    location_id,
    visitorVisible
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSignProcedure)
