import moment from 'moment'
import _ from 'lodash'

export const MEETING_DEFAULT_COUNT = 48
export const MEETING_START_TIME = 0
export const MEETING_END_TIME = 24
export const MEETING_TIME_COUNT = MEETING_END_TIME - MEETING_START_TIME
export const MEETING_DEFAULT_GRID_WIDTH = 20

// 初始化统计时间
export function getStatisticInitialDate(data = new Date(), type = 'weeks') {
  let defaultTime = moment(data).subtract(1, 'days')
  var from = null

  if (type == 'daily') {
    from = defaultTime
      .clone()
      .subtract(1, 'months')
      .toDate()
  } else if (type == 'monthly') {
    from = defaultTime
      .clone()
      .subtract(12, 'months')
      .toDate()
  } else {
    from = defaultTime
      .clone()
      .subtract(3, 'months')
      .toDate()
  }

  let to = defaultTime.toDate()

  return {
    from,
    to
  }
}

/**
 * 初始时间
 */
export function getInitialDate(time) {
  let currentTime = moment(time) || moment()

  let year = currentTime.get('year')
  let month = currentTime.get('month')
  let date = currentTime.get('date')
  const START_DATE = currentTime
    .clone()
    .set({ year, month, date, hour: MEETING_START_TIME, minute: 0, second: 0 })
  const END_DATE = currentTime
    .clone()
    .set({
      year,
      month,
      date,
      hour: MEETING_END_TIME - 1,
      minute: 59,
      second: 59
    })

  return {
    initStartDate: START_DATE,
    initEndDate: END_DATE,
    initStartDateString: START_DATE.format('YYYY-MM-DD HH:mm:ss'),
    initEndDateString: END_DATE.format('YYYY-MM-DD HH:mm:ss')
  }
}

export function getWeeks(weekNumber) {
  let w = weekNumber || 0
  let day = moment().add(w * 7, 'day')
  let startDay = day
    .clone()
    .startOf('week')
    .set({ hour: MEETING_START_TIME, minute: 0, second: 0 })
  let endDay = day
    .clone()
    .endOf('week')
    .set({ hour: MEETING_END_TIME - 1, minute: 59, second: 59 })

  return {
    startDay,
    endDay
  }
}

// 星期 转换成 中英的显示
export const WEEK = {
  en: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
}

export var Highcharts = require('highcharts')
require('components/plugins/highchart/noDataHint')(Highcharts)
require('highcharts/modules/funnel')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts-export-csv')(Highcharts)

// export var DefaultHighcharts = Highcharts
export var setDeskHighcharts = () => {
  Highcharts.theme = {
    colors: [
      '#2b908f',
      '#90ee7e',
      '#f45b5b',
      '#7798BF',
      '#aaeeee',
      '#ff0066',
      '#eeaaee',
      '#55BF3B',
      '#DF5353',
      '#7798BF',
      '#aaeeee'
    ],
    chart: {
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [
          [0, '#2a2a2b'],
          [1, '#3e3e40']
        ]
      },
      style: {
        fontFamily: "'Unica One', sans-serif"
      },
      plotBorderColor: '#606063'
    },
    title: {
      style: {
        color: '#E0E0E3',
        textTransform: 'uppercase',
        fontSize: '20px'
      }
    },
    subtitle: {
      style: {
        color: '#E0E0E3',
        textTransform: 'uppercase'
      }
    },
    xAxis: {
      gridLineColor: '#707073',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#707073',
      minorGridLineColor: '#505053',
      tickColor: '#707073',
      title: {
        style: {
          color: '#A0A0A3'
        }
      }
    },
    yAxis: {
      gridLineColor: '#707073',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#707073',
      minorGridLineColor: '#505053',
      tickColor: '#707073',
      tickWidth: 1,
      title: {
        style: {
          color: '#A0A0A3'
        }
      }
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      style: {
        color: '#F0F0F0'
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          color: '#B0B0B3'
        },
        marker: {
          lineColor: '#333'
        }
      },
      boxplot: {
        fillColor: '#505053'
      },
      candlestick: {
        lineColor: 'white'
      },
      errorbar: {
        color: 'white'
      }
    },
    legend: {
      itemStyle: {
        color: '#E0E0E3'
      },
      itemHoverStyle: {
        color: '#FFF'
      },
      itemHiddenStyle: {
        color: '#606063'
      }
    },
    credits: {
      style: {
        color: '#666'
      }
    },
    labels: {
      style: {
        color: '#707073'
      }
    },

    drilldown: {
      activeAxisLabelStyle: {
        color: '#F0F0F3'
      },
      activeDataLabelStyle: {
        color: '#F0F0F3'
      }
    },

    navigation: {
      buttonOptions: {
        symbolStroke: '#DDDDDD',
        theme: {
          fill: '#505053'
        }
      }
    },

    // scroll charts
    rangeSelector: {
      buttonTheme: {
        fill: '#505053',
        stroke: '#000000',
        style: {
          color: '#CCC'
        },
        states: {
          hover: {
            fill: '#707073',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          },
          select: {
            fill: '#000003',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          }
        }
      },
      inputBoxBorderColor: '#505053',
      inputStyle: {
        backgroundColor: '#333',
        color: 'silver'
      },
      labelStyle: {
        color: 'silver'
      }
    },

    navigator: {
      handles: {
        backgroundColor: '#666',
        borderColor: '#AAA'
      },
      outlineColor: '#CCC',
      maskFill: 'rgba(255,255,255,0.1)',
      series: {
        color: '#7798BF',
        lineColor: '#A6C7ED'
      },
      xAxis: {
        gridLineColor: '#505053'
      }
    },

    scrollbar: {
      barBackgroundColor: '#808083',
      barBorderColor: '#808083',
      buttonArrowColor: '#CCC',
      buttonBackgroundColor: '#606063',
      buttonBorderColor: '#606063',
      rifleColor: '#FFF',
      trackBackgroundColor: '#404043',
      trackBorderColor: '#404043'
    },

    // special colors for some of the
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
  }

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme)

  return Highcharts
}

export const setSendHightcharts = () => {
  Highcharts.theme = {
    colors: [
      '#f45b5b',
      '#8085e9',
      '#8d4654',
      '#7798BF',
      '#aaeeee',
      '#ff0066',
      '#eeaaee',
      '#55BF3B',
      '#DF5353',
      '#7798BF',
      '#aaeeee'
    ],
    chart: {
      backgroundColor: null,
      style: {
        fontFamily: 'Signika, serif'
      }
    },
    title: {
      style: {
        color: 'black',
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    subtitle: {
      style: {
        color: 'black'
      }
    },
    tooltip: {
      borderWidth: 0
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '13px'
      }
    },
    xAxis: {
      labels: {
        style: {
          color: '#6e6e70'
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: '#6e6e70'
        }
      }
    },
    plotOptions: {
      series: {
        shadow: true
      },
      candlestick: {
        lineColor: '#404048'
      },
      map: {
        shadow: false
      }
    },

    // Highstock specific
    navigator: {
      xAxis: {
        gridLineColor: '#D0D0D8'
      }
    },
    rangeSelector: {
      buttonTheme: {
        fill: 'white',
        stroke: '#C0C0C8',
        'stroke-width': 1,
        states: {
          select: {
            fill: '#D0D0D8'
          }
        }
      }
    },
    scrollbar: {
      trackBorderColor: '#C0C0C8'
    },

    // General
    background2: '#E0E0E8'
  }

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme)
}

const DefaultHighchartsConfig = {
  credits: {
    enabled: false
  },
  chart: {
    animation: true
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  }
}

export const extendDefaultHighchartsConfig = config => {
  return _.merge({}, DefaultHighchartsConfig, config)
}

export var setDefaultHighcharts = () => {
  Highcharts.setOptions({})
  return Highcharts
}

;(() => {
  setDefaultHighcharts()
  // setDeskHighcharts()
  // setSendHightcharts()

  Highcharts.setOptions({
    lang: {
      printChart: '打印图表',
      downloadJPEG: '下载JPEG 图片',
      downloadPDF: '下载PDF文档',
      downloadPNG: '下载PNG 图片',
      downloadSVG: '下载SVG 矢量图',
      downloadCSV: '下载CSV 文件',
      downloadXLS: '下载XLS 文件',
      viewData: '显示图表数据',
      exportButtonTitle: '导出图片'
    }
  })

  if (Highcharts.getOptions().exporting) {
    Highcharts.getOptions().exporting.buttons.contextButton.menuItems.splice(
      2,
      2
    )
  }
})()
