import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { successState } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBUserInput,
  KBPopover,
  KBSelect,
  KBTextarea,
  KBMorePhotoUpload
} from 'components'
import {
  LOCATION_SEND_MESSAGE_RANGE,
  SEND_MESSAGE_RANGE_OBJ,
  DUTIES_TYPE_OBJ
} from 'app/constants'
import * as selectors from 'app/selectors'

var LocationSendMessageForm = React.createClass({
  getInitialState() {
    return {
      location_members: this.props.location_members || []
    }
  },

  componentDidMount() {
    const {
      fields: { message_content },
      msgContent
    } = this.props
    if (msgContent) {
      message_content.onChange(msgContent)
    }
  },

  sendMessage(model) {
    const { loc_id, activity } = this.props
    if (loc_id) {
      model.location_ids = loc_id
    }
    if (activity) {
      model.source_id = activity.id
      model.source_type = 'Activity'
      model.payload = {
        url: activity.detail_url
      }
    }
    KBPopover.close()
    this.certainSend(model)
  },

  certainSend(model) {
    const { successActions, memberType, role, callback } = this.props
    if (
      memberType == 'message' ||
      !memberType ||
      (memberType == 'member' && role == '')
    ) {
      KBPopover.plugins.confirm(
        '是否发送',
        `确定发送消息给${SEND_MESSAGE_RANGE_OBJ[model.to_type]}`,
        {
          confirm: () => {
            api.createBroadcastMessages(model).then(() => {
              KBPopover.close()
              successActions({
                title: `消息发送成功`,
                message: '请前往“消息”页查看详情'
              })
              callback && callback()
            })
          }
        }
      )
    } else if (memberType == 'member' && role != '') {
      model.to_type = role
      model.to_type = model.to_type != 'employer' ? role : 'all_ceo'
      KBPopover.plugins.confirm(
        '是否发送',
        `确定发送消息给${DUTIES_TYPE_OBJ[role]}`,
        {
          confirm: () => {
            api.createBroadcastMessages(model).then(() => {
              KBPopover.close()
              successActions({
                title: `消息发送成功`,
                message: '请前往“消息”页查看详情'
              })
              callback && callback()
            })
          }
        }
      )
    }
  },

  render() {
    const {
      fields: { message_content, location_ids, to_type, open_image_url },
      error,
      submitting,
      handleSubmit,
      loc_id,
      role
    } = this.props
    const { allLocations } = this.props
    const location = allLocations.filter(json => json.id == loc_id)
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }

    return (
      <form onSubmit={handleSubmit(this.sendMessage)}>
        <header className="kb-form-header">
          <h2 className="header-title">发送消息</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div className="kb-form-container">
          <p style={{ marginBottom: 15 }}>通过App内通知给会员群发即时消息</p>
          <div className="kb-row">
            {role && role != '' ? (
              <div className="kb-form-group kb-form-c18 kb-form-alone">
                <label>选择范围</label>
                <div className="group-content color-nine">
                  <span field={to_type} style={{ fontWeight: 'normal' }}>
                    {DUTIES_TYPE_OBJ[role]}
                  </span>
                </div>
              </div>
            ) : (
              <KBSelect
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="发布范围"
                field={to_type}
                arrayData={LOCATION_SEND_MESSAGE_RANGE}
              />
            )}
          </div>
          <div className="kb-row">
            {loc_id ? (
              <div className="kb-form-group kb-form-c18 kb-form-alone">
                <label>选择分店</label>
                <div className="group-content color-nine">
                  <span style={{ fontWeight: 'normal' }}>
                    {location[0].name}
                  </span>
                </div>
              </div>
            ) : (
              <KBUserInput
                title="选择分店"
                {...oneClass}
                placeholder="选择分店"
                field={location_ids}
                isRequired={false}
                multi={true}
                users={allLocations}
              />
            )}
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={message_content}
              title="消息内容"
            />
          </div>
          <div className="kb-row f-left">
            <KBMorePhotoUpload
              title="消息图片"
              multiple={false}
              defaultImages={open_image_url.values || []}
              // uploadedText='上传图片'
              photoShow={true}
              // sort={true}
              className="kb-form-group kb-form-alone"
              field={open_image_url}
              img_width={232}
              img_height={232}
              // hint='我们建议上传1000px*1000px的正方形图片，如果您想自己设计图片请下载：PSD Sketch'
              text="上传图片"
              accept="image/*,.psd,.sketch"
              imgStyle={{ width: '232px', height: '232px', marginBottom: 10 }}
              prefix={`images/messages`}
              isPublic
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              发送
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let allLocations = selectors.getLocations(state)

  return {
    allLocations,
    initialValues: {
      to_type: 'all_user'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (valid.required(values.location_ids)) {
  //   errors.location_ids = '请选择分店'
  // }

  if (valid.required(values.message_content)) {
    errors.message_content = '消息内容不能为空'
  }

  return errors
}

const formConfig = {
  form: 'SelectOrganizationForm',
  fields: [
    'message_content',
    'location_ids',
    'to_type',
    'to_value',
    'open_image_url'
  ],
  validate: validate,
  touchOnBlur: false
}

LocationSendMessageForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSendMessageForm)

export default LocationSendMessageForm
