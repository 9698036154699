import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput, KBSelect } from 'components'
import * as selectors from 'app/selectors'
import { CONTRACTAPPROVALSETTING } from 'app/constants'
import { APPROVE_CONTRACT } from 'app/constants/hint'
import * as role from 'app/reducers/role'

var SpacesSettingCRMForm = React.createClass({
  displayName: 'SpacesSettingCRMForm',
  updateRoles({ contract_role_setting, contract_role_setting_users }) {
    const { updateSpaceRole } = this.props
    return updateSpaceRole({
      role: 'contract',
      range: contract_role_setting,
      user_ids:
        typeof contract_role_setting_users === 'string' &&
        contract_role_setting_users
          ? contract_role_setting_users.split(',')
          : []
    })
  },
  render() {
    const {
      fields: { contract_role_setting, contract_role_setting_users },
      error,
      submitting,
      handleSubmit,
      users,
      selectUsers
    } = this.props
    const hint = APPROVE_CONTRACT
    return (
      <form onSubmit={handleSubmit(this.updateRoles)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <KBFormError err={error} />
          <div className="kb-row">
            <KBSelect
              title="审批设置"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={contract_role_setting}
              arrayData={CONTRACTAPPROVALSETTING}
            />
          </div>
          {contract_role_setting.value === 'group' && (
            <div>
              <KBUserInput
                className={'kb-form-group kb-form-c18 kb-form-alone'}
                hint={hint.approver}
                title={'审批人'}
                users={users}
                selectedUsers={selectUsers}
                multi={true}
                field={contract_role_setting_users}
              />
            </div>
          )}
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { roles_settings, roles } = props.spaceRole
  const setting_value = roles_settings.contract_role_setting
  let users = selectors.getSpaceUsers(state)
  let user_group = role.getRoleUserByKey(roles, 'contract')
  let selectUsers = selectors.getUsersByUserIds(state, user_group) || []

  return {
    users,
    selectUsers,
    initialValues: {
      contract_role_setting: setting_value,
      contract_role_setting_users: user_group || []
    }
  }
}

const formConfig = {
  form: 'SpacesSettingCRMForm',
  fields: ['contract_role_setting', 'contract_role_setting_users'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpacesSettingCRMForm)
