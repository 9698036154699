import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBInput, KBFormError, KBButton } from 'components'

var PassWord = React.createClass({
  displayName: 'PassWord',
  render() {
    const {
      fields: { current_password, password, password_confirmation },
      error,
      submitting,
      handleSubmit,
      updateUserPasswordForm
    } = this.props

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title">
            <span>账号密码</span>
          </div>
        </header>
        <div className="title-box">
          <div className="kb-title">
            <span className="color-six">登录密码</span>
          </div>
        </div>
        <form onSubmit={handleSubmit(updateUserPasswordForm)}>
          <div className="kb-form-container">
            <KBFormError err={error} />
            <div className="kb-row">
              <KBInput
                title="当前密码"
                field={current_password}
                type="password"
                className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
              />
            </div>
            <div className="kb-row">
              <KBInput
                title="新密码"
                field={password}
                type="password"
                className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
              />
            </div>
            <div className="kb-row">
              <KBInput
                title="确认密码"
                field={password_confirmation}
                type="password"
                className="kb-form-group kb-form-c18 kb-form-alone o-hidden"
              />
            </div>
            <div className="kb-row">
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </form>
      </section>
    )
  }
})

const validate = values => {
  const errors = {}

  if (valid.required(values.current_password)) {
    errors.current_password = '请输入当前密码'
  }

  if (valid.required(values.password)) {
    errors.password = '请输入新密码'
  }

  if (valid.required(values.password_confirmation)) {
    errors.password_confirmation = '请输入确认密码'
  }

  if (values.current_password == values.password) {
    errors.password = '新密码不能与当前密码相同'
  }

  if (values.password_confirmation != values.password) {
    errors.password_confirmation = '请输入两次密码输入不一致'
  }

  return errors
}

const formConfig = {
  form: 'PassWordForm',
  fields: ['current_password', 'password', 'password_confirmation'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(formConfig)(PassWord)
