import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {} from 'app/actions'
import { KBPopover, KBLoadingContainer } from 'components'
import VisitAddNewTypeForm from './VisitAddNewTypeForm'

var VisitSignInSet = React.createClass({
  changeSlidesVisiblity(index) {
    const { updateVisitorSettings, config, signin } = this.props
    let newConfig = Object.assign({}, config)
    let newValue = !signin.visitor_types[index].enabled
    newConfig.signin.visitor_types[index].enabled = newValue
    updateVisitorSettings(newConfig)
  },

  movePosition(index, type) {
    const { updateVisitorSettings, config, signin } = this.props
    const { visitor_types } = signin
    let newConfig = Object.assign({}, config)
    let newVisitTypes = visitor_types || []
    let movingEle = visitor_types[index]
    newVisitTypes.splice(index, 1)
    let indexAdd = type === 'up' ? index - 1 : index + 1
    newVisitTypes.splice(indexAdd, 0, movingEle)
    newConfig.signin.visitor_types = newVisitTypes
    updateVisitorSettings(newConfig)
  },

  toEditPage(index) {
    const { location_id, routerActions } = this.props
    routerActions.push(
      `/admin/locations/${location_id}/visit/settings/visitor_type_edit?index=${index}&type=sign_in`
    )
  },

  addNewVisitorType() {
    const { config, updateVisitorSettings } = this.props
    KBPopover.show(
      <VisitAddNewTypeForm
        config={config}
        updateVisitorSettings={updateVisitorSettings}
      />
    )
  },

  render() {
    const { visitor_types } = this.props.signin

    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>访客签到</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <div className="m-bottom-sm color-six">
              访客类型是针对不同访问事由的人进行不同的自定义设置
            </div>
            <div className="visit-type-list">
              {visitor_types &&
                visitor_types.map((type, index) => {
                  return (
                    <div className="visit-type-block" key={index}>
                      <div className="type-info">
                        <div>
                          <div className="name">{type.name}</div>
                          <div
                            className="slide-btn"
                            onClick={this.changeSlidesVisiblity.bind(
                              null,
                              index
                            )}
                          >
                            <span
                              className={`slide-bg ${
                                type.enabled ? 'slide-left' : 'slide-right'
                              }`}
                            >
                              <span className="slide-move" />
                            </span>
                          </div>
                        </div>
                        <button
                          className="certain-btn"
                          onClick={this.toEditPage.bind(null, index)}
                        >
                          编辑
                        </button>
                      </div>
                      <div className="type-sort">
                        {index === 0 ? null : (
                          <i
                            className="iconfont icon-arrow_drop_down_circle arrow-up"
                            onClick={this.movePosition.bind(null, index, 'up')}
                          />
                        )}
                        {index === visitor_types.length - 1 ? null : (
                          <i
                            className="iconfont icon-arrow_drop_down_circle arrow-down"
                            onClick={this.movePosition.bind(
                              null,
                              index,
                              'down'
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
            <button
              className="certain-btn"
              style={{ marginLeft: 0, marginTop: 10 }}
              onClick={this.addNewVisitorType}
            >
              添加访客类型
            </button>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { config, params } = props
  const location_id = params && params.id
  let signin = (config && config.signin) || {}

  return {
    location_id,
    signin,
    config
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSignInSet)
