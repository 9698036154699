import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { KBPopover } from 'components'
import { connect } from 'react-redux'

var ValidPlaceholderList = React.createClass({
  displayName: 'ValidPlaceholderList',
  getInitialState() {
    return {
      copySuccess: false
    }
  },
  componentDidMount() {},

  copyPlaceholder(json) {
    let string = json.name + 'String'
    let copyContent = this.refs[string]
    copyContent.select()
    document.execCommand('Copy')
    this.setState({
      copySuccess: true
    })
    setTimeout(() => {
      this.setState({ copySuccess: false })
    }, 1500)
  },

  deletePlaceholder(placeholder) {
    const { deleteSubscriptionPlaceholderAction } = this.props
    KBPopover.plugins.confirm(
      '删除模板命令字符',
      `您确定要删除${placeholder.description}吗?`,
      {
        confirm: () => {
          api
            .deleteSubscriptionSpecialPlaceholder({
              special_placeholder_id: placeholder.id
            })
            .then(
              ({ json }) => {
                let obj = {}
                let id = json.id
                obj[json.id] = json
                deleteSubscriptionPlaceholderAction.success(
                  { entities: { subscriptionPlaceholder: obj } },
                  { id, key: 'subscriptionPlaceholder' }
                )
                KBPopover.close()
              },
              error => {
                deleteSubscriptionPlaceholderAction.failure(error)
              }
            )
        }
      }
    )
  },

  render() {
    const { placeholder, type } = this.props
    const { copySuccess } = this.state

    return (
      <div
        className="placeholder-content"
        onClick={this.copyPlaceholder.bind(null, placeholder)}
      >
        {/*{
          type == 'special' ? <span className="delete-icon" onClick={ (e) => { e.stopPropagation();this.deletePlaceholder(placeholder) } }>x</span> : ''
        }*/}
        <span>{placeholder.description}</span>
        <input
          type="text"
          ref={`${placeholder.name}String`}
          value={placeholder.description}
          style={{ position: 'absolute', zIndex: '-1' }}
        />
        {copySuccess ? (
          <div className="copy-success_popover">复制成功</div>
        ) : (
          ''
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(actions.successState, dispatch),
    deleteSubscriptionPlaceholderAction: bindActionCreators(
      actions.subscriptionPlaceholder.delete,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidPlaceholderList)
