import React, { Component, PropTypes } from 'react'
import StatisticMeetingReserveCountTrend from './meeting/StatisticMeetingReserveCountTrend'
import StatisticMeetingUsageRate from './meeting/StatisticMeetingUsageRate'
import StatisticMeetingReserCountByHour from './meeting/StatisticMeetingReserCountByHour'
import StatisticMeetingReserveTimeLength from './meeting/StatisticMeetingReserveTimeLength'
import StatisticMettingEachUsageRate from './meeting/StatisticMettingEachUsageRate'

/**
 * 会议室预定统计box
 * @type {[type]}
 */
var StatisticMeetingBox = React.createClass({
  displayName: 'StatisticMeetingBox',
  render() {
    return (
      <section className="nav-section-container  f-right">
        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <StatisticMeetingUsageRate group_id={this.props.group_id} />
          <StatisticMettingEachUsageRate group_id={this.props.group_id} />
          <StatisticMeetingReserveCountTrend group_id={this.props.group_id} />
          <StatisticMeetingReserCountByHour group_id={this.props.group_id} />
          <StatisticMeetingReserveTimeLength group_id={this.props.group_id} />
        </div>
      </section>
    )
  }
})

export default StatisticMeetingBox
