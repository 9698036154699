import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput, KBASNumberInput } from 'components'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { KBSelect } from 'components/views'

var SpaceSettingServiceForm = React.createClass({
  displayName: 'SpaceSettingServiceForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          settings: model
        })
      },
      {
        key: 'settings'
      }
    )
  },
  render() {
    const {
      fields: { service_request_follow_up_cycle },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <label>服务申请跟踪周期(天), 默认1天</label>
            </div>
            <KBASNumberInput
              field={service_request_follow_up_cycle}
              className="f-right"
              type="number"
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let setting_value = setting.settings.service_request_follow_up_cycle || 1
  return {
    initialValues: { service_request_follow_up_cycle: setting_value }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingServiceForm',
  fields: ['service_request_follow_up_cycle'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingServiceForm)
