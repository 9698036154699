import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError, KBLoadingContainer, KBPopover } from 'components'
import {
  locationActions,
  memberActions,
  groupsActions,
  successState
} from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import LocationForm from './LocationForm'
import LocationBlock from './LocationBlock'
import * as selectors from 'app/selectors'
import {
  groupLocationsByCity,
  groupLocationsByLocationGroup,
  arrayToObject
} from 'utils/kbUtil'
import { canAdminSpace } from 'app/reducers/role'
import SpacesSettingLocationGroupForm from './setting/SpacesSettingLocationGroupForm'
import { Link } from 'react-router'
import _ from 'lodash'
var Groups = React.createClass({
  getInitialState() {
    const { locations, group } = this.props
    return {
      loading: false,
      locations,
      group,
      enteringRates: []
    }
  },

  addLocation() {
    KBPopover.showForm(
      <LocationForm group={this.props.group} callBack={this.loadData} />
    )
  },
  deleteGroup() {
    const { group } = this.props
    KBPopover.plugins.confirm(`删除项目`, `你是否确定删除${group.name}项目?`, {
      confirm: () => {
        const { groupId, deleteGroupsAction, successAction } = this.props
        api.deleteGroups(groupId).then(
          json => {
            successAction({ message: '删除成功!' })
            deleteGroupsAction.success(json, {
              id: groupId,
              key: 'locationGroups'
            })
            KBPopover.close()
            // window.history.go(-1)
            window.location.href = '/admin/locations'
          },
          errors => {
            deleteGroupsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.locations) !==
      JSON.stringify(nextProps.locations)
    ) {
      this.setState({
        locations: nextProps.locations
      })
    }
    if (JSON.stringify(this.props.group) !== JSON.stringify(nextProps.group)) {
      this.setState({
        group: nextProps.group
      })
    }
  },
  getLocations(params = {}) {
    this.setState({ loading: true })
    api
      .getLocations({ per_page: 100, ...params })
      .then(
        json => {
          this.props.getLocationsAction.success(json)
          let ids = ''
          _.forEach(_.get(json, 'response.body'), item => {
            ids = ids + item.id + ','
          })
          ids = ids.substring(0, ids.length - 2)
          api.getEnteringRate(ids).then(
            cjson => {
              this.setState({
                enteringRates: _.get(cjson, 'response.body', [])
              })
            },
            cerrors => {
              console.log('console log for chrom cerrors', cerrors)
            }
          )
        },
        errors => {
          this.props.getLocationsAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false })
        }
      })
  },
  loadData() {
    const { locations, groupId, getGroupsInfoAction } = this.props
    this.mounted = true

    this.setState({ loading: true })
    api
      .getGroupsInfo(groupId)
      .then(
        json => {
          getGroupsInfoAction.success(json)
        },
        errors => {
          getGroupsInfoAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false })
        }
      })
  },

  componentDidMount() {
    this.getLocations()
    this.loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  render() {
    const { user, locationGroups } = this.props
    const { locations, group } = this.state
    return (
      <section className="kb-content">
        <div className="kb-content-container clear-fix">
          <header className="nav-header clear-fix">
            <div className="nav-section-header-title">
              <span>{_.get(group, 'name', '')}</span>
              {_.get(group, 'city', null) && (
                <span
                  className="certain-btn-ms"
                  style={{
                    backgroundColor: '#4898E9',
                    cursor: 'default',
                    marginLeft: 10,
                    fontSize: 10
                  }}
                >
                  {_.get(group, 'city.name')}
                </span>
              )}
            </div>
            {locations && canAdminSpace(user) && (
              <div className="nav-header-btn" style={{ paddingBottom: 2 }}>
                <button
                  className="c-btn-red"
                  style={{ marginLeft: 10 }}
                  onClick={this.deleteGroup}
                >
                  <i className="iconfont icon-close" />
                  删除此项目
                </button>

                <button className="bordered-btn" onClick={this.addLocation}>
                  <i className="iconfont icon-add" />
                  添加新分店
                </button>
              </div>
            )}
          </header>

          <div className="nav-section-content-container">
            {!locations && (
              <div className="add-new-subbranch" onClick={this.addLocation}>
                <div className="add-new">
                  <i className="iconfont add-new-btn" />
                  <span>添加新分店</span>
                </div>
                <p>目前还没有分店信息,请添加新分店</p>
              </div>
            )}
            <div className="city-subbranch">
              {_.get(group, 'description', null) ? (
                <header>
                  <p
                    style={{
                      fontSize: 14
                    }}
                  >
                    项目介绍：{_.get(group, 'description')}
                  </p>
                </header>
              ) : (
                <div />
              )}
              {_.get(group, 'locations', []).length > 0 &&
                _.get(group, 'locations').map((location, index) => (
                  <LocationBlock
                    key={index}
                    enteringRate={_.get(
                      this.state.enteringRates,
                      `[${location.id}]`,
                      {}
                    )}
                    location={location}
                    user={user}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getLocations(state) || []
  let user = selectors.getCurrentUser(state)
  const locationGroups = arrayToObject(selectors.getLocationGroups(state))
  let groupId = props.params.id
  const group = selectors.getCurrentGroup(state, groupId)
  return {
    locations,
    user,
    locationGroups,
    groupId,
    group
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationsAction: bindActionCreators(locationActions.replace, dispatch),
    getGroupsInfoAction: bindActionCreators(groupsActions.get, dispatch),
    deleteGroupsAction: bindActionCreators(groupsActions.delete, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups)
