import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api } from 'app/services'
import { tasksActions } from 'app/actions'
import {
  checkObject,
  paginate,
  queryFilter,
  getFilterAndQuery,
  kbCloseDropDown
} from 'utils/kbUtil'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBPagination,
  KBDropdown,
  KBCheckBox,
  KBTableWithoutData
} from 'components'
import TaskForm from './TaskForm'
import TaskRow from './TaskRow'
import * as selectors from 'app/selectors'
import {
  TASK_STATES,
  TASK_PRIORITIES,
  TASK_SOURCE,
  TASK_SECTIONS,
  TASK_API_ASSIGNED_TO,
  TASK_UPDATE_STATE,
  TASK_SOURCE_OBJ
} from 'app/constants'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import {
  canAccessLocation,
  canAccessSpaceRoles,
  canAccessLocationRepairOnly
} from 'app/reducers/role'
import { Filters } from 'components/mixins'
import TasksReportForm from './TasksReportForm'
import moment from 'moment'

const FILTER_STATE = ['state', 'location_id', 'source']

const DROP_DOWNS = ['stateDropdown', 'location_idDropdown', 'sourceDropdown']

var TasksList = React.createClass({
  displayName: 'TasksList',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      location_id: this.props.location_id,
      filters,
      tasks: [],
      showCompleteStatus: true,
      queryFiltersStr: ''
    }
  },

  componentDidMount() {
    const { tasks_type, page, newTask, filters, type } = this.props

    this.mounted = true
    if (newTask) {
      this.createTask()
    }
    this._loadData(filters, page, tasks_type, type)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters, page, tasks_type = 'all', t_type) {
    const { allTasksAction, location_id, routerActions, query } = this.props
    let filterStrObj = Object.assign({}, query, filters)
    let task_type = t_type || this.props.type
    filterStrObj.page = page
    let queryFiltersStr = queryFilter(filterStrObj)
    this.setState({
      loading: true
    })

    let params = TASK_API_ASSIGNED_TO[tasks_type]
    var filterObj = Object.assign({}, filters)
    const per_page = 10
    params = Object.assign(
      {},
      params,
      filterObj,
      { page, per_page },
      location_id ? { location_id } : {},
      task_type ? { task_type } : {}
    )
    if (!params.state) {
      delete params.state
    }
    api
      .getTasks(params)
      .then(
        resp => {
          let pagination = paginate(resp.response)
          allTasksAction.success(resp)
          let tasks = getEntitiesJsonArray(resp, 'tasks')
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          if (location_id) {
            routerActions.replace(
              `/admin/locations/${location_id}/tasks/repair/type/all${queryFiltersStr}`
            )
          } else {
            routerActions.replace(
              `/admin/tasks/repair/type/all${queryFiltersStr}`
            )
          }

          this.setState({
            pagination,
            tasks: tasks,
            queryFiltersStr
          })
          return true
        },
        errors => {
          allTasksAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { url, filters, tasks, tasks_type, type } = nextProps
    if (
      tasks.length - this.props.tasks.length === 1 ||
      (url && nextProps.page !== this.props.page) ||
      JSON.stringify(filters) !== JSON.stringify(this.props.filters)
    ) {
      this._loadData(
        { ...this.state.filters, ...filters },
        nextProps.page,
        tasks_type,
        type
      )
    }
  },

  createTask() {
    const { location_id } = this.props
    KBPopover.show(<TaskForm location_id={location_id} />, {
      closeOnOutsideClick: false
    })
  },

  _clearFilters() {
    const { tasks_type } = this.props
    const { showCompleteStatus } = this.state
    showCompleteStatus && this.showCompleteTask(false)
    const filters = { filters: {}, loading: true }
    this.setState(filters)

    this._loadData({}, 1, tasks_type)
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )
    value == 'completed' && this.showCompleteTask(true)
    !value && this.showCompleteTask(false)

    this.setState({ filters: newFilters, loading: true }, () => {
      this._refresh(key, newFilters)
    })
  },
  _refresh(key, filters) {
    const { tasks_type } = this.props
    let dropDown = key + 'Dropdown'
    this[dropDown] && this[dropDown].hide()
    setTimeout(this._loadData(filters, 1, tasks_type))
  },

  showCompleteTask(status) {
    this.setState({
      showCompleteStatus: status
    })
  },

  reportTasks() {
    let initValue = {
      startYMD: moment()
        .date(1)
        .toDate(),
      endYMD: new Date(),
      start_at: moment(new Date())
        .clone()
        .date(1)
        .set({ hour: 9, minute: 0, second: 0 }),
      end_at: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 })
    }
    KBPopover.show(
      <TasksReportForm
        initialValues={initValue}
        loc_id={this.props.location_id}
      />
    )
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations, location_id } = this.props
    const { showCompleteStatus } = this.state
    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {
            <KBDropdown
              ref={ref => {
                this.stateDropdown = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'stateDropdown')}
                >
                  <i className="iconfont icon-finance" />
                  <span>
                    {this.state.filters.state
                      ? TASK_STATES[this.state.filters.state]
                      : '选择进度'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter('state', '')}>全部</li>
                    {TASK_UPDATE_STATE.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => this._setFilter('state', state.id)}
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
        </div>
        {this.props.location_id ? (
          <div
            className="task-status-title"
            style={{ display: 'inline-block' }}
          >
            <i className="iconfont icon-position" />
            <span>{locations[location_id].name || ''}</span>
          </div>
        ) : (
          <KBDropdown
            ref={ref => {
              this.location_idDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
              >
                <i className="iconfont icon-position" />
                <span>
                  {this.state.filters.location_id
                    ? locations[this.state.filters.location_id].name
                    : '选择分店'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <ul className="task-type">
                <li onClick={() => this._setFilter('location_id', '')}>全部</li>
                {locations &&
                  Object.keys(locations).map((location_id, index) => {
                    let location = locations[location_id]
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter('location_id', location.id)
                        }
                      >
                        <span>{location.name}</span>
                      </li>
                    )
                  })}
              </ul>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        )}
        {
          <KBDropdown
            ref={ref => {
              this.sourceDropdown = ref
            }}
            style={{ marginLeft: 20 }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'sourceDropdown')}
              >
                <i className="iconfont icon-account_circle" />
                <span>
                  {this.state.filters.source
                    ? TASK_SOURCE_OBJ[this.state.filters.source]
                    : '选择发起人'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <ul className="task-type">
                <li onClick={() => this._setFilter('source', '')}>全部</li>
                {TASK_SOURCE.map((source, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => this._setFilter('source', source.id)}
                    >
                      <span>{source.name}</span>
                    </li>
                  )
                })}
              </ul>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        }
        <div className="d-iblock m-left-sm">
          <KBCheckBox
            checked={showCompleteStatus}
            callback={this.showCompleteTask}
          />
        </div>
        <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>
          显示已完成
        </span>
      </div>
    )
  },
  render() {
    const {
      loading,
      tasks,
      pagination,
      showCompleteStatus,
      queryFiltersStr
    } = this.state
    const {
      location_id,
      isManager,
      isRepair,
      isLocationRepair,
      type
    } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')

    let undoneTasks = tasks.filter(task => task.state !== 'completed')
    let filtersTasks = showCompleteStatus ? tasks : undoneTasks

    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>所有报修任务</span>
            </div>
            {isManager || isRepair || isLocationRepair ? (
              <div className="f-right">
                <button className="c-btn-secondary" onClick={this.reportTasks}>
                  导出报修信息
                </button>
                <button className="bordered-btn" onClick={this.createTask}>
                  <i className="iconfont icon-add" />
                  新增报修
                </button>
              </div>
            ) : null}
          </div>

          {this._renderFilters()}
          {selectFilters.length > 0 ? (
            <div className="clear-criteria" onClick={this._clearFilters}>
              <i className="iconfont icon-close" />
              <span>清除所有筛选条件</span>
            </div>
          ) : null}
        </header>
        <div
          className="nav-section-content-container"
          style={{ minHeight: 500 }}
        >
          <KBLoadingContainer loading={loading}>
            <table className="content-table table-align">
              <thead>
                <tr>
                  <th style={{ paddingLeft: 60 }}>标题</th>
                  <th>负责人</th>
                  <th>分店</th>
                  <th>截止时间</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={filtersTasks.length > 0}
                tableHeadNum="4"
                tipMessage="暂无记录"
              >
                {filtersTasks &&
                  filtersTasks.map(task => (
                    <TaskRow
                      location_id={location_id}
                      task={task}
                      tasks_type="all"
                      type={type}
                      key={task.id}
                    />
                  ))}
              </KBTableWithoutData>
            </table>
            {location_id ? (
              <KBPagination
                pagination={pagination}
                template={`/admin/locations/${location_id}/tasks/repair/type/all${
                  queryFiltersStr
                    ? queryFiltersStr + '&page=#PAGE#'
                    : '?page=#PAGE#'
                }`}
              />
            ) : (
              <KBPagination
                pagination={pagination}
                template={`/admin/tasks/repair/type/all${
                  queryFiltersStr
                    ? queryFiltersStr + '&page=#PAGE#'
                    : '?page=#PAGE#'
                }`}
              />
            )}
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    location,
    params: { id }
  } = props
  const { user } = state
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let tasks_type = 'all'
  let newTask =
    location && location.query && location.query.task == 'new' ? true : false
  let locations = selectors.getLocationsAsDictionary(state)
  let tasks = selectors.getTasks(state)

  let location_id = id
  let type = 'repair'

  let url = props.location.pathname + props.location.search
  let isManager = canAccessLocation(user, location_id)
  let isRepair = canAccessSpaceRoles(user, ['repair'])
  let isLocationRepair = canAccessLocationRepairOnly(user, location_id)

  const params = getFilterAndQuery(location.query, FILTER_STATE)
  return {
    page,
    url,
    tasks,
    tasks_type,
    locations,
    location_id,
    newTask,
    isManager,
    isRepair,
    isLocationRepair,
    type,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    allTasksAction: bindActionCreators(tasksActions.update, dispatch),
    deleteTasksAction: bindActionCreators(tasksActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)
