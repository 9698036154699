import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBSelect, KBASNumberInput, KBTextarea, KBInput } from 'components'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { meetingSettingActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { LOCKER_RESERVATION_PRIVILEGES } from 'app/constants'

var GroupSetActivityForm = React.createClass({
  displayName: 'GroupSetActivityForm',

  // componentWillReceiveProps(nextprops) {
  //   if(nextprops.values[this.props.type] != this.state.funcSetting)
  //   this.setState({
  //     funcSetting: nextprops.value[this.props.type]
  //   })
  // },

  updateSpace(model) {
    const { updateLocationSetting, setting_value } = this.props
    // model.send_message_to_operator = this.props.values.send_message_to_operator
    updateLocationSetting(
      {
        activity_order_settings: model
      },
      { key: 'activity_order_settings' }
    )
  },

  render() {
    const {
      fields: { cancellation_offset, create_reservation_note },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <label>可以在活动开始前多久取消报名</label>
              <p className="color-nine">
                可取消活动报名的小时数,默认值为0.5小时
              </p>
            </div>
            <KBASNumberInput
              field={cancellation_offset}
              className="f-right"
              type="number"
              maxValue='24'
              counterStyle={{ marginRight: 130 }}
            />
          </div>

          <fieldset className="fieldset">
            <legend>预定成功通知</legend>
              <div>
                <KBTextarea
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  isRequired={true}
                  field={create_reservation_note}
                  placeholder='预定成功通知字符长度需小于20'
                  maxLength='20'
                />
              </div>
          </fieldset>

          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { groupSetting } = state
  let setting_value = groupSetting.activity_order_settings
  return {
    initialValues: {
      ...setting_value
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'GroupSetActivityForm',
  fields: ['cancellation_offset', 'create_reservation_note'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetActivityForm)
