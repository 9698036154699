import React from 'react'
import { connect } from 'react-redux'
import GroupSettingManageForm from './GroupSettingManageForm'
import * as selectors from 'app/selectors'

var GroupSetRole = React.createClass({
  render() {
    const { updateLocation, loc_id, locationSetting } = this.props

    if (!locationSetting.settings) {
      return null
    }

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>修改权限</span>
            </div>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>管理项目</legend>
            <GroupSettingManageForm
              updateLocation={updateLocation}
              loc_id={loc_id}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getGroupSetting(state) || {}
  return {
    loc_id,
    locationSetting
  }
}

export default connect(mapStateToProps)(GroupSetRole)
