import React from 'react'
import SpaceSettingMeetingForm from './SpaceSettingMeetingForm'

var SpaceSettingMeeting = React.createClass({
  displayName: 'SpaceSettingMeeting',
  render() {
    const { updateLocationSetting, setting, groupSetting } = this.props
    // if (!setting.settings) {
    //   return null
    // }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>会议室设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>租期时限</legend>
            <SpaceSettingMeetingForm
              setting={groupSetting}
              updateLocationSetting={updateLocationSetting}
            />
          </fieldset>

        </div>
      </div>
    )
  }
})

export default SpaceSettingMeeting
