import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError, KBLoadingContainer, KBPopover } from 'components'
import {
  locationActions,
  memberActions,
  locationGroupsAction
} from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import LocationForm from './LocationForm'
import LocationBlock from './LocationBlock'
import * as selectors from 'app/selectors'
import {
  groupLocationsByCity,
  groupLocationsByLocationGroup,
  arrayToObject
} from 'utils/kbUtil'
import { canAdminSpace, canAccessSpaceRoles } from 'app/reducers/role'
import SpacesSettingLocationGroupForm from './setting/SpacesSettingLocationGroupForm'
import { Link } from 'react-router'
import _ from 'lodash'
var Locations = React.createClass({
  getInitialState() {
    const { locations } = this.props
    return {
      loading: false,
      locations,
      enteringRates: []
    }
  },

  addLocation() {
    KBPopover.showForm(<LocationForm />)
  },

  addLocationGroup() {
    KBPopover.show(
      <SpacesSettingLocationGroupForm
        fromLocList
        createCallback={this.getLocations}
      />
    )
  },

  getLocations(params = {}) {
    this.setState({ loading: true })
    api
      .getLocations({ per_page: 100, ...params })
      .then(
        json => {
          this.props.getLocationsAction.success(json)
          let ids = ''
          _.forEach(_.get(json, 'response.body'), item => {
            ids = ids + item.id + ','
          })
          ids = ids.substring(0, ids.length - 2)
          api.getEnteringRate(ids).then(
            cjson => {
              this.setState({
                enteringRates: _.get(cjson, 'response.body', [])
              })
            },
            cerrors => {
              console.log('console log for chrom cerrors', cerrors)
            }
          )
        },
        errors => {
          this.props.getLocationsAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false })
        }
      })
  },

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.locations) !==
      JSON.stringify(nextProps.locations)
    ) {
      this.setState({
        locations: nextProps.locations
      })
    }
  },

  componentDidMount() {
    const { locations, getLocationGroupsAction, isFinance } = this.props
    this.mounted = true
    // load locations
    //  !locations.length && this.getLocations()
    this.getLocations()
    api.getLocationGroups().then(
      json => {
        getLocationGroupsAction.success(json)
      },
      error => {
        getLocationGroupsAction.failure(error)
      }
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },
  render() {
    const { user, locationGroups, cities, locCities } = this.props
    const { locations } = this.state
    let groups = groupLocationsByLocationGroup(locations)
    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <div className="kb-content-container clear-fix">
            <header className="nav-header clear-fix">
              <div className="nav-section-header-title">
                <span>分店管理</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <span
                  className="color-link"
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({ locations: this.props.locations })
                  }
                >
                  所有城市
                </span>
                {locCities.map(lc_id => {
                  if (!lc_id) {
                    return null
                  }
                  return (
                    <span
                      key={lc_id}
                      className="color-link"
                      style={{ marginRight: 10, cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          locations: this.props.locations.filter(
                            loc => loc.city_id == lc_id
                          )
                        })
                      }
                    >
                      {(cities[lc_id] || {}).name}
                    </span>
                  )
                })}
              </div>
              {locations && canAdminSpace(user) && (
                <div
                  className="nav-header-btn"
                  style={{ paddingBottom: 2, right: 165 }}
                >
                  <button
                    className="bordered-btn"
                    onClick={this.addLocationGroup}
                  >
                    <i className="iconfont icon-add" />
                    添加新项目
                  </button>
                </div>
              )}
              {locations && canAdminSpace(user) && (
                <div className="nav-header-btn" style={{ paddingBottom: 2 }}>
                  <button className="bordered-btn" onClick={this.addLocation}>
                    <i className="iconfont icon-add" />
                    添加新分店
                  </button>
                </div>
              )}
            </header>

            <div className="nav-section-content-container">
              {!locations && (
                <div className="add-new-subbranch" onClick={this.addLocation}>
                  <div className="add-new">
                    <i className="iconfont add-new-btn" />
                    <span>添加新分店</span>
                  </div>
                  <p>目前还没有分店信息,请添加新分店</p>
                </div>
              )}
              <KBLoadingContainer loading={this.state.loading}>
                {Object.keys(groups).map(group_id => {
                  if (!group_id || !locationGroups) {
                    return ''
                  }
                  let group_locations = groups[group_id]
                  let btnShowArr = user.space_roles.map(role => {
                    if (
                      role.role == 'location_group_wechat_manager' ||
                      role.role == 'location_group_operator' ||
                      role.role == 'location_group_content_manager'
                    ) {
                      if (role.location_group_id == group_id) {
                        return true
                      }
                    }
                    if (role.role == 'admin') {
                      return true
                    }
                    return false
                  })
                  const btnShow = btnShowArr.filter(blone => blone === true)
                  return (
                    <div className="city-subbranch" key={group_id}>
                      <header>
                        <span>
                          {
                            (
                              locationGroups[group_id] || {
                                name: '暂未分配项目'
                              }
                            ).name
                          }
                        </span>
                        {_.get(locationGroups[group_id], 'city', null) && (
                          <span
                            className="certain-btn-ms"
                            style={{
                              backgroundColor: '#4898E9',
                              cursor: 'default',
                              marginLeft: 10,
                              fontSize: 10
                            }}
                          >
                            {_.get(locationGroups[group_id], 'city.name')}
                          </span>
                        )}
                        {_.get(locationGroups[group_id], 'name', null) &&
                          btnShow.length > 0 &&
                          canAccessSpaceRoles(user, [
                            'admin',
                            'location_group_operator',
                            'location_group_content_manager'
                          ]) && (
                            <Link
                              to={`/admin/groups/${_.get(
                                locationGroups[group_id],
                                'id'
                              )}`}
                            >
                              <button
                                className="c-btn-green"
                                style={{ position: 'absolute', right: 8 }}
                              >
                                项目详情
                              </button>
                            </Link>
                          )}
                      </header>

                      {group_locations &&
                        group_locations.map(location => (
                          <LocationBlock
                            key={location.id}
                            enteringRate={_.get(
                              this.state.enteringRates,
                              `[${location.id}]`,
                              {}
                            )}
                            location={location}
                            user={user}
                          />
                        ))}
                    </div>
                  )
                })}
              </KBLoadingContainer>
            </div>
          </div>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state) {
  // const { user } = state
  const locations = selectors.getLocations(state) || []
  const cities = selectors.getCities(state) || []
  const locCities = [...new Set(locations.map(loc => loc.city))]
  let user = selectors.getCurrentUser(state)
  const locationGroups = arrayToObject(selectors.getLocationGroups(state))
  const space_roles = user.space_roles
  const isFinance = space_roles.filter(
    role => !role.location_id && role.role == 'finance'
  )
  // const isFinance = space_roles.map((role)=>{
  //   if(!role.location_id && role.role=='finance'){
  //     return true
  //   }
  // })
  return {
    locations,
    user,
    cities,
    locCities,
    locationGroups,
    isFinance
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationsAction: bindActionCreators(locationActions.replace, dispatch),
    getMembersAction: bindActionCreators(memberActions.all, dispatch),
    getLocationGroupsAction: bindActionCreators(
      locationGroupsAction.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations)
