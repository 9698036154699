import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBInput,
  KBASNumberInput,
  KBTipsy,
  KBCheckBox,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import { routerActions } from 'react-router-redux'
import { arrayToObject, formatYearDayEN } from 'utils/kbUtil'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as actions from 'app/actions'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

import {
  SUBSCRIPTION_STATE_OBJ,
  SUBSCRIPTION_STATE_CLASSNAME
} from 'app/constants'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

import { api, apiUtils } from 'app/services'

var LocationSetAutoMail = React.createClass({
  displayName: 'LocationSetAutoMail',
  getInitialState() {
    return {
      attachmentTemplateList: [],
      currentSelect: ''
    }
  },

  componentDidMount() {
    const { invoice_settings } = this.props
    this.setState({
      currentSelect: _.get(invoice_settings, 'auto_send_template_id')
    })
    if (_.get(invoice_settings, 'auto_send_with_attachment')) {
      this.getSubScriptionTempletes()
    }
  },
  componentWillReceiveProps(nextProps) {
    const { invoice_settings } = nextProps
    if (
      _.get(invoice_settings, 'auto_send_with_attachment') !==
      _.get(this.props, 'invoice_settings.auto_send_with_attachment')
    ) {
      this.setState({
        currentSelect: invoice_settings.auto_send_template_id
      })
      if (invoice_settings.auto_send_with_attachment) {
        this.getSubScriptionTempletes()
      }
    }
  },
  updateSpace(model) {
    const { updateCurrentSpace, apiCallFailureActions } = this.props
    const { currentSelect } = this.state
    if (model.auto_send_with_attachment && !currentSelect) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择催款单附件模板'
      })
      return
    }

  model.auto_send_template_id = currentSelect
  if(!model.auto_send_with_attachment){
    model.auto_send_template_id = null
  }
    updateCurrentSpace(JSON.stringify({ invoice_settings: model }), {
      key: 'invoice_settings'
    })
  },

  changeAutoSendEmail() {
    const {
      fields: { auto_send_email }
    } = this.props
    auto_send_email.onChange(!auto_send_email.value)
  },

  changeAutoSendOverdueEmail() {
    const {
      fields: { auto_send_overdue_email }
    } = this.props
    auto_send_overdue_email.onChange(!auto_send_overdue_email.value)
  },

  changeAutoContainsAttachment() {
    const {
      fields: { auto_send_with_attachment }
    } = this.props
    auto_send_with_attachment.onChange(!auto_send_with_attachment.value)
    if (!auto_send_with_attachment.value) {
      this.getSubScriptionTempletes()
    }
  },
  getSubScriptionTempletes() {
    api
      .getSubScriptionTempletes({
        template_type: 'contract_invoice_attachment'
      })
      .then(json => {
        let announcements = getEntitiesJsonArray(json, 'subscriptionTempletes')
        this.setState({
          attachmentTemplateList: announcements
        })
      })
  },
  renderEditStateJSX(templete) {
    const { template_url } = templete

    return (
      <td className="templete">
        <KBDownloadPrivacy url={template_url}>
          <KBTipsy content={template_url ? '下载' : '无'}>
            {template_url ? (
                <span className="templete-download a-hover" />
            ) : (
              <span className="templete-empty">-</span>
            )}
          </KBTipsy>
        </KBDownloadPrivacy>
      </td>
    )
  },
  changeKBCheckBox(e, templete) {
    if (e) {
      this.setState({
        currentSelect: templete.id
      })
    }else{
      this.setState({
        currentSelect: null
      })
    }

  },
  createTemplete() {
    const { routerActions, groups_id } = this.props
    routerActions.push(`/admin/groups/${groups_id}/settings/invoice/editTemplate?type=create`)
  },
  disabledTemplete(id, status = 'disabled') {
    const { updateTempletesActions, successAction } = this.props
    let changeStatus
    if (status == 'disabled') {
      changeStatus = 'enabled'
    } else if (status == 'enabled') {
      changeStatus = 'disabled'
    } else if (status == 'draft') {
      changeStatus = 'enabled'
    }
    const isDisabled = status != 'disabled' && status != 'draft'
    KBPopover.plugins.confirm(
      isDisabled ? '禁用模板' : '启用模板',
      isDisabled ? '您是否禁用该模板?' : '您是否启用该模板',
      {
        confirm: () => {
          api
            .putSubscriptionTemplete(id, {
              status: changeStatus
            })
            .then(
              json => {
                updateTempletesActions.success(json, {
                  id,
                  key: 'subscriptionTempletes'
                })
                successAction({
                  message: isDisabled ? '禁用模板成功!' : '启用模板成功!'
                })
                KBPopover.close()

                this.getSubScriptionTempletes()
              },
              e => {
                updateTempletesActions.failure(e)
              }
            )
        }
      }
    )
  },
  render() {
    const {
      fields: {
        auto_send_email,
        auto_send_day,
        auto_send_urge_day,
        auto_send_overdue_email,
        auto_send_with_attachment
      },
      setting,
      error,
      submitting,
      handleSubmit,
      invoice_settings,
      validPlaceholders,
      updateCurrentSpace
    } = this.props
    const accountOnly = false
    const { attachmentTemplateList, currentSelect } = this.state
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>是否自动发送账单</div>
              <div
                className="slide-btn"
                style={{ paddingTop: 5 }}
                onClick={() => {
                  this.changeAutoSendEmail()
                }}
              >
                <span
                  className={`slide-bg ${
                    auto_send_email.value ? 'slide-left' : 'slide-right'
                  }`}
                >
                  <span className="slide-move" />
                </span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>
                缴租日前&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{auto_send_day.value}</span>
                &nbsp;)&nbsp;&nbsp;天定时自动发送账单
              </div>
              {/* <p className="color-nine d-iblock">示例：如果设置为5，意味着会在合同开始日之前5天催付押金</p> */}
            </div>
            <KBASNumberInput
              field={auto_send_day}
              className="f-right"
              type="number"
              maxValue="31"
            />
          </div>
          <div className="kb-row">
            <div className="f-left">
              <div>
                缴租日前&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{auto_send_urge_day.value}</span>
                &nbsp;)&nbsp;&nbsp;天自动发送第一次催款单
              </div>
              {/* <p className="color-nine d-iblock">示例：收款日是31日，设置值为15，则会在缴租阶段前一个月的16日生成账单</p> */}
            </div>
            <KBASNumberInput
              field={auto_send_urge_day}
              className="f-right"
              type="number"
              maxValue="31"
            />
          </div>
          <div className="kb-row">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>自动发送第二次催款单</div>
              <div
                className="slide-btn"
                style={{ paddingTop: 5 }}
                onClick={() => {
                  this.changeAutoSendOverdueEmail()
                }}
              >
                <span
                  className={`slide-bg ${
                    auto_send_overdue_email.value ? 'slide-left' : 'slide-right'
                  }`}
                >
                  <span className="slide-move" />
                </span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>催款邮件中是否包含附件</div>
              <div
                className="slide-btn"
                style={{ paddingTop: 5 }}
                onClick={() => {
                  this.changeAutoContainsAttachment()
                }}
              >
                <span
                  className={`slide-bg ${
                    auto_send_with_attachment.value
                      ? 'slide-left'
                      : 'slide-right'
                  }`}
                >
                  <span className="slide-move" />
                </span>
              </div>
            </div>
          </div>
          <div className="m-top-sm border-bottom" style={{ paddingBottom: 15 }}>
            <button
              className="bordered-btn"
              style={{ marginLeft: 0 }}
              onClick={this.createTemplete}
            >
              <i className="iconfont icon-add" />
              新增模板
            </button>
          </div>
          {auto_send_with_attachment.value && attachmentTemplateList.length ? (
            <div>
              <table className="content-table" style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th>选择</th>
                    <th>模板名称</th>
                    <th className="t-center">模板状态</th>
                    <th>创建日期</th>
                    <th>更新日期</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  {attachmentTemplateList.map((templete, index) => {
                    const {
                      name,
                      status,
                      id,
                      created_at,
                      updated_at
                    } = templete
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            position: 'static',
                            verticalAlign: 'middle'
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={templete}
                            disabled={
                              templete.status == 'enabled' ? false : true
                            }
                            callback={e => this.changeKBCheckBox(e, templete)}
                            checked={
                              (attachmentTemplateList &&
                                attachmentTemplateList.length &&
                                currentSelect == templete.id) ||
                              false
                            }
                          />
                        </td>
                        <td>{name}</td>
                        {/* <td className="t-center">
                            <span className={SUBSCRIPTION_STATE_CLASSNAME[status]} >{SUBSCRIPTION_STATE_OBJ[status]}</span>
                          </td> */}
                        <td className="t-center">
                          {
                            <KBTipsy content="点击修改当前模板状态">
                              <span
                                className={SUBSCRIPTION_STATE_CLASSNAME[status]}
                                onClick={this.disabledTemplete.bind(
                                  null,
                                  id,
                                  status
                                )}
                              >
                                {SUBSCRIPTION_STATE_OBJ[status]}
                              </span>
                            </KBTipsy>
                          }
                        </td>
                        <td>{formatYearDayEN(created_at)}</td>
                        <td>{formatYearDayEN(updated_at)}</td>
                        {this.renderEditStateJSX(templete)}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  // const loc_id = _.get(props, 'locationId')
  // const locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  const { setting: { invoice_settings } ={} } = props
  return {
    initialValues: invoice_settings || {},
    invoice_settings: invoice_settings || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(actions.successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(actions.apiCallFailure, dispatch),
    updateTempletesActions: bindActionCreators(
      actions.subscriptionTempletesAction.update,
      dispatch
    )
  }
}

const formConfig = {
  form: 'LocationSetAutoMail',
  fields: [
    'auto_send_email',
    'auto_send_day',
    'auto_send_urge_day',
    'auto_send_overdue_email',
    'auto_send_with_attachment'
  ],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetAutoMail)
