import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import SpaceSettingOptionsForm from './SpaceSettingOptionsForm'
import { KBPopover, KBTipsy } from 'components'
import { api } from 'app/services'
import { leaveEnterOptionActions, successState } from 'app/actions'
import SpaceSettingCompanyForm from './SpaceSettingCompanyForm'

var SpaceSettingOptions = React.createClass({
  displayName: 'SpaceSettingOptions',
  addOptions(type) {
    KBPopover.show(<SpaceSettingOptionsForm type={type} />)
  },
  updateOption(option) {
    KBPopover.show(
      <SpaceSettingOptionsForm
        state="update"
        option={option}
        type={option.cate}
        initialValues={option}
      />
    )
  },
  componentDidMount() {
    const { getLeaveEnterOptionActions } = this.props
    // 查找所有的 入驻 退租待办事项
    api.getLeveOrEnterOptions().then(
      json => {
        getLeaveEnterOptionActions.success(json)
      },
      errors => {
        getLeaveEnterOptionActions.failure(errors)
      }
    )
  },
  updateCurrentSpace(model, { key }) {
    const { user } = this.props
    const { updateCurrentSpaceAction, successAction } = this.props
    return api
      .putSpaceSetting({
        ...model,
        target_type: 'Space',
        target_id: user.space
      })
      .then(json => {
        successAction({ message: '保存成功!' })
        updateCurrentSpaceAction.success(json, { key })
      })
  },
  removeOption(option) {
    const { deleteLeaveEnterOptionAction } = this.props

    KBPopover.plugins.confirm(
      `删除待办事项`,
      `你是否确定当前“${option.item}”代办事项?`,
      {
        confirm: () => {
          api.deleteLeaveOrEnterOptions(option.id).then(
            json => {
              deleteLeaveEnterOptionAction.success(json, {
                id: option.id,
                key: 'leaveOrEnterOptions'
              })
              KBPopover.close()
            },
            error => {
              deleteLeaveEnterOptionAction.failure(error)
              KBPopover.close()
            }
          )
        }
      }
    )
  },
  render() {
    const { enterOptions, leaveOptions, setting } = this.props
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>待办事项设置</span>
          </div>
        </header>
        <div className="kb-form-container" style={{ paddingBottom: 0 }}>
          {setting.settings ? (
            <fieldset className="fieldset">
              <legend>公司入驻</legend>
              <SpaceSettingCompanyForm
                setting={setting}
                updateCurrentSpace={this.updateCurrentSpace}
              />
            </fieldset>
          ) : null}
          <p style={{ marginBottom: 15 }}>
            待办事项是客户入驻或退租时运营人员需要执行的操作，比如结算账单，检查家具等。待办事项分为必须完成或非必需完成。运营在入驻或退租公司时将填写这些事务是否完成。
          </p>
          <fieldset className="fieldset">
            <legend>入驻待办事项</legend>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>待办事项</th>
                  <th>备注</th>
                  <th className="t-right" style={{ paddingRight: 40 }}>
                    修改
                  </th>
                </tr>
              </thead>
              <tbody>
                {enterOptions.map((option, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="color-nine">{option.item}</span>
                        {option.is_required ? (
                          <KBTipsy content="必须完成">
                            <i
                              className="iconfont icon-must"
                              style={{ marginLeft: 5, color: '#ed7373' }}
                            />
                          </KBTipsy>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        <span className="color-nine">{option.notes}</span>
                      </td>
                      <td className="t-right">
                        <i
                          className="iconfont icon-edit"
                          onClick={this.updateOption.bind(null, option)}
                        />
                        <i
                          className="iconfont icon-delete m-left-sm"
                          onClick={this.removeOption.bind(null, option)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <button
              className="certain-btn m-top-sm"
              onClick={this.addOptions.bind(null, 'enter')}
            >
              <i className="iconfont icon-add" />
              添加入驻待办事项
            </button>
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingBottom: 0 }}>
          <fieldset className="fieldset">
            <legend>退租待办事项</legend>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>待办事项</th>
                  <th>备注</th>
                  <th className="t-right" style={{ paddingRight: 40 }}>
                    修改
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaveOptions.map((option, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="color-nine">{option.item}</span>
                        {option.is_required ? (
                          <KBTipsy content="必须完成">
                            <i
                              className="iconfont icon-must"
                              style={{ marginLeft: 5, color: '#ed7373' }}
                            />
                          </KBTipsy>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        <span className="color-nine">{option.notes}</span>
                      </td>
                      <td className="t-right">
                        <i
                          className="iconfont icon-edit"
                          onClick={this.updateOption.bind(null, option)}
                        />
                        <i
                          className="iconfont icon-delete m-left-sm"
                          onClick={this.removeOption.bind(null, option)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <button
              className="certain-btn m-top-sm"
              onClick={this.addOptions.bind(null, 'archive')}
            >
              <i className="iconfont icon-add" />
              添加退租待办事项
            </button>
          </fieldset>
        </div>
      </div>
    )
  }
})

function getLeaveOptions(options) {
  return options.filter(option => option.cate == 'archive')
}

function getEnterOptions(options) {
  return options.filter(option => option.cate == 'enter')
}

function mapStateToProps(state, props) {
  let leaveOrEnterOptions = selectors.getSpaceLeaveOrEnterOptions(state)

  leaveOrEnterOptions = leaveOrEnterOptions.filter(option => {
    return !option.organization_id
  })

  let leaveOptions = getLeaveOptions(leaveOrEnterOptions)
  let enterOptions = getEnterOptions(leaveOrEnterOptions)
  let user = selectors.getCurrentUser(state)
  return {
    leaveOptions,
    enterOptions,
    leaveOrEnterOptions,
    user
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    getLeaveEnterOptionActions: bindActionCreators(
      leaveEnterOptionActions.replace,
      dispatch
    ),
    deleteLeaveEnterOptionAction: bindActionCreators(
      leaveEnterOptionActions.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceSettingOptions)
