import React from 'react'
import SpacesSettingBaseForm from './SpacesSettingBaseForm'
import { LogoCropperForm } from 'components/views'
import {KBSwitch, KBPopover} from 'components'
import { KBPopoverConfirm } from 'components/tools'

var SpacesSettingBase = React.createClass({
  getInitialState() {
    return {
      url: this.props.space.logo2,
      disabledStart: false,
      disabledWx: false,
      switchStatus: '关闭'
    }
  },
  updateImageUrl(url) {
    if (!url) {
      return
    }
    this.setState({
      url
    })
  },
  switchChange(key, val) {
    // 后端的字段和前端的开关是反过来的，所以上传和展示都需要取反
    const { updateSpace } = this.props
    const status = val ? '关闭' : '开启'
    if(!val) {
      // 不用提示
      updateSpace({
        [key]: !val
      })
      return
    }
    if(key === 'use_morpheus') {
      KBPopoverConfirm({
        name: `是否确认${status}凯德星相关接口？`,
        context: `请谨慎操作，${status}后，所有项目都将${status}★凯星卡支付和星积分支付功能`,
        callback: () => {
          updateSpace({
            [key]: !val
          })
          KBPopover.close()
        }
      })
    }
    if(key === 'use_morpheus_pay') {
      KBPopoverConfirm({
        name: `是否确认${status}中台微信支付相关接口？`,
        context: `请谨慎操作，${status}后，所有项目都将${status}微信支付功能`,
        callback: () => {
          updateSpace({
            [key]: !val
          })
          KBPopover.close()
        }
      })
    }
  },
  render() {
    const { space, updateSpace } = this.props
    const { url, disabledStart, disabledWx, switchStatus } = this.state

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>基本设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <div style={{marginBottom: 20}}>
            <div className='kb-row'>
              <KBSwitch title={`是否关闭凯德星相关接口`} titleWidth="210px" value={!space.use_morpheus} onChange={val => this.switchChange('use_morpheus', val)} />
            </div>
            <div className='kb-row'>
              <KBSwitch title={`是否关闭中台微信支付相关接口`} titleWidth="210px" value={!space.use_morpheus_pay} onChange={val => this.switchChange('use_morpheus_pay', val)} />
            </div>
          </div>
          <fieldset className="fieldset">
            <legend>基本信息</legend>
            <SpacesSettingBaseForm updateSpace={updateSpace} space={space} />
          </fieldset>
          <fieldset className="fieldset">
            <legend>App 标题栏图片</legend>
            <p>上传图片定制App中的标题栏显示</p>
            <div className="f-right space-app-logo">
              <span className="title">App内效果预览</span>
              <div className="app-logo">
                {url ? <img src={url} alt="预览" /> : ''}
              </div>
            </div>
            <div className="space-base-setting">
              <LogoCropperForm
                aspect={false}
                accept=".jpg,.jpeg,.png"
                updateImageUrl={this.updateImageUrl}
                shape={2}
                imgSize={{ width: 'auto', height: '100px' }}
                imgSubmit={updateSpace}
                user={space}
                filedType="logo2"
                type={`spaces/${space.id}`}
                description="请选择背景透明或白色的jpg, jpeg, 或png文件"
                isPublic
              />
            </div>
          </fieldset>
          <fieldset className="fieldset">
            <legend>空间logo</legend>
            <p style={{ marginBottom: 20 }}>上传图片定制App中的空间logo显示</p>
            <div className="space-base-setting">
              <LogoCropperForm
                aspect={1}
                accept=".jpg,.jpeg,.png"
                shape={2}
                imgSize={{ width: '100px', height: '100px' }}
                imgSubmit={updateSpace}
                user={space}
                filedType="big_logo"
                type={`spaces/${space.id}`}
                description="请选择jpg, jpeg, 或png文件"
                isPublic
              />
            </div>
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpacesSettingBase
