import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBUserInput,
  KBASNumberInput,
  KBInput
} from 'components'
import * as selectors from 'app/selectors'
import { TAXES_FORM } from 'app/constants/hint'

var SpaceSettingLateFeeForm = React.createClass({
  displayName: 'SpaceSettingLateFeeForm',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    model.late_fee_daily_rate =
      Math.floor(model.late_fee_daily_rate * 10000) / 1000000
    updateCurrentSpace(
      {
        settings: JSON.stringify({
          invoice_settings: model
        })
      },
      {
        key: 'invoice_settings'
      }
    )
  },

  render() {
    const {
      fields: { late_fee_free_date, late_fee_daily_rate },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <div>
                信誉期：隔&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{late_fee_free_date.value}</span>
                &nbsp;)&nbsp;&nbsp;天开始收取滞纳金
              </div>
              <p className="color-nine d-iblock">
                示例：如果设置为5，则会从最终期限的5天后开始收取滞纳金
              </p>
            </div>
            <KBASNumberInput
              field={late_fee_free_date}
              className="f-right"
              type="number"
              maxValue="31"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass="input-percent"
              field={late_fee_daily_rate}
              title="滞纳金日利率"
              hint={TAXES_FORM.rate}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let { late_fee_free_date, late_fee_daily_rate } = setting.invoice_settings
  return {
    initialValues: {
      late_fee_free_date: late_fee_free_date || false,
      late_fee_daily_rate:
        Math.floor(late_fee_daily_rate * 1000000) / 10000 || ''
    }
  }
}

const formConfig = {
  form: 'SpaceSettingLateFeeForm',
  fields: ['late_fee_free_date', 'late_fee_daily_rate'],
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(SpaceSettingLateFeeForm)
