import React from 'react'
import { KBFileUpload, KBInput, KBForm, KBButton } from 'components'
import { api } from 'app/services'
import { createFengMap, fengmap } from 'utils/kbUtil'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'

const templete_url =
  'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/018896ca-4f2b-4fc5-814f-5e894c291f46.xlsx'
const DEFAULT_COLOR = '#FFFFFF'

var LocationImportAreasMap = React.createClass({
  getInitialState() {
    return {
      mapModels: [],
      mapId: null
    }
  },

  componentDidMount() {
    // const map = createFengMap({
    //   idName: 'map-container',
    //   level: 'false',
    //   loadComplete: this.loadComplete
    // })
    // // 点击事件
    // map.on('mapClickNode', this.mapClickNode);
    // this.map = map
    // this.searchAnalyser = this.map.searchAnalyser
  },

  loadComplete() {
    const { mapModels } = this.state
    this.map.mapScaleLevel = 22

    mapModels.forEach(json => {
      if (json.type === 'area') {
        this.renderColorOfMapId(json.map_id, DEFAULT_COLOR)
      }
    })
  },

  mapClickNode(event) {
    var model = event
    var d
    var eventID = ''
    switch (event.nodeType) {
      case fengmap.FMNodeType.FLOOR:
        if (event.eventInfo.eventID == eventID) return
        d = {
          name: '地图',
          x: event.eventInfo.coord.x,
          y: event.eventInfo.coord.y
        }
        break
      case fengmap.FMNodeType.MODEL:
        if (event.eventInfo.eventID == eventID) {
          this.map.selectNull()
          return
        }
        // 过滤类型为墙的model
        if (event.typeID == '30000') {
          // 其他操作
          return
        }
        //模型高亮
        this.map.storeSelect(model)
        this.setState({
          mapId: model.FID
        })
        this.showModalMapId(event, model.FID)
        break
      case fengmap.FMNodeType.FACILITY:
      case fengmap.FMNodeType.IMAGE_MARKER:
        eventID = event.eventInfo.eventID
        d = {
          name: '公共设施',
          x: event.target.x,
          y: event.target.y
        }
        break
      default:
        break
    }
  },

  showModalMapId(event, map_id) {
    var ctlOpt = new fengmap.controlOptions({
      mapCoord: {
        //设置弹框的x轴
        x: event.target.x,
        //设置弹框的y轴
        y: event.target.y,
        height: 6, //控制信息窗的高度
        //设置弹框位于的楼层
        groupID: 1
      },
      //设置弹框的宽度
      width: 250,
      //设置弹框的高度
      height: 80,
      marginTop: 10,
      //设置弹框的内容
      content: `MapId: ${map_id}`
    })

    //添加弹框到地图上
    var popMarker = new fengmap.FMPopInfoWindow(this.map, ctlOpt)

    //popMarker.close(); 关闭信息窗
  },

  saveDeskMap(modal) {
    const { updateLocation } = this.props
    modal.map_provider = 'fengmap'
    updateLocation && updateLocation(modal)
  },

  render() {
    const {
      fields: { map_id },
      location,
      handleSubmit,
      submitting
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.saveDeskMap)}>
        <div className="kb-row kb-form-66">
          <KBInput
            className="kb-form-group kb-div-210"
            title="MapId"
            isRequired={true}
            field={map_id}
          />
          <div />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <KBFileUpload
            url={api.locationAreasImportUrl(location.id)}
            fileField="areas_map"
          />
          <a
            className="color-link"
            href={templete_url}
            style={{ marginLeft: 20 }}
            download="Excel模板.xlsx"
          >
            下载EXCEL模板
          </a>
        </div>
        <div className="kb-row" style={{ marginTop: 25 }}>
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
        <div
          id="map-container"
          style={{ height: 500, marginTop: 30, position: 'relative' }}
        />
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props

  return {
    initialValues: {
      map_id: (location && location.map_id) || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.map_id)) {
    errors.map_id = '请输入MapId'
  }

  return errors
}

const formConfig = {
  form: 'LocationImportAreasMap',
  fields: ['map_id'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationImportAreasMap)
