import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import VisitWelcomeSetImage from './VisitWelcomeSetImage'
import VisitWelcomeSetSlides from './VisitWelcomeSetSlides'
import VisitWelcomeSetLogo from './VisitWelcomeSetLogo'
import VisitWelcomeSetColor from './VisitWelcomeSetColor'

var VisitWelcomeSet = React.createClass({
  saveAndUpdate(model, field) {
    let { updateVisitorSettings, config } = this.props
    let { welcome } = config
    welcome[field] = model[field]
    config.welcome = welcome
    return updateVisitorSettings(config)
  },

  render() {
    const { welcome } = this.props

    return (
      <div className="nav-section-container f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>欢迎屏幕</span>
            </div>
          </div>
        </header>

        <div
          className="nav-section-content-container"
          style={{ minHeight: 500, paddingTop: 30 }}
        >
          <KBLoadingContainer loading={this.props.loading}>
            <VisitWelcomeSetImage
              saveAndUpdate={this.saveAndUpdate}
              welcome={welcome}
            />

            <VisitWelcomeSetSlides
              saveAndUpdate={this.saveAndUpdate}
              welcome={welcome}
            />

            <VisitWelcomeSetColor
              saveAndUpdate={this.saveAndUpdate}
              welcome={welcome}
            />

            <VisitWelcomeSetLogo
              saveAndUpdate={this.saveAndUpdate}
              welcome={welcome}
            />
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, config } = props
  let location_id = params && params.id
  let welcome = (config && config.welcome) || {}

  return {
    location_id,
    config,
    welcome
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitWelcomeSet)
