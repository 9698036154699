import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'
import { api, apiUtils } from 'app/services'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'

var LocationSettingContractForm = React.createClass({
  displayName: 'LocationSettingContractForm',
  setLocation(model) {
    const { updateLocation } = this.props
    const { contract_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'contract',
          range: 'all',
          user_ids: contract_ids ? contract_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { contract_ids },
      error,
      submitting,
      users,
      handleSubmit,
      updateLocation,
      approver
    } = this.props
    const hint = FIX_LOCATION

    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <div className="kb-row">
            <KBUserInput
              title="合同审批人"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.contract}
              field={contract_ids}
              multi={true}
              users={users}
              selectedUsers={approver}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const users = selectors.getSpaceUsers(state)
  let approver =
    selectors.getTeamRolesOfLocation(state, loc_id, 'contract') || []
  return {
    users,
    approver
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'LocationSettingContractForm',
  fields: ['contract_ids'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSettingContractForm)
