import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { printersAction } from 'app/actions'
import { KBInput, KBPopover, KBFormError, KBButton, KBRadio } from 'components'
import * as selectors from 'app/selectors'
import { PRINTER_STATUS } from 'app/constants'

var LocationSetPrinterForm = React.createClass({
  displayName: 'LocationSetPrinterForm',

  createPrinter(model) {
    const {
      createPrinterAction,
      updatePrinterAction,
      state,
      space_id,
      loc_id,
      printer
    } = this.props

    if (state == 'update') {
      model.space_id = printer.space.id
      model.location_id = printer.location.id
      return api.updatePrinter(printer.id, model).then(json => {
        updatePrinterAction.success(json)
        KBPopover.close()
        return
      })
    }
    model.space_id = space_id
    model.location_id = loc_id
    return api.createPrinter(model).then(json => {
      createPrinterAction.success(json)
      KBPopover.close()
      return
    })
  },

  changePrinterStatus(id) {
    const {
      fields: { status }
    } = this.props
    status.onChange(id)
  },

  render() {
    const {
      fields: { name, cmd, status, station_id },
      error,
      submitting,
      handleSubmit,
      state
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39 kb-form-alone'
    }
    return (
      <form onSubmit={handleSubmit(this.createPrinter)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '修改打印机' : '添加打印机'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              isRequired={true}
              {...halfClass}
              field={name}
              title="打印机名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              isRequired={true}
              {...halfClass}
              field={station_id}
              title="打印设备基站ID"
              type="number"
            />
          </div>
          <div className="kb-row">
            <KBInput {...halfClass} field={cmd} title="打印命令" />
          </div>
          <div className="kb-row">
            <div {...halfClass}>
              <label>状态</label>
              <div>
                <div className="kb-form-group r1_radios">
                  {PRINTER_STATUS.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="kb-form-group r1_radio"
                        onClick={this.changePrinterStatus.bind(null, data.id)}
                      >
                        <div
                          className={`radio-btn ${
                            status.value && status.value == data.id
                              ? 'radio-btn-active'
                              : ''
                          }`}
                        ></div>
                        <label className="label">{data.name}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

LocationSetPrinterForm.PropTypes = {
  loc_id: PropTypes.number
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createPrinterAction: bindActionCreators(printersAction.create, dispatch),
    updatePrinterAction: bindActionCreators(printersAction.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入打印机名称'
  }

  if (valid.required(values.station_id)) {
    errors.station_id = '请输入打印设备基站ID'
  }

  return errors
}

const formConfig = {
  form: 'LocationSetPrinterForm',
  fields: ['name', 'cmd', 'status', 'station_id'],
  validate: validate,
  touchOnBlur: false
}

LocationSetPrinterForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetPrinterForm)

export default LocationSetPrinterForm
