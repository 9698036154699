import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import { deptActions } from 'app/actions'
import * as selectors from 'app/selectors'
import _ from 'lodash'

var DepartmentForm = React.createClass({
  createDepartment(model) {
    if (model.parent_id == -1) {
      model.parent_id = null
    }
    const params = Object.assign(model)
    if (this.props.updating) {
      return api
        .updateDepartment(this.props.department.id, params)
        .then(json => {
          this.props.updateDepartmentAction.success(json, {
            key: 'departments'
          })
          KBPopover.close()
        })
    } else {
      return api.createDepartment(params).then(json => {
        this.props.createDepartmentAction.success(json, { key: 'departments' })
        KBPopover.close()
      })
    }
  },
  render() {
    const {
      fields: { name, parent_id },
      error,
      submitting,
      handleSubmit,
      departments
    } = this.props
    const parent = (this.props.parent && [this.props.parent]) || []
    let isDisabled = false
    if (!this.props.updating && !parent_id) {
      parent_id = '-1'
    }
    if (parent && parent.length) {
      isDisabled = parent[0].type == 'org' ? true : false
    }
    return (
      <form onSubmit={handleSubmit(this.createDepartment)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">
            {this.props.updating ? '修改部门' : '添加新部门'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container-m">
          {parent ? (
            <div className="kb-row">
              <KBUserInput
                title="上级部门"
                placeholder="选择上级部门"
                hint={`${parent.length ? '' : '当前部门没有上级'}`}
                disabled={isDisabled}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={parent_id}
                multi={false}
                users={departments}
                selectedUsers={
                  isDisabled || parent.length === 0
                    ? [{ id: -1, name: '全公司' }]
                    : parent
                }
              />
            </div>
          ) : (
            ''
          )}
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="部门名称"
              field={name}
              fistFoucs={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

DepartmentForm.propTypes = {
  parent: PropTypes.object,
  department: PropTypes.object,
  updating: PropTypes.bool
}

function getParentDept(dept, departmentObj) {
  if (!dept || !dept.parent_id) {
    return null
  }
  let parent = departmentObj[dept.parent_id]

  return parent
}

function mapStateToProps(state) {
  let departmentObj = selectors.getDepartmentsObj(state)
  let departments = selectors.getDepartments(state)

  departments = _.cloneDeep(departments).map(dep => {
    let parent = getParentDept(dep, departmentObj)
    let parentName = ''
    while (parent) {
      parentName = parent.name + (parentName ? ' -> ' + parentName : parentName)
      let newParent = departmentObj[parent.id]
      parent = getParentDept(newParent, departmentObj)
    }

    dep.name = parentName ? parentName + ' -> ' + dep.name : dep.name
    return dep
  })
  departments.unshift({ id: -1, name: '全公司' })

  return {
    departments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createDepartmentAction: bindActionCreators(deptActions.create, dispatch),
    updateDepartmentAction: bindActionCreators(deptActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入部门名称'
  }
  return errors
}

const formConfig = {
  form: 'createDepartment',
  fields: ['name', 'parent_id'],
  touchOnBlur: false,
  validate: validate
}

DepartmentForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(DepartmentForm)

export default DepartmentForm
