// import { renderRaw } from 'redraft'
import _ from 'lodash'
import moment from 'moment'
import config from 'app/config'

var time_data = []

var checkServiceError = [
    {
        key : 'unlock_token',
        error : '邮箱链接无效!'
    },{
        key : 'reset_password_token',
        error : '您的邮箱验证过期!'
    }
]

export function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    navigator.clipboard.writeText(text).then(
      () => {
        resolve(true)
      },
      (err) => {
        reject(err)
      }
    )
  })
}


export function fillDate(start_date, end_date, cycle, date_str = 'YYYY-MM-DD') {
  start_date = moment(start_date); end_date = moment(end_date);
  let date_range = [], diff = 0;
  switch (cycle) {
    case 'monthly':
      diff = (end_date.year() - start_date.year()) * 12 + end_date.month() - start_date.month();
      date_range = Array.from({length: diff+1}).map((e,n) => {
        return start_date.clone().startOf('month').add(n, 'months');
      });
      break;
    case 'daily':
      diff = (end_date - start_date) / (24 * 60 * 60 * 1000);
      date_range = Array.from({length: diff+1}).map((e,n) => {
        return start_date.clone().startOf('day').add(n, 'days');
      });
      break;
    case 'weekly':
      diff = Math.ceil((end_date - start_date) / (24 * 60 * 60 * 1000 * 7.0));
      date_range = Array.from({length: diff}).map((e,n) => {
        return start_date.clone().startOf('week').add(n, 'weeks');
      });
      break;
  }
  return date_range.map(e=>e.format(date_str));
}

export function fillNoDataDate(data, start_date, end_date, cycle, value = 0){
  // get date format of data
  let date_format = 'YYYY-MM-DD';
  let result = {};
  let full_dates = fillDate(start_date, end_date, cycle, date_format);
  full_dates.forEach(k => result[k] = value);
  Object.keys(data).map(e => {
    let date_str = e;
    if(cycle == 'monthly' && e.toString().length == 6) date_str += '01';
    let key = moment(date_str).format(date_format);
    result[key] = data[e];
  });
  return result;
}

export function objectIsNull (data = {}) {
  if (!data) {
    return true
  }

  const values = Object.values(data)
  if (!values.length) {
    return true
  }

  return false
}

export function checkJsonError(data){
    var temp = ''

    if(!data){
        return temp
    }

    switch (Object.prototype.toString.call(data)){
        case '[object String]':
            temp = data
            break
        case '[object Array]':
            temp = _getArrayOne(data)
            break
        case '[object Object]':
            temp = _getArrayOne(data)
            break
    }

    function _getArrayOne(data){
        if(data.message){
            return data.message;
        }

        if(data.errors) {
            let errors = data.errors;
            if(Object.prototype.toString.call(errors) !== '[object Array]'){
                return ''
            }
            return errors[0].key+' : '+errors[0].error
        }
        return ''
    }

    return temp
}

// Group locations by city
export function groupLocationsByCity(locations) {
    let group = {}
    locations && locations.map(location => {
      if (group[location.city]) {
        group[location.city] = [...group[location.city], location]
      } else {
        group[location.city] = [location]
      }
    })
    return group
}

// Group locations by location group
export function groupLocationsByLocationGroup(locations) {
	let group = {}
	locations && locations.map(location => {
		location.location_groups && location.location_groups.length > 0 ?
			location.location_groups.map(lg => {
				if (group[lg.id]) {
					group[lg.id] = [...group[lg.id], location]
				} else {
					group[lg.id] = [location]
				}
			})
		:
			group.no_group = group.no_group ? [...group.no_group, location] : [location]
	})
	return group
}

// Get object as associated array from Redux entities tree.
// json is the object usually returned from API and through nomralizr
export const getEntitiesJson = (json, key) => _.get(json, ['json', 'entities', key])

// Get object as an array from Redux entities tree.
// json is the object usually returned from API and through nomralizr
export const getEntitiesJsonArray = (json, key) => {
  let entities = getEntitiesJson(json, key)
  if (!entities) {
    return []
  }
  let result = _.get(json, ['json', 'result'])
  if (result) {
    if (Array.isArray(result)) {
      return result.map(id => entities[id])
    } else {
      return entities[result]
    }
  }
  return Object.values(entities)
}

export const parseFloatFixed = (number) => {
  return number ? parseFloat(number).toFixed(2) : 0
}

export var util = {
  setUrlTime: function (url) {
    var newUrl = url;
    if (url.indexOf("?") != -1) {
      newUrl += "&_=" + (new Date().getTime());
    } else {
      newUrl += "?_=" + (new Date().getTime());
    }
    return newUrl;
  },
  setTimeout: function (name, callback, time) {
    clearTimeout(time_data[name]);
    time_data[name] = setTimeout(callback, time);
  },
  setInterval: function (name, callback, time) {
    var setTimeOut = function () {
      callback();
      time_data[name] = setTimeout(setTimeOut, time);
    };
    setTimeOut();
  },
  clearTimeout: function (name) {
    clearTimeout(time_data[name]);
  }
  ,
  bind: function (eventName, fn) {
    if (window.addEventListener) {
      window.addEventListener(eventName, fn, false);
    } else if (window.attachEvent) {
      window.attachEvent("on" + eventName, fn);
    }
  },
  //清除空格
  clearSpacing: function (text) {
    if (!text) {
      return ''
    }
    return text.replace(/\s/ig, '');
  },
  addHandler: function (element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler);
    } else if (element.attachEvent) {
      element.attachEvent("on" + type, handler);
    } else {
      element["on" + type] = handler;
    }
  },
  removeHandler: function (element, type, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(type, handler, false);
    } else if (element.detachEvent) {
      element.detachEvent("on" + type, handler);
    } else {
      element["on" + type] = null;
    }
  },
  appendScript: function(url,callback){
    var head= document.getElementsByTagName('head')[0];
    var script= document.createElement('script');
    script.type= 'text/javascript';
    script.onreadystatechange= function () {
      if (this.readyState == 'complete')
        callback();
    };
    script.onload= function(){
      callback();
    };
    script.src= url;
    head.appendChild(script);
  },
  getQueryString: function(name){
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }
};

export var FillerErrorMsg = (str) => {
    return checkServiceError.find((a,i) => {
        return str.indexOf(a.key) !== -1
    }).error
}

/**
 * checkObject({a : { b : { c : 13123 } }},'a','b','c')
 *  检查元素
 */
export function checkObject(src){
  var argumentArray = Array.prototype.slice.call(arguments)
  var src = Object.assign({},argumentArray.shift())
  var result = undefined

  argumentArray.every((value) => {
    let srcTemp = src[value]

    if(!srcTemp){
      result = undefined
      return false
    }

    result = src = srcTemp

    return true
  })

  return result
}

export function checkName(name){
  return name || ''
}

export function encryptionPhone (phone) {
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
}

export const paginate = function (response) {
  if (response && response.header && response.header['x-pagination']){
    let paginationText = response.header['x-pagination']
    let pairs = paginationText.split(',')
    let result = {}
    pairs.map(pair => {
      let [key, val] = pair.split('=')
      result[key.trim()] = parseInt(val)
    })

    return result
  }
  return {}
}

const loadPage = {
  nextPage (next_page,prev_page = 0,total_pages) {
    let new_next_page = next_page + 1
    let new_prev_page = (isNaN(prev_page) ? 0 : prev_page) + 1

    new_next_page = new_next_page > total_pages ? NaN : new_next_page

    return {
      next_page : new_next_page,
      prev_page : new_prev_page
    }
  },
  prePage (next_page = 1,prev_page) {
    let new_next_page = (isNaN(next_page) ? prev_page + 2 : next_page) - 1
    let new_prev_page = prev_page - 1

    new_prev_page = new_prev_page ? new_prev_page : NaN

    return {
      next_page : new_next_page,
      prev_page : new_prev_page
    }
  }
}

export function isMatch(a,b){
  return _.isEqual(a,b)
}

export function checkNull(text,def){
  return text || (def || '')
}

export function percent (a,b) {
  let denom = parseFloat(a)
  let nom = parseFloat(b)

  if (isNaN(denom) || denom === 0) {
    return 0
  }
  if (isNaN(nom)) {
    nom = 0
  }
  return ((nom/denom)*100).toFixed(2)
}

export function momentFromNow (data, isTrue){
  return moment(data).fromNow(isTrue)
}

export function randomNumber (number = 10){
  return Math.floor(Math.random()*number+1)
}

export function mergeArrayWithKey (oldArray, newArray, key) {
  if (!key || !newArray || !newArray.length) {
    return
  }

  if (!oldArray.length) {
    return newArray || []
  }

  var tempArray = Object.assign([], oldArray)

  for (let i = 0, length = tempArray.length, temp = tempArray[i]; i < length, temp = tempArray[i++];) {
    let oldKey = temp[key]
    let findObj = newArray.find(value => value[key] == oldKey)
    if (!findObj) {
      continue
    }

    _.merge(temp, findObj)
  }

  return tempArray
}

/**
 * 数组转换成Object     Object 必须 存在ID
 * @return {[object]} [object]
 */
export function arrayToObject (array) {
  var result = {}
  const temp = Object.assign([], array)
  temp.forEach((obj) => {
    if (obj.id) {
      result[obj.id] = obj
    }
  })

  return result
}

/**
 * 对象转换成Array
 */
export const objectToArray = (object) => {
  return object && Object.keys(object).length > 0 && Object.values(object) || []
}

/**
 * 对比两个时间日期
 * @param  {[date]} start [第一个日期]
 * @param  {[date]} end   [第二个日期]
 * @param  {[string]} type   [类型]
 * @return {[int]}       [时间差]
 */
export function contrastDate (start, end, type) {
  if (type == 'integer') {
    return moment(getIntegralDate(start)).diff(moment(getIntegralDate(end)))
  } else if (type) {
    return moment(start).diff(moment(end), type)
  }

  return moment(start).diff(moment(end))
}

/**
 * 返回整点时间
 */
export function getIntegralDate (date) {
  return moment(date).hours(0).minutes(0).seconds(0).toDate()
}

/**
 * 返回数据的类型
 */
export function getDateType (date) {
  return date && Object.prototype.toString(date)
}

export function updateDataToArrayOfIndex (list, data, index = 0) {
  var array = Object.assign([], list)

  if (!array.length || !data) {
    return []
  }

  array.splice(index, 1, data)
  return array
}

export function deleteDataToArrayOfIndex (list, index) {
  var array = Object.assign([], list)

  array.splice(index, 1)
  return array
}

export function addDataToArrayOfIndex (list, data) {
  var array = Object.assign([], list)

  array.unshift(data)

  return array
}

// 判断当前空间是不是 尚科
export function isHideModule (space_id) {
  if (space_id && space_id == '12396') {
    return true
  } else {
    return false
  }
}

// 判断当前空间是不是 funwork
export const isFunwork = (space_id) => {
  if (space_id && space_id == '6') {
    return true
  } else {
    return false
  }
}

// 判断当前空间是不是 无界
export const isWooSpace = (space_id) => {
	return false
	// if (space_id && space_id == '1') {
	// 	return true
	// } else {
	// 	return false
	// }
}

// 判断当前空间是不是 星库
export const isXingKuSpace = (space_id) => {
  if (space_id && space_id == '6197') {
    return true
  } else {
    return false
  }
}

export function fillHttp (herf) {
  if (/^http/.test(herf)) {
    return herf
  }

  return "http://" + herf
}

/**
 * 对比两个日期  默认年月日比较
 * @param  {[string || moment]} start   [第二个日期]
 * @param  {[string || moment]} end     [第二个日期]
 * @return {[Boolean]}         [是否相同  true 为 相同  false 不相同]
 */
export function contrastYMDDate (start, end) {
  return moment(start).isSame(moment(end), 'year', 'month', 'day')
}

/**
 * 判断 CurrentValue 是否为 value 中的一个
 * @param {[string || array]} value [value]
 * @param {[string]} currentValue [当前value]
 */
export function isListValue (value, currentValue) {
  if (Object.prototype.toString.call(value) == '[object Array]') {
    let t = value.find(json => json === currentValue)
    return t || t === '' ? true : false
  }

  return value === currentValue
}

/**
 * 如果为 array 返回数组第一个 [1,2] => 1
 * @param {[string || array]} value [value]
 */
export function getListFirstValue (value, index = 0) {
  if (Object.prototype.toString.call(value) == '[object Array]') {
    return value[index]
  }

  return value
}

export const getUUID = () => {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

export function preventDefaultPropagation (e) {
  e.stopPropagation()
  e.preventDefault()
}

export function getYMD (t) {
  let year = moment(t).get('year')
  let month = moment(t).get('month')
  let day = moment(t).get('date')

  return {
    year,
    month,
    day
  }
}

//将筛选条件转为string
export function queryFilter (object) {
  if (Object.keys(object).length == 0) {
    return ''
  }

  let arrayKeys = Object.keys(object)
  let arrayValues = Object.values(object)
  var str = ''

  arrayKeys.map((data,index) => {
    str += (data + '=' +  arrayValues[index] + `${index + 1 == arrayKeys.length ? '' : '&'}`)
  })
  return '?' + str
}
// 比较原有query与filter
export function getFilterAndQuery (query, paramsArray) {
  let newQuery = Object.assign({}, query)
  let filters = {}
  paramsArray.forEach(filter => {
    const value = newQuery[filter]
    if (value) {
      filters[filter] = value
      delete newQuery[filter]
    }
  })
  return {
    filters,
    queryAll : query,
    query    : newQuery
  }
}

export function parseStringFilesToArray (files) {
  if (!files) {
    return []
  }
  const fileList = files.split(',')
  return fileList.map(json => {
    let i = json
    return {
      name     : i.substring(i.lastIndexOf('/') + 1, i.lastIndexOf('.')),
      file_url : i
    }
  })
}

export function parseListFilesToString (files) {
  if (!files) {
    return ''
  }
  return files.map(json => json.file_url).join(',')
}

// 筛选数组最大值与最小值
export function getMaximin(arr,maximin) {
  if(maximin=="max"){
    return Math.max.apply(Math,arr);
  } else if(maximin=="min") {
    return Math.min.apply(Math, arr);
  }
}

// 数字转换千分位
export const fmoney = (s, n) => {
  let minus = false
  if(s < 0){
    s = -s
    minus = true
  }
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  let l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1],
    t = "";
  for(let i = 0; i < l.length; i ++ )
  {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  let num = t.split("").reverse().join("") + "." + r
  return minus ? ('-' + num) : num
}

// 小数转百分数
export const toPercent = (point, x) => {
  var str=Number(point*100).toFixed(x);
  str+="%";
  return str;
}

export const getFloatOfSetting = (num, type) => {
  switch (type) {
    case 'ceiling':
      return Math.ceil(num)
    case 'floor':
      return Math.floor(num)
    case 'round':
      return Math.round(num)
    default:
      return num
  }
}

const initMoment = moment()

export const formatYearDayCHS = (date) => {
  if (!date) {
      return ''
  }
  if (typeof(date) === 'number') {
      return moment.unix(date).format('YYYY年MM月DD日')
  }
  return moment(date).format('YYYY年MM月DD日')
}

export const formatYearDayEN =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY-MM-DD')
    }
  return moment(date).format('YYYY-MM-DD')
}

export const formatYearDaySOL =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY/MM/DD')
    }
  return moment(date).format('YYYY/MM/DD')
}

export const formatOnlyMinuteSecondCHS =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('HH时mm分ss秒')
    }
  return moment(date).format('HH时mm分ss秒')
}

export const formatOnlyMinuteSecondEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('HH:mm:ss')
    }
  return moment(date).format('HH:mm:ss')
}

export const formatOnlyMinuteEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('HH:mm')
    }
  return moment(date).format('HH:mm')
}

export const formatMinuteSecondCHS =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY年MM月DD日 HH时mm分ss秒')
    }
  return moment(date).format('YYYY年MM月DD日 HH时mm分ss秒')
}

export const formatMinuteSecondSOL=  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY/MM/DD HH:mm:ss')
    }
  return moment(date).format('YYYY/MM/DD HH:mm:ss')
}

export const formatHoursMinutesCHS =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY年MM月DD日 HH:mm')
    }
  return moment(date).format('YYYY年MM月DD日 HH:mm')
}
export const formatHoursMinutesPON =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY.MM.DD HH:mm')
    }
  return moment(date).format('YYYY.MM.DD HH:mm')
}

export const formatHoursMinutesEN =  (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY-MM-DD HH:mm')
    }
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export const formatMinuteSecondEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatMonthDayCHS = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('MM月DD日')
    }
  return moment(date).format('MM月DD日')
}

export const formatMonthDayEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('MM-DD')
    }
  return moment(date).format('MM-DD')
}

export const formatOnlyHmStageEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('h:mm a')
    }
  return moment(date).format('h:mm a')
}

export const formatYMDMinuteStageEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('YYYY-MM-DD h:mm a')
    }
  return moment(date).format('YYYY-MM-DD h:mm a')
}
export const formatHmsStageEN = (date) => {
    if (!date) {
        return ''
    }
    if (typeof(date) === 'number') {
        return moment.unix(date).format('h:mm:ss a')
    }
  return moment(date).format('h:mm:ss a')
}

export const formatYMDN4TwoDate = (date, date1) => {
  return `${formatYearDayCHS(date)} - ${formatYearDayCHS(date1)}`
}

export const formatHmN4TwoDate = (date, date1) => {
  return `${formatOnlyMinuteEN(date)} - ${formatOnlyMinuteEN(date1)}`
}


export const kbCloseDropDown = (mine, ref_array, ref_name) => {
  ref_array.forEach(ref_item => {
    if(ref_item == ref_name){
      return
    }

    let closeDropDowns = mine[ref_item]
    closeDropDowns && closeDropDowns.hide()
  })
}

// 转换时间数字为 HH:MM 格式
export const formatTimeNumber = (t_number) => {
    const zeroFormat = (num) => {
        if (num < 10) {
            return '0' + num
        }
        return String(num)
    }
    if (typeof(t_number) !== 'number' || t_number < 0) {
        return ''
    }
    let hour = Math.floor(t_number / 60)
    let minute = Math.floor(t_number % 60)
    return zeroFormat(hour) + ':' + zeroFormat(minute)
}


export const getDataOfSourceByIDS = (ids, source) => {
  if (!ids || !ids.length) {
    return []
  }

  let list = []
  let idsList = ids && ids.split(',') || []
  idsList.forEach(id => {
    list.push(source[id])
  })
  return list
}

export const getFieldErrorMsg = (fields) => {
  const v = Object.values(fields)
  const field = v.find(j => j.touched && j.error && (typeof j.error === 'string'))
  return field && field.error || ''
}

export const listIsNullCallBack = (list, callback) => {
  if (!list || !list.length) {
    callback && callback()
  }
}

export const setCurrentLocation = (location) => {
    if (!location) {
        return
    }
    sessionStorage && sessionStorage.setItem('current_location', JSON.stringify(location))
}

export const removeCurrentLocation = () => {
    sessionStorage && sessionStorage.removeItem('current_location')
}

export const getCurrentLocation = () => {
    return JSON.parse(sessionStorage && sessionStorage.getItem('current_location')) || null
}

// 获取API错误信息返回
export const getServiceErrorMessage = (error) => {
  const errorResponse = error.response && JSON.parse(error.response)
  const errorMessage = (error && error.message) || (errorResponse ? (errorResponse.message || (errorResponse._error && errorResponse._error.message)) : null)
  if(error.status === 404){
    return '页面不存在'
  }

  return errorMessage
}

// fengmap 蜂鸟
export var fengmap = window.fengmap || {}

export const createFengMap = (obj = {}) => {
  const fmapID = obj.fmapID || 'kubantest1'; //mapId
  let config = {
    container: document.getElementById(obj.idName), // 渲染dom
    appName:'kubantest1',           // 开发者应用名称
    key:'784ce34083545d12ea066be442670b8d',  // 开发者申请应用下web服务的key
    defaultViewMode: fengmap.FMViewMode.MODE_2D,
    mapThemeURL: 'https://media-ssl.kuban.io/static/ent-web/data/theme',
    defaultThemeName: '2001',
    defaultMapScaleLevel : obj.level || 23
  }

  if (obj.level === 'false') {
    delete config.defaultMapScaleLevel
  }

  const map = new fengmap.FMMap(config);


  // 放大、缩小控件配置
  const ctlOpt1 = new fengmap.controlOptions({
    //设置显示的位置为左上角
    position: fengmap.controlPositon.LEFT_TOP,
    //位置x,y的偏移量
    offset: {
      x: 20,
      y: 20
    },
    size: 20,
    imgURL: 'https://media-ssl.kuban.io/static/ent-web/file/'
  });

  // 地图加载完成事件
  map.on('loadComplete',() => {
    // 放大、缩小控件
    var zoomControl = new fengmap.zoomControl(map, ctlOpt1);

    const searchAnalyser = map.searchAnalyser;
    const searchReq = new fengmap.FMSearchRequest(fengmap.FMNodeType.MODEL)
    // searchAnalyser.query(searchReq, function(request, result) {
    //   result.map(res => {
    //     // res && res.setColor('#B8CAD3')
    //     res && res.setColor('#EDEEEE')
    //   })
    // })

    obj.loadComplete&&obj.loadComplete()
  });

  // 打开Fengmap服务器的地图数据和主题
  map.openMapById(fmapID);

  return map
}

export const getBlobService = (url, sas) => {
  var blobService = AzureStorage.Blob.createBlobServiceWithSas(url, sas).withFilter(new AzureStorage.Blob.ExponentialRetryPolicyFilter());
  return blobService;
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

// 判断图片是否为七牛路径(https://media-dev-ssl.kuban.io/images/locations/87bd589550-0adb-4f08-b341-2053624a18f2.jpg?imageView2/1/w/644/h/410/interlace/1/ignore-error/1)
export const isImageQiniuPath = (url) => {
  let urlArray = url.split('//')
  let urlPath = urlArray[1]
  let paramsArray = urlPath.split('/')
  let domainStr = paramsArray[0]
  if(domainStr && domainStr.indexOf('media') != -1){
    return true
  }
  return false
}

/**
 * 图片裁剪处理
 */
export const toImageProxyDirect = (url, width, height, resizing_type = 'fill') => {
  // 这个版本不需要裁剪
  return url
  if (!url) {
    return
  }

  if(isImageQiniuPath(url)){
    return url + `?imageView2/1/w/${width}/h/${height}/interlace/1/ignore-error/1`
  }

  return toImageProxy(url, {
    resizing_type,
    width,
    height
  })
}

export const toFormatAzore = (url, imageObj) => {
  url = url.replace('https://' + config.azure_domain, 'http://' + config.azure_cdn_domain)

  imageObj = Object.assign({}, {
    resizing_type: 'fill',
    width: 400,
    height: 400,
    gravity: 'ce',
    enlarge: 1,
    extension: 'png'
  }, imageObj)

  url = `${url}?basic=${imageObj.height}h_${imageObj.width}w_1c_0l_0e`

  return url
}

/**
 * 图片裁剪
 */
export const toImageProxy = (url, imgproxyObj) => {
  const crypto = require('crypto')

  if (!url) {
    return
  }

  imgproxyObj = Object.assign({}, {
    resizing_type: 'fill',
    width: 400,
    height: 400,
    gravity: 'ce',
    enlarge: 1,
    extension: 'png'
  }, imgproxyObj)

  const KEY = '6a1db7fbe3b385ad3f51a7d8ee9ba3d27366573b4621a08c8aab30f23428'
  const SALT = '66491aedcd3f90d3faa8a2b6d3a09cd028bfda47b4fa164148297672a672'

  const urlSafeBase64 = (string) => {
    return new Buffer(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }

  const hexDecode = (hex) => Buffer.from(hex, 'hex')

  const sign = (salt, target, secret) => {
    const hmac = crypto.createHmac('sha256', hexDecode(secret))
    hmac.update(hexDecode(salt))
    hmac.update(target)
    return urlSafeBase64(hmac.digest())
  }

  const resizing_type = imgproxyObj.resizing_type
  const width = imgproxyObj.width
  const height = imgproxyObj.height
  const gravity = imgproxyObj.gravity
  const enlarge = imgproxyObj.enlarge
  const extension = imgproxyObj.extension

  const encoded_url = urlSafeBase64(url)
  const path = `/${resizing_type}/${width}/${height}/${gravity}/${enlarge}/${encoded_url}.${extension}`

  const signature = sign(SALT, path, KEY)
  const result = `/${signature}${path}`

  return config.imgproxy_url + result
}

/**
 * 根据合同会员费和税率获取合同税额
 */
export const getTaxAmountOfContract = (rent, tax_rate) => {
	let rentAmount = rent || 0
	let rate = tax_rate || 0
	return Math.round(parseFloat((rentAmount * rate) / (1 + rate)) * 100) / 100
}

// 判断当前 location 是否是 写字楼 类型的 location
export const isTowerLocation = (location) => {
	const towerLocationTypes = ['sales_office', 'self_office']
	return location && towerLocationTypes.includes(location.location_type)
}
