import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBUserInput,
  KBASNumberInput,
  KBSelect
} from 'components'
import {
  CAN_BINDING_CARD,
  CAN_SET_TOUCH_PASSWORD,
  DISPLAY_BLUETOOTH_LOCK
} from 'app/constants'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { LOCK_SETTING_FORM } from 'app/constants/hint'

var SpaceSettingLockForm = React.createClass({
  displayName: 'SpaceSettingLockForm',
  updateSpace(model) {
    model.can_binding_card = model.can_binding_card == 'true' ? true : false
    model.can_set_touch_password =
      model.can_set_touch_password == 'true' ? true : false
    model.display_bluetooth_lock =
      model.display_bluetooth_lock == 'true' ? true : false

    const { updateCurrentSpace } = this.props

    updateCurrentSpace(
      {
        settings: JSON.stringify({
          lock_settings: model
        })
      },
      {
        key: 'lock_settings'
      }
    )
  },
  render() {
    const {
      fields: {
        can_binding_card,
        can_set_touch_password,
        display_bluetooth_lock
      },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: '0 400px 0 0' }}>
          <div className="kb-row">
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-210 kb-form-alone"
              title="自行绑卡"
              field={can_binding_card}
              arrayData={CAN_BINDING_CARD}
              hint={LOCK_SETTING_FORM.bind_card}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-210 kb-form-alone"
              title="触摸密码"
              field={can_set_touch_password}
              arrayData={CAN_SET_TOUCH_PASSWORD}
              hint={LOCK_SETTING_FORM.set_lock_password}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue={'-1'}
              className="kb-form-group kb-div-210 kb-form-alone"
              title="蓝牙门禁"
              field={display_bluetooth_lock}
              arrayData={DISPLAY_BLUETOOTH_LOCK}
              hint={LOCK_SETTING_FORM.display_bluetooth}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const setting = props.setting || {}
  let lock_settings = setting.lock_settings
  return {
    initialValues: {
      can_binding_card: lock_settings.can_binding_card + '',
      can_set_touch_password: lock_settings.can_set_touch_password + '',
      display_bluetooth_lock: lock_settings.display_bluetooth_lock + ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingLockForm',
  fields: [
    'can_binding_card',
    'can_set_touch_password',
    'display_bluetooth_lock'
  ],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingLockForm)
