import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { percent } from 'utils/kbUtil'
import { OFFICE_AREA_STATE } from 'app/constants'

var LocationAreasUsage = React.createClass({
  displayName: 'LocationAreasUsage',
  getInitialState() {
    return {
      areasUsage: null
    }
  },

  componentDidMount() {
    const { loc_id } = this.props

    api.getLocationAreasUsage(loc_id).then(json => {
      let areasUsage = json.json
      this.setState({
        areasUsage
      })
    })
  },

  disposeAreaPrice(usage) {
    let newPrice = null
    if (usage.min_price == usage.max_price) {
      newPrice = usage.min_price
    } else {
      newPrice = usage.min_price + '~' + usage.max_price
    }
    return newPrice
  },

  render() {
    const { areasUsage } = this.state

    return (
      <section className="kb-content">
        <div className="kb-content-container clear-fix">
          <header className="nav-header">
            <div className="nav-section-header-title">分区列表</div>
          </header>
          <div className="nav-section-content-container">
            <table className="content-table">
              <thead>
                <tr>
                  <th>工位类型</th>
                  <th>市场价格(n/人/月)</th>
                  <th>总工位数</th>
                  <th>已租工位数</th>
                  <th>剩余工位数</th>
                  <th className="t-center">目前是否空置</th>
                </tr>
              </thead>
              <tbody>
                {areasUsage &&
                  areasUsage.map((usage, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {OFFICE_AREA_STATE[usage.area_type]}
                          {usage.capacity ? `(${usage.capacity}人间)` : ''}
                        </td>
                        <td>{this.disposeAreaPrice(usage)}</td>
                        <td>{usage.total}</td>
                        <td>{usage.total - usage.remain}</td>
                        <td>{usage.remain}</td>
                        <td className="t-center">
                          {usage.is_vacant ? '是' : '否'}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { id } = params
  return {
    loc_id: id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationAreasUsage)
