import React from 'react'
import SpaceSettingCurrencyForm from './SpaceSettingCurrencyForm'
import SpaceSettingPoint from './SpaceSettingPoint'
import SpacePointPlans from './pointplans/SpacePointPlans'

var SpaceSettingCurrency = React.createClass({
  displayName: 'SpaceSettingCurrency',
  render() {
    const { updateCurrentSpace, setting } = this.props
    if (!setting.settings) {
      return null
    }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>虚拟货币设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>基本设置</legend>
            <SpaceSettingCurrencyForm
              setting={setting}
              updateCurrentSpace={updateCurrentSpace}
            />
          </fieldset>

          <fieldset className="fieldset">
            <legend>充值优惠设置</legend>
            <SpacePointPlans />
          </fieldset>

          <fieldset className="fieldset">
            <legend>行为设置</legend>
            <SpaceSettingPoint
              setting={setting}
              updateCurrentSpace={updateCurrentSpace}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

export default SpaceSettingCurrency
