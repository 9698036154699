import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBFormError,
  KBButton,
  KBUserInput,
  KBASNumberInput,
  KBSelect,
  KBRadio
} from 'components'
import {
  CAN_BINDING_CARD,
  CAN_SET_TOUCH_PASSWORD,
  DISPLAY_BLUETOOTH_LOCK,
  NEED_CLOSE_TO_LOCK
} from 'app/constants'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { successState } from 'app/actions'

var LocationSetLock = React.createClass({
  displayName: 'SpaceSettingLockForm',

  componentDidMount() {
    const { locationSetting } = this.props
    this.updateFileds(locationSetting)
  },

  setLocationSetting(model) {
    model.can_binding_card = model.can_binding_card == 'true' ? true : false
    model.can_set_touch_password =
      model.can_set_touch_password == 'true' ? true : false
    model.display_bluetooth_lock =
      model.display_bluetooth_lock == 'true' ? true : false
    model.need_close_to_lock = model.need_close_to_lock == 'true' ? true : false

    const { updateLocationSetting } = this.props
    return updateLocationSetting(
      {
        lock_settings: model
      },
      {
        key: 'lock_settings'
      }
    )
  },

  updateFileds(setting = {}) {
    const {
      fields: {
        can_binding_card,
        can_set_touch_password,
        display_bluetooth_lock,
        need_close_to_lock
      }
    } = this.props

    can_binding_card.onChange(
      setting.lock_settings && setting.lock_settings.can_binding_card + ''
    )
    can_set_touch_password.onChange(
      setting.lock_settings && setting.lock_settings.can_set_touch_password + ''
    )
    display_bluetooth_lock.onChange(
      setting.lock_settings && setting.lock_settings.display_bluetooth_lock + ''
    )
    need_close_to_lock.onChange(
      setting.lock_settings && setting.lock_settings.need_close_to_lock + ''
    )
  },

  componentWillReceiveProps(nextProps) {
    const { locationSetting } = nextProps
    if (
      locationSetting &&
      locationSetting.settings &&
      locationSetting != this.props.locationSetting
    ) {
      this.updateFileds(locationSetting)
    }
  },

  render() {
    const {
      fields: {
        can_binding_card,
        can_set_touch_password,
        display_bluetooth_lock,
        need_close_to_lock
      },
      location,
      error,
      submitting,
      handleSubmit,
      updateLocation
    } = this.props

    const isNearOpenLock = [
      { title: '是', value: true },
      { title: '否', value: false }
    ]

    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>门禁设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <form onSubmit={handleSubmit(this.setLocationSetting)}>
            <KBFormError error={this.props.error} />
            <div
              className="kb-form-container"
              style={{ padding: '0 400px 0 0' }}
            >
              <div className="kb-row">
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-210 kb-form-alone"
                  title="自行绑卡"
                  field={can_binding_card}
                  arrayData={CAN_BINDING_CARD}
                />
              </div>
              <div className="kb-row">
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-210 kb-form-alone"
                  title="触摸密码"
                  field={can_set_touch_password}
                  arrayData={CAN_SET_TOUCH_PASSWORD}
                />
              </div>
              <div className="kb-row">
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-210 kb-form-alone"
                  title="蓝牙门禁"
                  field={display_bluetooth_lock}
                  arrayData={DISPLAY_BLUETOOTH_LOCK}
                />
              </div>
              <div className="kb-row" style={{ 'margin-top': 15 }}>
                <KBRadio
                  className="kb-form-group kb-div-210 kb-form-alone"
                  inClassName="kb-form-group kb-div-33"
                  groupStyle={{ width: '100%' }}
                  divClassName="kb-form-group kb-div-66-active"
                  rightLabel="label-rt"
                  title="靠近开门"
                  arrayType={isNearOpenLock}
                  name="external"
                  field={need_close_to_lock}
                />
                <p
                  style={{ 'font-size': 12, color: '#666666', 'margin-top': 5 }}
                >
                  备注：设置是否需要靠近门禁才能开门
                </p>
              </div>
              <div className="kb-row m-top">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                >
                  保存
                </KBButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const loc_id = props.params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id)
  return {
    locationSetting
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'LocationSetLock',
  fields: [
    'can_binding_card',
    'can_set_touch_password',
    'display_bluetooth_lock',
    'need_close_to_lock'
  ],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetLock)
