import React from 'react'
import { KBSelect, KBUserInput } from 'components'
import { SPACE_MEMBER_LOCATION_ROLES } from 'app/constants'

var MemberLocationRolesSettings = React.createClass({
  componentDidMount() {},

  render() {
    const { location_permission_settings, locations, locationsObj } = this.props
    return (
      <div>
        {location_permission_settings.length > 0 &&
          location_permission_settings.map((json, index) => {
            let spaceMemberRoles = SPACE_MEMBER_LOCATION_ROLES

            return (json.enabled||{}).value ? (
              <div style={{ position: 'relative' }}>
                <div className="kb-row kb-form-66" key={index}>
                  <KBUserInput
                    title="分店"
                    placeholder="选择分店"
                    className="kb-form-group kb-div-c39"
                    field={json.location_id}
                    isRequired={true}
                    multi={false}
                    users={locations}
                    selectedUsers={[locationsObj[(json.location_id||{}).value]]}
                  />
                  <KBSelect
                    defaultValue=" "
                    isRequiredR={true}
                    className="kb-form-group kb-div-c39"
                    titleClass="label-rt"
                    title="角色"
                    field={json.role}
                    arrayData={spaceMemberRoles}
                  />
                  {/*<KBSelect defaultValue=' ' isRequiredR={true} className='kb-form-group kb-div-48' titleClass='label-rt' title='激活' field={json.enabled} arrayData={LOCATION_ROLES_ENABLED}  />*/}
                </div>
                <i
                  className="iconfont icon-delete hover-delete"
                  style={{ position: 'absolute', top: 38, right: 0 }}
                  onClick={() =>
                    location_permission_settings[index].enabled.onChange(false)
                  }
                />
              </div>
            ) : (
              ''
            )
          })}
      </div>
    )
  }
})

export default MemberLocationRolesSettings
