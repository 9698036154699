import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import {
  KBButton,
  KBTextarea,
  KBFormError,
  KBASNumberInput,
  KBSelect,
  KBInput
} from 'components'
import { api } from 'app/services'
// import { KBSelect } from 'components/views'
import * as selectors from 'app/selectors'
import _ from 'lodash'
import { DAY_TIME_HOUR } from 'app/constants'
import GroupSetStationImageForm from './GroupSetStationImageForm'
import { CUSTOM_TIME_ARRAY } from 'app/constants'

let GroupSetStation = React.createClass({
  getInitialState() {
    return {
      WEEK_ARRAY: [
        {
          title: '周一',
          check: true,
          value: 1,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周二',
          check: true,
          value: 2,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周三',
          check: true,
          value: 3,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周四',
          check: true,
          value: 4,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周五',
          check: true,
          value: 5,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周六',
          check: true,
          value: 6,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周日',
          check: true,
          value: 7,
          startTime: '09:00',
          endTime: '18:00'
        }
      ]
    }
  },

  ChangeHourMinutestr(str) {
    if (str !== '0' && str !== '' && str !== null) {
      return (
        (Math.floor(str / 60).toString().length < 2
          ? '0' + Math.floor(str / 60).toString()
          : Math.floor(str / 60).toString()) +
        ':' +
        ((str % 60).toString().length < 2
          ? '0' + (str % 60).toString()
          : (str % 60).toString())
      )
    } else {
      return ''
    }
  },

  ChangeStrToMinutes(str) {
    var arrminutes = str.split(':')
    if (arrminutes.length == 2) {
      var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1])
      return minutes
    } else {
      return 0
    }
  },
  getSetting() {
    const {
      loc_id,
      fields: {
        cancellation_offset,
        lock_permission_end_at,
        lock_permission_start_at,
        max_duration,
        note,
        create_reservation_note,
        advance_limitation
      }
    } = this.props
    const { WEEK_ARRAY } = this.state
    api
      .getSpaceSetting({
        target_type: 'LocationGroup',
        target_id: loc_id,
        inherit: true
      })
      .then(json => {
        const settingValue = _.get(json, 'response.body', {})
        const {
          desk_reservation_settings: { reservation_time_range },
          desk_reservation_settings
        } = settingValue
        if (reservation_time_range) {
          let week = []
          if (reservation_time_range.length > 1) {
            week = WEEK_ARRAY.map(x => {
              reservation_time_range.forEach(item => {
                if (item.day === x.value) {
                  x.check = !item.nowork
                  x.startTime = this.ChangeHourMinutestr(item.begin)
                  x.endTime = this.ChangeHourMinutestr(item.end)
                }
              })
              return x
            })
          } else {
            week = WEEK_ARRAY.map(x => {
              const item = reservation_time_range[0]
              x.check = !item.nowork
              x.startTime = this.ChangeHourMinutestr(item.begin)
              x.endTime = this.ChangeHourMinutestr(item.end)

              return x
            })
          }

          this.setState({ WEEK_ARRAY: week })
        }
        cancellation_offset.onChange(
          desk_reservation_settings.cancellation_offset
        )
        max_duration.onChange(desk_reservation_settings.max_duration)
        note.onChange(desk_reservation_settings.note)
        create_reservation_note.onChange(desk_reservation_settings.create_reservation_note)
        advance_limitation.onChange(
          desk_reservation_settings.advance_limitation
        )
        // lock_permission_end_at.onChange(
        //   desk_reservation_settings.lock_permission_end_at / 60
        // )
        // const end_time = desk_reservation_settings.lock_permission_start_at / 60
        // const end_at = end_time == 24 ? 0 : end_time
        // lock_permission_start_at.onChange(end_at)
      })
  },

  componentDidMount() {
    this.getSetting()
  },

  updateSpace(model) {
    const { updateLocationSetting } = this.props
    const { WEEK_ARRAY } = this.state
    let reservation_time_range = []
    let isAll = true
    WEEK_ARRAY.forEach(x => {
      if (!x.check) isAll = false
    })
    if (isAll) {
      reservation_time_range = [{ day: -1, nowork: false }]
    } else {
      reservation_time_range = WEEK_ARRAY.map(x => ({
        day: x.value,
        nowork: !x.check,
        begin: this.ChangeStrToMinutes(x.startTime),
        end: this.ChangeStrToMinutes(x.endTime)
      }))
    }
    const end_at =
      model.lock_permission_end_at == 0
        ? 24 * 60
        : model.lock_permission_end_at * 60
    const params = {
      ...model,
      lock_permission_start_at: model.lock_permission_start_at * 60,
      lock_permission_end_at: end_at,
      reservation_time_range
    }
    updateLocationSetting(
      {
        desk_reservation_settings: params
      },
      { key: 'desk_reservation_settings' }
    ).then(res => {
      console.log(res)
    })
  },

  stationStartHour(value) {
    const {
      fields: { lock_permission_start_at }
    } = this.props
    lock_permission_start_at.onChange(value)
  },
  stationEndHour(value) {
    const {
      fields: { lock_permission_end_at }
    } = this.props
    lock_permission_end_at.onChange(value)
  },
  render() {
    const {
      fields: {
        cancellation_offset,
        lock_permission_end_at,
        lock_permission_start_at,
        max_duration,
        note,
        advance_limitation,
        create_reservation_note
      },
      handleSubmit,
      submitting,
      group,
      updateLocation,
      loc_id
    } = this.props
    const { WEEK_ARRAY } = this.state
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>工位设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            {/* <legend>租期时限</legend> */}
            <form onSubmit={handleSubmit(this.updateSpace)}>
              <KBFormError error={this.props.error} />
              <div className="kb-form-container" style={{ padding: 0 }}>
                <div className="kb-row">
                  <div className="f-left">
                    <div>最大预定天数</div>
                    <p className="color-nine">最大可以预定的天数，默认值为0</p>
                  </div>
                  <KBASNumberInput
                    field={max_duration}
                    className="f-right"
                    type="number"
                    maxValue="24"
                    counterStyle={{ marginRight: 130 }}
                  />
                </div>
                <div className="kb-row">
                  <div className="f-left">
                    <div>可以提前多久预订</div>
                    <p className="color-nine">可以提前预订的天数，默认值为30</p>
                  </div>
                  <KBASNumberInput
                    field={advance_limitation}
                    className="f-right"
                    type="number"
                    maxValue="24"
                    counterStyle={{ marginRight: 130 }}
                  />
                </div>
                <div className="kb-row">
                  <div className="f-left">
                    <div>可以提前多久取消预订</div>
                    <p className="color-nine">
                      可以提前取消预订的小时数，默认值为0
                    </p>
                  </div>
                  <KBASNumberInput
                    field={cancellation_offset}
                    className="f-right"
                    type="number"
                    maxValue="24"
                    counterStyle={{ marginRight: 130 }}
                  />
                </div>

                <div className="kb-row">
                  <div className="f-left">
                    <label>每周开放时间</label>
                  </div>
                </div>
                <div className="kb-row">
                  <table className="content-table">
                    <thead>
                      <tr>
                        <th>星期</th>
                        <th>是否营业</th>
                        <th>门禁开放时间</th>
                      </tr>
                    </thead>
                    <tbody>
                      {WEEK_ARRAY.map((week, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: 130 }}>{week.title}</td>
                            <td style={{ width: 130 }}>
                              <div
                                className="slide-btn"
                                onClick={() => {
                                  const newWeekArray = [...WEEK_ARRAY]
                                  newWeekArray[index].check = !newWeekArray[
                                    index
                                  ].check
                                  this.setState({ WEEK_ARRAY: newWeekArray })
                                }}
                              >
                                <span
                                  className={`slide-bg ${
                                    week.check ? 'slide-left' : 'slide-right'
                                  }`}
                                >
                                  <span className="slide-move" />
                                </span>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <KBSelect
                                  className="kb-form-group kb-div-c39"
                                  field={{
                                    value: week.startTime,
                                    onChange: date => {
                                      const newWeekArray = [...WEEK_ARRAY]
                                      newWeekArray[index].startTime = date
                                      this.setState({
                                        WEEK_ARRAY: newWeekArray
                                      })
                                    }
                                  }}
                                  selectStyle={{ width: 100 }}
                                  arrayData={CUSTOM_TIME_ARRAY}
                                />
                                <span>-</span>
                                <KBSelect
                                  className="kb-form-group kb-div-c39"
                                  field={{
                                    value: week.endTime,
                                    onChange: date => {
                                      const newWeekArray = [...WEEK_ARRAY]
                                      newWeekArray[index].endTime = date
                                      this.setState({
                                        WEEK_ARRAY: newWeekArray
                                      })
                                    }
                                  }}
                                  selectStyle={{ width: 100 }}
                                  arrayData={CUSTOM_TIME_ARRAY}
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <fieldset className="fieldset">
                  <legend>预定须知</legend>
                  <div>
                    <KBTextarea
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      isRequired={true}
                      field={note}
                    />
                  </div>
                </fieldset>

                <fieldset className="fieldset">
                  <legend>预定成功通知</legend>
                    <div>
                      <KBTextarea
                        className="kb-form-group kb-form-c18 kb-form-alone"
                        isRequired={true}
                        field={create_reservation_note}
                        placeholder='预定成功通知字符长度需小于20'
                        maxLength='20'
                      />
                    </div>
                </fieldset>

                <div className="kb-row m-top">
                  <KBButton
                    className="certain-btn"
                    type="submit"
                    submitting={submitting}
                  >
                    保存
                  </KBButton>
                </div>
              </div>
            </form>
            <GroupSetStationImageForm
              loc_id={loc_id}
              updateLocation={updateLocation}
              group={group}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    params: { id }
  } = props
  const group = selectors.getCurrentGroup(state, id) || {}
  return {
    loc_id: id,
    group
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'GroupSetStation',
  fields: [
    'cancellation_offset',
    'lock_permission_end_at',
    'lock_permission_start_at',
    'max_duration',
    'note',
    'reservation_time_range',
    'advance_limitation',
    'create_reservation_note'
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetStation = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetStation)

export default GroupSetStation
