import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { successState } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBSelect,
  KBInput,
  KBPopover,
  KBUserInput
} from 'components'
import KBEditor from 'components/plugins/KBEditor'
import _ from 'lodash'

var SpaceEmailTempleteForm = React.createClass({
  displayName: 'SpaceEmailTempleteForm',
  getInitialState() {
    return {
      templateKeys: [],
      selectedKey: [],
      keywordsOfKey: [],
      temp_keywords: []
    }
  },

  componentDidMount() {
    const {
      fields: { template_key }
    } = this.props

    api.getTemplateKeys().then(
      json => {
        let templateKeys = json.response.body || []
        let selectedKey = templateKeys.filter(k => {
          return k.key == template_key.value
        })
        selectedKey &&
          selectedKey.length &&
          this.getTemplateKeywordsOfKey(selectedKey[0].id)
        this.setState({ templateKeys, selectedKey })
      },
      errors => {}
    )
  },

  updateTemplate(model) {
    const { successAction, temp, updateTemplates } = this.props

    model.body = this.replaceTemplateKeywords(model.body)

    if (temp && temp.id) {
      return api.updateTemplate(temp.id, model).then(
        json => {
          updateTemplates && updateTemplates()
          KBPopover.close()
        },
        errors => {}
      )
    }

    return api.createTemplate(model).then(
      json => {
        updateTemplates && updateTemplates()
        KBPopover.close()
      },
      errors => {}
    )
  },

  getTemplateKeywordsOfKey(key_id) {
    let params = {
      template_key_id: key_id
    }
    api.getTemplateKeywordsOfKey(params).then(
      json => {
        let keywordsOfKey = json.response.body || []
        this.setState({ keywordsOfKey })
      },
      errors => {}
    )
  },

  replaceTemplateKeywords(templateStr) {
    // const { fields: { template_key } } = this.props
    const { keywordsOfKey } = this.state
    keywordsOfKey &&
      templateStr &&
      keywordsOfKey.map(keyword => {
        let substr = `&lt;&lt;${keyword.description}&gt;&gt;`
        let newSubstr = `%{${keyword.name}}`
        templateStr = templateStr.replace(substr, newSubstr)
      })
    return templateStr || ''
  },

  draftCallBack(html) {
    const {
      fields: { body }
    } = this.props
    body.onChange(html)
  },

  onSelectTempKey(k) {
    const {
      fields: { template_type }
    } = this.props
    const { key, category } = k
    template_type.onChange(category)
    this.getTemplateKeywordsOfKey(key)
  },

  selectKeyword(keyword) {
    let { temp_keywords } = this.state

    let string = keyword.name + 'String'
    let copyContent = this.refs[string]
    copyContent.select()
    document.execCommand('Copy')
    this.setState({
      ['copySuccess' + keyword.id]: true
    })
    setTimeout(() => {
      this.setState({ ['copySuccess' + keyword.id]: false })
    }, 1500)
  },

  convertDataFormat(array) {
    let formatedArray = _.cloneDeep(array).map(a => {
      let id = a.id
      a.id = a.key
      a.key = id
      return a
    })
    return formatedArray
  },

  render() {
    const {
      fields: { name, body, template_type, template_key },
      error,
      submitting,
      handleSubmit
    } = this.props
    const {
      templateKeys,
      selectedKey,
      keywordsOfKey,
      temp_keywords
    } = this.state
    const { temp } = this.props
    let onSelectTemplateKeys = this.convertDataFormat(templateKeys)
    let onSelectedKey = this.convertDataFormat(selectedKey)

    return (
      <form onSubmit={handleSubmit(this.updateTemplate)}>
        <KBFormError err={error} />
        <header className="kb-form-header">
          <h2 className="header-title">{temp ? '修改' : '新建'}模板</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container invoice-certification-form">
          <div className="kb-row">
            <KBInput
              title="模板名称"
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="模板类型"
              isRequired={true}
              placeholder="选择模板类型"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={template_key}
              multi={false}
              users={onSelectTemplateKeys}
              selectedUsers={onSelectedKey}
              callback={this.onSelectTempKey}
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-div-c18 kb-form-alone">
              <label>模板字符</label>
              <div>
                {keywordsOfKey &&
                  keywordsOfKey.map((keyword, index) => {
                    return (
                      <div
                        key={index}
                        onClick={this.selectKeyword.bind(null, keyword)}
                        className={`placeholder-content`}
                        style={{ padding: 5, margin: 5, marginLeft: 0 }}
                      >
                        <span>{keyword.description}</span>
                        <input
                          type="text"
                          ref={`${keyword.name}String`}
                          value={keyword.description}
                          style={{
                            position: 'absolute',
                            zIndex: '-1',
                            opacity: 0
                          }}
                        />
                        {this.state['copySuccess' + keyword.id] ? (
                          <div
                            className="copy-success_popover"
                            style={{ width: 75 }}
                          >
                            复制成功
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )
                  })}
                <p style={{ color: '#c8c8c8', fontSize: 12, float: 'left' }}>
                  模板字符是用于替换模板内容中的部分字段的，点击以复制相应的模板字符，并以“&lt;&lt;模板字符&gt;&gt;”格式粘贴到模板内容相应地方即可
                </p>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>
                模板内容<span className="must-fill">*</span>
              </label>
              <div>
                <KBEditor
                  field={body}
                />
                {body.touched && body.error && (
                  <p className="lr-error">{body.error}</p>
                )}
              </div>
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { temp, recoveredBody } = props

  return {
    initialValues: {
      name: temp && temp.name,
      body: recoveredBody,
      template_key: temp && temp.template_key,
      template_type: temp && temp.template_type
    }
  }
}
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入模板名称'
  }

  if (valid.required(values.template_key)) {
    errors.template_key = '请选择模板类型'
  }

  if (valid.required(values.body)) {
    errors.body = '请输入模板内容'
  }

  return errors
}

const formConfig = {
  form: 'SpaceEmailTempleteForm',
  fields: [
    'location_id',
    'name',
    'body',
    'template_type',
    'template_key',
    'temp_keywords'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceEmailTempleteForm)
