import React from 'react'
import { reduxForm } from 'redux-form'
import { KBFormError, KBButton, KBSelect } from 'components'
import { RENTING_PHASES_RECURRING_DATE_COMPUTE_DAY } from 'app/constants'
import * as selectors from 'app/selectors'
import _ from 'lodash'

var SpaceSettingInvoiceDefaultDate = React.createClass({
  displayName: 'SpaceSettingInvoiceDefaultDate',

  updateSpace(model) {
    const { updateCurrentSpace } = this.props

    return updateCurrentSpace(
      {
        subscription_settings: {
          invoice_due_day: model.default_fixed
        }
      },
      {
        key: 'subscription_settings'
      }
    )
  },

  render() {
    const {
      fields: { default_fixed },
      error,
      submitting,
      handleSubmit
    } = this.props
    let DAYS = []
    const onChange = default_fixed.onChange
    for (let i = 1; i <= 31; i++) {
      DAYS.push(i)
    }

    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError err={error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>账单默认日期</label>
              <div>
                {
                  <select
                    name="dueday"
                    value={default_fixed.value || null}
                    onChange={onChange}
                  >
                    {DAYS.map(day => {
                      return (
                        <option
                          value={day}
                          key={day}
                        >{`${day} ${'号'}`}</option>
                      )
                    })}
                  </select>
                }
              </div>
            </div>
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { setting } = props

  let setting_value = _.get(
    setting,
    'subscription_settings.invoice_due_day',
    15
  )
  return {
    initialValues: { default_fixed: setting_value }
  }
}

const formConfig = {
  form: 'SpaceSettingInvoiceDefaultDate',
  fields: ['default_fixed'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(SpaceSettingInvoiceDefaultDate)
