import React from 'react'
import SpaceSettingInvoiceForm from './setting/SpaceSettingInvoiceForm'
import LocationProviderSetForm from './LocationProviderSetForm'
import LocationSetAutoMail from './LocationSetAutoMail'
import { APPROVE_CONTRACT } from 'app/constants/hint'
import SpaceSettingInvoiceDefaultDate from './SpaceSettingInvoiceDefaultDate'
import SpaceSettingInvoiceRemindDate from './setting/SpaceSettingInvoiceRemindDate'
import GroupSetAccountHuifu from './GroupSetAccountHuifu'
import GroupSetInvoice from './GroupSetInvoice'
import _ from 'lodash'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import {
  groupSettingActions
} from 'app/actions'

var LocationSetInvoice = React.createClass({
  displayName: 'LocationSetInvoice',


  render() {
    const {
      updateLocationSetting,
      setting,
      updateLocation,
      location,
      cities,
      provinces,
      groups_id,
      groupSetting
    } = this.props
    const hint = APPROVE_CONTRACT
    if (!_.get(setting, 'settings')) {
      return null
    }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>财务设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <LocationProviderSetForm
              updateLocation={updateLocation}
              location={location}
              groups_id={groups_id}
              cities={cities}
              provinces={provinces}
            />
          </fieldset>
        </div>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <GroupSetAccountHuifu groups_id={groups_id} />
          </fieldset>
        </div>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <GroupSetInvoice groups_id={groups_id} />
          </fieldset>
        </div>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>收款设置</legend>
            <SpaceSettingInvoiceForm
              setting={groupSetting}
              updateCurrentSpace={updateLocationSetting}
              locationId={groups_id}
            />
          </fieldset>
        </div>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>系统自动催缴邮件</legend>
            <LocationSetAutoMail
              setting={groupSetting}
              updateCurrentSpace={updateLocationSetting}
              locationId={groups_id}
              groups_id={groups_id}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单默认日期设置</legend>
            <SpaceSettingInvoiceDefaultDate
              updateCurrentSpace={updateLocationSetting}
              setting={groupSetting}
              location={location}
            />
          </fieldset>
        </div>
        <div className="kb-form-container" style={{ paddingTop: 0 }}>
          <fieldset className="fieldset">
            <legend>账单生成日期设置</legend>
            <SpaceSettingInvoiceRemindDate
              updateCurrentSpace={updateLocationSetting}
              setting={groupSetting}
              locationId={groups_id}
            />
          </fieldset>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { id } = props.params
  const updateLocation = props.updateLocation
  return {
    groups_id: id,
    updateLocation
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getGroupSettingAction: bindActionCreators(
      groupSettingActions.get,
      dispatch
    ),
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'LocationSetInvoice',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

LocationSetInvoice = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationSetInvoice)

export default LocationSetInvoice
