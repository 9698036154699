import React from 'react'
import { reduxForm } from 'redux-form'
import { KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'

var LocationSettingVisitorForm = React.createClass({
  setLocation(model) {
    const { updateLocation } = this.props
    const { visitor_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'visitor',
          range: 'all',
          user_ids: visitor_ids ? visitor_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { visitor_ids },
      submitting,
      handleSubmit,
      users,
      visitorManager
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <div className="kb-row">
            <KBUserInput
              title="访客管理人员"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={visitor_ids}
              multi={true}
              users={users}
              selectedUsers={visitorManager}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation
  let visitorManager = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'visitor'
  )

  return {
    updateLocation,
    users,
    visitorManager
  }
}

const formConfig = {
  form: 'LocationSettingVisitorForm',
  fields: ['visitor_ids'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps
)(LocationSettingVisitorForm)
