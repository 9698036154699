import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBImageCropperInput, KBButton } from 'components'
import { toImageProxyDirect } from 'utils/kbUtil'
import { notification } from 'antd'

var VisitWelcomeSetImage = React.createClass({
  save(model) {
    const { saveAndUpdate } = this.props
    return saveAndUpdate(model, 'image_url')
  },

  deleteCurrentImg() {
    const {
      fields: { image_url }
    } = this.props
    image_url.onChange('')
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: { image_url },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.save)}>
        <div className="visit-setting-block">
          <div className="title-container">
            <div className="title-and-des">
              <div className="title">欢迎页标志展示</div>
              <div className="des">
                你可以选择一个图形或LOGO来展示平板的欢迎页面上。
              </div>
            </div>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBImageCropperInput
              aspect={false}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={image_url}
              type="locations"
              accept="image/*, .psd,.sketch"
              failureCallBack={this.failureCallBack}
              isPublic
            >
              <div className="upload-portrait">
                {image_url && image_url.value ? (
                  <img
                    src={toImageProxyDirect(image_url.value, 232, 134)}
                    style={{
                      width: '232px',
                      height: '134px',
                      display: 'inline-block',
                      marginLeft: 0,
                      borderRadius: 0
                    }}
                    alt=""
                  />
                ) : (
                  ''
                )}
                <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                  <button
                    className="bordered-btn"
                    style={{ marginLeft: 0 }}
                    type="button"
                  >
                    {image_url.dirty ? '图片已上传' : '上传图片'}
                  </button>
                  {image_url && image_url.value ? (
                    <span
                      className="delete-opt"
                      onClick={e => {
                        this.deleteCurrentImg()
                        e.stopPropagation()
                      }}
                    >
                      <i className="iconfont icon-delete" />
                      删除
                    </span>
                  ) : null}
                  <p
                    className="color-nine"
                    style={{ paddingTop: 10, fontSize: 12 }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    我们建议上传1000px*1000px的正方形图片，如果您想自己设计图片请下载：PSD
                    Sketch
                  </p>
                </div>
              </div>
            </KBImageCropperInput>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { welcome } = props
  let { image_url } = welcome

  return {
    initialValues: {
      image_url: image_url || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitWelcomeSetImageForm',
  fields: ['image_url'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitWelcomeSetImage)
