import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { arrayToObject } from 'utils/kbUtil'
import * as actions from 'app/actions'
import { connect } from 'react-redux'
import _ from 'lodash'

var SpaceTempleteBox = React.createClass({
  displayName: 'SpaceTempleteBox',
  getInitialState() {
    return {}
  },
  componentDidMount() {
    const {
      subscriptionTempletesAction,
      getSubscriptionPlaceholderAction
    } = this.props
    api.getSubScriptionTempletes().then(
      json => {
        subscriptionTempletesAction.success(json)
      },
      e => {
        subscriptionTempletesAction.failure(e)
      }
    )

    api.getValidPlaceholder().then(
      ({ json }) => {
        const { contract, customer, special } = json
        contract.map(holder => {
          holder.type = 'contract'
        })
        customer.map(holder => {
          holder.type = 'customer'
        })
        special.map(holder => {
          holder.type = 'special'
        })
        let placeholders = _.concat(contract, customer, special)
        getSubscriptionPlaceholderAction.success({
          entities: { subscriptionPlaceholder: arrayToObject(placeholders) }
        })
      },
      e => subscriptionTempletesAction.failure(e)
    )
  },
  render() {
    const { updateCurrentSpace, setting, validPlaceholders } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        setting,
        updateCurrentSpace,
        validPlaceholders
      })
    )

    if (!setting.settings) {
      return null
    }
    return (
      <div className="nav-section-content-container" style={{ padding: 0 }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>合同模板设置</span>
          </div>
        </header>
        {childrenWithProps}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let subscriptionPlaceholder = selectors.getSubscriptionPlaceholder(state)
  let placeholderOfcontract =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'contract')
  let placeholderOfcustomer =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'customer')
  let placeholderOfspecial =
    subscriptionPlaceholder &&
    subscriptionPlaceholder.filter(holder => holder.type == 'special')
  let validPlaceholders = {
    contract: placeholderOfcontract,
    customer: placeholderOfcustomer,
    special: placeholderOfspecial
  }
  return {
    validPlaceholders
  }
}

function mapDispatchToProps(dispatch) {
  return {
    subscriptionTempletesAction: bindActionCreators(
      actions.subscriptionTempletesAction.get,
      dispatch
    ),
    getSubscriptionPlaceholderAction: bindActionCreators(
      actions.subscriptionPlaceholder.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceTempleteBox)
