import React, { Component, PropTypes } from 'react'
import { KBExcelUpload } from 'components'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { api, apiUtils } from 'app/services'
import LocationForm from './LocationForm'
var menuTop = [
  { index: 1, name: '部门信息' },
  { index: 2, name: '员工信息' },
  { index: 3, name: '分店设置' }
]
const QINGNIU_STATIC_FILE = 'https://media-ssl.kuban.io/static/'

var GuidePage = React.createClass({
  displayName: 'GuidePage',
  getDefaultProps() {
    return {}
  },
  getInitialState() {
    return {
      //第一页的menuIndex
      menuIndex: 1,
      //location 创建成功状态
      locationSuccess: false
    }
  },

  selectMenu(index) {
    this.setState({
      menuIndex: index
    })
  },

  preStep() {
    var menuIndex = this.state.menuIndex - 1

    if (menuIndex < 1) {
      menuIndex = 1
    }

    this.setState({
      menuIndex
    })
  },

  nextStep() {
    var menuIndex = this.state.menuIndex + 1

    if (menuIndex > 3) {
      menuIndex = 3
    }

    this.setState({
      menuIndex
    })
  },

  departmentJsx() {
    return (
      <div className="excel-upload-box">
        <KBExcelUpload url={api.departmentsImportUrl} />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/添加空间组织架构文档.xlsx`}
            download="添加空间组织架构文档"
          >
            空间组织架构模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  MemberJsx() {
    return (
      <div className="excel-upload-box">
        <KBExcelUpload url={api.membersImportUrl} />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/添加空间员工文档.xlsx`}
            download="添加空间员工文档"
          >
            添加空间员工文档模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  desksJsx() {
    const { location } = this.state
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.areasImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/添加分店工位分区.xlsx`}
            download="添加分店工位分区"
          >
            添加分店工位分区模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  orgJsx() {
    const { location } = this.state
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.orgImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/添加分店公司信息.xlsx`}
            download="添加分店公司信息"
          >
            添加分店公司信息模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  subscriptionsJsx() {
    const { location } = this.state

    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.subscriptionsImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/导入分店合同信息.xlsx`}
            download="导入分店合同信息"
          >
            导入分店合同信息模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  orgMemberJsx() {
    const { location } = this.state
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.orgMembersImportUrl(location.id)}
          params={{ location_id: location.id }}
        />
        <div className="upload-text">
          <span style={{ marginRight: '2px' }}>下载</span>
          <a
            href={`${QINGNIU_STATIC_FILE}/templates/会员到店登记表.xlsx`}
            download="会员到店登记表"
          >
            会员到店登记表模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>
            提示:下载标准文档, 填写完毕后, 点击中间位置进行文档上传,
            点击下一步进行后续步骤
          </span>
        </div>
      </div>
    )
  },

  locationJsx() {
    return (
      <LocationForm successCallBack={this.locationSuccess} hideOther={true} />
    )
  },

  locationSuccess({ json }) {
    const id = json.result
    const name = json.entities.locations[json.result].name

    this.setState({
      location: {
        id,
        name
      },
      page: 2,
      locationSuccess: true
    })
  },

  toCurrentLocationPage() {
    const { location } = this.state

    if (!location.id) {
      return
    }

    this.props.routerActions.replace(
      `/admin/locations/${location.id}/settings/guide`
    )
  },

  continueLocation() {
    this.setState({
      menuIndex: 3,
      locationSuccess: false
    })
  },

  createLocation() {
    ReactDOM.findDOMNode(document.querySelector('#locationFormButton')).click()
  },

  render() {
    const { menuIndex, errorMsg, locationSuccess, location } = this.state

    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content" style={{ width: '960px' }}>
          <div className="kb-content-container clear-fix">
            <header className="nav-header">
              <div className="header-title-box">
                <h2 className="header-title">
                  {menuTop.find(json => json.index == menuIndex).name}
                </h2>
              </div>
            </header>
            {!locationSuccess ? (
              <ul className="kb-form-nav">
                {menuTop.map((json, index) => (
                  <li
                    key={index}
                    className={classNames(
                      menuIndex == json.index ? 'kb-nav-hover' : ''
                    )}
                  >
                    <span onClick={this.selectMenu.bind(null, json.index)}>
                      {json.name}
                    </span>
                    <i className="iconfont icon-navigate_next" />
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
            {!locationSuccess ? (
              <div
                className="space-setting"
                style={{ opacity: 1, width: menuIndex == 3 ? '100%' : '620px' }}
              >
                {menuIndex == 1 ? this.departmentJsx() : ''}
                {menuIndex == 2 ? this.MemberJsx() : ''}
                {menuIndex == 3 ? this.locationJsx() : ''}
                <div
                  className="kb-row m-top-lg clear-fix"
                  style={{ padding: '0 30px 30px 30px' }}
                >
                  <div className="f-left">
                    {menuIndex != 1 ? (
                      <button
                        className="certain-btn"
                        type="button"
                        onClick={this.preStep}
                      >
                        上一步
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="kb-form-fr">
                    {menuIndex == 3 ? (
                      <button
                        className="certain-btn m-none"
                        type="submit"
                        onClick={this.createLocation}
                      >
                        创建分店
                      </button>
                    ) : (
                      <button
                        className="certain-btn m-none"
                        type="button"
                        onClick={this.nextStep}
                      >
                        下一步
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {locationSuccess ? (
              <div
                className="space-setting"
                style={{ opacity: 1, width: '620px' }}
              >
                <div className="excel-upload-box">
                  <div className="rotate-box">
                    <img
                      src="https://media-ssl.kuban.io/static/web/v1/images/logo-b.svg"
                      width="125"
                      height="125"
                    />
                  </div>
                  <div className="m-top">
                    <span style={{ fontSize: '24px', color: '#999999' }}>
                      恭喜您已完成“{location && location.name}” 的设置
                    </span>
                  </div>
                </div>
                <div className="kb-row m-top-lg clear-fix">
                  <div>
                    <button
                      className="bordered-btn f-left"
                      onClick={this.continueLocation}
                    >
                      继续添加分店
                    </button>
                    <button
                      className="certain-btn f-right"
                      type="button"
                      onClick={this.toCurrentLocationPage}
                    >
                      前往该分店
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidePage)
