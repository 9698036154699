import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { meetingsActions, apiCallFailure } from 'app/actions'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBSearchList,
  KBMorePhotoUpload
} from 'components'
import { getCurrentUser } from 'app/selectors'
import { util, getServiceErrorMessage } from 'utils/kbUtil'
import _ from 'lodash'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

var AddVisitBlackForm = React.createClass({
  getInitialState() {
    return {
      placeState: null,
      areaDevices: [],
      orderOption: '',
      floors: [],
      bridge_enabled: false,
      selectOrgFullname: ''
    }
  },

  componentDidMount() {
    const {
      record,
      fields: { name, organization_id, phone_num, voucher, note }
    } = this.props
    if (record) {
      name.onChange(record.name)
      phone_num.onChange(record.phone_num)
      voucher.onChange(record.voucher)
      note.onChange(record.note)
    }
  },

  createBlackList(model) {
    const { loc_id, callBack, apiCallFailureActions } = this.props
    const organization_id = model.organization_id
    const phone_num = model.phone_num
    let isclick = true
    api
      .getUsers({ organization_id, phone_num, location_id: loc_id })
      .then(response => {
        const isEmployees = _.get(response, 'json.result', [])
        if (isEmployees.length > 0) {
          KBPopover.close()
          KBPopover.plugins.confirm(
            '提示',
            `此人为 ${this.state.selectOrgFullname} 员工，添加黑名单后将其从员工中删除，请问继续吗？`,
            {
              confirm: () => {
                if (isclick) {
                  isclick = false
                  model.dismiss_user = true
                  api
                    .postVisitorBlacklists({ location_id: loc_id, ...model })
                    .then(
                      json => {
                        // createMeetingAction.success(json, { loc_id })
                        callBack && callBack()
                        KBPopover.close()
                        return true
                      },
                      errors => {
                        apiCallFailureActions({
                          status: 'error',
                          message: getServiceErrorMessage(errors)
                        })
                        // KBPopover.close()
                      }
                    )
                } else {
                  apiCallFailureActions({
                    status: 'error',
                    message: '请勿重复点击，请耐心等待'
                  })
                }
              }
            }
          )
        } else {
          return api
            .postVisitorBlacklists({ location_id: loc_id, ...model })
            .then(
              json => {
                // createMeetingAction.success(json, { loc_id })
                callBack && callBack()
                KBPopover.close()
                return true
              },
              errors => {
                apiCallFailureActions({
                  status: 'error',
                  message: getServiceErrorMessage(errors)
                })
                // KBPopover.close()
              }
            )
        }
      })
  },

  changeInvoiceContent(id) {
    const {
      fields: { orientation }
    } = this.props
    orientation.onChange(id)
  },
  _changeAreaType(event) {
    this.setState({ orderOption: event.target.value })
    const {
      fields: { order_option }
    } = this.props
    order_option.onChange(event)
  },

  getOrgs(str, callback) {
    const { loc_id } = this.props
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api
          .getSearchAllType({
            location_id: loc_id,
            query: str,
            type: 'org',
            org_status: 'entered'
          })
          .then(({ json }) => {
            callback && callback(json)
          })
      },
      0
    )
  },

  _selectCallBack(data) {
    const {
      fields: { organization_id }
    } = this.props
    this.setState(
      {
        selectOrgFullname: _.get(data, 'full_name', '')
      },
      () => {
        organization_id.onChange(data && data.id)
      }
    )
  },

  render() {
    const {
      fields: { name, phone_num, voucher, organization_id, note },
      record,
      submitting,
      handleSubmit,
      state
    } = this.props
    const { defaultImages } = this.props
    const organization_name = _.get(record, 'organization.full_name', '')
    return (
      <form onSubmit={handleSubmit(this.createBlackList)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '查看黑名单' : '添加黑名单'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              disabled={state == 'update'}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="姓名"
              isRequired={true}
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              disabled={state == 'update'}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={phone_num}
              title="手机号"
              isRequired={true}
            />
          </div>
          {state == 'update' ? (
            <div className="kb-row">
              <KBInput
                disabled={state == 'update'}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={organization_id}
                value={organization_name}
                title="所属公司"
                isRequired={true}
              />
            </div>
          ) : (
            <div style={{ margin: '15px 0 15px' }}>
              <div className="kb-form-group kb-form-c18 kb-form-alone">
                <label>
                  所属公司<span className="must-fill">*</span>
                </label>
                <KBSearchList
                  ref="dropDownOrg"
                  field={organization_id}
                  getUsers={this.getOrgs}
                  custom="full_name"
                  selectCallBack={this._selectCallBack}
                  placeholder="公司搜索"
                  style={{ display: 'inline-block', width: '100%' }}
                  itemWidth="full"
                />
              </div>
            </div>
          )}
          <div className="kb-row">
            <KBInput
              disabled={state == 'update'}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={note}
              title="添加原因"
              isRequired={true}
            />
          </div>
          {state == 'update' ? (
            defaultImages &&
            defaultImages.map(file => {
              return (
                <KBDownloadPrivacy url={file}>
                  <a>
                    <img
                      src={file}
                      alt=""
                      style={{ width: '100px', height: '100px' }}
                    />
                  </a>
                </KBDownloadPrivacy>
              )
            })
          ) : (
            <div className="kb-row">
              <KBMorePhotoUpload
                isRequired={true}
                multiple={true}
                defaultImages={defaultImages}
                title="上传图片"
                hint="请上传租户申请添加黑名单的沟通记录截图,允许多张上传,格式为png或jpg,大小不大于1M"
                photoShow={true}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={voucher}
                text="上传凭证图片"
                prefix="images/meeting/"
                accept="image/*"
              />
            </div>
          )}
          {state == 'update' ? (
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                确定
              </span>
            </div>
          ) : (
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          )}
        </div>
      </form>
    )
  }
})

//

function mapStateToProps(state, props) {
  const user = getCurrentUser(state)
  const record = props.record
  var params = {
    user
  }
  if (record && record.voucher) {
    params.defaultImages = []
    let records = record.voucher.split(',')
    records.map(value => {
      params.defaultImages.push(value)
    })
  }
  return params
}

function mapDispatchToProps(dispatch) {
  return {
    createMeetingAction: bindActionCreators(meetingsActions.create, dispatch),
    updateMeetingAction: bindActionCreators(meetingsActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入姓名'
  } else if (values.name.length > 20) {
    errors.name = '姓名不能大于20个字'
  }

  if (valid.required(values.organization_id)) {
    errors.organization_id = '请选择公司'
  }

  if (valid.required(values.phone_num) || valid.isMobile(values.phone_num)) {
    errors.phone_num = '请输入正确的手机号'
  }

  if (valid.required(values.voucher)) {
    errors.voucher = '请上传租户申请添加黑名单的沟通记录截图'
  }

  if (valid.required(values.note)) {
    errors.note = '请输入添加原因'
  }

  return errors
}

const formConfig = {
  form: 'AddVisitBlackForm',
  fields: ['name', 'organization_id', 'phone_num', 'voucher', 'note'],
  validate: validate,
  touchOnBlur: false
}

AddVisitBlackForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AddVisitBlackForm)

export default AddVisitBlackForm
