import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBInput, KBASNumberInput } from 'components'
import * as selectors from 'app/selectors'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import _ from 'lodash'

var SpaceSettingInvoiceRemindDate = React.createClass({
  displayName: 'SpaceSettingInvoiceRemindDate',
  updateSpace(model) {
    const { updateCurrentSpace } = this.props
    updateCurrentSpace(
      JSON.stringify({
        subscription_settings: model
      }),
      {
        key: 'subscription_settings'
      }
    )
  },
  render() {
    const {
      fields: { generate_invoice_offset_day, },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.updateSpace)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container" style={{ padding: 0 }}>
          <div className="kb-row">
            <div className="f-left">
              <div>
                缴租日前&nbsp;&nbsp;(&nbsp;
                <span className="color-link">{generate_invoice_offset_day.value}</span>
                &nbsp;)&nbsp;&nbsp;天生成账单
              </div>
              <p className="color-nine d-iblock">
                示例：收款日是31日，设置值为15，则会在16日生成账单
              </p>
            </div>
            <KBASNumberInput
              field={generate_invoice_offset_day}
              className="f-right"
              type="number"
              maxValue="31"
              onChange={(e)=>{
                let value = Number(e.target.value)
                if(value>31) value=31
                generate_invoice_offset_day.onChange(value)}}
            />
          </div>
          <div className="kb-row m-top">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { setting:{subscription_settings={}} ={} } = props
  return {
    initialValues: {generate_invoice_offset_day:subscription_settings.generate_invoice_offset_day}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'SpaceSettingInvoiceRemindDate',
  fields: ['generate_invoice_offset_day'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingInvoiceRemindDate)
