import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { customAction, apiCallFailure } from 'app/actions'
import * as selectors from 'app/selectors'
import SpaceSettingUpdateBillTypeForm from './SpaceSettingUpdateBillTypeForm'
import { KBPopover } from 'components'
import { connect } from 'react-redux'

var SpaceSettingInvoiceType = React.createClass({
  displayName: 'SpaceSettingInvoiceType',
  getInitialState() {
    return {
      invoiceTypeList: []
    }
  },
  componentDidMount() {
    this.getSelfDefinedLineItemTypes()
  },

  getSelfDefinedLineItemTypes() {
    const { getTaxesAction } = this.props
    api.getSelfDefinedLineItemTypes().then(
      ({ json }) => {
        this.setState({
          invoiceTypeList: json
        })
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  updateTax(tax) {
    KBPopover.show(
      <SpaceSettingUpdateBillTypeForm
        taxId={tax.id}
        initialValues={{
          name: tax.name,
          charge_code: tax.charge_code
        }}
        getSelfDefinedLineItemTypes={this.getSelfDefinedLineItemTypes}
        state="true"
      />
    )
  },
  createTax() {
    KBPopover.show(
      <SpaceSettingUpdateBillTypeForm
        getSelfDefinedLineItemTypes={this.getSelfDefinedLineItemTypes}
      />
    )
  },

  render() {
    const { invoiceTypeList } = this.state

    return (
      <div>
        <table className="content-table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>账单名称</th>
              <th>财务编码</th>
              {/* <th className="t-right" >操作</th> */}
            </tr>
          </thead>
          <tbody>
            {invoiceTypeList.map(tax => {
              return (
                <tr>
                  <td>{tax.name}</td>
                  <td>{tax.charge_code}</td>
                  {/* <td className="t-right">
                    <i className="iconfont icon-edit" onClick={this.updateTax.bind(null, tax)} />
                  </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
        <p style={{ fontSize: 11, color: '#7F8FA4', margin: '9px 0 3px' }}>
          定义后不能删除,如果删除需要找供应商
        </p>
        <div className="m-top-sm">
          <button
            className="certain-btn"
            onClick={() => {
              this.createTax()
            }}
          >
            添加账单类型
          </button>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let self_defined_line_item_types = selectors.getBillType(state)
  return {
    self_defined_line_item_types
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTaxesAction: bindActionCreators(customAction.get, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingInvoiceType)
