import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import {
  usersActions,
  apiCallFailure,
  orgUserActions,
  successState
} from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBForm,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBTextarea,
  KBImageCropperInput,
  KBAvatar,
  KBMoreImageCropperInput
} from 'components'
import { notification, Icon, Select } from 'antd'

const { Option } = Select
const priceType = [
  {
    id: '',
    name: '请选择计价单位'
  },
  {
    id: 'hour',
    name: '小时'
  },
  {
    id: 'day',
    name: '天'
  },
  {
    id: 'month',
    name: '月'
  }
]



var GroupSetBridgePlusAddProductForm = React.createClass({
  getInitialState() {
    return {
      loading: false,
      fileList: [],
      DEVICES: [],
      SERVICES: [],
      PRODUCT_TAG: [],
      SPACE_PRODUCT: []
    }
  },

  componentDidMount() {
    const {
      fields: {}
    } = this.props
    api.getCtags().then(res => {
      const { json = [] } = res
      const DEVICES = json.filter(item => item.tag_type === 'device')
      const SERVICES = json.filter(item => item.tag_type === 'service')
      const PRODUCT_TAG = json.filter(item => item.tag_type === 'product_tag')
      const SPACE_PRODUCT = json.filter(
        item => item.tag_type === 'space_product'
      )
      SPACE_PRODUCT.unshift({ id: '', name: '请选择类别' })
      PRODUCT_TAG.unshift({ id: '', name: '请选择类别' })
      this.setState({ DEVICES, SERVICES, PRODUCT_TAG, SPACE_PRODUCT })
    })
  },

  addProduct(model) {
    const { groupId, callBack } = this.props
    api.setBridgePlusProduct(groupId, model).then(res => {
      const { json = {} } = res
      const value = json.product_tags || []
      callBack && callBack(value)
    })
  },

  loadingCallBack(loading, type, index) {
    if (type === 'space') {
      this.setState({ loading })
    } else {
      this.setState({ [`${type}_loading${index}`]: loading })
    }
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({ message: '上传失败', description: msg, top: 100 })
    }
  },

  removeFile(index) {
    const {
      fields: { images }
    } = this.props
    let newFileList = [...images.value]
    newFileList.splice(index, 1)
    images.onChange(newFileList)
  },

  render() {
    const {
      fields: {
        ctag_id,
        description,
        address,
        open_date,
        size,
        devices,
        services,
        images,
        space_products
      },
      handleSubmit,
      modalType,
      submitting,
      groupId
    } = this.props
    const {
      fileList,
      DEVICES,
      SERVICES,
      PRODUCT_TAG,
      SPACE_PRODUCT
    } = this.state
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.addProduct)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {modalType === 'create' ? '新增' : '编辑'}产品
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <legend>基本信息</legend>
            <div className="kb-row">
              <KBSelect
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="产品类型"
                field={ctag_id}
                isRequiredR={true}
                arrayData={PRODUCT_TAG}
              />
            </div>
            <div className="kb-row">
              <div className="kb-row">
                <KBTextarea
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={description}
                  title="产品描述"
                />
              </div>
            </div>
            <div className="kb-row">
              <KBInput
                title="位置名称"
                field={address}
                className="kb-form-group kb-div-48 kb-form-alone"
                isRequiredR={true}
              />
            </div>
            <div className="kb-row">
              <KBDateInput
                field={open_date}
                title="开业时间"
                isRequiredR={true}
                className="kb-form-c18 kb-form-group kb-form-alone"
              />
            </div>
            <div className="kb-row">
              <KBInput
                title="面积"
                inputClass="input-area"
                field={size}
                className="kb-form-group kb-div-48 kb-form-alone"
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label
                style={{
                  fontSize: 11,
                  margin: '9px 0 3px',
                  color: '#7F8FA4',
                  whiteSpace: 'nowrap',
                  width: '100% !important'
                }}
              >
                <div>配套设施</div>
              </label>
              <div>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  value={devices.value}
                  // placeholder=""
                  onChange={value => {
                    devices.onChange(value)
                  }}
                >
                  {DEVICES.map((item, index) => (
                    <Option key={item.name}>{item.name}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: 15 }}>
              <label
                style={{
                  fontSize: 11,
                  margin: '9px 0 3px',
                  color: '#7F8FA4',
                  whiteSpace: 'nowrap',
                  width: '100% !important'
                }}
              >
                <div>配套服务</div>
              </label>
              <div>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  value={services.value}
                  // placeholder=""
                  onChange={value => {
                    services.onChange(value)
                  }}
                >
                  {SERVICES.map((item, index) => (
                    <Option key={item.name}>{item.name}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="kb-row kb-form-group">
              <label>
                <span className="must-fillR">*</span>
                产品图片
              </label>
              <p style={{ fontSize: 12 }}>
                图片格式为png, jpeg或jpg, 文件大小不大于2M
              </p>
              <div style={{ marginTop: 10 }}>
                <KBMoreImageCropperInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={images}
                  style={{ display: 'inline-block' }}
                  type={`groups/${groupId}`}
                  prefix={`groups/${groupId}/`}
                  imageSize={1024*2}
                  failureCallBack={this.UploadFailureCallBack}
                  width={340}
                  height={170}
                  aspect={340 / 170}
                  previewBorderRadius={0}
                  callback={value => images.onChange(value)}
                  accept=".png,.jpg,.jpeg"
                  isPublic
                >
                  <div className="upload-portrait">
                    <div
                      className="d-iblock"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button className="bordered-btn m-none" type="button">
                        {images.value && images.value.length
                          ? '继续上传图片'
                          : '上传图片'}
                      </button>
                      {/* <p
                        className="color-nine"
                        style={{ paddingTop: 20, fontSize: 12 }}
                      >
                        图片格式为png, jpeg或jpg,文件大小不大于1M
                      </p> */}
                    </div>
                  </div>
                </KBMoreImageCropperInput>
                <div>
                  {images.value ? (
                    <ul className="kb-more-file" style={{ margin: 0 }}>
                      {(images.value || []).map((file, index) => {
                        return (
                          <li key={index} style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            marginBottom: '10px'
                          }}>
                            <img
                              src={file}
                              alt="loading"
                              style={{
                                width: '270px',
                                height: '135px',
                              }}
                            />

                            <div className="delete">
                              <i
                                className="iconfont icon-close"
                                onClick={this.removeFile.bind(null, index)}
                              />
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {images.error && <p className="lr-error">{images.error}</p>}
            </div>
          </fieldset>

          <fieldset className="fieldset">
            <legend>场地信息</legend>
            <div>
              {space_products &&
                space_products.map((item, index) => {
                  const {
                    ctag_id: product_type,
                    image: product_img,
                    price,
                    unit,
                    description: product_description
                  } = item
                  return (
                    <div key={index} style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          zIndex: 10
                        }}
                      >
                        <Icon
                          type="delete"
                          onClick={() => {
                            space_products.removeField(index)
                          }}
                        />
                      </div>
                      <div className="kb-row">
                        <KBSelect
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="场地类别"
                          field={product_type}
                          isRequiredR={true}
                          arrayData={SPACE_PRODUCT}
                        />
                      </div>
                      <div className="kb-row kb-form-group">
                        <label>
                          <span className="must-fillR">*</span>产品图片
                        </label>
                        <p style={{ fontSize: 12 }}>
                          图片格式为png, jpeg或jpg, 文件大小不大于1M
                        </p>
                        <div style={{ marginTop: 10 }}>
                          <KBImageCropperInput
                            field={product_img}
                            style={{ display: 'inline-block' }}
                            type={`groups/${groupId}`}
                            loadingCallBack={value =>
                              this.loadingCallBack(value, 'product', index)
                            }
                            imageSize={1024 * 1}
                            failureCallBack={this.UploadFailureCallBack}
                            width={150}
                            height={150}
                            aspect={1}
                            previewBorderRadius={0}
                            accept=".png,.jpg,.jpeg"
                            isPublic
                          >
                            <div className="upload-portrait">
                              <button
                                className="bordered-btn"
                                type="button"
                                style={{
                                  marginLeft: 0,
                                  marginBottom: 20,
                                  width: 100
                                }}
                              >
                                {this.state[`product_loading${index}`]
                                  ? '上传中...'
                                  : product_img.value
                                  ? '上传新图片'
                                  : '上传图片'}
                              </button>
                              <br />
                              {product_img.value && (
                                <KBAvatar
                                  imgSize={{
                                    width: 150,
                                    height: 150
                                  }}
                                  user={{ avatar: product_img.value }}
                                  imgStyle={{
                                    marginLeft: 0,
                                    verticalAlign: 'middle',
                                    borderRadius: 0
                                  }}
                                  style={{
                                    display: 'inline-block',
                                    marginLeft: 0
                                  }}
                                />
                              )}
                            </div>
                          </KBImageCropperInput>
                        </div>
                        {product_img.error && (
                          <p className="lr-error">{product_img.error}</p>
                        )}
                      </div>
                      <div className="kb-row kb-form-66">
                        <KBInput
                          className="kb-form-group kb-div-48"
                          isRequiredR={true}
                          field={price}
                          title="价格"
                        />
                        <KBSelect
                          className="kb-form-group kb-div-48"
                          title="计价单位"
                          field={unit}
                          isRequiredR={true}
                          arrayData={priceType}
                        />
                      </div>

                      <div className="kb-row">
                        <KBTextarea
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          field={product_description}
                          title="描述"
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="kb-row" style={{ marginTop: 10 }}>
              <KBButton
                type="button"
                className="certain-btn"
                onClick={() => {
                  space_products.addField({
                    ctag_id: '',
                    image: '',
                    unit: '',
                    price: '',
                    description: ''
                  })
                }}
              >
                添加场地类型
              </KBButton>
            </div>
          </fieldset>
        </div>
        <div className="kb-row fixed-form-btn">
          <span className="cancel-btn" onClick={KBPopover.close}>
            取消
          </span>
          <KBButton
            className="certain-btn"
            type="submit"
            style={{ marginLeft: 20 }}
            submitting={submitting}
          >
            {modalType === 'create' ? '添加' : '保存'}
          </KBButton>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { product, modalType } = props
  console.log(product,'product');
  let initialValues = {
    devices: [],
    services: []
  }
  if (modalType === 'edit') {
    initialValues = product
  }
  return {
    initialValues
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const placeValidate = values => {
  const errors = {}
  if (!values.ctag_id) {
    errors.ctag_id = '请选择场地类别'
  }
  if (!values.image) {
    errors.image = '请上传产品图片'
  }
  if (!values.price) {
    errors.price = '请输入价格'
  }
  if (!values.unit) {
    errors.unit = '请选择计价单位'
  }
  return errors
}

const validate = values => {
  const errors = {}
  if (!values.ctag_id) {
    errors.ctag_id = '请选择产品类型'
  }
  if (!values.address) {
    errors.address = '请输入位置'
  }
  if (!values.open_date) {
    errors.open_date = '请选择开业时间'
  }
  if (!values.images) {
    errors.images = '请上传产品图片'
  }
  errors.space_products = values.space_products.map(placeValidate)

  return errors
}

const formConfig = {
  form: 'GroupSetBridgePlusAddProductForm',
  fields: [
    'ctag_id',
    'description',
    'address',
    'open_date',
    'size',
    'devices',
    'services',
    'images',
    'space_products[].ctag_id',
    'space_products[].image',
    'space_products[].price',
    'space_products[].unit',
    'space_products[].description'
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetBridgePlusAddProductForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetBridgePlusAddProductForm)

export default GroupSetBridgePlusAddProductForm
