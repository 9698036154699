import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBInput, KBButton, KBPopoverTop } from 'components'
import { api } from 'app/services'

var GroupSetAccountHuifuForm = React.createClass({
  getInitialState() {
    return {}
  },

  createLocationProvider(model) {
    const { callBack, index,type, group } = this.props
    const data = {...model}

    if(group && group.id) {
      data.id = group.id
    }

    callBack && callBack(data, type, index)

    // api.createMerchants(params ).then(res=>{
    //   console.log(res);
    // })
    KBPopoverTop.close()
  },

  render() {
    const {
      fields: {
        name,
        hui_mer_cus_id,
        hui_mer_acct_id,
        account_name,
        account_no,
        bank_name,
        bank_no,
      },
      error,
      submitting,
      handleSubmit,
      type
    } = this.props


    return (
      <form onSubmit={handleSubmit(this.createLocationProvider)}>
        <header className="kb-form-header">
          <h2 className="header-title">{type==='add'?'添加':'编辑'}收款账户信息(汇付)</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={name}
                title="账户名称"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={hui_mer_cus_id}
                title="商户客户号"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={hui_mer_acct_id}
                title="商户账户号"
                isRequired={true}
              />
            </div>


            {/* ★凯星卡退款需要数据 */}
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={account_name}
                title="银行开户名"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={account_no}
                title="公司银行账号"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={bank_name}
                title="开户行支行"
                isRequired={true}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={bank_no}
                title="支行银联号"
              />
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { group } = props

  return {
    initialValues: {
      name: group && group.name,
      hui_mer_cus_id: group && group.hui_mer_cus_id,
      hui_mer_acct_id: group && group.hui_mer_acct_id,
      modified:true,
      account_name: group && group.account_name,
      account_no: group && group.account_no,
      bank_name: group && group.bank_name,
      bank_no: group && group.bank_no,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请填账户名称'
  }
  if (valid.required(values.hui_mer_cus_id)) {
    errors.hui_mer_cus_id = '请填写商户客户号'
  }
  if (valid.required(values.hui_mer_acct_id)) {
    errors.hui_mer_acct_id = '请填写商户账户号'
  }
  if (valid.required(values.account_name)) {
    errors.account_name = '请填写银行开户名'
  }
  if (valid.required(values.account_no)) {
    errors.account_no = '请填写公司银行账号'
  }
  if (valid.required(values.bank_name)) {
    errors.bank_name = '请填写开户行支行'
  }
  return errors
}

const formConfig = {
  form: 'GroupSetAccountHuifuForm',
  fields: [
    'name',
    'hui_mer_cus_id',
    'hui_mer_acct_id',
    'modified',
    'account_name',
    'account_no',
    'bank_name',
    'bank_no'
  ],
  validate: validate,
  touchOnBlur: false
}

GroupSetAccountHuifuForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(GroupSetAccountHuifuForm)

export default GroupSetAccountHuifuForm
