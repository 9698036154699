import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import * as actions from 'app/actions'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBPopover, KBTableWithoutData } from 'components'
import BuildingForm from './floor/BuildingForm'
import FloorInfoFrom from './floor/FloorInfoFrom'
import { listIsNullCallBack } from 'utils/kbUtil'

var FloorManage = React.createClass({
  displayName: 'FloorManage',
  componentDidMount() {
    this.loadData()
  },
  loadData() {
    const {
      loc_id,
      getBuildingInfoActions,
      getFloorInfosAction,
      floorInfos
    } = this.props
    api.getBuildingInfos(loc_id).then(
      json => {
        getBuildingInfoActions.success(json)
      },
      error => {
        getBuildingInfoActions.failure(error)
      }
    )

    listIsNullCallBack(floorInfos, () => {
      api.getFloorInfos(loc_id, { per_page: 1000 }).then(
        json => {
          getFloorInfosAction.success(json, {
            entityName: 'floorInfos'
          })
        },
        errors => {
          getFloorInfosAction.failure(errors)
        }
      )
    })
  },
  addBuilding() {
    const { loc_id } = this.props
    KBPopover.show(<BuildingForm loc_id={loc_id} />)
  },
  updateBuilding(building) {
    const { loc_id } = this.props
    KBPopover.show(
      <BuildingForm
        initialValues={{
          ...building
        }}
        loc_id={loc_id}
        state="update"
        building={building}
      />
    )
  },
  deleteBuilding(building) {
    const { id } = building
    const { loc_id, deleteBuildingAction } = this.props
    KBPopover.plugins.confirm('删除大楼', '确定要删除该大楼?', {
      confirm: () => {
        api.deleteBuildingInfos(id, loc_id).then(
          json => {
            deleteBuildingAction.success(json, { id, key: 'buildingInfo' })
            KBPopover.close()
          },
          errors => {
            deleteBuildingAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },
  addFloorInfo(building_info_id) {
    const { loc_id } = this.props
    KBPopover.show(
      <FloorInfoFrom loc_id={loc_id} building_info_id={building_info_id} />
    )
  },
  updateFloorInfo(floorInfo, building_info_id) {
    const { loc_id } = this.props
    KBPopover.show(
      <FloorInfoFrom
        loc_id={loc_id}
        initialValues={{
          ...floorInfo
        }}
        building_info_id={building_info_id}
        state="update"
        floorInfo={floorInfo}
      />
    )
  },
  deleteFloorInfo(floorInfo) {
    const { id } = floorInfo
    const { loc_id, deleteFloorInfoAction } = this.props
    KBPopover.plugins.confirm('删除楼层', '确定要删除该楼层?', {
      confirm: () => {
        api.deleteFloorInfos(id, loc_id).then(
          json => {
            deleteFloorInfoAction.success(json, { id, key: 'floorInfos' })
            KBPopover.close()
          },
          errors => {
            deleteFloorInfoAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },
  render() {
    const { loc_id, buildingInfo, getFloorInfos } = this.props
    return (
      <div>
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>楼层管理</span>
          </div>
          <button className="bordered-btn f-right" onClick={this.addBuilding}>
            添加大楼
          </button>
        </header>
        <div className="nav-section-content-container">
          <table style={{ width: '100%' }}>
            <KBTableWithoutData
              hasData={buildingInfo.length > 0}
              tableHeadNum="1"
              tipMessage="暂无楼层信息"
            >
              {buildingInfo &&
                buildingInfo.map((building, index) => {
                  const { id } = building
                  const building_info_id = id
                  return (
                    <div className="m-top" key={index}>
                      <div
                        className="clear-fix border-bottom"
                        style={{ paddingBottom: 10 }}
                      >
                        <span
                          className="f-left"
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: '#666'
                          }}
                        >
                          {building.name}
                        </span>
                        <span
                          className="bordered-btn-small f-right"
                          style={{ fontSize: 12 }}
                          onClick={this.deleteBuilding.bind(null, building)}
                        >
                          删除大楼
                        </span>
                        <span
                          className="bordered-btn-small f-right"
                          style={{ fontSize: 12 }}
                          onClick={this.updateBuilding.bind(null, building)}
                        >
                          修改大楼
                        </span>
                        <span
                          className="bordered-btn-small f-right"
                          style={{ fontSize: 12 }}
                          onClick={this.addFloorInfo.bind(null, id)}
                        >
                          添加楼层
                        </span>
                      </div>
                      <table
                        className="content-table"
                        style={{ tableLayout: 'fixed' }}
                      >
                        <thead>
                          <tr>
                            <th>大楼信息</th>
                            <th>第几层</th>
                            <th>备注</th>
                            <th
                              className="t-right"
                              style={{ paddingRight: 36 }}
                            >
                              操作
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getFloorInfos(building_info_id).map(
                            (json, child_index) => {
                              const {
                                id,
                                layer_num,
                                layer_description,
                                notes
                              } = json
                              return (
                                <tr key={child_index}>
                                  <td>{layer_description}</td>
                                  <td>{layer_num}</td>
                                  <td>{notes}</td>
                                  <td className="t-right">
                                    <i
                                      className="iconfont icon-edit m-right-sm"
                                      onClick={this.updateFloorInfo.bind(
                                        null,
                                        json,
                                        building_info_id
                                      )}
                                    />
                                    <i
                                      className="iconfont icon-delete"
                                      onClick={this.deleteFloorInfo.bind(
                                        null,
                                        json
                                      )}
                                    />
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  )
                })}
            </KBTableWithoutData>
          </table>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let buildingInfo = selectors.getBuildingInfo(state, loc_id)
  let floorInfos = selectors.getFloorInfos(state, loc_id)
  let getFloorInfos = build_id => {
    return selectors.getFloorInfosOfLocIdAndBuildId(state, loc_id, build_id)
  }

  return {
    loc_id,
    floorInfos,
    getFloorInfos,
    buildingInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteBuildingAction: bindActionCreators(
      actions.buildingInfoAction.delete,
      dispatch
    ),
    deleteFloorInfoAction: bindActionCreators(
      actions.floorInfosAction.delete,
      dispatch
    ),
    getFloorInfosAction: bindActionCreators(
      actions.floorInfosAction.replace,
      dispatch
    ),
    getBuildingInfoActions: bindActionCreators(
      actions.buildingInfoAction.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorManage)
