import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBFormError, KBButton, KBUserInput } from 'components'
import * as selectors from 'app/selectors'
import { FIX_LOCATION } from 'app/constants/hint'

var LocationSettingManageForm = React.createClass({
  setLocation(model) {
    const { updateLocation } = this.props
    const { manager_id, operator_ids } = model
    return updateLocation({
      roles: [
        {
          role: 'location_manager',
          range: 'all',
          user_ids: [manager_id]
        },
        {
          role: 'location_operator',
          range: 'all',
          user_ids: operator_ids ? operator_ids.split(',') : []
        }
      ]
    })
  },
  render() {
    const {
      fields: { manager_id, operator_ids },
      error,
      submitting,
      handleSubmit,
      users,
      manager,
      operators
    } = this.props
    const hint = FIX_LOCATION
    return (
      <form onSubmit={handleSubmit(this.setLocation)}>
        <div>
          <p style={{ color: '#c8c8c8', fontSize: 12, marginBottom: 20 }}>
            {hint.manage_team}
          </p>
          <div className="kb-row">
            <KBUserInput
              title="负责人"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={manager_id}
              multi={false}
              users={users}
              selectedUsers={manager}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="单楼栋运营"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.staff}
              field={operator_ids}
              multi={true}
              users={users}
              selectedUsers={operators}
            />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  const operators = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'location_operator'
  )
  const manager = selectors.getTeamRolesOfLocation(
    state,
    loc_id,
    'location_manager'
  )
  const users = selectors.getSpaceUsers(state)
  const updateLocation = props.updateLocation

  return {
    operators,
    updateLocation,
    manager,
    users
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.operator_ids)) {
    errors.operator_ids = '请选择至少一位运营成员'
  }

  return errors
}

const formConfig = {
  form: 'LocationSettingManageForm',
  fields: ['manager_id', 'operator_ids'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(LocationSettingManageForm)
