import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import { TAXES_FORM } from 'app/constants/hint'
import { taxesAction, successState } from 'app/actions'

var SpaceSettingUpdateTaxesForm = React.createClass({
  componentDidMount() {
    const {
      fields: { rate }
    } = this.props

    if (rate) {
      rate.onChange(Math.floor(rate.value * 1000000) / 10000)
    }
  },

  createTax(model) {
    const {
      createTaxesAction,
      updateTaxesAction,
      state,
      successAction,
      taxId
    } = this.props
    model.rate = Math.floor(model.rate * 10000) / 1000000

    if (state) {
      return api.updateSpaceTaxes(taxId, model).then(json => {
        updateTaxesAction.success(json)
        successAction({ message: '修改税率成功!' })
        KBPopover.close()
        return true
      })
    }

    return api.postSpaceTaxes(model).then(json => {
      createTaxesAction.success(json)
      successAction({ message: '创建税率成功!' })
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { name, rate },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { state } = this.props

    return (
      <form onSubmit={handleSubmit(this.createTax)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改税率' : '创建税率'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass="input-percent"
              field={rate}
              title="税率"
              hint={TAXES_FORM.rate}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createTaxesAction: bindActionCreators(taxesAction.create, dispatch),
    updateTaxesAction: bindActionCreators(taxesAction.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入名称'
  }

  if (valid.required(values.rate)) {
    errors.rate = '请输入税率'
  }

  return errors
}

const formConfig = {
  form: 'SpaceSettingUpdateTaxesForm',
  fields: ['name', 'rate'],
  validate: validate,
  touchOnBlur: false
}

SpaceSettingUpdateTaxesForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceSettingUpdateTaxesForm)

export default SpaceSettingUpdateTaxesForm
