import React from 'react'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { locationActions, apiCallFailure } from 'app/actions'
import { KBButton, KBPopover } from 'components'
import _ from 'lodash';

/**
 * 修改分店 基本设置
 */
var LocationPublicForm = React.createClass({
  displayName: 'LocationBaseForm',

  getInitialState() {
    return {
      submitting: false
    }
  },

  changeSlide(publicState) {
    const { updateLocationAction, loc_id } = this.props

    return KBPopover.plugins.confirm(
      `启用小程序用户自助认证`,
      `小程序用户可以通过扫描分店二维码自助认证为员工，认证成功后系统将分配相应的门禁权限
      如果关闭，此分店禁用自助认证功能`,
      {
        confirm: () => {
          api.updateLocation(loc_id, { auto_auth: !publicState }).then(
            json => {
              KBPopover.close()
              updateLocationAction.success(json)
            },
            errors => {
              updateLocationAction.failure(errors)
              KBPopover.close()
            }
          )
        }
      }
    )
  },

  downloadQrcode() {
    this.setState({
      submitting: true
    })
    const location_groups_id = _.get(this.props.location, 'location_groups[0].id', '')
    api.getCommonQrCode({
      path: '/pages/subPages/autoAuth/index',
      scene: location_groups_id,
      qrcode_type: 'auto_auth'
    }).then(res => {
      window.open(res.json.url)
    })
    .catch(error => {
      this.props.apiCallFailureActions(error)
    }).finally(() => {
      this.setState({
        submitting: false
      })
    })
  },

  render() {
    const { auto_auth } = this.props
    const { submitting } = this.state

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
          <div
            className="slide-btn"
            onClick={this.changeSlide.bind(null, auto_auth)}
          >
            <span
              className={`slide-bg ${
                auto_auth ? 'slide-left' : 'slide-right'
              }`}
            >
              <span className="slide-move" />
            </span>
          </div>
          <span style={{ marginLeft: 15 }}>
            小程序用户可以通过扫描分店二维码自助认证为员工，认证成功后系统将分配相应的门禁权限
            <br />
            如果关闭，此分店禁用自助认证功能
          </span>
        </div>
        {
          auto_auth && <KBButton className="certain-btn" disabled={submitting} onClick={this.downloadQrcode}>下载自助认证二维码</KBButton>
        }
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let loc_id = location.id
  let auto_auth = location.auto_auth

  console.log(location)

  return {
    location,
    loc_id,
    auto_auth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationPublicForm)
