import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBImageCropperInput, KBButton } from 'components'
import { toImageProxyDirect } from 'utils/kbUtil'
import { notification } from 'antd'

var VisitWelcomeSetLogo = React.createClass({
  save(model) {
    const { saveAndUpdate } = this.props
    return saveAndUpdate(model, 'logo')
  },

  deleteCurrentLogo() {
    const {
      fields: { logo }
    } = this.props
    logo.onChange('')
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: { logo },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.save)}>
        <div className="visit-setting-block">
          <div className="title-container">
            <div className="title-and-des">
              <div className="title">Logo</div>
              <div className="des">你可以选择一个LOGO</div>
            </div>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBImageCropperInput
              aspect={false}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={logo}
              type="locations"
              accept="image/*"
              failureCallBack={this.failureCallBack}
              isPublic
            >
              <div className="upload-portrait">
                {logo && logo.value ? (
                  <img
                    src={toImageProxyDirect(logo.value, 190, 190)}
                    style={{
                      width: '190px',
                      height: '190px',
                      display: 'inline-block',
                      marginLeft: 0,
                      borderRadius: 0
                    }}
                    alt=""
                  />
                ) : (
                  ''
                )}
                <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                  <button
                    className="bordered-btn"
                    style={{ marginLeft: 0 }}
                    type="button"
                  >
                    {logo.dirty ? '图片已上传' : '上传图片'}
                  </button>
                  {logo && logo.value ? (
                    <span
                      className="delete-opt"
                      onClick={e => {
                        this.deleteCurrentLogo()
                        e.stopPropagation()
                      }}
                    >
                      <i className="iconfont icon-delete" />
                      删除
                    </span>
                  ) : null}
                </div>
              </div>
            </KBImageCropperInput>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { welcome } = props
  let { logo } = welcome

  return {
    initialValues: {
      logo: logo || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'VisitWelcomeSetLogoForm',
  fields: ['logo'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(VisitWelcomeSetLogo)
